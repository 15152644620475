import React, { useState, useRef } from "react";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import BallotTable from "../ballot/listing/BallotTable";

const CommitteeTabBallots: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [isDefaultConfiguration, setIsDefaultConfiguration] =
    useState<boolean>(true);

  return (
    <>
      <BallotTable isDefaultConfiguration={isDefaultConfiguration} />
    </>
  );
};

export default CommitteeTabBallots;
