import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BallotCommitteeReports } from "../types/BallotReport";
import Table from "../../common/Table";
import { Button } from "primereact/button";
interface CommitteeCardProps {
  title: string;
  committeeReports: BallotCommitteeReports[] | [];
  filterCondition: (report: BallotCommitteeReports) => boolean;
  setActiveIndex?: any;
  primaryCommittee: string | null | undefined;
  columns: any[];
  isPrint?: boolean;
}

const BallotReportCommitteeCard: React.FC<CommitteeCardProps> = ({
  title,
  committeeReports = [],
  filterCondition,
  primaryCommittee,
  columns,
  isPrint = false,
}) => {
  const [t] = useTranslation("ballot");
  const { id } = useParams();
  const navigate = useNavigate();

  const handleViewComment = async () => {
    navigate(`/ballot/${id}`, { state: { activeTab: "viewcomment" } });
  };
  return (
    <>
      {committeeReports?.length > 0 && (
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {title}
            </h2>
          </div>
          {committeeReports
            .filter(filterCondition)
            .map((ballotreportData, index) => (
              <div
                className={`cardBody flex flex-column gap-7 ${isPrint ? "p-2" : "p-5"}`}
                key={index}
              >
                <div className="">
                  <div className="flex flex-wrap align-items-center justify-content-between gap-4 mb-4">
                    <h3 className="text-title text-lg text-capitalize m-0">
                      <span className="font-bold mr-2">
                        {ballotreportData.CommitteeName}
                      </span>
                      {ballotreportData.CommitteeName === primaryCommittee && (
                        <span className="font-normal">
                          ({t("ballot.primaryResponsibleCommittee")})
                        </span>
                      )}
                    </h3>
                    <h4 className="text-lg font-bold m-0">
                      {ballotreportData.VoteRecivedByCommittee}/
                      {ballotreportData.TotalMemberInvitedByCommittee}{" "}
                      {t("ballot.memberVoted")}
                    </h4>
                  </div>
                  <Table
                    isPrint={isPrint}
                    products={ballotreportData?.BallotCommitteeDetailReportList}
                    loading={false}
                    columns={columns}
                    disablePagination={true}
                  />
                  {!isPrint && (
                    <Button
                      text
                      className="p-button-plain underline"
                      label={t("ballot.viewComments")}
                      onClick={handleViewComment}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default BallotReportCommitteeCard;
