import axoisInstance from "../Standard/axiosInstanceStandard";

export async function fetchData(endpoint: string, params: any) {
  try {
    console.log(params)
    const response = await axoisInstance.get(endpoint, params);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function postData(endpoint: string, data?: any, postData?: any) {
  try {
    const response = await axoisInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}

export async function putData(
  endpoint: string,
  id: string | undefined,
  data: any,
  type?: string,
) {
  try {

    if (id && !type) {
      const response = await axoisInstance.put(`/${endpoint}/${id}`, data);
      return response.data;
    } else if (type) {
      const response = await axoisInstance.put(
        `/${endpoint}?${type}=${id}`,
        data,
      );
      return response.data;
    } else if (!id && data !== undefined) {
      const response = await axoisInstance.put(`/${endpoint}`, data);
      return response.data;
    } else {
      const response = await axoisInstance.put(`/${endpoint}`);
      return response.data;
    }
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
}

export async function deleteData(endpoint: string, id: string) {
  try {
      const response = await axoisInstance.delete(`/${endpoint}/${id}`);
      return response.data;
  } catch (error) {
      console.error("Error deleting data:", error);
      throw error;
  }
}
