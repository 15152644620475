import React, { useState, useEffect } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData } from "../../../services/apiService";

interface CommitteeData {
  committeeId: string;
  userProfileIds: string[];
}

const CommitteeForm: React.FC<{
  onDataChange: (data: CommitteeData[]) => void;
}> = ({ onDataChange }) => {
  const [committeeList, setCommitteeList] = useState<CommitteeData[]>([
    { committeeId: "", userProfileIds: [] },
  ]);
  const [committeeListing, setCommitteeListing] = useState<any[]>([]);
  const [membersByCommittee, setMembersByCommittee] = useState<
    Record<string, any[]>
  >({});
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();

  // Perform language action
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  // Fetch committee data for the dropdown
  useEffect(() => {
    const fetchCommitteeData = async () => {
      try {
        const response = await fetchData(
          `Committee/GetCommitteesList?PageIndex=-1`
        );
        const formattedList = response.Collection.map((item: any) => ({
          label: item.Name,
          value: item.Id,
        }));
        setCommitteeListing(formattedList);
      } catch (err) {
        console.error("Error fetching committees:", err);
      }
    };

    fetchCommitteeData();
  }, []);

  // Fetch members based on the selected committee
  const fetchMembersByCommittee = async (committeeId: string) => {
    try {
      const response = await fetchData(
        `CommitteeMembership/GetListByParentId?parentId=${committeeId}`
      );
      return response.collection.map((member: any) => ({
        label: member.userProfile.displayName,
        value: member.userProfileId,
      }));
    } catch (error) {
      console.error("Error fetching members:", error);
      return [];
    }
  };

  // Handle dropdown changes
  const handleCommitteeChange = async (
    e: DropdownChangeEvent,
    index: number,
    field: "committeeId" | "userProfileIds"
  ) => {
    const updatedList = [...committeeList];
    updatedList[index] = { ...updatedList[index], [field]: e.value };

    if (field === "committeeId" && e.value) {
      const members = await fetchMembersByCommittee(e.value);
      updatedList[index].userProfileIds = []; // Clear previous members
      setMembersByCommittee((prev) => ({ ...prev, [e.value]: members }));
    }

    setCommitteeList(updatedList);
    onDataChange(updatedList); // Notify parent of changes
  };

  // Add a new committee
  const handleAddCommittee = () => {
    const updatedList = [
      ...committeeList,
      { committeeId: "", userProfileIds: [] },
    ];
    setCommitteeList(updatedList);
    onDataChange(updatedList); // Notify parent of changes
  };

  return (
    <div className="flex flex-column gap-2 w-full mb-5">
      <div className="grid grid-xl mt-0">
        <span className="xl:col-3 font-bold">
          {t("committee.selectCommittee")}
        </span>
        <span className="xl:col-3 font-bold">{t("committee.members")}</span>
      </div>

      {committeeList.map((committee, index) => (
        <div key={index} className="grid grid-xl align-items-center">
          <div className="xl:col-3">
            <Dropdown
              value={committee.committeeId}
              onChange={(e) => handleCommitteeChange(e, index, "committeeId")}
              options={committeeListing}
              optionLabel="label"
              placeholder={t("committee.selectCommittee")}
            />
          </div>
          <div className="xl:col-3">
            <MultiSelect
              value={committee.userProfileIds}
              options={membersByCommittee[committee.committeeId] || []}
              optionLabel="label"
              onChange={(e) => {
                const updatedList = [...committeeList];
                updatedList[index].userProfileIds = e.value;
                setCommitteeList(updatedList);
              }}
              placeholder={t("committee.selectMembers")}
              display="chip"
            />
          </div>
        </div>
      ))}

      <Button
        text
        className="p-button-plain gap-1 pl-0 underline"
        onClick={handleAddCommittee}
      >
        <FeatherIcon name="plus" size={20} />
        <span className="font-bold">{t("committee.addCommittee")}</span>
      </Button>
    </div>
  );
};

export default CommitteeForm;
