import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../services/apiService";
import { formatCommitteeData } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { usePermission } from "../auth/Authorization";
import { MultiSelect } from "primereact/multiselect";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import DataGrid from "../common/DataGrid";
import { putData } from "../../services/Standard/apiServiceStandard";
import { RootState } from "../../store/store";
import { setToast } from "../../slices/toastSlice";
import { getContibutionData } from "./utills/standardUtills";
import { useForm } from "react-hook-form";
import {
  setActiveStep,
  setContributionCommitteeId,
} from "../../slices/createStandard";

interface ContirubutionCommmiteeProps {
  onNext: (data: Record<string, any>) => void;
  hierarchyData?: any; // Make it optional
}

export interface ContributionCommitteeRef {
  submit: () => void;
}

const ContributingCommittee = forwardRef<
  ContributionCommitteeRef,
  ContirubutionCommmiteeProps
>(({ onNext, hierarchyData }, ref) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // const ContributingCommittee: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const [commiiteeList, setCommiteeList] = useState([]);
  const [ContributingCommittees, setContributingCommittees] = useState([]);
  const [heirarchyLevelCheckboxChecked, setHeirarchyLevelCheckboxChecked] =
    useState<boolean>(false);
  const [allCommittees, setAllCommittees] = useState<boolean>(false);
  const [parentCommitteeCheckboxChecked, setParentCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [staffCheckboxChecked, setStaffCheckboxChecked] =
    useState<boolean>(false);
  const [isbalanceCheckboxChecked, setIsBalanceCheckboxChecked] =
    useState<boolean>(false);
  const [isbalanceYesChecked, setIsbalanceYesChecked] =
    useState<boolean>(false);
  const [isbalanceNoChecked, setIsbalanceNoChecked] = useState<boolean>(false);
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [selectedHeirarchyLevels, setSelectedHeirarchyLevels] = useState<any[]>(
    []
  );
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  //Pagination
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortField, setSortField] = useState<string>("CreatedDate");
  const [userRole, setUserRole] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const dispatch = useDispatch();
  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getFilter = async () => {
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: any }[] = [];
    const userProfileID = localStorage.getItem("userProfileID");

    if (heirarchyLevelCheckboxChecked) {
      const heirarchylevelIds = selectedHeirarchyLevels.map(
        (heirarchy) => heirarchy.Id
      );
      filters.push({
        Key: "HierarchyLevelId",
        Value: heirarchylevelIds.join(","),
      }); //check this
    }

    if (
      isbalanceCheckboxChecked &&
      (isbalanceYesChecked || isbalanceNoChecked)
    ) {
      filters.push({
        Key: "IsBalanced",
        Value: isbalanceYesChecked ? "true" : "false",
      });
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({
        Key: "RequestedOn",
        Value: `${fromDateStr},${toDateStr}`,
      });
    }

    if (userRole === "Staff") {
      filters.push({ Key: "StaffId", Value: userProfileID });
    } else if (userRole === "Staff") {
      filters.push({ Key: "UserProfileId", Value: userProfileID });
    }
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = pageIndex;
    queryParams["PageSize"] = pageSize;
    queryParams["SearchText"] = debouncedSearchTerm;
    queryParams["SortBy"] = sortField;
    queryParams["SortDir"] = sortOrderValue;
    return queryParams;
  };

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  const getCommiteeData = async () => {
    setLoading(true);
    const getParams = await getFilter();
    try {
      const committeeResponse = await fetchData(
        `Committee/GridSearch?${new URLSearchParams(getParams).toString()}`
      );
      setTotalCount(committeeResponse?.TotalCount);
      const committeeData = formatCommitteeData(committeeResponse.Collection);
      setCommiteeList(committeeData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 500); // 500ms delay
    // Clear the timeout if searchTerm changes (i.e. user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (userRole) {
      getCommiteeData();
    }
  }, [
    userRole,
    pageSize,
    pageIndex,
    debouncedSearchTerm,
    sortField,
    sortOrder,
  ]);

  const onSubmit = async (data: Record<string, any>) => {
    if (ContributingCommittees.length) {
      let contributeData = getContibutionData(
        ContributingCommittees,
        commiiteeList
      );
      dispatch(setContributionCommitteeId(contributeData));
      setLoading(true);
      const contributingCommittees = {
        ContributingCommittees,
      };
      try {
        let createStandard = await putData(
          "Standard/UpdateContributingMember",
          createRequestDetail?.id,
          contributingCommittees
        );
        setToast({
          message: t("standard.standardContributeCommiteeSuccess"),
          severity: "success",
        });
        dispatch(setActiveStep(3));
        onNext(ContributingCommittees);
      } catch (error: any) {
        dispatch(setToast({ message: error.message, severity: "error" }));
        dispatch(setActiveStep(3));
        onNext([]);
      } finally {
        setLoading(false);
      }
    } else {
      onNext([]);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit(onSubmit)();
    },
  }));

  const handleContirbution = (contributionData: any) => {
    const filteredCommittees = contributionData.filter(
      (committee: any) =>
        committee.CanVote || committee.MustVote || committee.CanComment
    );
    let data: any = filteredCommittees.map((element: any) => {
      return {
        CommitteeId: element.CommitteeId,
        CanVote: element.CanVote ? element.CanVote : false,
        MustVote: element.MustVote ? element.MustVote : false,
        CanComment: element.CanComment ? element.CanComment : false,
      };
    });
    setContributingCommittees(data);
  };

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setDateActivationFrom(null);
      } else {
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setDateActivationTo(null);
      } else {
        setDateActivationTo(date);
      }
    }
  };

  const handleCheckboxChange =
    (checkbox: string) => async (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "heirarchylevel":
          setHeirarchyLevelCheckboxChecked(isChecked);
          break;
        case "parentcommittee":
          setParentCommitteeCheckboxChecked(isChecked);
          break;
        case "staffid":
          setStaffCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        case "isbalance":
          setIsBalanceCheckboxChecked(isChecked);
          if (!isChecked) {
            setIsbalanceYesChecked(false);
            setIsbalanceNoChecked(false);
          }
          break;
        case "allCommittees":
          setAllCommittees(isChecked);
          setLoading(true);
          break;
        default:
          break;
      }
    };

  const fetchFilteredData = async (event: any) => {
    setIsFilterApplied(true);
    if (pageIndex !== 1) {
      setPageIndex(1);
    } else {
      getCommiteeData();
    }
    overlayPanelRef.current?.toggle(event);
  };

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column gap-2 px-5 py-4 border-bottom-1 border-gray-200 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("standard.contributingCommittee")}
            </h2>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>

          <div className="p-5 cardBody">
            <div className="flex justify-content-between align-items-center pb-3 gap-3 w-full">
              <IconField iconPosition="left" className="w-full md:w-16rem">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  id="searchCommittee"
                  placeholder={t("standard.searchCommittee")}
                  className="w-full"
                  aria-label={t("standard.searchCommittee")}
                />
              </IconField>

              <div className="relative">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleFilterPanel}
                  aria-label={t("standard.filter")}
                >
                  <FeatherIcon name="filter" size={20} color="inherit" />
                  <span className="font-bold text-capitalize hidden md:flex">
                    {t("standard.filter")}
                  </span>
                </Button>
                {/* OverlayPanel for Filter */}
                <OverlayPanel
                  ref={overlayPanelRef}
                  onHide={() => setShowFilterPanel(false)}
                  appendTo={"self"}
                  dismissable
                  className="p-overlaypanel-filter left-auto right-0 top-100"
                  aria-labelledby="filter-heading"
                  role="dialog"
                >
                  <div className="flex flex-column gap-1" tabIndex={0}>
                    <p
                      id="filter-heading"
                      className="text-lg font-bold m-0 p-3"
                    >
                      {t("standard.filter")}
                    </p>
                    <div className="filter-wrap">
                      <div className="">
                        <label
                          htmlFor="hierarchylevelFilter"
                          className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                        >
                          <Checkbox
                            inputId="hierarchylevelFilter"
                            onChange={handleCheckboxChange("heirarchylevel")}
                            checked={heirarchyLevelCheckboxChecked}
                            className="mr-2"
                            tabIndex={0}
                          />
                          {t("standard.committeeLevel")}
                        </label>
                        <div className="pr-3 py-2 pl-6 w-full relative">
                          <MultiSelect
                            value={selectedHeirarchyLevels}
                            onChange={(e: any) =>
                              setSelectedHeirarchyLevels(e.value)
                            }
                            options={hierarchyData}
                            optionLabel="Name"
                            filter
                            placeholder={t("standard.committeeLevel")}
                            maxSelectedLabels={3}
                            className="w-full"
                            aria-label={t("standard.committeeLevel")}
                          />
                        </div>
                      </div>

                      <div className="p-0">
                        <label
                          htmlFor="requestDateFilter"
                          className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                        >
                          <Checkbox
                            inputId="requestDateFilter"
                            onChange={handleCheckboxChange("date")}
                            checked={dateCheckboxChecked}
                            className="mr-2"
                            tabIndex={0}
                          />
                          {t("standard.createdOn")}
                        </label>
                        <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                          <Calendar
                            className="custom-datepicker"
                            inputId="dateActivationFrom"
                            onChange={(e) =>
                              handleDateSelect(
                                e.value as Date,
                                "dateActivationFrom"
                              )
                            }
                            value={dateActivationFrom}
                            dateFormat="mm/dd/yy"
                            tabIndex={0}
                            ariaLabel={t("standard.fromDate")}
                            placeholder={t("standard.fromDate")}
                            maxDate={
                              dateActivationTo
                                ? (dateActivationTo as Date)
                                : undefined
                            }
                          />
                          <Calendar
                            className="custom-datepicker"
                            inputId="dateActivationTo"
                            onChange={(e) =>
                              handleDateSelect(
                                e.value as Date,
                                "dateActivationTo"
                              )
                            }
                            value={dateActivationTo}
                            dateFormat="mm/dd/yy"
                            tabIndex={0}
                            ariaLabel={t("standard.toDate")}
                            placeholder={t("standard.toDate")}
                            minDate={
                              dateActivationFrom
                                ? (dateActivationFrom as Date)
                                : undefined
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      className="button-md w-full justify-content-center"
                      onClick={(e: any) => fetchFilteredData(e)}
                    >
                      {t("standard.applyFilter")}
                    </Button>
                  </div>
                </OverlayPanel>
              </div>
            </div>
            <DataGrid
              loading={loading}
              products={commiiteeList}
              columns={[
                {
                  field: "CommitteeName",
                  header: t("standard.committeeName"),
                },
                { field: "PrimaryStaff", header: t("standard.primaryStaff") },
                {
                  field: "CommitteeLevel",
                  header: t("standard.committeeLevel"),
                },
                { field: "MustVote", header: t("standard.mustVote") },
                { field: "CanVote", header: t("standard.canVote") },
                { field: "CanComment", header: t("standard.canComment") },
              ]}
              pageIndex={pageIndex}
              totalRecords={totalCount}
              pageSize={pageSize}
              onPageChange={pageHandler}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortChange={sortHandler}
              onChangeContribution={handleContirbution}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default ContributingCommittee;
