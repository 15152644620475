import React, { useState, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import {
  fetchData,
  deleteData,
} from "../../services/Standard/apiServiceStandard";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import pdfIcon from "../../assets/icons/fileIcon.svg";

interface DocumentUpload {
  containerName: string;
  sasToken: string;
  storageAccountName: string;
}

interface Document {
  id: string;
  name: string;
  blobURL: string;
}

interface Props {
  standardDocumentType: string;
  StandardId: string;
  showNoDataMessage?: boolean;
  showDeleteIcon?: boolean; // Optional prop to indicate whether to show "No Data Available" message or not
}

const DocumentList: React.FC<Props> = ({
  standardDocumentType,
  StandardId,
  showNoDataMessage = true,
  showDeleteIcon = true, // Default value is true
}) => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const [documentDetails, setDocumentDetails] = useState<Document[] | null>(
    null
  );
  const [documentUploadData, setDocumentUploadData] = useState<
    DocumentUpload | undefined
  >();

  const handleGetAllDocumentUpload = async () => {
    try {
      const response = await fetchData(
        `StandardDocument/GetStandardDocument/${StandardId}`,
        {}
      );
      const documentData: Document[] = response;
      setDocumentDetails(updateDocumentNames(documentData));
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const updateDocumentNames = (documents: Document[]): Document[] => {
    const nameCount: { [key: string]: number } = {};
    const updatedDocuments = documents.map((doc) => {
      if (nameCount[doc.name]) {
        nameCount[doc.name]++;
      } else {
        nameCount[doc.name] = 1;
      }

      if (nameCount[doc.name] > 1) {
        doc.name += ` (${nameCount[doc.name]})`;
      }
      return doc;
    });

    return updatedDocuments;
  };

  const handleFetchUserDocument = async () => {
    try {
      const getTokeInfo = await fetchData(
        "/StandardDocument/GetStandardDocumentValetKey",
        { StandardId, standardDocumentType }
      );
      setDocumentUploadData(getTokeInfo);
      return getTokeInfo;
    } catch (error) {
      console.error("Error fetching user document:", error);
    }
  };

  const downloadDocument = async (blobUrl: string) => {
    try {
      const documentData = await handleFetchUserDocument();
      const sasToken = documentData?.sasToken;
      if (sasToken) {
        const urlWithToken = `${blobUrl}?${sasToken}`;
        window.open(urlWithToken, "_blank");
      } else {
        console.error(
          "SAS token is not available. Cannot download the document."
        );
      }
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const confirmDeleteDocument = (documentId: string) => {
    confirmDialog({
      header: t("profile.confirmation"),
      message: t("profile.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("profile.yes"),
      rejectLabel: t("profile.no"),
      defaultFocus: "accept",
      accept: () => handleDeleteDocument(documentId),
    });
  };

  const handleDeleteDocument = async (documentId: string) => {
    try {
      if (documentId) {
        await deleteData("StandardDocument", documentId);
        await handleGetAllDocumentUpload();
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  useEffect(() => {
    handleGetAllDocumentUpload();
  }, [StandardId]);

  return (
    <>
      <ConfirmDialog />
      <div className="">
        {documentDetails && documentDetails.length > 0
          ? documentDetails.map((document: Document, index: number) => (
              <div key={index} className="flex flex-column gap-5">
                <div className="flex flex-column gap-3">
                  <p className="m-0 flex align-items-center gap-2">
                    <span
                      className="cursor-pointer flex align-items-center"
                      onClick={() => downloadDocument(document.blobURL)}
                    >
                      <img src={pdfIcon} alt="PDF" className="mr-2" />
                      <span className="underline text-charcoal">
                        {document?.name}
                      </span>
                    </span>
                    {showDeleteIcon && (
                      <Button
                        type="button"
                        text
                        className="p-button-plain gap-2 text-delete underline"
                        onClick={() => confirmDeleteDocument(document.id)}
                        aria-label="Delete"
                      >
                        <FeatherIcon name="trash-2" size={20} />
                      </Button>
                    )}
                  </p>
                </div>
              </div>
            ))
          : showNoDataMessage && <p className="m-0">No Data Available</p>}
      </div>
    </>
  );
};

export default DocumentList;
