import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { BlobServiceClient } from "@azure/storage-blob";
import { useLanguageContext } from "../../../LanguageContext";
import { fetchData as getData } from "../../../../services/apiService";
import { fetchData, postData} from "../../../../services/Ballot/apiservice";
import BallotReportRecord from "../../common/BallotReportRecord";
import BallotReportVotingCriteria from "../../common/BallotReportVotingCriteria";
import BallotCommitteeReport from "../../common/BallotCommitteeReport";
import { BallotReport } from "../../types/BallotReport";
import pdfIcon from "../../../../assets/icons/pdf.svg";
import BallotClosureReportPrint from "../../Print/BallotClosureReportPrint";

interface BallotTabClosureReportProps {
  setActiveIndex: (index: number) => void;
}

const BallotTabClousureReport: React.FC<BallotTabClosureReportProps> = ({setActiveIndex}) => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [ ballotDetails, setBallotDetails] = useState<any>([]);
  const { selectedLanguage } = useLanguageContext();
  const [ballotRecordData, setBallotRecordData] = useState<any>(null);
  const [documentTypeId, setDocumentTypeId] = useState<string>("");
  const [ballotReport, setBallotReport] = useState<BallotReport | null>(null);
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [ballotReport, ballotDetails, recordList, documentType] = await Promise.all([
          fetchData(`BallotRecordVote/BallotReports/${id}`),
          fetchData(`Ballot/${id}`),
          fetchData(`BallotRecord/BallotClosureRecordlistDetails/${id}`),
          getData(`DocumentType/GetAll`)
        ]);
      setBallotDetails(ballotDetails);
      setBallotReport(ballotReport);
      const filteredArray = documentType.Collection.filter((item: any) => item.TypeName === "BallotClosuredocument");
      if (documentType?.Collection && Array.isArray(documentType.Collection)) {
        const filteredArray = documentType.Collection.filter((item: any) => item.TypeName === "BallotClosuredocument");
        if (filteredArray.length > 0) {
          setDocumentTypeId(filteredArray[0].Id);
        }
      }
      if (recordList) {
        const sortedData = recordList.collection.map((item:  any) => ({
          recordNumber: item.recordNumber,
          committeeName: item.committeeName,
          oldStatusName: item.previousRecordStatusName,
          newStatusName: item.recordStatusName,
          recordId: item.recordId
        }));
        setBallotRecordData(sortedData);
      }
      } catch {
        console.error("");
      }
    };
    fetchInitialData();
  }, []);

 const initializeBlobService = async (documentResponse: any) => {
  const connectionString = `https://${documentResponse?.storageAccountName}.blob.core.windows.net?${documentResponse?.sasToken}`;
  const blobServiceClient = new BlobServiceClient(connectionString);
  return blobServiceClient;
};

const handleFetchUserDocument = async (BallotId: string | undefined) => {
  try {
    const documentResponse = await fetchData(
      `BallotDocument/GetBallotDocumentValetKey?ballotId=${BallotId}&userDocumentType=BallotDocument`
    );
    return documentResponse;
  } catch (error) {
    console.error("Error fetching user document:", error);
  }
};

 const handleDownload = async () => {
  const element = componentRef.current;
  if (element) {
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL('image/png'); 
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('BallotClosureReport.pdf');
    const pdfBlob = pdf.output('blob');
    const formData = new FormData();
    formData.append('file', pdfBlob, 'BallotClosureReport.pdf'); 
    try {
      const documentResponse = await handleFetchUserDocument(id);
      const blobService =
      await initializeBlobService(documentResponse);
      const containerName = documentResponse.containerName;
      const subContainerName = containerName.substring(0, containerName.length - 1);
      const containerClient = blobService.getContainerClient(subContainerName);
      const currentTime = Date.now();
      const blobName = `${currentTime}_BallotClosureReport.pdf`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const modifiedBlobUrl = blockBlobClient.url;
      if (modifiedBlobUrl) {
        const userDocumentData = {
          isDirty: true,
          isNew: true,
          isActive: true,
           ballotId: id,
          documentName: "BallotClosureReport",
          documentTypeId: documentTypeId,
          blobURL: modifiedBlobUrl,
        };
        await postData("BallotDocument", userDocumentData);
      }
    } catch (error) {
      console.error('Error uploading PDF', error);
    }
  }
};

  return (
    <>
      <div>
        <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
          <BallotClosureReportPrint ref={componentRef} />
        </div>
        <BallotReportVotingCriteria ballotTotalMember={ballotReport?.TotalMemberInvited} ballotVoteReceived={ballotReport?.VoteRecived} />
        <BallotReportRecord recordLists={ballotRecordData} isBallotClosure={false} oldStatusFalse={true}  />
        <BallotCommitteeReport isReport={true} isBallotClosure={false}  committeeReports={ballotReport?.ballotCommitteeReports ?? []} primaryCommittee={ballotDetails.Committee?.Name ?? ""} setActiveIndex={setActiveIndex}  />
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.closureReport")}
            </h2>
          </div>

          <div className="p-5 cardBody flex flex-column gap-7">
            <a onClick={handleDownload} className="m-0 flex align-items-center gap-2 underline">
              <img src={pdfIcon} alt="PDF" />
              <span className="text-link font-bold text-capitalize">
                {t("ballot.closureReport")} - 987632
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BallotTabClousureReport;