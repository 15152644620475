import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // PrimeReact theme
import "primereact/resources/primereact.min.css"; // PrimeReact core styles
import "primeicons/primeicons.css"; // PrimeIcons
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import { useTranslation } from "react-i18next";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import { fetchData, postData } from "../../../services/apiService";
import Conference from "./Conference";
import CommitteeAddMeeting from "./CommitteeAddMeeting";
import { createJsonData } from "./utils";

interface Option {
  name: string;
  code: string;
}

interface Meeting {
  meetingName?: string;
  meetingStartTime?: string;
  meetingEndTime?: string;
  meetingLocation?: string;
  meetingAgenda?: string;
  meetingNumber?: string;
  meetingStatusId?: string;
  committeeConferenceId?: string | null;
  meetingTypeId?: string;
  meetingCommitteeDTO?: {
    committeeId: string;
    userProfileIds: string[];
  }[];
}

export interface ConferenceFormData {
  conferenceName: string;
  conferenceStartDate: Date | null;
  conferenceEndDate: Date | null;
  meetingTypeId: string;
  conferenceStatusId: string;
  conferenceNumber: string;
  committeeMeetings?: {
    meetingName: string;
    meetingStartTime: string;
    meetingEndTime: string;
    meetingLocation: string;
    meetingAgenda: string;
    meetingStatusId: string;
    meetingNumber: string;
    meetingTypeId: string;
    meetingCommitteeDTO: {
      committeeId: string;
      userProfileIds: string[]; // Array of strings for userProfileIds
    }[]; // An array of objects for meetingCommitteeDTO
  }[][]; // Array of arrays for committeeMeetings
}

// Helper function to calculate the number of days between two dates
const calculateDays = (startDate: Date, endDate: Date) => {
  const timeDiff = endDate.getTime() - startDate.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // inclusive
};

const CommitteeAddConference: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [conferenceExists, setConferenceExists] = useState(false);
  const [meetingsByDay, setMeetingsByDay] = useState<any[]>([]); // Store meetings for each day
  const [conferenceDays, setConferenceDays] = useState<number>(0);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [selectedMeetingType, setSelectedMeetingType] = useState<Option | null>(
    null
  );
  const [options, setOptions] = useState([]);
  const [disableDay, setDisableDay] = useState<boolean[]>([]);
  const [formData, setFormData] = useState<ConferenceFormData>({
    conferenceName: "",
    conferenceStartDate: null,
    conferenceEndDate: null,
    meetingTypeId: "",
    conferenceStatusId: "",
    conferenceNumber: "",
    committeeMeetings: [
      [
        {
          meetingName: "",
          meetingStartTime: "",
          meetingEndTime: "",
          meetingLocation: "",
          meetingAgenda: "",
          meetingStatusId: "",
          meetingNumber: "",
          meetingTypeId: "",
          meetingCommitteeDTO: [
            {
              committeeId: "",
              userProfileIds: [""],
            },
          ],
        },
      ],
    ],
  });

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [committeeData, setCommitteeData] = useState<any[]>([]);
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // Callback function to receive data from CommitteeForm
  const handleCommitteeDataChange = (data: any[]) => {
    setCommitteeData(data); // Update state with data from CommitteeForm
  };

  useEffect(() => {
    if (startDate && endDate) {
      const totalDays = calculateDays(startDate, endDate);
      setConferenceDays(totalDays);

      const initialMeetings = Array.from({ length: totalDays }, () => [
        {
          meetingName: "",
          meetingStartTime: "",
          meetingEndTime: "",
          meetingLocation: "",
          meetingAgenda: "",
          meetingNumber: "",
          meetingStatusId: "",
          committeeConferenceId: null,
          meetingTypeId: "",
          meetingCommitteeDTO: committeeData.map((committee) => ({
            committeeId: committee.committeeId,
            userProfileIds: committee.members || [],
          })),
        },
      ]);

      const initialDisableState = Array(totalDays).fill(false);

      setMeetingsByDay(initialMeetings);
      setDisableDay(initialDisableState);
    }
  }, [startDate, endDate, committeeData]);

  const handleMeetingTypeChange = (e: DropdownChangeEvent) => {
    const selectedOption = e.value;

    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        meetingTypeId: selectedOption.id,
      };
      return updatedFormData;
    });

    setSelectedOption(selectedOption.id);
    setSelectedMeetingType(selectedOption);
  };

  const handleChildDataChange = (updatedData: Partial<ConferenceFormData>) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        conferenceName:
          updatedData.conferenceName || prevFormData.conferenceName,
        conferenceStartDate:
          updatedData.conferenceStartDate ?? prevFormData.conferenceStartDate,
        conferenceEndDate:
          updatedData.conferenceEndDate ?? prevFormData.conferenceEndDate,
        meetingTypeId: updatedData.meetingTypeId || prevFormData.meetingTypeId,
        conferenceStatusId:
          updatedData.conferenceStatusId || prevFormData.conferenceStatusId,
        conferenceNumber:
          updatedData.conferenceNumber || prevFormData.conferenceNumber,
        committeeMeetings:
          updatedData.committeeMeetings ?? prevFormData.committeeMeetings,
      };

      return updatedFormData;
    });
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};

    // Validate conferenceName
    // if (!formData.conferenceName) {
    //   errors.conferenceName = t("committee.conferenceNameRequired");
    // }

    // Validate startDate
    // if (!formData.conferenceStartDate) {
    //   errors.startDate = t("committee.startDateRequired");
    // }

    // Validate endDate
    // if (!formData.conferenceEndDate) {
    //   errors.endDate = t("committee.endDateRequired");
    // } else if (
    //   formData.conferenceStartDate &&
    //   formData.conferenceEndDate < formData.conferenceStartDate
    // ) {
    //   errors.endDate = t("committee.endDateValidation");
    // }

    // Validate meetingType
    // if (!formData.meetingTypeId) {
    //   errors.meetingType = t("committee.meetingTypeRequired");
    // }

    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form and set errors
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const jsonData = createJsonData(formData, options);
        const response = await postData("CommitteeConference", jsonData);
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const handleCommitteeType = async () => {
    const committeeType = await fetchData("MeetingType/GetAll");
    const formattedOptions = committeeType.Collection.map((item: any) => ({
      name: item.TypeName,
      id: item.Id,
    }));
    setOptions(formattedOptions);
  };

  useEffect(() => {
    handleCommitteeType();
  }, []);

  const renderSelectedComponent = () => {
    if (!selectedMeetingType || selectedMeetingType?.name === "Meeting") {
      return <CommitteeAddMeeting onDataChange={handleChildDataChange} />;
    }
    if (selectedMeetingType?.name === "Conference") {
      return (
        <Conference
          onDataChange={handleChildDataChange}
          formErrors={formErrors}
          onCommitteeDataChange={handleCommitteeDataChange}
        />
      );
    }

    return null;
  };

  return (
    <div>
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
          <Button
            text
            className="p-button-plain"
            aria-label={t("committee.back")}
            onClick={() => navigate("/committee")}
          >
            <FeatherIcon name="arrow-left" size={20} />
          </Button>
          <h1 className="text-title text-lg font-bold text-capitalize m-0">
            {t("committee.addMeeting")}
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-5 cardBody">
            <div className="grid grid-xl">
              <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                <label
                  htmlFor="meetingTypeId"
                  className={`block font-bold text-input-label capitalize ${formErrors.meetingTypeId ? "p-error" : ""}`}
                >
                  {t("committee.meetingType")}
                </label>
                <Dropdown
                  inputId="meetingTypeId"
                  name="meetingTypeId"
                  value={formData.meetingTypeId}
                  onChange={handleMeetingTypeChange}
                  options={options}
                  optionLabel="name"
                  placeholder={t("committee.selectAnOption")}
                />
                <span
                  id="meetingTypeError"
                  className={`p-error font-bold text-capitalize ${formErrors.meetingTypeId ? "" : "error-hidden"}`}
                >
                  {formErrors.meetingTypeId}
                </span>
              </div>
            </div>
            <div>{renderSelectedComponent()}</div>

            <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
              <Button
                label={t("committee.cancel")}
                className="button-md"
                severity="secondary"
                type="button"
                onClick={() => navigate("/committee/details")}
              />
              <Button
                label={t("committee.addMeeting")}
                className="button-md"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommitteeAddConference;
