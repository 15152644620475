import React, { useState, useRef, useEffect, act, useCallback } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { fetchData, putData } from "../../services/apiService";
import { getCommitteeData, sortByStartDate } from "../../utils/utils";
import { useLoadingContext } from "../Layout";
import {
  addMembersInCommitteeRequest,
  categoryOfInteresetRequest,
  configureCommitteeRequest,
  createCommitteeRequest,
} from "../../slices/createCommitteeSlice";
import { usePermission } from "../auth/Authorization";
import withLoader from "../common/LoaderIntercepter";
import { MultiSelect } from "primereact/multiselect";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { staffConfigureProfile } from "../../slices/createProfileSlice";
import { Menu } from "primereact/menu";
import { focusOnField } from "../../utils/focusField";
import { clearAllSessionStorage } from "../../utils/storage";
import { RadioButton } from "primereact/radiobutton";
import CustomMenu, { getStatusBadge } from "../common/customMenu";

interface Option {
  name: string;
  code: string;
}

interface RowData {
  id: string;
  name: string;
  status: string;
  StatusName: string;
  Id: string;
  committeeId: string;
  membershipExpiry: boolean;
}

const CommitteeTabRoster: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const [search, setSearch] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    terminatedThroughError: "",
    ballotOrMeetingNumberError: "",
    selectedReasonError: "",
    terminateValueError: "",
    remarkError: "",
  });
  const [ballotOrMeetingNumber, setBallotOrMeetingNumber] = useState("");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const toast = useRef<Toast>(null);
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [terminateValue, setTerminateValue] = useState("");
  const [terminatedThroughValue, setTerminatedThroughValue] = useState<
    string | null
  >(null);
  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];
  const [validationError, setValidationError] = useState("");
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );
  const [membershipStatus, setMembershipStatus] = useState([
    {
      StatusName: "",
      Id: "",
    },
  ]);
  const [committeeMemberDataForTable, setCommitteeMemberDataForTable] =
    useState([
      {
        id: "",
        memberName: "",
        name: "",
        position: "",
        categoryOf: "",
        tenure: "",
        startDate: "",
        endDate: "",
        StatusName: "",
        action: "",
        memberID: "",
        isMembershipExpiring: "",
      },
    ]);

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const dropdownOptions = [
    { label: "Direct Termination", value: "DirectTermination" },
    { label: "Meeting", value: "Meeting" },
    { label: "Ballot", value: "Ballot" },
  ];
  const navigate = useNavigate();
  const [memberShipId, setMemberShipId] = useState("");
  const [committeeId, setCommitteeId] = useState("");
  const [visibleTerminate, setVisibleTerminate] = useState(false);
  const [visibleEndMembership, setVisibleEndMembership] = useState(false);
  const [endMembershipValue, setEndMembershipValue] = useState("");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  // filter
  const [coiData, setCoiData] = useState([]);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [membershipStatusData, setMembershipStatusData] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [memberStatusCheckboxChecked, setMemberStatusCheckboxChecked] =
    useState<boolean>(false);
  const [startDateCheckboxChecked, setStartDateCheckboxChecked] =
    useState<boolean>(false);
  const [endDateCheckboxChecked, setEndDateCheckboxChecked] =
    useState<boolean>(false);
  const [selectedMemberStatuses, setSelectedMemberStatuses] = useState<any[]>(
    []
  );
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [terminateValidationError, setTerminateValidationError] = useState("");
  const [endMembershipValidationError, setEndMembershipValidationError] =
    useState("");
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel
  const [membershipFlag, setMembershipFlag] = useState(false);
  const [membershipExpires30DayCount, setMembershipExpires30DayCount] =
    useState<number>(0);
  const [noOfMember, setNoOfMember] = useState<number>(0);
  const [memberComapreToLastMonth, setMemberComapreToLastMonth] =
    useState<number>(0);
  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const handleViewMember = () => {
    navigate("/profile");
  };

  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  const fetchMembershipStatus = async () => {
    try {
      const response = await fetchData("MembershipStatus/GetAll");
      const memberstatus = response.Collection;
      const filteredStatus = memberstatus.filter((status: any) => {
        const removedStatusNames = [
          "Draft",
          "Submitted",
          "Rejected",
          "Withdrawn",
          "Convert to Record",
        ];
        return !removedStatusNames.includes(status.StatusName);
      });
      const modifiedStatus = filteredStatus.map((status: any) => {
        if (status.StatusName === "Terminate") {
          return { ...status, StatusName: "Terminated" };
        }
        if (status.StatusName === "Resign") {
          return { ...status, StatusName: "Resigned" };
        }
        return status;
      });
      setMembershipStatusData(modifiedStatus);
    } catch (error) {
      console.error("Error fetching membership status:", error);
    }
  };

  const handleDateSelect = (date: Date | null, field: string) => {
    focusOnField(field);
    if (field === "startDate") {
      setStartDate(date);
    } else if (field === "endDate") {
      setEndDate(date);
    }
  };

  const setError = useCallback(
    (field: any, errorMessage: any) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage,
      }));
    },
    [validationErrors]
  );

  const handleTerminatedThroughChange = (e: any) => {
    const value = e.value;
    setTerminatedThroughValue(value);
    setBallotOrMeetingNumber("");
    if (value === "Meeting" || value === "Ballot") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        ballotOrMeetingNumberError: "",
        terminatedThroughError: "",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        terminatedThroughError: "",
      }));
    }
  };

  useEffect(() => {
    //clearAllSessionStorage();
    sessionStorage.removeItem("editMemberCommittee");
    fetchCategoryOfInterest();
    fetchMembershipStatus();
  }, []);

  const handleCheckboxChange =
    (checkbox: string) => (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "status":
          setMemberStatusCheckboxChecked(isChecked);
          break;
        case "startdate":
          setStartDateCheckboxChecked(isChecked);
          break;
        case "enddate":
          setEndDateCheckboxChecked(isChecked);
          break;
        default:
          break;
      }
    };

  const fetchFilteredData = async (event: any) => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: string }[] = [];

    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (memberStatusCheckboxChecked) {
      const memberStatusIds = selectedMemberStatuses.map((status) => status.Id);
      filters.push({
        Key: "MembershipStatusId",
        Value: memberStatusIds.join(","),
      });
    }
    if (startDateCheckboxChecked) {
      const startDateStr = formatDate(startDate);
      filters.push({ Key: "StartDate", Value: `${startDateStr}` });
    }
    if (endDateCheckboxChecked) {
      const endDateStr = formatDate(endDate);
      filters.push({ Key: "EndDate", Value: `${endDateStr}` });
    }
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = -1;
    //queryParams["PageSize"] = 10;
    queryParams["SearchText"] = "";
    queryParams["SortBy"] = "";
    queryParams["SortDir"] = "asc";
    const apiUrl = `CommitteeMembership/Search?${new URLSearchParams(queryParams).toString()}`;
    try {
      const response = await fetchData(apiUrl);
      const filteredData = response;
      const committeeNames = committeeMemberDataForTable.map(
        (data: any) => data.name
      );
      const filteredByCommitteeName = filteredData.Collection.filter(
        (data: any) => committeeNames.includes(data.Committee.Name)
      );
      const filterMembershipId = filteredByCommitteeName.map(
        (data: any) => data.Id
      );
      const filterfromcommitteemember: any = committeeMemberDataForTable.filter(
        (data: any) => filterMembershipId.includes(data.memberId)
      );
      let sortedFilterMemberData = sortByStartDate(
        filterfromcommitteemember,
        "startDate"
      );
      setFilteredRecords(sortedFilterMemberData);
      setIsFilterApplied(true);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoading(false);
    }
    overlayPanelRef.current?.toggle(event);
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const showSuccessEndMembership = (msg: string) => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: msg,
      life: 3000,
    });
  };

  const showSuccessTerminatedMembership = (msg: string) => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: msg,
      life: 3000,
    });
  };

  const committeeDataHandler = async () => {
    let [
      committeeData,
      configurationData,
      memberData,
      categoryOfInteresetData,
    ] = await getCommitteeData();
    dispatch(createCommitteeRequest(committeeData));
    dispatch(configureCommitteeRequest(configurationData));
    dispatch(addMembersInCommitteeRequest(memberData));
    dispatch(categoryOfInteresetRequest(categoryOfInteresetData));
  };

  const handleTerminationDialog = (
    committeeMembershipId: string,
    committeeId: string
  ) => {
    setVisibleTerminate(true);
    setMemberShipId(committeeMembershipId);
    setCommitteeId(committeeId);
  };

  const handleEndMembershipDialog = (
    committeeMembershipId: string,
    committeeId: string
  ) => {
    setVisibleEndMembership(true);
    setMemberShipId(committeeMembershipId);
    setCommitteeId(committeeId);
  };

  const handleEndMembership = async () => {
    setEndMembershipValidationError("");
    if (!endMembershipValue.trim()) {
      setEndMembershipValidationError(`${t("committee.reasonEndMembership")}`);
      return;
    }
    setLoading(true);
    let reason = {
      remarkEndMembership: endMembershipValue,
    };

    if (memberShipId && committeeId) {
      try {
        const EndMember = await putData(
          "CommitteeMembership/EndCommitteeMembership",
          memberShipId,
          reason,
          "committeeMembershipId"
        );
        setLoading(false);
        setVisibleEndMembership(false);
        committeeDataHandler();
        setTimeout(() => {
          showSuccessEndMembership(
            `${t("committee.membershiphasEndedSuccessfully")}`
          );
          navigate(`/committee/details/${committeeId}`);
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleTermination = useCallback(async () => {
    let hasError = false;

    if (!terminateValue.trim()) {
      setError("remarkError", t("committee.reasonForTermination"));
      hasError = true;
    } else {
      setError("remarkError", "");
    }
    if (!terminatedThroughValue) {
      setError(
        "terminatedThroughError",
        t("committee.terminatedThroughRequired")
      );
      hasError = true;
    } else {
      setError("terminatedThroughError", "");
    }

    if (
      (terminatedThroughValue === "Ballot" ||
        terminatedThroughValue === "Meeting") &&
      !ballotOrMeetingNumber.trim()
    ) {
      setError(
        "ballotOrMeetingNumberError",
        terminatedThroughValue === "Meeting"
          ? t("committee.meetingNumberRequired")
          : t("committee.ballotNumberRequired")
      );
      hasError = true;
    } else {
      setError("ballotOrMeetingNumberError", "");
    }

    if (!selectedReason) {
      setError(
        "selectedReasonError",
        t("committee.selectReasonForTermination")
      );
      hasError = true;
    } else {
      setError("selectedReasonError", "");
    }

    if (hasError) return;

    const membershipTerminateBallotNumber =
      terminatedThroughValue === "Ballot" ? ballotOrMeetingNumber : null;
    const membershipTerminateMeetingNumber =
      terminatedThroughValue === "Meeting" ? ballotOrMeetingNumber : null;
    let reason = {
      reasonForTerminate: terminateValue,
      membershipTerminateBallotNumber: membershipTerminateBallotNumber,
      membershipTerminateMeetingNumber: membershipTerminateMeetingNumber,
      membershipTerminatIsLackOfParticipation:
        selectedReason === "lackOfParticipation",
      membershipTerminatIsDisciplinaryAction:
        selectedReason === "disciplinaryAction",
    };
    if (memberShipId && terminateValue) {
      try {
        const putDataResponse = await putData(
          `CommitteeMembership/TerminateCommitteeMembership?committeeMembershipId=${memberShipId}`,
          undefined,
          reason
        );
        setVisibleTerminate(false);

        setValidationError("");
        setSelectedReason("");
        setBallotOrMeetingNumber("");
        setTerminatedThroughValue("");
        setTerminateValue("");
        setValidationErrors({
          terminatedThroughError: "",
          ballotOrMeetingNumberError: "",
          selectedReasonError: "",
          terminateValueError: "",
          remarkError: "",
        });
        committeeDataHandler();
        setTimeout(() => {
          showSuccessTerminatedMembership(
            `${t("committee.memberHasTerminatedSuccessfully")}`
          );
          navigate(`/committee/details/${committeeId}`);
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    }
  }, [
    terminateValue,
    terminatedThroughValue,
    ballotOrMeetingNumber,
    selectedReason,
  ]);

  const actionPositionBodyTemplate = (rowData: RowData) => {
    // Initialize an empty array for action items
    let actionItems: MenuItem[] = [];

    // If membershipExpiry is true, only show "Re-Appointment"
    if (rowData.membershipExpiry === true) {
      actionItems = [
        {
          label: t("committee.reappointment"),
          command: () => {
            sessionStorage.setItem("reappointment", "true");
            navigate("/member/create");
          },
        },
      ];
    } else {
      actionItems = [
        {
          label: t("committee.viewMember"),
          command: () => handleViewMember(),
        },
        {
          label: t("committee.terminateMember"),
          command: () =>
            handleTerminationDialog(rowData.Id, rowData.committeeId),
        },
        {
          label: t("committee.endMembership"),
          command: () =>
            handleEndMembershipDialog(rowData.Id, rowData.committeeId),
        },
      ];

      actionItems = actionItems.filter((item) => {
        if (
          rowData.StatusName === "Retired" ||
          rowData.StatusName === "Pending" ||
          rowData.StatusName === "Terminated"
        ) {
          return (
            item.label !== t("committee.terminateMember") &&
            item.label !== t("committee.endMembership")
          );
        }
        return true;
      });
    }

    // Enable actions if userRole is Admin, Staff, or membershipExpiry is true
    const isActionEnabled =
      userRole === "Admin" ||
      userRole === "Staff" ||
      rowData.membershipExpiry === true;

    return (
      <CustomMenu
        actionItems={actionItems} // Only show "Re-Appointment" if membershipExpiry is true
        rowData={rowData}
        disabled={!isActionEnabled} // Actions are disabled based on conditions
      />
    );
  };

  const footerContentReject = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.cancel")}
        onClick={() => {
          setVisibleTerminate(false);
          setTerminateValidationError("");
          setSelectedReason("");
          setBallotOrMeetingNumber("");
          setTerminatedThroughValue("");
          setTerminateValue("");
          setValidationErrors({
            terminatedThroughError: "",
            ballotOrMeetingNumberError: "",
            selectedReasonError: "",
            terminateValueError: "",
            remarkError: "",
          });
          setValidationError("");
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.terminate")}
        onClick={handleTermination}
        className="button-md"
      />
    </div>
  );

  const footerContentEnd = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.cancel")}
        onClick={() => {
          setVisibleEndMembership(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.endMembership")}
        onClick={handleEndMembership}
        className="button-md"
      />
    </div>
  );

  const handleNewMember = () => {
    sessionStorage.setItem("editMemberCommittee", "On");
    navigate("/committee/members");
  };

  const formatRoasterData = (data: any) => {
    let formatedMembershipRecordData = data?.map((details: any) => {
      return {
        Id: details?.UserProfile.Id,
        UserProfileId: details?.UserProfileId,
        categoryOfInterestId: {
          name: details?.CategoryOfInterest.CategoryName,
          id: details.CategoryOfInterest.Id,
        },
        committeeID: details?.CommitteeId,
        endDate: details?.EndDate,
        isMembershipExpiring: details?.IsMembershipExpiring,
        memberId: details?.Id,
        membershipPositionId: {
          name: details?.MembershipPosition.PositionName,
          id: details?.MembershipPosition.Id,
        },
        membershipStatusId: details?.MembershipStatus.Id,
        Name: {
          name: details?.UserProfile.DisplayName,
          id: details?.UserProfile.UserId,
        },
        startDate: details?.StartDate,
        tenureLength: details?.TenureLength,
      };
    });
    return formatedMembershipRecordData;
  };

  const createRosterTable = async () => {
    const committeeId = sessionStorage.getItem("committeeId");
    const response = await fetchData(
      `CommitteeMembership/Search?Filters[0].Key=CommitteeId&Filters[0].Value=${committeeId}&PageIndex=-1`
    );
    const committeesData = response?.Collection;
    const requiredData = formatRoasterData(committeesData);
    const changeMemberObjToArray: any = Object.values(requiredData).map(
      (val) => val
    );
    setNoOfMember(changeMemberObjToArray.length);
    let currentStatusId = membershipStatus.filter(
      (val) => val.StatusName === "Current"
    );
    const expireCount = changeMemberObjToArray.filter(
      (val: any) =>
        isBeforeNext30Days(val.endDate) &&
        val.membershipStatusId === currentStatusId[0]?.Id
    ).length;
    const fromLastMonth = changeMemberObjToArray.filter((val: any) =>
      isDateFromLastMonth(val.startDate)
    ).length;
    setMemberComapreToLastMonth(fromLastMonth);
    setMembershipExpires30DayCount(expireCount);
    const sortedMembershipData = sortByStartDate(
      formatMembershipDataForTable(changeMemberObjToArray),
      "startDate"
    );

    setCommitteeMemberDataForTable(sortedMembershipData);
    setLoading(false);
  };
  const formatMembershipStatus = (data: any) => {
    return data.map((status: any) => {
      return {
        StatusName: status?.StatusName,
        Id: status?.Id,
      };
    });
  };

  const formatMembershipDataForTable = (data: any) => {
    const getStatusById = (Id: any) => {
      const statusName = membershipStatus.find((data) => data.Id === Id);
      if (statusName?.StatusName === "Terminate") {
        return "Terminated";
      }
      return statusName?.StatusName;
    };
    const committeeMember = data?.map((details: any) => {
      let fullName = "";
      let email = "";
      if (details?.Name?.name) {
        const name = details.Name.name.split("(");
        fullName = name[0] ? name[0].trim() : "";
        email = name[1] ? name[1].substring(0, name[1].length - 1).trim() : "";
      }

      return {
        memberName: fullName,
        name: createCommitteeData?.createCommittee?.committeeName,
        email: email,
        position: details?.membershipPositionId?.name,
        categoryOf: details?.categoryOfInterestId?.name,
        tenure: details?.tenureLength,
        startDate: details.startDate
          ? new Date(details.startDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "",
        endDate: details.endDate
          ? new Date(details.endDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "",
        StatusName: getStatusById(details?.membershipStatusId),
        action: t("committee.action"),
        memberId: details?.memberId,
        UserProfileId: details?.UserProfileId,
        Id: details?.Id,
        sessionKey: "committeeMembershipId",
        membershipExpiry: details.isMembershipExpiring,
        committeeId: details.committeeID,
      };
    });
    return committeeMember;
  };

  useEffect(() => {
    let path = sessionStorage.getItem("redirectionPath");
    if (path === "member") {
      showSuccessEndMembership(`${t("committee.memberAddedSuccessfully")}`);
      sessionStorage.removeItem("redirectionPath");
    }
    const getMembershipStatus = async () => {
      let membershipStatusResponse = await fetchData(`MembershipStatus/GetAll`);
      let formatStatus = formatMembershipStatus(
        membershipStatusResponse.Collection
      );
      setMembershipStatus(formatStatus);
      setMembershipFlag(true);
    };
    getMembershipStatus();
  }, []);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (membershipFlag || createCommitteeData.addMembersInCommittee) {
      createRosterTable();
    }
  }, [
    membershipFlag,
    createCommitteeData.addMembersInCommittee,
    membershipStatus,
  ]);

  function isBeforeNext30Days(dateString: string) {
    var inputDate = new Date(dateString);
    var today = new Date();
    var next30Days = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);
    inputDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (inputDate < next30Days && inputDate >= today) {
      return true;
    } else {
      return false;
    }
  }

  function isDateFromLastMonth(dateToCheck: string) {
    var current = new Date();
    var checkDate = new Date(dateToCheck);
    var currentMonth = current.getMonth();
    var currentYear = current.getFullYear();
    var checkMonth = checkDate.getMonth();
    var checkYear = checkDate.getFullYear();
    var previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    var previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    if (checkYear === previousYear && checkMonth === previousMonth) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <div className="flex flex-wrap flex-row row-gap-3 column-gap-7 py-3">
          <div className="flex flex-row gap-2">
            <span className="m-0 font-bold text-capitalize text-base">
              {t("committee.numberMembers")}:
            </span>
            <p className="m-0 font-normal text-base m-0">{noOfMember}</p>
          </div>
          <div className="flex flex-row gap-2">
            <span className="m-0 font-bold text-capitalize text-base">
              {t("committee.membersExpiring30Days")}:
            </span>
            <p className="m-0 font-normal text-base m-0">
              {membershipExpires30DayCount}
            </p>
          </div>
          <div className="flex flex-row gap-2">
            <span className="m-0 font-bold text-capitalize text-base">
              {t("committee.NumberMemberLastMonth")}:
            </span>
            <p className="m-0 font-normal text-base m-0">
              {memberComapreToLastMonth}
            </p>
          </div>
        </div>

        <div className="flex flex-wrap md:flex-nowrap md:align-items-center py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="recordId"
              placeholder={t("committee.searchCommittee")}
              className="w-full"
              aria-label={t("committee.searchCommittee")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </IconField>
          <div className="flex gap-3 w-full md:w-auto align-items-center ml-auto">
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleFilterPanel}
                aria-label={t("committee.filter")}
              >
                <FeatherIcon name="filter" size={20} color="inherit" />
                <span className="font-bold text-capitalize hidden md:flex">
                  {t("committee.filter")}
                </span>
              </Button>
              {/* OverlayPanel for Filter */}
              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-filter left-auto md:right-0 top-100"
                aria-labelledby="filter-heading"
                role="dialog"
              >
                <div className="flex flex-column gap-1" tabIndex={0}>
                  <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                    {t("committee.filter")}
                  </p>
                  <div className="filter-wrap">
                    <div className="">
                      <label
                        htmlFor="categoryOfInterestFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="categoryOfInterestFilter"
                          onChange={handleCheckboxChange("coi")}
                          checked={coiCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.categoryOfInterest")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo="self"
                          value={selectedCOIs}
                          onChange={(e) => setSelectedCOIs(e.value)}
                          options={coiData}
                          optionLabel="CategoryName"
                          filter
                          placeholder={t("committee.categoryOfInterest")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.categoryOfInterest")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="statusFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="statusFilter"
                          onChange={handleCheckboxChange("status")}
                          checked={memberStatusCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.status")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo="self"
                          value={selectedMemberStatuses}
                          onChange={(e) => setSelectedMemberStatuses(e.value)}
                          options={membershipStatusData}
                          optionLabel="StatusName"
                          filter
                          placeholder={t("committee.status")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.status")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="startDateFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="startDateFilter"
                          onChange={handleCheckboxChange("startdate")}
                          checked={startDateCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.startDate")}
                      </label>
                      <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                        <Calendar
                          className="custom-datepicker"
                          inputId="startDate"
                          onChange={(e) =>
                            handleDateSelect(e.value as Date, "startDate")
                          }
                          value={startDate}
                          dateFormat="mm/dd/yy"
                          tabIndex={0}
                          ariaLabel={t("committee.startDate")}
                          placeholder={t("committee.startDate")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="endDateFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="endDateFilter"
                          onChange={handleCheckboxChange("enddate")}
                          checked={endDateCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.endDate")}
                      </label>
                      <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                        <Calendar
                          className="custom-datepicker"
                          inputId="endDate"
                          onChange={(e) =>
                            handleDateSelect(e.value as Date, "endDate")
                          }
                          value={endDate}
                          dateFormat="mm/dd/yy"
                          tabIndex={0}
                          ariaLabel={t("committee.endDate")}
                          placeholder={t("committee.endDate")}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    className="button-md w-full justify-content-center"
                    onClick={(e: any) => fetchFilteredData(e)}
                  >
                    {t("committee.applyFilter")}
                  </Button>
                </div>
              </OverlayPanel>
            </div>
            {userRole !== "Member" && (
              <Button
                disabled={
                  userRole === "Admin" || userRole === "Staff" ? false : true
                }
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleNewMember}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("committee.newMember")}
                </span>
              </Button>
            )}
          </div>
        </div>

        <Dialog
          id="wantToTerminate"
          header={t("committee.wantToTerminate")}
          visible={visibleTerminate}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => {
            setValidationErrors({
              terminatedThroughError: "",
              ballotOrMeetingNumberError: "",
              selectedReasonError: "",
              terminateValueError: "",
              remarkError: "",
            });
            setSelectedReason("");
            setBallotOrMeetingNumber("");
            setTerminatedThroughValue("");
            setTerminateValue("");
            setVisibleTerminate(false);
          }}
          footer={footerContentReject}
          draggable={false}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-3 w-full">
            {/* Terminated Through Dropdown */}
            <div className="grid grid-xl">
              <div className="flex flex-column gap-2 sm:col-6 col-12">
                <label
                  htmlFor="terminatedThrough"
                  className={`block font-light font-bold text-capitalize ${
                    validationErrors.terminatedThroughError ? "p-error" : ""
                  }`}
                >
                  {t("committee.terminatedThrough")}
                </label>
                <Dropdown
                  inputId="terminatedThrough"
                  value={terminatedThroughValue}
                  options={dropdownOptions}
                  onChange={handleTerminatedThroughChange}
                  placeholder={t("committee.selectTerminatedThrough")}
                  className={`w-full ${validationErrors.terminatedThroughError ? "p-invalid" : ""}`}
                />
                {validationErrors.terminatedThroughError && (
                  <small className="p-error font-bold">
                    {validationErrors.terminatedThroughError}
                  </small>
                )}
              </div>

              {(terminatedThroughValue === "Meeting" ||
                terminatedThroughValue === "Ballot") && (
                <div className="flex flex-column gap-2 sm:col-6 col-12">
                  <label
                    htmlFor="ballotOrMeetingNumber"
                    className={`block font-light font-bold text-capitalize ${
                      validationErrors.ballotOrMeetingNumberError
                        ? "p-error"
                        : ""
                    }`}
                  >
                    {terminatedThroughValue === "Meeting"
                      ? t("committee.meetingNumber")
                      : t("committee.ballotNumber")}
                  </label>
                  <InputText
                    id="ballotOrMeetingNumber"
                    value={ballotOrMeetingNumber}
                    onChange={(e) => {
                      setBallotOrMeetingNumber(e.target.value);
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        ballotOrMeetingNumberError: "",
                      }));
                    }}
                    className={`w-full ${validationErrors.ballotOrMeetingNumberError ? "p-invalid" : ""}`}
                  />
                  {validationErrors.ballotOrMeetingNumberError && (
                    <small className="p-error font-bold">
                      {validationErrors.ballotOrMeetingNumberError}
                    </small>
                  )}
                </div>
              )}
            </div>

            {/* Radio Buttons */}
            <div className="grid grid-xl">
              <div className="col-12">
                <label
                  htmlFor="requestTerminate"
                  className={`block font-light font-bold text-capitalize ${
                    validationErrors.selectedReasonError ? "p-error" : ""
                  }`}
                >
                  {t("committee.reasonForTerminate")}
                </label>
              </div>
              <div className="flex col-12">
                <fieldset className="border-none p-0">
                  <div className="flex flex-wrap gap-3 column-gap-5">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="lackOfParticipation"
                        value="lackOfParticipation"
                        checked={selectedReason === "lackOfParticipation"}
                        onChange={(e) => {
                          setSelectedReason(e.value);
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            selectedReasonError: "",
                          }));
                        }}
                      />
                      <label
                        htmlFor="lackOfParticipation"
                        className="ml-2 font-bold"
                      >
                        {t("committee.basedOnLackOfParticipation")}
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="disciplinaryAction"
                        value="disciplinaryAction"
                        checked={selectedReason === "disciplinaryAction"}
                        onChange={(e) => {
                          setSelectedReason(e.value);
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            selectedReasonError: "",
                          }));
                        }}
                      />
                      <label
                        htmlFor="disciplinaryAction"
                        className="ml-2 font-bold"
                      >
                        {t("committee.basedOnDisciplinaryAction")}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              {validationErrors.selectedReasonError && (
                <div className="col-12">
                  <small className="p-error font-bold">
                    {validationErrors.selectedReasonError}
                  </small>
                </div>
              )}
            </div>

            {/* Reason for Termination Label and Textarea */}
            <div className="grid grid-xl">
              <div className="flex flex-column gap-2 col-12">
                <label
                  htmlFor="requestTerminate"
                  className={`block font-light font-bold text-capitalize ${
                    validationErrors.remarkError ? "p-error" : ""
                  }`}
                >
                  {t("committee.remarks")}
                </label>
                <InputTextarea
                  id="requestTerminate"
                  value={terminateValue}
                  onChange={(e) => {
                    setTerminateValue(e.target.value);
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      remarkError: "",
                    }));
                  }}
                  className={validationErrors.remarkError ? "p-invalid" : ""}
                  rows={5}
                  cols={30}
                />
                <span
                  className={`p-error font-bold text-capitalize ${
                    validationErrors.remarkError ? "" : "error-hidden"
                  }`}
                >
                  {validationErrors.remarkError}
                </span>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          id="wantToEndMembership"
          header={t("committee.wantToEndMembership")}
          visible={visibleEndMembership}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => setVisibleEndMembership(false)}
          footer={footerContentEnd}
          draggable={false}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="requestEnd"
              className={`block font-light font-bold text-capitalize ${
                endMembershipValidationError ? "p-error" : ""
              }`}
            >
              {t("committee.reasonForEnd")}
            </label>
            <InputTextarea
              id="requestEnd"
              onChange={(e) => setEndMembershipValue(e.target.value)}
              rows={5}
              cols={30}
              className={endMembershipValidationError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${endMembershipValidationError ? "" : "error-hidden"}`}
            >
              {endMembershipValidationError}
            </span>
          </div>
        </Dialog>
        <Table
          loading={loading}
          products={
            isFilterApplied
              ? filteredRecords?.filter((item: any) => {
                  const MemberId = (item && item.membershipId) || "";
                  const MemberName = (item && item.memberName) || "";
                  const Position = (item && item.position) || "";
                  return (
                    search === "" ||
                    MemberName.toLowerCase().includes(search.toLowerCase()) ||
                    MemberId.toLowerCase().includes(search.toLowerCase()) ||
                    Position.toLowerCase().includes(search.toLowerCase())
                  );
                })
              : committeeMemberDataForTable?.filter((item: any) => {
                  const MemberId = (item && item.membershipId) || "";
                  const MemberName = (item && item.memberName) || "";
                  const Position = (item && item.position) || "";
                  return (
                    search === "" ||
                    MemberName.toLowerCase().includes(search.toLowerCase()) ||
                    MemberId.toLowerCase().includes(search.toLowerCase()) ||
                    Position.toLowerCase().includes(search.toLowerCase())
                  );
                })
          }
          columns={[
            { field: "memberName", header: t("committee.memberName") },
            { field: "position", header: t("committee.Position") },
            { field: "categoryOf", header: t("committee.categoryOfInterest") },
            { field: "tenure", header: t("committee.tenure") },
            { field: "startDate", header: t("committee.startDate") },
            { field: "endDate", header: t("committee.endDate") },
            {
              field: "StatusName",
              header: t("committee.status"),
              body: (rowData: any) => getStatusBadge(rowData.StatusName),
            },
            {
              field: "Action",
              header: t("committee.action"),
              body: actionPositionBodyTemplate,
              sortable: false,
            },
          ]}
        />
      </div>
    </>
  );
};

export default withLoader(CommitteeTabRoster);
