import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomStepWizard from "../../common/CustomStepWizard";
import { useLanguageContext } from "../../LanguageContext";
import { Badge } from "primereact/badge";
import { useSelector } from "react-redux";

interface CreateBallotHeaderProps {
  activeStep: number;
}

const BallotHeader: React.FC<CreateBallotHeaderProps> = ({ activeStep }) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const { ballotDetailsInfo } = useSelector(
    (state: any) => state.ballotDetails
  );
  const { isEdit } = useSelector((state: any) => state.ballotSelectRecords);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const stepItems = [
    {
      icon: "cached",
      label: t("ballot.selectRecords"),
    },
    {
      icon: "cached",
      label: t("ballot.ballotsDetails"),
    },
    {
      icon: "cached",
      label: t("ballot.committee"),
    },
    {
      icon: "cached",
      label: t("ballot.preview"),
    },
  ];
  return (
    <>
      <div className="mb-5">
        <div className="mb-5 flex flex-column gap-2">
          {!isEdit && (
            <h1 className="text-title display-xs font-bold m-0">
              {t("ballot.createNewBallot")}
            </h1>
          )}
          {isEdit && (
            <div className="flex gap-2">
              <h1 className="text-title display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
                {t("ballot.ballotId")} - {ballotDetailsInfo?.ballotNumber}
              </h1>
              <Badge
                value={ballotDetailsInfo?.ballotStatusName}
                className="active"
              />
            </div>
          )}
        </div>
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>
    </>
  );
};

export default BallotHeader;
