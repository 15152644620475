import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MenuItem } from "primereact/menuitem";
import { FilterMatchMode } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Table from "../common/Table";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { createRecordRequest } from "../../slices/createRecordSlice";
import CreateRecordHeader from "./createRecordHeader";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import  ConfirmDialogComponent  from "../common/ConfirmDialogComponent";

interface Option {
  name: string;
  code: string;
}

const VolunteerSelection: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState(false);
  //const menuRight = useRef<Menu>(null);
  const [createRecord, setCreateRecord] = useState<any>({
    userProfileId: "",
  });
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [selectedVolunteer, setSelectedVolunteer] = useState<any>(null);
  //const [rowClick, setRowClick] = useState<any>(true);
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [search, setSearch] = useState("");
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [userProfileId, setUserProfileId] = useState<string>("");
  const [volunteers, setVolunteers] = useState([]);
  const [dateActivation, setDateActivation] = useState(null);
  React.useEffect(() => {
    let userProfileID = sessionStorage.userProfileId;
    if (userProfileID) {
      setUserProfileId(userProfileID);
      setSelectedVolunteer(userProfileID);
    }

    const fetchAllMembers = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/SearchVolenteerMembers"
        );
        const modifiedData = responseData.map((item: any) => ({
          ...item,
          fullName: item.Firstname + " " + item.Lastname,
          UserProfileId: item?.UserProfileUserType[0]?.UserProfileId,
          JobTitle: item?.UserExperiences[0]?.Designation,
          Department: item?.UserExperiences[0]?.Department,
          Organization: item?.UserExperiences[0]?.CompanyName,
          sessionKey: "staffUpdatingMember",
        }));
        setVolunteers(modifiedData);
      } catch (error) {
        console.error("Error fetching members:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllMembers();
  }, []);

  const navigate = useNavigate();

  const handleViewMember = () => {
    return () => {
      navigate("/profile/");
    };
  };

  const actionItems: MenuItem[] = [
    {
      label: t("record.editProfile"),
      command: handleViewMember(),
    },
  ];

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };

  const handleRadioClick = (rowData: any) => {
    const userId = rowData.UserProfileId;
    dispatch(createRecordRequest({ userProfileId: userId }));
    sessionStorage.setItem("userProfileId", userId);
    setSelectedVolunteer(userId);
    setCreateRecord((prevData: any) => ({
      ...prevData,
      userProfileId: userId,
    }));
  };

  const createMemberRecord = async () => {
    navigate("/record/create");
  };

  const [visible, setVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setVisible(true); 
  };

  const handleConfirm = () => {
    let navigateFrom = sessionStorage.getItem("redirectionPath");
    if (navigateFrom === "record") {
      let committeeId = sessionStorage.getItem("committeeId");
      navigate(`/committee/details/${committeeId}`);
    } else {
      navigate("/record");
    }
  }

  const handleCancelDialog = () => {
    setVisible(false);
  }

  return (
    <>
      <div className="">
      <ConfirmDialogComponent
                visible={visible}
                onHide={() => setVisible(false)}
                message="Are you sure you want to exit?"
                header="Confirmation"
                onConfirm={() => handleConfirm()}
                onCancel={() => handleCancelDialog()}
            />
        <CreateRecordHeader activeStep={0} />
        <div className="flex align-items-center py-3 gap-3 justify-content-between w-full">
          {/* <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchCommittee"
              className="w-full"
              placeholder={t("record.searchCommittee")}
              aria-label={t("record.searchCommittee")}
            />
          </IconField> */}
          <div className=" flex flex-column gap-2">
            <h2 className="text-title text-lg font-bold m-0">
              {t("record.selectAppilcant")}
            </h2>
            <p className="text-base font-normal m-0">
              {t("record.selectApplicantSubText")}
            </p>
          </div>
          <div className="flex align-items-center gap-3">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("record.searchApplicant")}
                aria-label={t("record.searchApplicant")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </IconField>
          </div>
        </div>

        <Table
          loading={loading}
          //products={volunteers}
          products={volunteers?.filter((item: any) => {
            const FullName = item.fullName || "";
            return (
              search === "" ||
              FullName.toLowerCase().includes(search.toLowerCase())
            );
          })}
          columns={[
            {
              field: "fullName",
              header: t("record.memberName"),
            },
            {
              field: "Email",
              header: t("record.email"),
            },
            {
              field: "Organization",
              header: t("record.organisation"),
            },
            {
              field: "Department",
              header: t("record.department"),
            },
            {
              field: "JobTitle",
              header: t("record.jobTitle"),
            },
            { field: "action", header: t("record.action"), sortable: false },
          ]}
          actionItems={actionItems}
          showRadioButton={true}
          onRadioClick={handleRadioClick}
          initialSelectedProduct={selectedVolunteer}
        />
      </div>
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("record.cancel")}
            className="button-md"
            severity="secondary"
            onClick={handleCancel}
          />
          <div className="flex md:flex-row gap-3">
            <Button
              onClick={createMemberRecord}
              disabled={
                selectedVolunteer === null ||
                selectedVolunteer === "" ||
                selectedVolunteer === undefined
              }
              label={t("record.next")}
              className="button-md"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VolunteerSelection;
