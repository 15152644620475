import React, { useState, useRef, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { useLocation, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData, postData } from "../../services/apiService";
import { formatCommitteeData } from "../../utils/utils";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { useDispatch } from "react-redux";
import { reset } from "../../slices/createCommitteeSlice";
import { usePermission } from "../auth/Authorization";
import { MultiSelect } from "primereact/multiselect";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import { clearAllSessionStorage } from "../../utils/storage";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import CustomMenu, { getStatusBadge } from "../common/customMenu";
import DataGrid from "../common/DataGrid";
interface Option {
  name: string;
  code: string;
}
interface ComitteeList {
  id: string;
  name: string;
  level: string;
  parentCommittee: string;
  ballotCount: string;
  inBalance: string;
  member: string;
  createdOn: string;
  action: string;
}

const CommitteeListing: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const [search, setSearch] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [visibleResign, setVisibleResign] = useState<boolean>(false);
  const [resignValidationError, setResignValidationError] = useState("");
  const [resignId, setResignId] = useState<string | null>(null);
  const [resignValue, setResignValue] = useState("");
  const toast = useRef<Toast>(null);
  const [heirarchylevels, setHeirarchyLevels] = useState<any>([]);
  const [selectedHeirarchyLevels, setSelectedHeirarchyLevels] = useState<any[]>(
    []
  );
  const [parentCommittees, setParentCommittees] = useState<any[]>([]);
  const [selectedParentCommittees, setSelectedParentCommittees] = useState<
    any[]
  >([]);
  const [selectedStaffs, setSelectedStaffs] = useState<any[]>([]);
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const [heirarchyLevelCheckboxChecked, setHeirarchyLevelCheckboxChecked] =
    useState<boolean>(false);
  const [allCommittees, setAllCommittees] = useState<boolean>(false);
  const [parentCommitteeCheckboxChecked, setParentCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [staffCheckboxChecked, setStaffCheckboxChecked] =
    useState<boolean>(false);
  const [isbalanceCheckboxChecked, setIsBalanceCheckboxChecked] =
    useState<boolean>(false);
  const [isbalanceYesChecked, setIsbalanceYesChecked] =
    useState<boolean>(false);
  const [isbalanceNoChecked, setIsbalanceNoChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();
  clearAllSessionStorage();
  const dispatch = useDispatch();
  const location = useLocation();
  // filter
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel
  const [commiiteeList, setCommitteList] = useState<ComitteeList[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  //Pagination
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortField, setSortField] = useState<string>("CreatedDate");

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const handleResignCancel = () => {
    setResignValidationError("");
    setResignValue("");
    setVisibleResign(false);
  };

  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: t("committee.memberResignSuccessfully"),
      life: 3000,
    });
  };

  const handleResignButtonClick = async (Id: any) => {
    try {
      setResignValidationError("");
      if (!resignValue.trim()) {
        setResignValidationError(t("committee.resignationError"));
        return;
      }
      setLoading(true);

      const userProfileID = localStorage.getItem("userProfileID");
      setVisibleResign(true);
      const data = {
        committeeId: Id,
        userProfileId: userProfileID,
        comments: resignValue,
      };

      const resignByCommittee = await postData(
        `MembershipRequest/MembershipRequestResignByCommitteeMembership`,
        data
      );
      setVisibleResign(false);
      setTimeout(() => {
        showSuccess();
      }, 1000);
      navigate("/committee");
    } catch {
      console.error("Error Posting Data");
    }
  };

  const footerContentResign = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.cancel")}
        onClick={handleResignCancel}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.resignMember")}
        onClick={() => handleResignButtonClick(resignId)}
        className="button-md"
      />
    </div>
  );

  const handleView = () => {
    let committeeId = sessionStorage.getItem("committeeId");
    navigate(`/committee/details/${committeeId}`);
  };

  const handleAddPosition = () => {
    let committeeId = sessionStorage.getItem("committeeId");
    navigate("/positions/add-position/", { state: { id: committeeId } });
  };

  const handleCommitteeData = async (roleName: string = "") => {
    const getParams = await getFilter();
    console.log(getParams);
    setLoading(true);
    try {
      let CommitteeData: any = [];
      if (roleName === "Staff") {
        const committeeResponse = await fetchData(
          `Committee/GridSearch?${new URLSearchParams(getParams).toString()}`
        );

        setTotalCount(committeeResponse?.TotalCount);
        CommitteeData = formatCommitteeData(committeeResponse.Collection);
      } else if (roleName === "Admin") {
        const committeeResponse = await fetchData(
          `Committee/GridSearch?${new URLSearchParams(getParams).toString()}`
        );
        setTotalCount(committeeResponse?.TotalCount);
        CommitteeData = formatCommitteeData(committeeResponse.Collection);
      } else {
        const memberCommitteeResponse = await fetchData(
          `Committee/GridSearch?${new URLSearchParams(getParams).toString()}`
        );
        const modifiedMemberData = memberCommitteeResponse.Collection;
        setTotalCount(memberCommitteeResponse?.TotalCount);
        CommitteeData = formatCommitteeData(modifiedMemberData);
      }
      setCommitteList(CommitteeData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (userRole) {
      handleCommitteeData(userRole);
    }
  }, [
    userRole,
    pageSize,
    pageIndex,
    debouncedSearchTerm,
    sortField,
    sortOrder,
    allCommittees,
  ]);

  useEffect(() => {
    fetchHeirarchyLevels();
    fetchParentCommittees();
  }, []);

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("committee.viewEdit"),
        command: () => {
          handleView();
        },
      },
      ...(userRole === "Member"
        ? [
            {
              label: t("committee.resignMember"),
              command: () => {
                setVisibleResign(true);
                setResignId(rowData.Id);
              },
            },
          ]
        : []),
      ...(rowData.statusName === "Created" && userRole !== "Member"
        ? [
            {
              label: t("committee.openPosition"),
              command: () => {
                handleAddPosition();
              },
            },
          ]
        : []),
    ];

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  const fetchHeirarchyLevels = async () => {
    try {
      const response = await fetchData("HierarchyLevel/GetAll");
      const hierarchyData = response.Collection;
      setHeirarchyLevels(hierarchyData);
    } catch (error) {
      console.error("Error fetching heirarchylevels:", error);
    }
  };

  const fetchParentCommittees = async () => {
    try {
      const response = await fetchData("Committee/Search?PageIndex=-1");
      const ParentcommitteesData = response.Collection;
      setParentCommittees(ParentcommitteesData);
    } catch (error) {
      console.error("Error fetching Parent committees:", error);
    }
  };

  const fetchFilteredData = async (event: any) => {
    setIsFilterApplied(true);
    if (pageIndex !== 1) {
      setPageIndex(1);
    } else {
      handleCommitteeData();
    }
    overlayPanelRef.current?.toggle(event);
  };

  const formatDatee = (dateString: any) => {
    const date = new Date(dateString);
    const options: any = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getFilter = async () => {
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: any }[] = [];
    if (heirarchyLevelCheckboxChecked) {
      const heirarchylevelIds = selectedHeirarchyLevels.map(
        (heirarchy) => heirarchy.Id
      );
      filters.push({
        Key: "HierarchyLevelId",
        Value: heirarchylevelIds.join(","),
      }); //check this
    }
    if (parentCommitteeCheckboxChecked) {
      const parentcommitteeIds = selectedParentCommittees.map(
        (parent) => parent.Id
      );
      filters.push({
        Key: "ParentCommitteeId",
        Value: parentcommitteeIds.join(","),
      }); //check the id
    }
    if (staffCheckboxChecked) {
      const staffIds = selectedStaffs.map((staff) => staff.Id); //check the id
      filters.push({
        Key: "StaffId",
        Value: staffIds.join(","),
      });
    }
    if (
      isbalanceCheckboxChecked &&
      (isbalanceYesChecked || isbalanceNoChecked)
    ) {
      filters.push({
        Key: "IsBalanced",
        Value: isbalanceYesChecked ? "true" : "false",
      });
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({
        Key: "RequestedOn",
        Value: `${fromDateStr},${toDateStr}`,
      });
    }
    const userProfileID = localStorage.getItem("userProfileID");
    switch (userRole) {
      case "Staff":
        if (!allCommittees) {
          filters.push({ Key: "StaffId", Value: userProfileID });
        }
        break;
      case "Member":
        filters.push({ Key: "UserProfileId", Value: userProfileID });
        break;
      default:
        break;
    }

    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });

    queryParams["PageIndex"] = pageIndex;
    queryParams["PageSize"] = pageSize;
    queryParams["SearchText"] = debouncedSearchTerm;
    queryParams["SortBy"] = sortField;
    queryParams["SortDir"] = sortOrderValue;
    return queryParams;
  };
  const handleCheckboxChange =
    (checkbox: string) => async (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "heirarchylevel":
          setHeirarchyLevelCheckboxChecked(isChecked);
          break;
        case "parentcommittee":
          setParentCommitteeCheckboxChecked(isChecked);
          break;
        case "staffid":
          setStaffCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        case "isbalance":
          setIsBalanceCheckboxChecked(isChecked);
          if (!isChecked) {
            setIsbalanceYesChecked(false);
            setIsbalanceNoChecked(false);
          }
          break;
        case "allCommittees":
          setAllCommittees(isChecked);
          setLoading(true);
          break;
        default:
          break;
      }
    };

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setToDateError(`${t("committee.fromDateBeforeToDate")}`);
        setDateActivationFrom(null);
      } else {
        setToDateError("");
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setToDateError(`${t("committee.toDateAfterFromDate")}`);
        setDateActivationTo(null);
      } else {
        setToDateError("");
        setDateActivationTo(date);
      }
    }
  };

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    {
      label:
        userRole === "Member"
          ? t("committee.myCommittees")
          : t("committee.committees"),
    },
  ];

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 500); // 500ms delay
    // Clear the timeout if searchTerm changes (i.e. user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {userRole === "Member"
              ? t("committee.myCommittees")
              : t("committee.committees")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("committee.committeeListingHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView>
            <TabPanel header={t("committee.standardCommittees")}>
              <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
                <IconField iconPosition="left" className="w-full md:w-16rem">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    id="recordId"
                    placeholder={t("committee.searchCommittee")}
                    className="w-full"
                    aria-label={t("committee.searchCommittee")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </IconField>

                <div className="flex gap-3 w-full md:w-auto align-items-center">
                  {userRole === "Staff" || userRole === "Member" ? (
                    <label
                      htmlFor="allCommitteeFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer flex align-items-center"
                    >
                      <Checkbox
                        inputId="allCommitteeFilter"
                        onChange={handleCheckboxChange("allCommittees")}
                        checked={allCommittees}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("committee.allCommittees")}
                    </label>
                  ) : null}
                  <div className="relative">
                    <Button
                      className="button-md gap-1"
                      severity="secondary"
                      onClick={toggleFilterPanel}
                      aria-label={t("committee.filter")}
                    >
                      <FeatherIcon name="filter" size={20} color="inherit" />
                      <span className="font-bold text-capitalize hidden md:flex">
                        {t("committee.filter")}
                      </span>
                    </Button>
                    {/* OverlayPanel for Filter */}
                    <OverlayPanel
                      ref={overlayPanelRef}
                      onHide={() => setShowFilterPanel(false)}
                      appendTo={"self"}
                      dismissable
                      className="p-overlaypanel-filter left-auto md:right-0 top-100"
                      aria-labelledby="filter-heading"
                      role="dialog"
                    >
                      <div className="flex flex-column gap-1" tabIndex={0}>
                        <h3
                          id="filter-heading"
                          className="text-lg font-normal m-0 p-3"
                        >
                          {t("committee.filter")}
                        </h3>
                        <div className="filter-wrap">
                          <div className="">
                            <label
                              htmlFor="hierarchylevelFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="hierarchylevelFilter"
                                onChange={handleCheckboxChange(
                                  "heirarchylevel"
                                )}
                                checked={heirarchyLevelCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("committee.committeeType")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedHeirarchyLevels}
                                onChange={(e: any) =>
                                  setSelectedHeirarchyLevels(e.value)
                                }
                                options={heirarchylevels}
                                optionLabel="Name"
                                filter
                                placeholder={t("committee.committeeType")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("committee.committeeType")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="parentCommitteeFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="parentCommitteeFilter"
                                onChange={handleCheckboxChange(
                                  "parentcommittee"
                                )}
                                checked={parentCommitteeCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("committee.parentCommittee")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                value={selectedParentCommittees}
                                onChange={(e: any) =>
                                  setSelectedParentCommittees(e.value)
                                }
                                options={parentCommittees}
                                optionLabel="Name"
                                filter
                                placeholder={t("committee.parentCommittee")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("committee.parentCommittee")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="inBalanceFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="inBalanceFilter"
                                onChange={handleCheckboxChange("isbalance")}
                                checked={isbalanceCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("committee.inBalance")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full">
                              <div className="flex flex-wrap gap-3">
                                <div className="flex align-items-center">
                                  <Checkbox
                                    inputId="isbalanceYes"
                                    onChange={() => {
                                      setIsbalanceYesChecked(
                                        !isbalanceYesChecked
                                      );
                                      setIsbalanceNoChecked(false);
                                    }}
                                    checked={isbalanceYesChecked}
                                  />
                                  <label
                                    htmlFor="isbalanceYes"
                                    className="ml-2"
                                  >
                                    {t("committee.yes")}
                                  </label>
                                </div>
                                <div className="flex align-items-center">
                                  <Checkbox
                                    inputId="isbalanceNo"
                                    onChange={() => {
                                      setIsbalanceNoChecked(
                                        !isbalanceNoChecked
                                      );
                                      setIsbalanceYesChecked(false);
                                    }}
                                    checked={isbalanceNoChecked}
                                  />
                                  <label htmlFor="isbalanceNo" className="ml-2">
                                    {t("committee.no")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="requestDateFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="requestDateFilter"
                                onChange={handleCheckboxChange("date")}
                                checked={dateCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("committee.createdOn")}
                            </label>
                            <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationFrom"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "dateActivationFrom"
                                  )
                                }
                                value={dateActivationFrom}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("committee.fromDate")}
                                placeholder={t("committee.fromDate")}
                                maxDate={
                                  dateActivationTo
                                    ? (dateActivationTo as Date)
                                    : undefined
                                }
                              />
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationTo"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "dateActivationTo"
                                  )
                                }
                                value={dateActivationTo}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("committee.toDate")}
                                placeholder={t("committee.toDate")}
                                minDate={
                                  dateActivationFrom
                                    ? (dateActivationFrom as Date)
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className="button-md w-full justify-content-center"
                          onClick={(e: any) => fetchFilteredData(e)}
                        >
                          {t("committee.applyFilter")}
                        </Button>
                      </div>
                    </OverlayPanel>
                  </div>
                  {userRole !== "Member" && (
                    <Button
                      className="button-md gap-1 justify-content-center w-full md:w-auto"
                      onClick={() => navigate("/committee/create")}
                      disabled={
                        userRole === "Admin" || userRole === "Staff"
                          ? false
                          : true
                      }
                    >
                      <FeatherIcon name="plus" size={20} color="inherit" />
                      <span className="font-bold text-capitalize">
                        {t("committee.newCommittee")}
                      </span>
                    </Button>
                  )}
                </div>
              </div>

              <DataGrid
                loading={loading}
                products={commiiteeList}
                columns={[
                  {
                    field: "CommitteeNumber",
                    header: t("committee.committeeId"),
                    link: "/committee/details",
                  },
                  {
                    field: "CommitteeName",
                    header: t("committee.committeeName"),
                  },
                  {
                    field: "CommitteeLevel",
                    header: t("committee.committeeType"),
                  },
                  {
                    field: "ParentCommitteeName",
                    header: t("committee.parentCommittee"),
                  },
                  { field: "IsBalanced", header: t("committee.inBalance") },

                  { field: "MemberCount", header: t("committee.memberCount") },
                  {
                    field: "PrimaryStaff",
                    header: t("committee.staffContact"),
                  },
                  {
                    field: "CreatedDate",
                    header: t("committee.createOn"),
                    body: (rowData: any) => formatDatee(rowData.CreatedDate),
                  },
                  {
                    field: "StatusName",
                    header: t("committee.status"),
                    body: (rowData: any) => getStatusBadge(rowData.StatusName),
                  },
                  {
                    field: "Action",
                    header: t("committee.action"),
                    body: actionPositionBodyTemplate,
                    sortable: false,
                  },
                ]}
                pageIndex={pageIndex}
                totalRecords={totalCount}
                pageSize={pageSize}
                onPageChange={pageHandler}
                sortField={sortField}
                sortOrder={sortOrder}
                onSortChange={sortHandler}
              />
              <Dialog
                id="visibleWithdrawId"
                header={t("committee.wantToResign")}
                visible={visibleResign}
                style={{ width: "50vw" }}
                breakpoints={{
                  "960px": "75vw",
                  "641px": "90vw",
                  "400px": "98vw",
                }}
                onHide={() => {
                  setResignValue("");
                  setResignValidationError("");
                  setVisibleResign(false);
                }}
                footer={footerContentResign}
                draggable={false}
                className="custom-dialog hide-close"
                ariaCloseIconLabel="Close"
              >
                <div className="flex flex-column gap-2 w-full">
                  <label
                    htmlFor="requestResign"
                    className={`block font-light font-bold text-capitalize ${
                      resignValidationError ? "p-error" : ""
                    }`}
                  >
                    {" "}
                    {t("committee.reasonForResign")}
                  </label>
                  <InputTextarea
                    id="requestResign"
                    value={resignValue}
                    onChange={(e) => {
                      setResignValidationError("");
                      setResignValue(e.target.value);
                    }}
                    rows={5}
                    cols={30}
                    className={resignValidationError ? "p-invalid" : ""}
                  />
                  <span
                    className={`p-error font-bold text-capitalize ${resignValidationError ? "" : "error-hidden"}`}
                  >
                    {resignValidationError}
                  </span>
                </div>
              </Dialog>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default CommitteeListing;
