import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData } from "../../../services/Ballot/apiservice";
import FeatherIcon from "../../common/FeatherIconComponent";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { useParams } from "react-router-dom";
import useDropDownOptions from "../common/useDropDownOptions";

const getIdByName = (
  items: { id: number; value: string }[],
  selectedNames: string[]
): number[] => {
  return selectedNames
    .map((name) => {
      const item = items.find((item) => item.value === name);
      return item ? item.id : -1;
    })
    .filter((id) => id !== -1);
};
const formatDate = (date: Date | null) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "";
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
interface BallotFilterProps {
  queryParamsByRole: string;
  searchHandler: (query: string | null) => void;
}
const BallotFilter: React.FC<BallotFilterProps> = ({
  queryParamsByRole,
  searchHandler,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const dispatch = useDispatch();
  const { fetchParentCommittee, handleDropDownOptions } = useDropDownOptions();
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [queryParamsfilters, setQueryParamsfilters] = useState<any>([]);
  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };
  const { id } = useParams<{ id: string }>();
  const {
    primaryCommitteOptions,
    ballotTypeOptions,
    ballotSubTypeOptions,
    ballotStatusOptions,
    ballotLevelOptions,
  } = useSelector((state: any) => state.ballotSelectRecords);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      primaryCommitte: [],
      ballotType: [],
      ballotSubType: [],
      ballotLevel: [],
      ballotStatus: [],
      dateActivationFrom: null,
      dateActivationTo: null,
      primaryCommitteFilter: false,
      ballotTypeFilter: false,
      ballotSubTypeFilter: false,
      ballotLevelFilter: false,
      ballotStatusFilter: false,
      requestDateFilter: false,
    },
  });
  const pushParamToFilters = (paramString: string) => {
    if (paramString === "") {
      setQueryParamsfilters([]);
    }
    const parts = paramString?.split("&");
    let tempFilter: { Key?: string; Value?: string } = {};
    parts?.forEach((part) => {
      const [fullKey, value] = part.split("=");
      const key = fullKey.split(".").pop();
      if (key === "Key") {
        tempFilter.Key = value;
      } else if (key === "Value") {
        tempFilter.Value = value;
      }
      if (tempFilter.Key && tempFilter.Value) {
        setQueryParamsfilters((prevFilters: any) => [
          ...prevFilters,
          { Key: tempFilter.Key, Value: tempFilter.Value },
        ]);
        // tempFilter = {};
      }
    });
  };
  const onSubmit = (data: any) => {
    const primaryCommitteIds = getIdByName(
      primaryCommitteOptions,
      data.primaryCommitte
    );
    const ballotTypeIds = getIdByName(ballotTypeOptions, data.ballotType);
    const ballotSubTypeIds = getIdByName(
      ballotSubTypeOptions,
      data.ballotSubType
    );
    const ballotLevelIds = getIdByName(ballotLevelOptions, data.ballotLevel);
    const ballotStatusIds = getIdByName(ballotStatusOptions, data.ballotStatus);
    const formData = {
      ...data,
      primaryCommitte: primaryCommitteIds,
      ballotType: ballotTypeIds,
      ballotSubType: ballotSubTypeIds,
      ballotStatus: ballotStatusIds,
      ballotLevel: ballotLevelIds,
    };
    const fromDateStr = formatDate(formData.dateActivationFrom);
    const toDateStr = formatDate(formData.dateActivationTo);
    const filters: any = [...queryParamsfilters];
    if (id) {
      filters.push({ Key: "committeeid", Value: id });
    }
    if (data.primaryCommitteFilter && data.primaryCommitte.length >= 1)
      filters.push({ Key: "committeeid", Value: formData.primaryCommitte });
    if (data.ballotTypeFilter && data.ballotType.length >= 1)
      filters.push({ Key: "ballottypeid", Value: formData.ballotType });
    if (data.ballotSubTypeFilter && data.ballotSubType.length >= 1)
      filters.push({ Key: "ballotsubtypeid", Value: formData.ballotSubType });
    if (data.ballotLevelFilter && data.ballotLevel.length >= 1)
      filters.push({ Key: "ballotlevelid", Value: formData.ballotLevel });
    if (data.ballotStatusFilter && data.ballotStatus.length >= 1)
      filters.push({ Key: "ballotstatusid", Value: formData.ballotStatus });
    if (
      data.requestDateFilter &&
      data.dateActivationFrom &&
      data.dateActivationTo
    )
      filters.push({ Key: "DateFilter", Value: `${fromDateStr}#${toDateStr}` });

    const queryString = filters
      .map((filter: any, index: number) => {
        const value = Array.isArray(filter.Value)
          ? filter.Value.map((val: string) => val).join(",")
          : encodeURIComponent(filter.Value);
        return `Filters[${index}].Key=${filter.Key}&Filters[${index}].Value=${value}`;
      })
      .join("&");

    searchHandler(filters);
    if (overlayPanelRef.current && filters.length > 0) {
      overlayPanelRef.current.hide();
    }
  };
  const clearFilters = () => {
    reset();
    searchHandler(null);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.hide();
    }
  };
  const handleBallotStatus = async () => {
    const dispatchRecords = (options: any, label: any) => {
      dispatch(ballotSelectRecords.initialRecordValues({ options, label }));
    };
    try {
      const ballotStatus = await fetchData(`BallotStatus/GetAll`);
      dispatchRecords(ballotStatus, "ballotStatus");
    } catch (err) {
      console.error("err", err);
    }
  };

  useEffect(() => {
    pushParamToFilters(queryParamsByRole);
  }, [queryParamsByRole]);
  useEffect(() => {
    fetchParentCommittee();
    handleDropDownOptions();
  }, [fetchParentCommittee, handleDropDownOptions]);
  useEffect(() => {
    handleBallotStatus();
  }, []);
  return (
    <>
      <Button
        className="button-md gap-1"
        severity="secondary"
        onClick={toggleFilterPanel}
        aria-label={t("ballot.filter")}
      >
        <FeatherIcon name="filter" size={20} color="inherit" />
        <span className="font-bold text-capitalize hidden md:flex">
          {t("ballot.filter")}
        </span>
      </Button>
      <OverlayPanel
        ref={overlayPanelRef}
        onHide={() => setShowFilterPanel(false)}
        appendTo={"self"}
        dismissable
        className="p-overlaypanel-filter left-auto md:right-0 top-100"
        aria-labelledby="filter-heading"
        role="dialog"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column gap-1" tabIndex={0}>
            <h3 id="filter-heading" className="text-lg font-normal m-0 p-3">
              {t("ballot.filter")}
            </h3>
            <div className="filter-wrap">
              {!id && (
                <div className="">
                  <Controller
                    name="primaryCommitteFilter"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <label
                        htmlFor="primaryCommitteFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="primaryCommitteFilter"
                          {...field}
                          checked={field.value}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("ballot.PrimaryCommittee")}
                      </label>
                    )}
                  />
                  <div className="pr-3 py-2 pl-6 w-full relative">
                    <Controller
                      name="primaryCommitte"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          options={primaryCommitteOptions}
                          optionLabel="value"
                          filter
                          placeholder={t("ballot.PrimaryCommittee")}
                          className="w-full"
                          aria-label={t("ballot.PrimaryCommittee")}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="p-0">
                <Controller
                  name="ballotTypeFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="ballotTypeFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="ballotTypeFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.ballotType")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="ballotType"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        options={ballotTypeOptions}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.ballotType")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("ballot.ballotType")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="ballotSubTypeFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="ballotSubTypeFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="ballotSubTypeFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.ballotSubType")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="ballotSubType"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        options={ballotSubTypeOptions}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.ballotSubType")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("ballot.ballotSubType")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="ballotLevelFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="ballotLevelFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="ballotLevelFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.ballotLevel")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="ballotLevel"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        options={ballotLevelOptions}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.ballotLevel")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("ballot.ballotLevel")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="ballotStatusFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="ballotStatus"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="ballotStatus"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.ballotStatus")}
                    </label>
                  )}
                />
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Controller
                    name="ballotStatus"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        options={ballotStatusOptions}
                        optionLabel="value"
                        filter
                        placeholder={t("ballot.ballotStatus")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("ballot.ballotStatus")}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="p-0">
                <Controller
                  name="requestDateFilter"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <label
                      htmlFor="requestDateFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="requestDateFilter"
                        {...field}
                        checked={field.value}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("ballot.requestDate")}
                    </label>
                  )}
                />
                <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                  <Controller
                    name="dateActivationFrom"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationFrom"
                        {...field}
                        value={field.value}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("ballot.fromDate")}
                        placeholder={t("ballot.fromDate")}
                      />
                    )}
                  />
                  <Controller
                    name="dateActivationTo"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationTo"
                        {...field}
                        value={field.value}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("ballot.toDate")}
                        placeholder={t("ballot.toDate")}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Button
              type="button"
              className="button-md p-button-secondary justify-content-center"
              onClick={clearFilters}
            >
              {t("ballot.clearFilter")}
            </Button>
            <Button
              type="submit"
              className="button-md w-full justify-content-center"
            >
              {t("ballot.applyFilter")}
            </Button>
          </div>
        </form>
      </OverlayPanel>
    </>
  );
};

export default BallotFilter;
