import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { MenuItem } from "primereact/menuitem";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Toast } from "primereact/toast";
import BallotFilter from "./BallotFilter";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { ballotDetails } from "../../../slices/ballotDetails";
import { ballotCommittees } from "../../../slices/ballotCommittees";
import { deleteData, fetchData } from "../../../services/Ballot/apiservice";
import { fetchData as getData } from "../../../services/apiService";
import { usePermission } from "../../auth/Authorization";
import { allBallotListings } from "../../../slices/ballotListing";
import { formatDate } from "../../../utils/utils";
import { setToast } from "../../../slices/toastSlice";
import { useParams } from "react-router-dom";
import {
  BALLOT_CANCELLED,
  BALLOT_CLOSE,
  BALLOT_DRAFT,
  BALLOT_OPEN,
  BALLOT_OUT_FOR_BALLOT,
  BALLOT_STATUS_KEY,
  MEMBERSHIP_PROFILE_KEY,
} from "../common/constants";
import {
  BallotStatus,
  BallotStatusResponse,
  QueryParamsByRole,
  TableBallotListing,
  FormattedBallotStatusList,
  BallotTextSearch,
  ToastContentProps,
  Column,
} from "../common/type";
import BallotListingTable from "../common/BallotListingTable";
interface Option {
  name: string;
  code: string;
}
interface BallotListingProps {
  isDefaultConfiguration?: boolean;
}
const buildQueryParam = (
  role: string,
  id: string | undefined,
  userID: string | null,
  ballotStatusList: any,
  isCode2: boolean,
  searchFilters: any
) => {
  let filters: any[] = searchFilters ? [...searchFilters] : [];
  if (id) {
    filters.push({ Key: "committeeid", Value: id });
  }
  if (isCode2) {
    filters = filters.filter((filter) => filter.Key !== MEMBERSHIP_PROFILE_KEY);
    if (role === "Member") {
      filters.push({
        Key: BALLOT_STATUS_KEY,
        Value:
          ballotStatusList &&
          `${ballotStatusList[BALLOT_OUT_FOR_BALLOT]?.id},${ballotStatusList[BALLOT_CLOSE]?.id},${ballotStatusList[BALLOT_OPEN]?.id},${ballotStatusList[BALLOT_CANCELLED]?.id}`,
      });
    }
  }
  if (role === "Member" && !isCode2) {
    filters.push(
      {
        Key: BALLOT_STATUS_KEY,
        Value:
          ballotStatusList &&
          `${ballotStatusList[BALLOT_OUT_FOR_BALLOT]?.id},${ballotStatusList[BALLOT_CLOSE]?.id}`,
      },
      { Key: MEMBERSHIP_PROFILE_KEY, Value: userID }
    );
  } else if (!id && !isCode2) {
    filters.push({ Key: MEMBERSHIP_PROFILE_KEY, Value: userID });
  }
  return filters
    .map(
      (filter, index) =>
        `Filters[${index}].Key=${filter.Key}&Filters[${index}].Value=${encodeURIComponent(filter.Value)}`
    )
    .join("&");
};
const BallotListing: React.FC<BallotListingProps> = ({
  isDefaultConfiguration,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [searchFilters, setSearchFilters] = useState<string | null>(``);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const columnsData: Column[] = [
    {
      field: "ballotId",
      header: t("ballot.ballotId"),
      link: "/ballot",
    },
    {
      field: "numberOfRecords",
      header: t("ballot.numberOfRecords"),
      sortable: true,
    },
    {
      field: "primaryCommitteeResponsible",
      header: t("ballot.primaryCommitteeResponsible"),
      sortable: true,
    },
    {
      field: "ballotType",
      header: t("ballot.ballotType"),
      sortable: true,
    },
    {
      field: "ballotSubType",
      header: t("ballot.ballotSubType"),
      sortable: true,
    },
    { field: "ballotLevel", header: t("ballot.ballotLevel"), sortable: true },
    { field: "statusName", header: t("ballot.status"), sortable: true },
    { field: "openDate", header: t("ballot.openDate"), sortable: true },
    { field: "endDate", header: t("ballot.endDate"), sortable: true },
  ];
  const [columns, setColumns] = useState<Column[]>(columnsData);
  const [selectedOption, setSelectedOption] = useState<Option | null>({
    name: t("ballot.myCommitteesBallot"),
    code: "1",
  });
  const options: Option[] = [
    { name: t("ballot.myCommitteesBallot"), code: "1" },
    { name: t("ballot.allCommitteesBallot"), code: "2" },
  ];
  const navigate = useNavigate();
  sessionStorage.clear();
  const dispatch = useDispatch();
  const toast = useRef<any>(null);
  const ballotListings: any = useSelector(
    (state: any) => state.ballotListingData.ballotTableData
  );
  const [filteredData, setFilteredData] = useState(ballotListings);
  const [ballotStatusList, setBallotStatusList] =
    useState<FormattedBallotStatusList>({});
  const { id } = useParams<{ id: string }>();
  const { hasPermission } = usePermission();
  const roleName = hasPermission();
  const userID = localStorage.getItem("userProfileID");
  const [queryParamsByRole, setQueryParamsByRole] = useState<QueryParamsByRole>(
    {
      Admin: {
        param: buildQueryParam(
          "Admin",
          id,
          userID,
          ballotStatusList,
          false,
          []
        ),
      },
      Staff: {
        param: buildQueryParam(
          "Staff",
          id,
          userID,
          ballotStatusList,
          false,
          []
        ),
      },
      Member: {
        param: buildQueryParam(
          "Member",
          id,
          userID,
          ballotStatusList,
          false,
          []
        ),
      },
    }
  );
  const getAllBallotStatus = async () => {
    try {
      const ballotStatusResponse: BallotStatusResponse =
        await fetchData(`BallotStatus/GetAll`);
      const allBallotStatusFormatted = ballotStatusResponse.Collection.map(
        (ballot: BallotStatus) => {
          return {
            id: ballot.Id,
            name: ballot.Name,
          };
        }
      );
      const formattedObject: FormattedBallotStatusList =
        allBallotStatusFormatted.reduce(
          (acc: FormattedBallotStatusList, status) => {
            acc[status.name] = { id: status.id };
            return acc;
          },
          {}
        );
      setQueryParamsByRole({
        Admin: {
          param: buildQueryParam(
            "Admin",
            id,
            userID,
            formattedObject,
            false,
            []
          ),
        },
        Staff: {
          param: buildQueryParam(
            "Staff",
            id,
            userID,
            formattedObject,
            false,
            []
          ),
        },
        Member: {
          param: buildQueryParam(
            "Member",
            id,
            userID,
            formattedObject,
            false,
            []
          ),
        },
      });
      setBallotStatusList(formattedObject);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handleDropdownChange = (e: DropdownChangeEvent) => {
    setLoading(true);
    const isCode2 = e.value?.code === "2";
    const updatedQueryParams = {
      Admin: {
        param: buildQueryParam(
          "Admin",
          id,
          userID,
          ballotStatusList,
          isCode2,
          searchFilters
        ),
      },
      Staff: {
        param: buildQueryParam(
          "Staff",
          id,
          userID,
          ballotStatusList,
          isCode2,
          searchFilters
        ),
      },
      Member: {
        param: buildQueryParam(
          "Member",
          id,
          userID,
          ballotStatusList,
          isCode2,
          searchFilters
        ),
      },
    };
    setQueryParamsByRole(updatedQueryParams);
    setSelectedOption(e.value);
  };
  const searchHandler = (searchFilters: any) => {
    setSearchFilters(searchFilters);
    const isCode2 = selectedOption?.code === "2";
    const updatedQueryParams = {
      Admin: {
        param: buildQueryParam(
          "Admin",
          id,
          userID,
          ballotStatusList,
          isCode2,
          searchFilters
        ),
      },
      Staff: {
        param: buildQueryParam(
          "Staff",
          id,
          userID,
          ballotStatusList,
          isCode2,
          searchFilters
        ),
      },
      Member: {
        param: buildQueryParam(
          "Member",
          id,
          userID,
          ballotStatusList,
          isCode2,
          searchFilters
        ),
      },
    };
    setQueryParamsByRole(updatedQueryParams);
  };
  const getAllBallot = async () => {
    try {
      const currentQueryParams = queryParamsByRole[
        roleName as keyof QueryParamsByRole
      ] || { param: "" };
      const allBallotData = await fetchData(
        `Ballot/BallotSearch?${currentQueryParams.param}&PageIndex=-1&SortDir=desc`
      );
      const ballotTableDetails = allBallotData.Collection.map(
        (ballot: TableBallotListing) => {
          return {
            Id: ballot.BallotId,
            ballotId: ballot.BallotNumber,
            numberOfRecords: `${ballot.NoOfRecord} Records`,
            primaryCommitteeResponsible: ballot.PrimaryResponsibleCommittees,
            ballotType: ballot.BallotType,
            ballotSubType: ballot.BallotSubType,
            ballotLevel: ballot.BallotLevel,
            statusName: ballot.Status,
            openDate:
              ballot.OpenDate !== null ? formatDate(ballot.OpenDate) : "-",
            endDate:
              ballot.CloseDate !== null ? formatDate(ballot.CloseDate) : "-",
            action: t("ballot.action"),
            sessionKey: "ballotTableDetails",
          };
        }
      );
      dispatch(
        allBallotListings.addAllBallotDetails({ value: ballotTableDetails })
      );
    } catch (err) {
      console.log(err, "err fetching data");
    } finally {
      setLoading(false);
    }
  };
  const ballotDeleteHandler = async (ballotId: string | null) => {
    try {
      await deleteData(`Ballot`, `${ballotId}`);
      dispatch(
        setToast({
          message: t("ballot.deletedSuccessfully"),
          severity: "success",
        })
      );
      getAllBallot();
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getActionItems = (rowData: any): MenuItem[] => [
    {
      label: t("ballot.viewEdit"),
      visible:
        rowData.statusName === BALLOT_DRAFT ||
        rowData.statusName === BALLOT_OPEN,
      command: () => {
        navigate(`/ballot/edit/${rowData.Id}`);
      },
    },
    {
      label: t("ballot.delete"),
      command: () => {
        ballotDeleteHandler(rowData.Id);
      },
    },
  ];
  useEffect(() => {
    getAllBallotStatus();
  }, []);
  useEffect(() => {
    if (roleName === "Admin" || roleName === "Staff") {
      getAllBallot();
    }
  }, [queryParamsByRole]);
  useEffect(() => {
    if (
      roleName === "Member" &&
      ballotStatusList[BALLOT_OUT_FOR_BALLOT]?.id &&
      ballotStatusList[BALLOT_CLOSE]?.id
    ) {
      getAllBallot();
    }
  }, [roleName, ballotStatusList, id, selectedOption]);

  useEffect(() => {
    if (roleName === "Admin" || roleName === "Staff") {
      getAllBallot();
      setColumns((prevData: Column[]) => [
        ...prevData,
        { field: "action", header: t("ballot.action") },
      ]);
    }
  }, [roleName, id, selectedOption]);

  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = ballotListings.filter(
      (record: BallotTextSearch) =>
        record.ballotId.toLowerCase().includes(lowercasedSearch) ||
        record.primaryCommitteeResponsible
          .toLowerCase()
          .includes(lowercasedSearch) ||
        record.ballotType.toLowerCase().includes(lowercasedSearch) ||
        record.ballotLevel.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  }, [search, ballotListings]);
  return (
    <>
      <div>
        <div className="flex flex-wrap align-items-center md:flex-nowrap md:justify-content-between py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchByBallotKeyword"
              placeholder={t("ballot.searchByBallotKeyword")}
              className="w-full"
              aria-label={t("ballot.searchByBallotKeyword")}
              onChange={(e) => setSearch(e.target.value)}
              tooltip={t("ballot.searchByBallotKeywordTooltip")}
              tooltipOptions={{
                position: "top",
                autoHide: true,
                event: "hover",
              }}
            />
          </IconField>
          <Dropdown
            inputId="myCommitteesBallot"
            value={selectedOption}
            onChange={handleDropdownChange}
            options={options}
            optionLabel="name"
            placeholder={t("ballot.myCommitteesBallot")}
            className="w-full md:w-16rem ml-auto"
            aria-label={t("ballot.myCommitteesBallot")}
          />
          <div className="flex gap-3 w-full md:w-auto align-items-center">
            <div className="relative">
              <BallotFilter
                queryParamsByRole={
                  queryParamsByRole[roleName as keyof QueryParamsByRole]?.param
                }
                searchHandler={searchHandler}
              />
            </div>

            <Button
              className="button-md gap-1 justify-content-center w-full md:w-auto"
              onClick={() => {
                dispatch(ballotDetails.reset());
                dispatch(ballotSelectRecords.reset());
                dispatch(ballotCommittees.reset());
                navigate("/ballot/create");
              }}
              disabled={
                !isDefaultConfiguration ||
                (roleName !== "Admin" && roleName !== "Staff")
              }
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("ballot.createNewBallot")}
              </span>
            </Button>
          </div>
        </div>
        <Toast ref={toast} />
        <BallotListingTable
          loading={loading}
          products={filteredData}
          columns={columns}
          getActionItems={getActionItems}
        />
      </div>
    </>
  );
};

export default BallotListing;
