import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store'; // Import the type for your store
import { clearToast } from '../slices/toastSlice';
import { Toast } from 'primereact/toast'

const ToastNotification: React.FC = () => {
  const dispatch = useDispatch();
  const message = useSelector((state: RootState) => state.toast.message);
  const severity = useSelector((state: RootState) => state.toast.severity); // Fetch severity from state
  const toastRef = React.useRef<Toast>(null);

 useEffect(() => {
    if (message) {
      toastRef.current?.show({
        severity: severity || 'info', // Use severity from state, fallback to 'info'
        summary: message,
        life: 3000, // 3 seconds visibility
      });
      const timer = setTimeout(() => dispatch(clearToast()), 3000); // Clear message after 3 seconds
      return () => clearTimeout(timer); // Clean up timeout on component unmount
    }
  }, [message, severity, dispatch]);
  return <Toast ref={toastRef} />;
};

export default ToastNotification;