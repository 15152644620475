import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { ConfirmDialog } from "primereact/confirmdialog";
import BallotTabComments from "../comments/BallotTabComments";
import BallotTabReport from "../BallotTabReport";
import BallotTabVoteSummary from "../BallotTabVoteSummary";
import BallotTabCastVote from "../BallotTabCastVote";
import BallotTabDetails from "../listing/BallotTabDetails";
import { BALLOT_DRAFT } from "../common/constants";
import BallotTabClousureReport from "./BallotTabs/BallotTabClosureReport";

interface BallotTabsProps {
  setActiveIndex: any;
  activeIndex: any;
  userRole: string;
  ballotStatus?: string;
  ballotIsConfedential?: boolean;
}

const BallotTabs: React.FC<BallotTabsProps> = ({
  setActiveIndex,
  activeIndex,
  userRole,
  ballotStatus = "",
  ballotIsConfedential,
}) => {
  const [t] = useTranslation("ballot");
  const [currentBallotStatus, setCurrentBallotStatus] = useState(ballotStatus);
  const [isSecret, setIsSecret] = useState(ballotIsConfedential);
  const location = useLocation();
  const activeTab = location.state?.activeTab || null;

  useEffect(() => {
    setCurrentBallotStatus(ballotStatus);
    setIsSecret(ballotIsConfedential);
    // Set the tab based on activeTab state
    if (activeTab === "closureReport") {
      setActiveIndex(3); // Assuming the closure report is at index 3
    }
    if (activeTab === "viewcomment") {
      setActiveIndex(5); // Assuming the closure report is at index 3
    }
  }, [ballotStatus, activeTab, setActiveIndex, ballotIsConfedential]);
  return (
    <>
      <ConfirmDialog />
      {/* Show when ballot is in draft */}
      {currentBallotStatus === BALLOT_DRAFT && (
        <TabView
          scrollable
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header={t("ballot.details")}>
            <BallotTabDetails />
          </TabPanel>
        </TabView>
      )}

      {/* Show when ballot is NOT in draft */}
      {currentBallotStatus !== BALLOT_DRAFT && (
        <TabView
          scrollable
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header={t("ballot.details")}>
            <BallotTabDetails />
          </TabPanel>
          {userRole !== "Member" && (
            <TabPanel header={t("ballot.castEditVoteOnBehalf")}>
              <BallotTabCastVote />
            </TabPanel>
          )}
          {userRole === "Member" && (
            <TabPanel header={t("ballot.castAVote")}>
              <BallotTabCastVote />
            </TabPanel>
          )}
          {currentBallotStatus === "Closed" && (
            <TabPanel header={t("ballot.ballotClosureReport")}>
              <BallotTabClousureReport setActiveIndex={setActiveIndex} />
            </TabPanel>
          )}
          {!isSecret ? (
            <TabPanel header={t("ballot.voteSummary")}>
              <BallotTabVoteSummary />
            </TabPanel>
          ) : (
            currentBallotStatus === "Closed" ||
            (currentBallotStatus === "Cancelled" && (
              <TabPanel header={t("ballot.voteSummary")}>
                <BallotTabVoteSummary />
              </TabPanel>
            ))
          )}
          <TabPanel header={t("ballot.comments")}>
            <BallotTabComments />
          </TabPanel>
          {currentBallotStatus !== "Closed" && userRole !== "Member" && (
            <TabPanel header={t("ballot.ballotReport")}>
              <BallotTabReport setActiveIndex={setActiveIndex} />
            </TabPanel>
          )}
        </TabView>
      )}
    </>
  );
};

export default BallotTabs;
