import { Button } from 'primereact/button';
import React, { useEffect, useMemo, useState } from 'react';
import FeatherIcon from "../../common/FeatherIconComponent";
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { fetchData, postData } from '../../../services/Ballot/apiservice';
import { fetchData as getData } from '../../../services/apiService';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import BallotConfigurationFields from "../ballotConfiguration/BasicConfigurationFields"
import { setToast } from '../../../slices/toastSlice';
import { useDispatch } from 'react-redux';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';

interface BallotConfigurationFormProp {
  handleBallotApprovalHideShow?: () => void;
  additionalConfigurationData: any;
  handleDropdownField?: any;
  errors?: any;
  setAdditionalConfigurationData: React.Dispatch<React.SetStateAction<any>>;
  handleCheckboxButtonChange: any;
  handleRadioButtonChange?: any;
  handleAdditionalInputChange: any;
  isShowFormField?: boolean;
  isCommitteeSelected?: boolean;
  isRecirculation?: boolean;
  handleMultiSelect: any;
  isDefaultConfiguration?: any
}

const AdditionalConfigurationForm: React.FC<BallotConfigurationFormProp> = ({
    handleBallotApprovalHideShow,
    additionalConfigurationData,
    handleDropdownField,
    errors,
    setAdditionalConfigurationData,
    isShowFormField = true,
    handleCheckboxButtonChange,
    handleRadioButtonChange,
    handleAdditionalInputChange,
    isCommitteeSelected = true,
    isRecirculation = false,
    handleMultiSelect,
    isDefaultConfiguration = false
}) => {
  const [t] = useTranslation("configuration");
const [VoteOptionCategory, setVoteOptionCategory] = useState<any>([]);
const [commentOptionCategory, setCommentOptionCategory] = useState<any>([]);
const [defaultVotingOptions, setDefaultVotingOption] = useState<any>([]);
const [committeeListOptions, setCommitteeList] = useState<any>([]);
const [operatorsOptions, setOperatorsOptions ] = useState<any>([
    {
        label: ">",
        value: ">"
    },
    {
        label: "<",
        value: "<"
    },
    {
      label: "=",
      value: "="
    },
    {
      label: "<=",
      value: "<="
    },
    {
      label: "=>",
      value: "=>"
    }
]);
const [logicalOperatorsOptions, setLogicalOperatorsOptions ] = useState<any>([
  {
      label: "AND",
      value: "AND"
  },
  {
      label: "OR",
      value: "OR"
  },
  {
    label: "NOT",
    value: "NOT"
  },
  {
    label: "XOR",
    value: "XOR"
  }
]);
const dispatch = useDispatch();
useEffect(() => {
    const fetchDataIntial = async () => {
      try {
        const [votingOptionCategory] = await Promise.all([
          fetchData(`VoteOptionCategory/GetAll`)
        ]);
        const commentOptions: any[] = [];
        const voteOptions: any[] = [];
        const defaultVotingOptions : any = [];
        votingOptionCategory.Collection.forEach((item: any) => {
            const option = {
              label: item.DisplayName,
              value: item.Id,
            };
            
            if (item.IsCommentOption) {
              commentOptions.push(option);
            } else {
              voteOptions.push(option);
            // Check if VoteOptionValues exists and is an array
                if (Array.isArray(item.VoteOptionValues)) {
                    item.VoteOptionValues.forEach((voteValue: any) => {
                    const defaultOption = {
                        label: voteValue.DisplayName,
                        value: voteValue.Id,
                    };
                    defaultVotingOptions.push(defaultOption);
                    });
                }
            }
        });
        setCommentOptionCategory(commentOptions);
        setVoteOptionCategory(voteOptions);
        setDefaultVotingOption(defaultVotingOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchDataIntial();
  }, []);

  const filteredVotingOptions = useMemo(() => {
    return defaultVotingOptions.filter(
      (option: any) => option.value !== additionalConfigurationData.defaultVoteOptionValueId
    );
  }, [defaultVotingOptions, additionalConfigurationData.defaultVoteOptionValueId]);


  useEffect(() => {
    const fetchAllCommittee = async () => {
      try {
        const details = await getData(`Committee/Search?PageIndex=-1`);
        const sorted = details.Collection.map((item: any) => ({
          label: item.Name,
          value: item.Id
        }));
        setCommitteeList(sorted);      
      } catch (error) {
        console.error("Error fetching configuration by ID:", error);
      }
    }
    fetchAllCommittee();
  }, [])
  
    return (
     <>
      <div className="card bg-white w-full mb-5 shadow-md">
        {
            isShowFormField && (
                <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
                    <Button
                    text
                    className="p-button-plain p-0"
                    aria-label={t("configuration.back")}
                    onClick={handleBallotApprovalHideShow}>
                    <FeatherIcon name="arrow-left" size={32} />
                    </Button>
                    <h2 className="text-title text-lg font-bold m-0">
                    {t("configuration.ballotAdditionalConfiguration")}
                    </h2>
                </div>
            )
        }
            
        <div className="p-5 cardBody">
            <div className="card bg-white w-full mb-5 shadow-md">
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-3">
                {
            isShowFormField && (
              <div className="grid grid-xl">
              <div className="flex flex-column gap-3">
                <fieldset className="border-none p-0">
                  <legend className="block font-bold text-input-label p-0 mb-3">
                    {t("configuration.applicableAt")}
                  </legend>
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton inputId="applicableAt1" name="applicableAt" value="Committee" 
                       onChange={(e) => handleRadioButtonChange(e, '')}
                      checked={isCommitteeSelected} />
                      <label htmlFor="applicableAt1" className="ml-2">Committee</label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton inputId="applicableAt2" name="applicableAt" value="Organization" 
                       onChange={(e) => handleRadioButtonChange(e, '')} 
                       checked={!isCommitteeSelected} />
                      <label htmlFor="applicableAt2" className="ml-2">Organization</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              </div>
            )
              }
                 {isShowFormField && isCommitteeSelected && (
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="committee"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.committee")}
                      </label>
                      <Dropdown
                        inputId="committee"
                        name="committee"
                        filter
                        value={additionalConfigurationData.committeeId}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e, 'committeeId')}
                        options={committeeListOptions}
                        optionLabel="label"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>
                )}

                  
                  {
                    isShowFormField && (
                      <BallotConfigurationFields
                      configurationData={additionalConfigurationData}
                      handleDropdownField={handleDropdownField}
                      errors={errors} />

                    )
                  }
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="minBallotDurationWeeks"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.minBallotDurationWeeks")}
                      </label>
                      <InputText
                          id="minBallotDuration"
                          name="minBallotDuration"
                          value={additionalConfigurationData.minBallotDuration}
                          onChange={(e) => handleAdditionalInputChange(e, 'minBallotDuration')} 
                          type="number"
                          placeholder={t("configuration.minBallotDurationWeeks")}
                          className="w-full"
                          min="1"
                          onInput={(e) => {
                            const inputElement = e.target as HTMLInputElement;
                            if (inputElement.value.length > 3) {
                              inputElement.value = inputElement.value.slice(0, 3);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (["e", "E", "+", "-"].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                      <label
                        htmlFor="votingOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOption")}
                      </label>
                      <Dropdown
                        inputId="votingOption"
                        name="votingOption"
                        value={additionalConfigurationData.voteOptionCategoryId}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e, 'voteOptionCategoryId')}
                        options={VoteOptionCategory}
                        optionLabel="label"
                        placeholder={t("configuration.selectAnOption")}
                      />
                       {errors?.voteOptionCategoryId && <small className="p-error">{errors?.voteOptionCategoryId}</small>}
                    </div>
                  </div>
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="defaultVotingOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.defaultVotingOption")}
                      </label>
                      <Dropdown
                        inputId="defaultVotingOption"
                        name="defaultVotingOption"
                        value={additionalConfigurationData.defaultVoteOptionValueId}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e, 'defaultVoteOptionValueId')}
                        options={defaultVotingOptions}
                        optionLabel="label"
                        placeholder={t("configuration.selectAnOption")}
                        disabled={!additionalConfigurationData.voteOptionCategoryId}
                      />
                        {errors?.defaultVoteOptionValueId && <small className="p-error">{errors?.defaultVoteOptionValueId}</small>}
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="votingConsensusCalculation"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingConsensusCalculation")}
                      </label>
                       <MultiSelect aria-label={t("configuration.votingConsensusCalculation")} value={additionalConfigurationData.ignoredVoteOptionValueIds} onChange={(e: MultiSelectChangeEvent) =>
                    handleMultiSelect(e)
                  }  options={filteredVotingOptions} optionLabel="label" 
                placeholder="Select Options" maxSelectedLabels={3} className="w-full md:w-20rem"
                disabled={!additionalConfigurationData.voteOptionCategoryId || !additionalConfigurationData.defaultVoteOptionValueId } />
                {errors?.ignoredVoteOptionValueIds && <small className="p-error">{errors?.ignoredVoteOptionValueIds}</small>}
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                      <label
                        htmlFor="commentOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.commentOption")}
                      </label>
                      <Dropdown
                        inputId="commentOption"
                        name="commentOption"
                        value={additionalConfigurationData.commentOptionCategoryId}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e, 'commentOptionCategoryId')}
                        options={commentOptionCategory}
                        optionLabel="label"
                        placeholder={t("configuration.selectAnOption")}
                      />
                       {errors?.commentOptionCategoryId && <small className="p-error">{errors?.commentOptionCategoryId}</small>}
                    </div>
                  </div>
                  {(isDefaultConfiguration || isRecirculation) && (
                      <>
                        <h3 className="font-bold text-lg m-0">
                          {t("configuration.recirculation")}
                        </h3>
                        <div className="flex flex-column gap-3 mb-3">
                          <div className="flex align-items-center">
                            <Checkbox
                              inputId="allowUsersToAddRemoveCommittees"
                              name="allowUsersToAddRemoveCommittees"
                              onChange={(e) =>
                                handleCheckboxButtonChange(e, "allowUsersToAddRemoveCommittees")
                              }
                              checked={additionalConfigurationData?.allowUsersToAddRemoveCommittees}
                              className="mr-2"
                            />
                            <label
                              htmlFor="allowUsersToAddRemoveCommittees"
                              className="text-base font-normal"
                            >
                              Allow users to add new or remove existing records from recirculating ballots
                            </label>
                          </div>
                          <div className="flex align-items-center">
                            <Checkbox
                              inputId="allowUsersToAddRemoveRecords"
                              name="allowUsersToAddRemoveRecords"
                              onChange={(e) =>
                                handleCheckboxButtonChange(e, "allowUsersToAddRemoveRecords")
                              }
                              checked={additionalConfigurationData?.allowUsersToAddRemoveRecords}
                              className="mr-2"
                            />
                            <label
                              htmlFor="allowUsersToAddRemoveRecords"
                              className="text-base font-normal"
                            >
                              Allow users to add new or remove existing committees from recirculating ballots
                            </label>
                          </div>
                        </div>
                      </>
                  )}
                  <h3 className="font-bold text-lg m-0">
                    {t("configuration.ballotApprovalCriteria")}
                  </h3>
                  <div className="ballot-approval-criteria-grid mb-3">
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator1")}*
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator1"
                        name="operator1"
                        value={additionalConfigurationData.acOperator1}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e, 'acOperator1')}
                        options={operatorsOptions}
                        optionLabel="label"
                        placeholder={t("configuration.operator1")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="percent1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        %*
                      </label>
                      <InputText
                        id="percent1"
                        name="percent1"
                        value={additionalConfigurationData.acPercentage1 || ''} // Bind the state value
                        onChange={(e) => handleAdditionalInputChange(e, 'acPercentage1')} 
                        type="number"
                        placeholder="%"
                        className="w-full"
                        disabled={!additionalConfigurationData.acOperator1}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="votingOptions"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOptions")}*
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="votingOptions"
                        name="votingOptions"
                        value={additionalConfigurationData.acVoteOptionValueId1}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e,  "acVoteOptionValueId1")}
                        options={defaultVotingOptions}
                        optionLabel="label"
                        placeholder={t("configuration.votingOptions")}
                        disabled={!additionalConfigurationData.acOperator1 || !additionalConfigurationData.acPercentage1 }
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator2"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator2")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator2"
                        name="operator2"
                        value={additionalConfigurationData.acOperator2}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e,  "acOperator2")}
                        options={logicalOperatorsOptions}
                        optionLabel="label"
                        placeholder={t("configuration.operator2")}
                        disabled={!additionalConfigurationData.acOperator1 || !additionalConfigurationData.acPercentage1 || !additionalConfigurationData.acVoteOptionValueId1 }
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator3"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator3")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator3"
                        name="operator3"
                        value={additionalConfigurationData.acOperator3}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e,  "acOperator3")}
                        options={operatorsOptions}
                        optionLabel="label"
                        placeholder={t("configuration.operator3")}
                        disabled={!additionalConfigurationData.acOperator1 || !additionalConfigurationData.acPercentage1 || !additionalConfigurationData.acVoteOptionValueId1 || !additionalConfigurationData.acOperator2 }
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="percent2"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        %
                      </label>
                      <InputText
                        id="percent2"
                        name="percent2"
                        value={additionalConfigurationData.acPercentage3 || ''}
                        onChange={(e) => handleAdditionalInputChange(e, 'acPercentage3')} 
                        type="number"
                        placeholder="%"
                        className="w-full"
                        disabled={!additionalConfigurationData.acOperator1 || !additionalConfigurationData.acPercentage1 || !additionalConfigurationData.acVoteOptionValueId1 || !additionalConfigurationData.acOperator2 || !additionalConfigurationData.acOperator3 }
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="votingOption1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOption")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="votingOption1"
                        name="votingOption1"
                        value={additionalConfigurationData.acVoteOptionValueId3}
                        onChange={(e: DropdownChangeEvent) => handleDropdownField(e,  "acVoteOptionValueId3")}
                        options={defaultVotingOptions.filter(
                          (option: any) =>
                            option.value !== additionalConfigurationData.acVoteOptionValueId1
                        )}
                        optionLabel="label"
                        placeholder={t("configuration.votingOption")}
                        disabled={!additionalConfigurationData.acOperator1 || !additionalConfigurationData.acPercentage1 || !additionalConfigurationData.acVoteOptionValueId1 || !additionalConfigurationData.acOperator2 || !additionalConfigurationData.acOperator3 || !additionalConfigurationData.acPercentage3 }
                      />
                    </div>
                  </div> 
                  {errors?.acOperator1 && <small className="p-error">{errors?.acOperator1}</small>}
                  {errors?.acOperator2 && <small className="p-error">{errors?.acOperator2}</small>}
                  <div className="flex align-items-center">
                      <Checkbox
                        inputId="isConsensusApprovalApplied"
                        onChange={(e) =>
                            handleCheckboxButtonChange(e, "isConsensusApprovalApplied")
                        }
                        checked={additionalConfigurationData.isConsensusApprovalApplied}
                        className="mr-2"
                      />
                      <label
                        htmlFor="isConsensusApprovalApplied"
                        className="text-base font-normal"
                      >
                        Consensus Required
                      </label>
                    </div>
                   <div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="thresholdPoints"
                          className="block font-bold text-input-label text-capitalize"
                        >
                          {t("configuration.thresholdPoints")}
                        </label>
                        <InputText
                          id="thresholdPoints"
                          name="thresholdPoints"
                          value={additionalConfigurationData.thresholdPoints}
                          onChange={(e) => handleAdditionalInputChange(e, 'thresholdPoints')} 
                          type="number"
                          placeholder={t("configuration.thresholdPoints")}
                          className="w-full"
                          min="1"
                          onInput={(e) => {
                            const inputElement = e.target as HTMLInputElement;
                            if (inputElement.value.length > 3) {
                              inputElement.value = inputElement.value.slice(0, 3);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (["e", "E", "+", "-"].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <p className="font-normal text-base m-0 mb-3">
                      {t("configuration.note")}: {t("configuration.thresholdPointsNote")}
                    </p>
                  </div>
                   <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="durationWithdrawDisapproval"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.durationWithdrawDisapproval")}
                      </label>
                      <InputText
                        id="durationWithdrawDisapproval"
                        name="durationWithdrawDisapproval"
                        value={additionalConfigurationData.durationForWithdrawDisapproval}
                        onChange={(e) => handleAdditionalInputChange(e, 'durationForWithdrawDisapproval')} 
                        type="number"
                        placeholder={t("configuration.durationWithdrawDisapproval")}
                        className="w-full"
                        min="1"
                        onInput={(e) => {
                          const inputElement = e.target as HTMLInputElement;
                          if (inputElement.value.length > 3) {
                            inputElement.value = inputElement.value.slice(0, 3);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (["e", "E", "+", "-"].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div> 
                </div>
              </div>
            </div>
        </div>
      </div>
     </>
    )
}

export default AdditionalConfigurationForm;