import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import Input from "../../utils/Input";
import fetchSignUpData from "../../services/authService";
import { BASE_URL, IDENTITY_URL } from "../../config";
import FeatherIcon from "../common/FeatherIconComponent";
import { getTenantNameFromDomain } from "../../utils/utils";
interface FormData {
  isDirty: true;
  isNew: true;
  tenantId: string;
  TenantDomain: any;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  isSSOLogin: false;
}

const SignUp: React.FC = () => {
  const [tenantId, setTenantId] = useState("");
  const [t, i18n] = useTranslation("auth");
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isNotRobot, setIsNotRobot] = useState(false);
  const [checkboxError, setCheckboxError] = useState<string>("");
  const toast = useRef<Toast>(null);
  const [formData, setFormData] = useState<FormData>({
    isDirty: true,
    isNew: true,
    tenantId: tenantId,
    TenantDomain: getTenantNameFromDomain(),
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "9800234565",
    password: "",
    isSSOLogin: false,
  });
  const getTenantId = async () => {
    try {
      let TenantDomain = getTenantNameFromDomain();
      let getTenantConfig: any = await fetch(
        `${IDENTITY_URL}/Tenant/GetTenantbyDomianName/${TenantDomain}`
      );
      let responseData = await getTenantConfig.json();
      setFormData({ ...formData, tenantId: responseData?.Id });
    } catch(e) {
      console.log('#########e', e)
    }
  };

  useEffect(() => {
    getTenantId();
  }, []);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotRobot(e.target.checked);
    if (e.target.checked) {
      setCheckboxError("");
    }
  };
  const handlebuttonClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const errors: Record<string, string> = {};
    let checkboxError = "";

    // Validation
    if (!formData.firstName.trim()) {
      errors.firstName = t("auth.firstNameText") + " " + t("auth.isRequired");
    }

    if (!formData.lastName.trim()) {
      errors.lastName = t("auth.lastNameText") + " " + t("auth.isRequired");
    }

    if (!formData.emailAddress.trim()) {
      errors.emailAddress = t("auth.emailAddress") + " " + t("auth.isRequired");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailAddress)) {
      errors.emailAddress = t("auth.invalidEmailFormat");
    }
    if (!formData.password.trim()) {
      errors.password = t("auth.password") + " " + t("auth.isRequired");
    } else if (formData.password.length < 8) {
      errors.password = t("auth.passwordCharactersLong");
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/.test(formData.password)) {
      errors.password = t("auth.passwordSpecialcharacter");
    }
    if (!isNotRobot) {
      checkboxError = t("auth.verifyNotRobot");
    }
    if (Object.keys(errors).length === 0 && !checkboxError) {
      try {
        const responseData = await fetchSignUpData<any>(formData);

        if (responseData && responseData.userVM.active === true) {
          const token = responseData.token;
          navigate("/email-sent", { state: { formData, token } });

          const UserCreation = {
            userId: responseData.userVM.id,
            gender: "N/A",
            prefix: "N/A",
            firstname: responseData.userVM.firstName,
            middleName: "",
            lastname: responseData.userVM.lastName,
            email: responseData.userVM.emailAddress,
            userName: responseData.userVM.emailAddress,
            displayName: responseData.userVM.firstName,
            publishingName: responseData.userVM.firstName,
            remark: "",
          };
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };

          const apiUrl = `${BASE_URL}/UserProfile`;

          const userProfileResponse = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(UserCreation),
          });
          const userProfileData = await userProfileResponse.json();
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          const apiErrors = error.response.data.errors;
          if (
            apiErrors &&
            apiErrors.RequestModel &&
            apiErrors.RequestModel.includes("User already exists")
          ) {
            setFormErrors({
              ...formErrors,
              emailAddress: t("auth.emailAlreadyExists"),
            });
          }
        } else if (error.message.includes("limit has reached")) {
          errorMessageToast(t("auth.limitReached"));
        } else if (error.message.includes("User already exists")) {
          errorMessageToast(t("auth.emailAlreadyExists"));
        } else {
          setFormErrors(errors);
          setCheckboxError(checkboxError);
        }
      }
    } else {
      setFormErrors(errors);
      setCheckboxError(checkboxError);
    }
  };

  const handleInputChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleCancel = () => {
    navigate("/");
  };

  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex align-items-center justify-content-center align-self-center h-full p-3">
        <div className="surface-card p-fluid sm:px-4 md:px-8 shadow-2 border-round w-full loginCards">
          <div className="flex flex-column gap-6 md:p-8 p-5">
            <div className="flex flex-column gap-1">
              <h1 className="text-title display-sm font-bold m-0">
                {t("auth.createYourAccount")}
              </h1>
              <p className="text-help text-base font-normal m-0">
                {t("auth.welcometoStanza")}
              </p>
            </div>
            <div className="flex flex-column gap-4">
              <form className="flex flex-column gap-6">
                <div className="flex flex-column gap-4">
                  <div className="flex flex-row gap-4">
                    <Input
                      type="text"
                      label={`${t("auth.firstNameText")}*`}
                      value={formData.firstName}
                      name="firstName"
                      onChange={(value) =>
                        handleInputChange("firstName", value)
                      }
                      placeholder={t("auth.enterFirstName")}
                      errorMessage={formErrors.firstName}
                    />
                    <Input
                      type="text"
                      label={`${t("auth.lastNameText")}*`}
                      value={formData.lastName}
                      name="lastName"
                      onChange={(value) => handleInputChange("lastName", value)}
                      placeholder={t("auth.enterLastName")}
                      errorMessage={formErrors.lastName}
                    />
                  </div>
                  <Input
                    type="text"
                    label={`${t("auth.email")}*`}
                    value={formData.emailAddress}
                    name="emailAddress"
                    onChange={(value) =>
                      handleInputChange("emailAddress", value)
                    }
                    placeholder={t("auth.enterYourEmailAddress")}
                    errorMessage={formErrors.emailAddress}
                  />
                  <div className="flex flex-column gap-2">
                    <label
                      htmlFor="password"
                      className={`block font-bold ${formErrors.password ? "p-error" : ""
                        }`}
                    >
                      {`${t("auth.password")} *`}
                    </label>
                    <div className="flex align-content-center relative">
                      <InputText
                        id="password"
                        type={showPassword ? "text" : "password"}
                        aria-describedby="passwordError"
                        value={formData.password}
                        name="password"
                        onChange={(value) =>
                          handleInputChange("password", value.target.value)
                        }
                        placeholder={t("auth.enterYourPassword")}
                        className={formErrors.password ? "p-invalid" : ""}
                      />
                      <Button
                        type="button"
                        text
                        className="p-2 absolute right-0 w-auto h-full"
                        aria-label={t("auth.togglePasswordVisibility")}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FeatherIcon
                            name="eye-off"
                            size={20}
                            color="var(--gray-600)"
                          />
                        ) : (
                          <FeatherIcon
                            name="eye"
                            size={20}
                            color="var(--gray-600)"
                          />
                        )}
                      </Button>
                    </div>
                    <span
                      id="passwordError"
                      className={`p-error font-bold text-capitalize ${formErrors.password ? "" : "error-hidden"}`}
                    >
                      {formErrors.password}
                    </span>
                  </div>

                  <div className="flex flex-column gap-2">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="notrobot"
                        aria-describedby="notrobotError"
                        checked={isNotRobot}
                        onChange={(e: any) => handleCheckboxChange(e)}
                        className="mr-2"
                      />
                      <label htmlFor="notrobot">I'm not a robot</label>
                    </div>
                    <span
                      id="notrobotError"
                      className={`p-error font-bold text-capitalize ${checkboxError ? "" : "error-hidden"}`}
                    >
                      {checkboxError}
                    </span>
                  </div>
                </div>
                <div className="flex gap-5">
                  <Button
                    label={`${t("auth.cancelText")}`}
                    className="w-full button-lg px-3"
                    severity="secondary"
                    onClick={handleCancel}
                  />
                  <Button
                    label={`${t("auth.signUpText")}`}
                    type="submit"
                    className="w-full button-lg px-3"
                    onClick={handlebuttonClick}
                  />
                </div>
              </form>
              <div>
                <span className="font-normal line-height-3">
                  {t("auth.alreadyHaveAccount")}
                </span>
                <Link to="/" className="ml-2 font-bold text-link">
                  {t("auth.logInText")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
