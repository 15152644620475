// config.tsx
import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
  PublicClientApplication,
  IPublicClientApplication,
  InteractionType,
} from "@azure/msal-browser";
import { getEnvInfo } from "./utils/storage";

// Define a global window interface if not already defined
interface Window {
  env?: {
    REACT_APP_IDENTITY_URL?: string;
    REACT_APP_COMMITTEE_URL?: string;
    REACT_APP_BALLOT_URL?: string;
    REACT_APP_LOCALHOST_DOMAINNAME?: string;
    REACT_APP_B2C_AUTHORITY_DOMAIN?: string;
    REACT_APP_B2C_SIGNIN_AUTHORITY_URL?: string;
    REACT_APP_B2C_CLIENT_ID?: string;
    REACT_APP_STANDARD_URL?: string;
  };
}

const env = getEnvInfo();

export const IDENTITY_URL =
  env?.REACT_APP_IDENTITY_URL || process.env.REACT_APP_IDENTITY_URL;
export const BASE_URL =
  env?.REACT_APP_COMMITTEE_URL || process.env.REACT_APP_BASE_URL;
export const BASE_URL_BALLOT =
  env?.REACT_APP_BALLOT_URL || process.env.REACT_APP_BASE_URL_BALLOT;
export const LOCALHOST_DOMAINNAME =
  env?.REACT_APP_LOCALHOST_DOMAINNAME ||
  process.env.REACT_APP_LOCALHOST_DOMAINNAME;
export const B2C_AUTHORITY_DOMAIN =
  env?.REACT_APP_B2C_AUTHORITY_DOMAIN ||
  process.env.REACT_APP_B2C_AUTHORITY_DOMAIN;
export const B2C_SIGNIN_AUTHORITY_URL =
  env?.REACT_APP_B2C_SIGNIN_AUTHORITY_URL ||
  process.env.REACT_APP_B2C_SIGNIN_AUTHORITY_URL;
export const B2C_CLIENT_ID =
  env?.REACT_APP_B2C_CLIENT_ID || process.env.REACT_APP_B2C_CLIENT_ID;
export const BASE_URL_STANDARD =
  env?.REACT_APP_STANDARD_URL || process.env.REACT_APP_BASE_URL_STANDARD;
// Optionally, you can add runtime validation or error handling
if (
  !IDENTITY_URL ||
  !BASE_URL ||
  !BASE_URL_BALLOT ||
  !LOCALHOST_DOMAINNAME ||
  !B2C_AUTHORITY_DOMAIN ||
  !B2C_SIGNIN_AUTHORITY_URL ||
  !B2C_CLIENT_ID ||
  !BASE_URL_STANDARD
) {
  console.error("One or more environment variables are missing.");
  // Optionally, throw an
}

function getUrlSegment(url: any, index: any): string {
  const defaultVal = "csagrpb2cstddev.onmicrosoft.com";
  try {
    const urlArr = url?.split("/");
    if (urlArr?.length > 0) {
      return urlArr[index] ? urlArr[index] : defaultVal;
    }
    return defaultVal;
  } catch (e) {
    return defaultVal;
  }
}

export const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;
const azureADAuthDomain = getUrlSegment(B2C_SIGNIN_AUTHORITY_URL, 3);

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: B2C_SIGNIN_AUTHORITY_URL,
    },
  },
  authorityDomain: B2C_AUTHORITY_DOMAIN,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    // clientId: "0fc4d519-1ad0-4ccc-b058-d1620de88e94",
    clientId: B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: window.location.origin + "/signin-oidc", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  apiTodoList: {
    endpoint: "", // http://localhost:5000/api/todolist
    scopes: {
      read: [`https://${azureADAuthDomain}/qa-api/qaapi.read`],
      write: [`https://${azureADAuthDomain}/qa-api/qaapi.write`],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    ...protectedResources.apiTodoList.scopes.read,
    ...protectedResources.apiTodoList.scopes.write,
  ],
};

export const tokenRequest = {
  scopes: [
    ...protectedResources.apiTodoList.scopes.read,
    ...protectedResources.apiTodoList.scopes.write,
  ],
  forceRefresh: false, // Set this to true if you want to always fetch a new token
};
