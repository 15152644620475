import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';

interface ConfirmDialogProps {
    visible: boolean;
    onHide: () => void;
    message: string;
    header: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmDialogComponent: React.FC<ConfirmDialogProps> = ({
    visible,
    onHide,
    message,
    header,
    onConfirm,
    onCancel,
}) => {
    return (
        <ConfirmDialog
            visible={visible}
            onHide={onHide}
            message={message}
            header={header}
            accept={onConfirm}
            reject={onCancel}
            draggable={false}
        />
    );
};

export default ConfirmDialogComponent;