import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { useLanguageContext } from "../LanguageContext";
import Table from "../common/Table";

const RemarkBallot: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/ballot")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("ballot.back")}</span>
        </Button>
        <div className="mb-5 flex flex-wrap gap-2">
          <div className="flex flex-column gap-2">
            <div className="flex gap-2">
              <h1 className="text-title display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
                {t("ballot.remarkEvidence")}
              </h1>
            </div>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>
        </div>
        <Table
          products={[]}
          loading={false}
          columns={[
            { field: "recordId", header: t("ballot.recordId") },
            {
              field: "onBehalfOfMember",
              header: t("ballot.onBehalfOfMember"),
            },
            { field: "remarksByStaff", header: t("ballot.remarksByStaff") },
            { field: "evidenceAttachment", header: t("ballot.evidenceAttachment") },
            { field: "dateTime", header: t("ballot.dateTime") },
          ]}
        />
      </div>
    </>
  );
};

export default RemarkBallot;
