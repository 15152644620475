import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import PreviewItem from "../common/PreviewItem";
import pdfIcon from "../../assets/icons/pdf.svg";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { setActiveStep } from "../../slices/createStandard";

import {
  getSelected,
  getAccreditationName,
  getCommitteeName,
} from "./utills/standardUtills";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface OtherConfigurationTabProps {
  showEditButton?: boolean;
  committeeData: any;
  hierarchyData: any;
  accreditationData: any;
}

const OtherConfigurationTab: React.FC<OtherConfigurationTabProps> = ({
  showEditButton,
  committeeData,
  hierarchyData,
  accreditationData,
}) => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const dispatch = useDispatch();

  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );

  const otherConfigurationDetails = createRequestDetail.otherConfiguration;

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.otherConfiguration")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => {
                  dispatch(setActiveStep(1));
                }}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("standard.accredited")}
                value={getSelected(otherConfigurationDetails.IsAccreditated)}
              />
              <PreviewItem
                label={t("standard.accreditationBody")}
                value={
                  otherConfigurationDetails.AccreditationBodyId
                    ? getAccreditationName(
                        otherConfigurationDetails.AccreditationBodyId,
                        accreditationData
                      )
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.standardJointlyDeveloped")}
                value={getSelected(
                  otherConfigurationDetails.IsStandardJointlyDeveloped
                )}
              />
              <PreviewItem
                label={t("standard.withInternalCommittee")}
                value={
                  otherConfigurationDetails.JointlyDevelopedwithInternalCommitteeIds
                    ? getCommitteeName(
                        otherConfigurationDetails.JointlyDevelopedwithInternalCommitteeIds,
                        committeeData
                      )
                    : ""
                }
              />
              <PreviewItem
                label={t("standard.withExternalCommittee")}
                value={
                  otherConfigurationDetails.jointlyDevelopedwithExternalCommittees
                    ? otherConfigurationDetails.jointlyDevelopedwithExternalCommittees
                    : "N/A"
                }
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.responsibleCommittee")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => {
                  dispatch(setActiveStep(1));
                }}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("standard.primaryResponsibleCommittee")}
                value={
                  otherConfigurationDetails.PrimaryCommitteeResponsibleCommitteeIds
                    ? getCommitteeName(
                        otherConfigurationDetails.PrimaryCommitteeResponsibleCommitteeIds,
                        committeeData
                      )
                    : ""
                }
              />
              <PreviewItem
                label={t("standard.board")}
                value={
                  otherConfigurationDetails.BoardCommitteeIds
                    ? getCommitteeName(
                        otherConfigurationDetails.BoardCommitteeIds,
                        committeeData
                      )
                    : ""
                }
              />
              <PreviewItem
                label={t("standard.standardCommittee")}
                value={
                  otherConfigurationDetails.StandardCommitteeIds
                    ? getCommitteeName(
                        otherConfigurationDetails.StandardCommitteeIds,
                        committeeData
                      )
                    : ""
                }
              />
              <PreviewItem
                label={t("standard.subTier")}
                value={
                  otherConfigurationDetails.JointlyDevelopedwithInternalCommitteeIds
                    ? getCommitteeName(
                        otherConfigurationDetails.SubTierCommitteeIds,
                        committeeData
                      )
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherConfigurationTab;
