import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  BALLOT_CLOSE,
  BALLOT_DRAFT,
  BALLOT_OPEN,
  BALLOT_OUT_FOR_BALLOT,
} from "../common/constants";
import ballotDetails from "../../../slices/ballotDetails";
import useBallotAccess from "../../../hooks/useBallotAccess";
import useUserData from "../../../hooks/useUserData";
interface BallotDetailsActionsProps {
  ballotId: string;
  ballotStatus: string;
  closeDate: string | null;
  ballotCancelHandler: () => void;
}

const BallotDetailsActions: React.FC<BallotDetailsActionsProps> = ({
  ballotId,
  ballotStatus,
  ballotCancelHandler,
  closeDate = "",
}) => {
  const [t] = useTranslation("ballot");
  const navigate = useNavigate();
  const menuBallot = useRef<Menu>(null);
  const showActionItem: MenuItem[] = [
    {
      label: "Duplicate Ballot",
    },
    {
      label: "Delete Ballot",
    },
    {
      label: "Cancel",
      command: ballotCancelHandler,
      visible: ballotStatus === BALLOT_DRAFT || ballotStatus === BALLOT_OPEN,
    },
  ];
  const { userProfileId } = useUserData();
  const toggleBallotMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      menuBallot.current?.toggle(event);
    },
    []
  );
  const {
    accessData: { CanManageBallot },
  } = useBallotAccess(ballotId, userProfileId);
  const isFutureDate = () => {
    if (closeDate) {
      const closeDateDetail = new Date(closeDate);
      const currentDate = new Date();
      return closeDateDetail > currentDate;
    }
  };

  const confirmDeleteDocument = () => {
    if (isFutureDate()) {
      confirmDialog({
        header: t("ballot.ballotCloseConfirmation"),
        message: t("ballot.closeBallotAlertMsg"),
        acceptLabel: "Yes",
        rejectLabel: "No",
        defaultFocus: "accept",
        accept: () => navigate(`/ballot/close/${ballotId}`),
      });
    } else {
      navigate(`/ballot/close/${ballotId}`);
    }
  };

  return (
    <>
      <div>
        {ballotStatus === BALLOT_OUT_FOR_BALLOT && (
          <>
            <Button
              className="button-md"
              onClick={() => confirmDeleteDocument()}
              disabled={!CanManageBallot}
              label={t("ballot.closeBallot")}
            ></Button>
          </>
        )}
        <Button
          className="button-md gap-1 ml-4"
          severity="secondary"
          onClick={toggleBallotMenu}
          disabled={!CanManageBallot}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              toggleBallotMenu(
                e as unknown as React.MouseEvent<HTMLButtonElement>
              );
            }
          }}
        >
          <span className="font-bold">{t("ballot.action")}</span>
          <FeatherIcon name="chevron-down" size={20} color="inherit" />
        </Button>
        <Menu
          model={showActionItem}
          popup
          ref={menuBallot}
          id="profileMenu"
          popupAlignment="right"
          role="listbox"
        />
      </div>
    </>
  );
};

export default BallotDetailsActions;
