import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import React, {
  useEffect,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
} from "react";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import {
  BallotComment,
  CommentApprovalTypeDetail,
  CommentApprovalTypeResponse,
  CommentApprovalTypeStatus,
  CommentPayload,
  CommentStatus,
  CommentStatusDetail,
  CommentStatusResponse,
  comments,
} from "./type";
import { formatDate } from "../../../utils/utils";
import CommentNewReply from "./CommentNewReply";
import CommentReply from "./CommentReply";
import { fetchData } from "../../../services/apiService";
import {
  putData,
  fetchData as ballotGetData,
} from "../../../services/Ballot/apiservice";
import RemarkPopover from "./RemarkPopover";
import EditCommentPopover from "./EditCommentPopover";
import RemarksSection from "./Remarks";
import useUserData from "../../../hooks/useUserData";
import useBallotAccess from "../../../hooks/useBallotAccess";
import { useParams } from "react-router-dom";

const initialStatuses: CommentStatus = {
  Open: {
    id: "",
  },
  Resolved: {
    id: "",
  },
  UnResolved: {
    id: "",
  },
};
const commentApprovalinitialStatuses: CommentApprovalTypeStatus = {
  "Disapproved without comment": {
    id: "",
  },
  "Unrelated to proposal": {
    id: "",
  },
};
const initialBallotComment: BallotComment = {
  Id: "",
  CommitteeMembershipId: "",
  CommitteeMemberName: "",
  CommitteeMemberPosition: "",
  CommitteeMemberStatusName: "",
  CommentText: "",
  CommentStatusId: "",
  CommentStatusName: "",
  CommentTypeId: "",
  CommentTypeName: "",
  CommentApprovalTypeId: "",
  CommentApprovalTypeName: "",
  BallotRecordVoteId: "",
  IsResolved: false,
  BookMarked: false,
  ParagraphSection: "",
  ProposedAction: "",
  Remark: null,
  RemarkAddedDate: null,
  RemarkAddedById: null,
  RemarkAddedByName: null,
  VoteOptionName: "",
  CreatedDate: "",
  RepliesCount: 0,
};
const CommentHierarchy = (props: comments) => {
  const { commentsInfo, recordsInfo, onStatusChange } = props;
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [replyIndex, setReplyIndex] = useState<number | null>(null);
  const [showReplies, setShowReplies] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [selectedComment, setSelectedComment] =
    useState<BallotComment>(initialBallotComment);
  const [allCommentStatus, setAllCommentStatus] =
    useState<CommentStatus>(initialStatuses);
  const [showcomment, setShowComment] = useState<boolean>(false);
  const [hasChildComment, setHasChildComment] = useState<boolean>(false);
  const [allCommentApprovalTypeStatus, setAllCommentApprovalTypeStatus] =
    useState<CommentApprovalTypeStatus>(commentApprovalinitialStatuses);
  const [filteredShowActionItems, setFilteredShowActionItems] = useState<
    MenuItem[]
  >([]);
  const [editVisibleHold, setEditVisibleHold] = useState<boolean>(false);
  const [remrksVisibleHold, setRemarksVisibleHold] = useState<boolean>(false);
  const [isDisapprove, setIsDisapprove] = useState<boolean>(false);
  const [commentPayload, setcommentPayload] = useState<CommentPayload>({
    isDirty: true,
    isNew: false,
    ballotId: recordsInfo?.ballot?.id,
    recordId: recordsInfo?.record?.id,
    committeeId: commentsInfo?.CommitteeId,
    committeeMembershipId: selectedComment?.CommitteeMembershipId,
    commentStatusId: selectedComment?.CommentStatusId,
    commentTypeId: selectedComment?.CommentTypeId,
    commentApprovalTypeId: selectedComment?.CommentApprovalTypeId,
    ballotRecordVoteId: selectedComment?.BallotRecordVoteId,
    isResolved: selectedComment?.IsResolved,
    bookMarked: selectedComment?.BookMarked,
    paragraphSection: selectedComment?.ParagraphSection,
    commentText: selectedComment?.CommentText,
    proposedAction: selectedComment?.ProposedAction,
    remark: selectedComment?.Remark,
    remarkAddedDate: selectedComment?.RemarkAddedDate,
    remarkAddedById: selectedComment?.RemarkAddedById,
    commentStatusName: selectedComment?.CommentStatusName,
  });
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const { id: ballotId } = useParams();
  const toast = useRef<any>(null);
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const { userProfileId } = useUserData();
  const {
    accessData: { CanRespondToComment },
  } = useBallotAccess(ballotId, userProfileId);
  // comments action
  const menuBallot = useRef<Menu>(null);
  const toggleBallotMenu = (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
    comment: BallotComment
  ) => {
    setSelectedComment(comment);
    if (menuBallot.current) {
      menuBallot.current.toggle(event);
    }
  };
  // comment payload
  const commentPayloadData = {
    isDirty: true,
    isNew: false,
    ballotId: recordsInfo?.ballot?.id,
    recordId: recordsInfo?.record?.id,
    committeeId: commentsInfo?.CommitteeId,
    committeeMembershipId: selectedComment?.CommitteeMembershipId,
    commentStatusId: selectedComment?.CommentStatusId,
    commentTypeId: selectedComment?.CommentTypeId,
    commentApprovalTypeId: selectedComment?.CommentApprovalTypeId,
    ballotRecordVoteId: selectedComment?.BallotRecordVoteId,
    isResolved: selectedComment?.IsResolved,
    bookMarked: selectedComment?.BookMarked,
    paragraphSection: selectedComment?.ParagraphSection,
    commentText: selectedComment?.CommentText,
    proposedAction: selectedComment?.ProposedAction,
    remark: selectedComment?.Remark,
    remarkAddedDate: selectedComment?.RemarkAddedDate,
    remarkAddedById: selectedComment?.RemarkAddedById,
  };
  const updateCommentStatusHandler = async (payload: CommentPayload) => {
    try {
      const updateStatus = await putData(
        `BallotComments`,
        `${selectedComment.Id}`,
        payload
      );
      toast.current.show({
        severity: "info",
        message: `Status Updated Successfully`,
        life: 3000,
        content: (props: any) => (
          <>
            <div
              className="flex flex-column align-items-left"
              style={{ flex: "1" }}
            >
              <div className="font-medium text-lg my-3 text-900">{`${props.message.message}`}</div>
            </div>
          </>
        ),
      });
      setTimeout(() => {
        onStatusChange();
      }, 1500);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchCommentApprovalTypeStatus = async () => {
    try {
      const CommentApprovalTypeStatuResponses: CommentApprovalTypeResponse =
        await fetchData(`CommentApprovalType/GetAll`);
      const commentApprovalTypeStatusFormatted =
        CommentApprovalTypeStatuResponses.Collection.map(
          (comment: CommentApprovalTypeDetail) => {
            return {
              id: comment.Id,
              name: comment.Name,
            };
          }
        );
      const transformedObject: CommentApprovalTypeStatus =
        commentApprovalTypeStatusFormatted.reduce(
          (acc: CommentApprovalTypeStatus, status) => {
            acc[status.name] = { id: status.id };
            return acc;
          },
          {}
        );
      setAllCommentApprovalTypeStatus(transformedObject);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchCommentStatus = async () => {
    try {
      const CommentStatuResponses: CommentStatusResponse =
        await fetchData(`CommentStatus/GetAll`);
      const commentStatusFormatted = CommentStatuResponses.Collection.map(
        (comment: CommentStatusDetail) => {
          return {
            id: comment.Id,
            name: comment.Name,
          };
        }
      );
      const transformedObject: CommentStatus = commentStatusFormatted.reduce(
        (acc: CommentStatus, status) => {
          acc[status.name] = { id: status.id };
          return acc;
        },
        {}
      );
      setAllCommentStatus(transformedObject);
    } catch (err) {
      console.log(err);
    }
  };
  const resolvedHandler = () => {
    const updateCommentPayload = { ...commentPayloadData };
    updateCommentPayload.commentStatusId = allCommentStatus["Resolved"].id;
    updateCommentStatusHandler(updateCommentPayload);
  };
  const unresolvedHandler = async () => {
    const updateCommentPayload = { ...commentPayloadData };
    updateCommentPayload.commentStatusId = allCommentStatus["UnResolved"].id;
    updateCommentStatusHandler(updateCommentPayload);
  };
  const disapprovedHandler = async (payload: CommentPayload) => {
    const updateCommentPayload = { ...payload };
    updateCommentPayload.commentApprovalTypeId =
      allCommentApprovalTypeStatus["Disapproved without comment"].id;
    updateCommentStatusHandler(updateCommentPayload);
  };
  const unrelatedHandler = async (payload: CommentPayload) => {
    const updateCommentPayload = { ...payload };
    updateCommentPayload.commentApprovalTypeId =
      allCommentApprovalTypeStatus["Unrelated to proposal"].id;
    updateCommentStatusHandler(updateCommentPayload);
  };
  const EditHandler = async (payload: CommentPayload) => {
    const updateCommentPayload = { ...payload };
    updateCommentStatusHandler(updateCommentPayload);
  };
  const showActionItem: MenuItem[] = [
    {
      label: "Edit",
      command: () => {
        setEditVisibleHold(true);
      },
    },
    {
      label: "Resolved",
      command: () => {
        resolvedHandler();
      },
    },
    {
      label: "Unresolved",
      command: () => {
        unresolvedHandler();
      },
      visible: false,
    },
    {
      label: "Disapproved without comment",
      command: () => {
        setIsDisapprove(true);
        setRemarksVisibleHold(true);
      },
    },
    {
      label: "Unrelated to proposal",
      command: () => {
        setIsDisapprove(false);
        setRemarksVisibleHold(true);
      },
    },
  ];
  useEffect(() => {
    const fetchEditPermission = async (commentId: string) => {
      try {
        const ballotReplyResponses = await ballotGetData(
          `BallotCommentResponses/GetBallotCommentResponsesByCommentId?ballotcommentId=${commentId}`
        );
        return ballotReplyResponses.Collection.length > 0;
      } catch (err) {
        console.log(err);
        return false;
      }
    };
    const filterActionItems = async () => {
      const hasEditPermission = selectedComment?.Id
        ? await fetchEditPermission(selectedComment.Id)
        : false;
      setHasChildComment(hasEditPermission);
      const filteredItems = showActionItem.filter((item) => {
        if (item.label === "Edit" && hasEditPermission) {
          return false;
        }
        if (
          item.label === "Disapproved without comment" &&
          selectedComment?.VoteOptionName !== "Disapproved"
        ) {
          return false;
        }
        if (
          item.label === "Unrelated to proposal" &&
          !(
            selectedComment?.VoteOptionName === "Approved" ||
            selectedComment?.VoteOptionName === "Abstain" ||
            selectedComment?.VoteOptionName === "Not Returned"
          )
        ) {
          return false;
        }
        return true;
      });
      setFilteredShowActionItems(filteredItems);
    };
    filterActionItems();
  }, [
    selectedComment,
    allCommentStatus,
    allCommentApprovalTypeStatus,
    showcomment,
  ]);
  const handleReplyClick = (
    index: number | null,
    commentPosted: boolean,
    comment?: BallotComment
  ) => {
    setReplyIndex(index === replyIndex ? null : index);
    if (commentPosted) {
      setShowComment(!showcomment);
      onStatusChange();
    }
    if (comment) {
      setSelectedComment(comment);
    }
  };
  const toggleReplies = (commentId: string) => {
    setShowReplies((prevShowReplies) => ({
      ...prevShowReplies,
      [commentId]: !prevShowReplies[commentId],
    }));
  };
  const hideRemarksHandler = () => {
    setRemarksVisibleHold(false);
  };
  const hideEditHandler = () => {
    setEditVisibleHold(false);
  };
  useEffect(() => {
    fetchCommentStatus();
    fetchCommentApprovalTypeStatus();
  }, []);
  useEffect(() => {
    setcommentPayload({
      isDirty: true,
      isNew: false,
      ballotId: recordsInfo?.ballot?.id,
      recordId: recordsInfo?.record?.id,
      committeeId: commentsInfo?.CommitteeId,
      committeeMembershipId: selectedComment?.CommitteeMembershipId,
      commentStatusId: selectedComment?.CommentStatusId,
      commentTypeId: selectedComment?.CommentTypeId,
      commentApprovalTypeId: selectedComment?.CommentApprovalTypeId,
      ballotRecordVoteId: selectedComment?.BallotRecordVoteId,
      isResolved: selectedComment?.IsResolved,
      bookMarked: selectedComment?.BookMarked,
      paragraphSection: selectedComment?.ParagraphSection,
      commentText: selectedComment?.CommentText,
      proposedAction: selectedComment?.ProposedAction,
      remark: selectedComment?.Remark,
      remarkAddedDate: selectedComment?.RemarkAddedDate,
      remarkAddedById: selectedComment?.RemarkAddedById,
      commentStatusName: selectedComment?.CommentStatusName,
    });
  }, [recordsInfo, selectedComment, commentsInfo]);

  return (
    <>
      <Toast ref={toast} />
      <RemarkPopover
        isVisible={remrksVisibleHold}
        payloadData={commentPayload}
        onSubmitHandler={isDisapprove ? disapprovedHandler : unrelatedHandler}
        onHideRemarks={hideRemarksHandler}
        isDisapprove={isDisapprove}
      />
      <EditCommentPopover
        isVisible={editVisibleHold}
        payloadData={commentPayload}
        onSubmitHandler={EditHandler}
        onHideRemarks={hideEditHandler}
      />
      <div className="p-4 cardBody flex flex-column gap-4 md:flex-row">
        <div className="flex flex-column gap-7 w-full">
          <div className="flex flex-column gap-3">
            <fieldset className="border-none p-0">
              <legend className="block font-bold text-input-label p-0 mb-3">{`${t("ballot.comments")} (${commentsInfo?.BallotComments.length})`}</legend>
              <div className="cardBody flex flex-column gap-7">
                {commentsInfo?.BallotComments.map((comments, index: number) => {
                  return (
                    <>
                      <div className="flex flex-column gap-4">
                        <div className="flex flex-column gap-3">
                          <div className="flex flex-wrap align-items-center justify-content-between py-3 gap-3">
                            <div className="flex flex-row align-items-center gap-2">
                              <Avatar
                                image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
                                size="large"
                                shape="circle"
                                className="flex-shrink-0"
                              />
                              <div className="flex flex-column gap-1">
                                <h3 className="text-title text-base font-bold text-capitalize m-0">
                                  {comments?.CommitteeMemberName}
                                </h3>
                                <p className="text-base font-normal text-capitalize m-0">
                                  {comments?.CommitteeMemberPosition}
                                </p>
                              </div>
                              <Badge
                                value={comments?.CommentStatusName}
                                severity="success"
                              ></Badge>
                              {comments.Remark && (
                                <Badge
                                  value={comments?.CommentApprovalTypeName}
                                  severity="success"
                                ></Badge>
                              )}
                            </div>
                            <div className="flex align-items-center gap-3">
                              <span>{formatDate(comments?.CreatedDate)}</span>
                              <Button
                                text
                                className="p-button-plain underline gap-1"
                                disabled={!CanRespondToComment}
                                onClick={(e) => toggleBallotMenu(e, comments)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    toggleBallotMenu(e, comments);
                                  }
                                }}
                              >
                                <span className="font-bold">
                                  {t("ballot.action")}
                                </span>
                                <FeatherIcon
                                  name="chevron-down"
                                  size={20}
                                  color="inherit"
                                />
                              </Button>
                              <Menu
                                model={filteredShowActionItems}
                                popup
                                ref={menuBallot}
                                id="profileMenu"
                                popupAlignment="right"
                                role="listbox"
                              />
                            </div>
                          </div>
                          <div className="flex flex-column gap-3">
                            <div>
                              <span className="inline font-bold text-capitalize text-input-label">
                                {t("ballot.paragraphSection")}:
                              </span>
                              <p className="p-0 inline ml-2">
                                {comments?.ParagraphSection}
                              </p>
                            </div>
                            <div>
                              <span className="inline font-bold text-capitalize text-input-label">
                                {t("ballot.typeOfComment")}:
                              </span>
                              <p className="p-0 inline ml-2">
                                {comments?.CommentTypeName}
                              </p>
                            </div>
                            <div>
                              <span className="inline font-bold text-capitalize text-input-label">
                                {t("ballot.comment")}:
                              </span>
                              <p className="p-0 inline ml-2">
                                {comments?.CommentText}
                              </p>
                            </div>
                            <div>
                              <span className="inline font-bold text-capitalize text-input-label">
                                {t("ballot.proposedAction")}:
                              </span>
                              <p className="p-0 inline ml-2">
                                {comments?.ProposedAction}
                              </p>
                            </div>
                          </div>
                          <div>
                            <Button
                              text
                              className="p-button-plain gap-2 p-0 underline"
                              disabled={!CanRespondToComment}
                              onClick={(e) => {
                                handleReplyClick(index, false, comments);
                              }}
                            >
                              <span className="font-normal text-capitalize">
                                {t("ballot.reply")}
                              </span>
                            </Button>
                            {comments?.RepliesCount > 0 && (
                              <Button
                                text
                                className="p-button-plain gap-2 p-0 underline ml-3"
                                onClick={() => toggleReplies(comments?.Id)}
                              >
                                <span className="font-normal text-capitalize">
                                  {!showReplies[comments?.Id]
                                    ? t("ballot.viewMoreReplies")
                                    : t("ballot.hideReplies")}
                                </span>
                              </Button>
                            )}
                          </div>
                          {/* Comment Reply */}
                          {comments.Remark && (
                            <RemarksSection comments={comments} />
                          )}
                          {replyIndex === index && (
                            <CommentNewReply
                              key={replyIndex}
                              ballotCommentId={comments?.Id}
                              onSubmitHandler={handleReplyClick}
                              parentHierarchyLevel={true}
                              hasChildComment={hasChildComment}
                              commentPayload={commentPayload}
                              allCommentStatus={allCommentStatus}
                              selectedComment={selectedComment}
                            />
                          )}
                          {showReplies[comments?.Id] && (
                            <CommentReply
                              ballotCommentId={comments?.Id}
                              showcomment={showcomment}
                              onChildCommentSubmit={handleReplyClick}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentHierarchy;
