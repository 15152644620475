import { getMembershipStatusIdByName } from "../../utils/utils";

const CreateApiData = {
  Id: "",
  isDirty: true,
  isNew: true,
  userProfileId: "",
  committeeId: "",
  membershipStatusId: "",
  membershipPositionId: "",
  categoryOfInterestId: "",
  remark: "",
  IsAgreeToTermandCondition: false,
  IsAgreeToConfidentialityAgreement: false,
  openPositionId: "" || null,
  AlternateMemberUserProfileId: "",
  isMemberReappoinmentRequest: false,
  isMemberReappointmentRecord: false,
};

export const formatCreateMemberData = async (data: any) => {
  if (data.Id) {
    CreateApiData["Id"] = data.Id;
  }
  CreateApiData["membershipPositionId"] = data.membershipPositionId;
  CreateApiData["categoryOfInterestId"] = data.categoryOfInterestId;
  CreateApiData["AlternateMemberUserProfileId"] =
    data.AlternateMemberUserProfileId;
  CreateApiData["remark"] = data.remark;
  CreateApiData["committeeId"] = data.committeeId;
  CreateApiData["userProfileId"] = data.userProfileId;
  CreateApiData["membershipStatusId"] =
    await getMembershipStatusIdByName("Draft");
  CreateApiData["openPositionId"] = data.openPositionId || null;
  return CreateApiData;
};

export const setOptionsFromRespose = (data: any, key: string) => {
  const optionObjectCollection: any[] = [];
  data.Collection.forEach((val: any, index: number) => {
    optionObjectCollection[index] = { name: val[key], id: val.Id };
  });
  return optionObjectCollection;
};
