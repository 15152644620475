import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { DropdownChangeEvent } from "primereact/dropdown";
import { RadioButtonChangeEvent } from "primereact/radiobutton";
import { MenuItem } from "primereact/menuitem";
import {
  fetchData,
  putData,
  postData,
} from "../../../services/Ballot/apiservice";
import { setToast } from "../../../slices/toastSlice";
import Table from "../../common/Table";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import {
  BallotType,
  BallotSubType,
} from "../ballotConfiguration/BallotConfigurationInterface";
import { BallotConfigurationType } from "./BallotConfigurationInterfaces";
import BallotConfigurationForm from "./BallotConfigurationForm";

const BallotConfigurationTab: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const dispatch = useDispatch();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [ballotConfigurationData, setBallotConfigurationData] = useState<
    BallotConfigurationType | any
  >({
    enableVotingPostBallotClosed: false,
    enableVotingBeforeBallotClosed: null,
    enableVotingAfterBallotEndDate: false,
    accreditationBodyId: null,
    ballotLevelId: "",
    ballotTypeId: "",
    ballotSubTypeId: "",
    recordTypeId: "",
    recordSubTypeId: "",
    votingFirstRemindersInDays: 0,
    votingSecondRemindersInDays: 0,
    votingThirdRemindersInDays: 0,
    allowUsersToAddRemoveRecords: null,
    allowUsersToAddRemoveCommittees: null,
    durationToAllowWithdrawDisapproval: 0,
    isDirty: false,
    isNew: true,
  });
  const [errors, setErrors] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [isBallotConfVisible, setIsBallotConfVisible] = useState(false);
  const [ballotTypes, setBallotTypes] = useState<BallotType[]>([]);
  const [ballotSubTypes, setBallotSubTypes] = useState<BallotSubType[]>([]);
  const [isWeightedBallot, setIsWeightedBallot] = useState<boolean>(false);
  const [isRecirculation, setIsRecirculation] = useState<boolean>(false);
  const [ballotConfigurationListing, setBallotConfigurationListing] =
    useState<any>([]);
  const [editConfigurationId, setEditConfigurationId] = useState<string>("");
  useEffect(() => {
    fetchDataballotConfiguration();
  }, []);

  useEffect(() => {
    if (isEditMode && editConfigurationId) {
      fetchConfigurationById(editConfigurationId);
    }
  }, [isEditMode, editConfigurationId]);

  const handleBallotConfHideShow = useCallback(() => {
    setIsEditMode(false);
    setEditConfigurationId("");
    resetForm();
    sessionStorage.removeItem("formattedTableData");
    setIsBallotConfVisible((prev) => !prev);
  }, []);

  const mapConfigurationDetails = useCallback(
    (details: any) => ({
      id: details.Id,
      accreditationBodyId: details.AccreditationBody?.Id,
      ballotLevelId: details.BallotLevel?.Id,
      ballotTypeId: details.BallotType?.Id,
      ballotSubTypeId: details.BallotSubType?.Id,
      recordTypeId: details.RecordType?.Id,
      recordSubTypeId: details.RecordSubType?.Id,
      enableVotingPostBallotClosed: details.EnableVotingPostBallotClosed,
      enableVotingBeforeBallotClosed: details.EnableVotingBeforeBallotClosed,
      enableVotingAfterBallotEndDate: details.EnableVotingAfterBallotEndDate,
      votingFirstRemindersInDays: details.VotingFirstRemindersInDays,
      votingSecondRemindersInDays: details.VotingSecondRemindersInDays,
      votingThirdRemindersInDays: details.VotingThirdRemindersInDays,
      allowUsersToAddRemoveRecords: details.AllowUsersToAddRemoveRecords,
      allowUsersToAddRemoveCommittees: details.AllowUsersToAddRemoveCommittees,
      durationToAllowWithdrawDisapproval: 0,
      isDirty: true,
      isNew: false,
    }),
    []
  );

  const formatBallotConfigurationData = useMemo(
    () => (collection: any[]) =>
      collection.map((item: any) => ({
        ballotLevel: item.BallotLevel?.Name,
        ballotType: item.BallotType?.Name,
        ballotSubType: item.BallotSubType?.Name,
        recordType: item.RecordType?.TypeName,
        recordSubType: item.RecordSubType?.Type,
        editVoteAfterClosed: item.EnableVotingPostBallotClosed,
        editVoteBeforeClosed: item.EnableVotingBeforeBallotClosed,
        firstNotification: item.VotingFirstRemindersInDays,
        secondNotification: item.VotingSecondRemindersInDays,
        thirdNotification: item.VotingThirdRemindersInDays,
        Id: item.Id,
        action: "Edit",
        sessionKey: "formattedTableData",
      })),
    []
  );

  const actionItems: MenuItem[] = useMemo(
    () => [
      {
        label: t("configuration.viewEdit"),
        command: async () => {
          const formattedTableData =
            sessionStorage.getItem("formattedTableData");
          if (formattedTableData) {
            setIsBallotConfVisible(true);
            setEditConfigurationId(formattedTableData);
            setIsEditMode(true);
          } else {
            setIsEditMode(false);
          }
        },
      },
    ],
    [t]
  );

  const fetchConfigurationById = useCallback(
    async (ConfigurationId: string) => {
      try {
        const details = await fetchData(
          `BallotConfiguration/${ConfigurationId}`
        );
        setBallotConfigurationData((prevData: any) => ({
          ...prevData,
          ...mapConfigurationDetails(details),
        }));
      } catch (error) {
        console.error("Error fetching configuration by ID:", error);
      }
    },
    [mapConfigurationDetails]
  );

  const fetchDataballotConfiguration = useCallback(async () => {
    try {
      const responseData = await fetchData("BallotConfiguration/GetAll");
      const formattedConfigList = formatBallotConfigurationData(
        responseData.Collection
      );
      const sortedList = formattedConfigList.map((config: any) => ({
        ...config,
        editVoteAfterClosed: config.editVoteAfterClosed ? "Yes" : "No",
        editVoteBeforeClosed: config.editVoteBeforeClosed ? "Yes" : "No",
      }));
      setBallotConfigurationListing(sortedList);
    } catch (error) {
      console.error("Error fetching Ballot Configuration:", error);
    }
  }, [formatBallotConfigurationData]);

  const handleDropdownField = useCallback(
    (
      event: DropdownChangeEvent,
      field: keyof typeof ballotConfigurationData
    ) => {
      setBallotConfigurationData((prevState: any) => ({
        ...prevState,
        [field]: event.value,
      }));
      const selectedBallot = ballotTypes.find((bt) => bt.Id === event.value);
      setIsWeightedBallot(selectedBallot?.Name === "Weighted Ballot");
      setIsRecirculation(
        ballotSubTypes.find((subType) => subType.Id === event.value)?.Name ===
          "Recirculation"
      );
    },
    [ballotTypes, ballotSubTypes]
  );

  const handleRadioButtonChange = useCallback(
    (
      e: RadioButtonChangeEvent,
      field: keyof typeof ballotConfigurationData
    ) => {
      setBallotConfigurationData((prevState: any) => ({
        ...prevState,
        [field]: e.value === "Yes",
      }));
    },
    []
  );

  const validateBallotConfiguration = useCallback(() => {
    let isValid = true;
    let validationErrors: any = {};
    const fieldLabels: { [key: string]: string } = {
      ballotLevelId: "Ballot Level",
      ballotTypeId: "Ballot Type",
      ballotSubTypeId: "Ballot Sub-Type",
      recordTypeId: "Record Type",
      recordSubTypeId: "Record Sub-Type",
      enableVotingBeforeBallotClosed: "This Field",
      votingFirstRemindersInDays: "First Reminder",
      votingSecondRemindersInDays: "Second Reminder",
      votingThirdRemindersInDays: "Third Reminder",
    };
    Object.keys(fieldLabels).forEach((field) => {
      if (field !== "enableVotingBeforeBallotClosed") {
        if (!ballotConfigurationData[field]) {
          validationErrors[field] = `${fieldLabels[field]} is required.`;
          isValid = false;
        }
      }
      if (field === "enableVotingBeforeBallotClosed") {
        if (ballotConfigurationData[field] === null) {
          validationErrors[field] = `${fieldLabels[field]} is required.`;
          isValid = false;
        }
      }
    });
    setErrors(validationErrors);
    return isValid;
  }, [ballotConfigurationData]);

  const resetForm = useCallback(() => {
    setBallotConfigurationData({
      enableVotingPostBallotClosed: false,
      enableVotingBeforeBallotClosed: null,
      enableVotingAfterBallotEndDate: false,
      accreditationBodyId: null,
      ballotLevelId: "",
      ballotTypeId: "",
      ballotSubTypeId: "",
      recordTypeId: "",
      recordSubTypeId: "",
      votingFirstRemindersInDays: 0,
      votingSecondRemindersInDays: 0,
      votingThirdRemindersInDays: 0,
      allowUsersToAddRemoveRecords: null,
      allowUsersToAddRemoveCommittees: null,
      durationToAllowWithdrawDisapproval: 0,
      isDirty: false,
      isNew: true,
    });
  }, []);

  const createPayload = useCallback(
    (isNew: boolean = false) => ({
      isDirty: isEditMode,
      isNew: !isEditMode,
      enableVotingPostBallotClosed:
        ballotConfigurationData.enableVotingPostBallotClosed ?? isNew,
      enableVotingBeforeBallotClosed:
        ballotConfigurationData.enableVotingBeforeBallotClosed ?? isNew,
      enableVotingAfterBallotEndDate:
        ballotConfigurationData.enableVotingAfterBallotEndDate ?? isNew,
      votingFirstRemindersInDays:
        parseInt(ballotConfigurationData.votingFirstRemindersInDays, 10) || 0,
      votingSecondRemindersInDays:
        parseInt(ballotConfigurationData.votingSecondRemindersInDays, 10) || 0,
      votingThirdRemindersInDays:
        parseInt(ballotConfigurationData.votingThirdRemindersInDays, 10) || 0,
      ballotLevelId: ballotConfigurationData.ballotLevelId ?? "",
      ballotTypeId: ballotConfigurationData.ballotTypeId ?? "",
      ballotSubTypeId: ballotConfigurationData.ballotSubTypeId ?? "",
      recordTypeId: ballotConfigurationData.recordTypeId ?? "",
      recordSubTypeId: ballotConfigurationData.recordSubTypeId ?? "",
      accreditationBodyId: ballotConfigurationData.accreditationBodyId ?? null,
      allowUsersToAddRemoveRecords:
        ballotConfigurationData.allowUsersToAddRemoveRecords ?? isNew,
      allowUsersToAddRemoveCommittees:
        ballotConfigurationData.allowUsersToAddRemoveCommittees ?? isNew,
      durationToAllowWithdrawDisapproval:
        ballotConfigurationData.durationToAllowWithdrawDisapproval,
    }),
    [ballotConfigurationData]
  );

  const saveConfiguration = async () => {
    const isValid = validateBallotConfiguration();
    if (!isValid) {
      console.error("Validation failed. Please correct the errors.");
      return;
    }
    try {
      const existingConfigs = await fetchData("BallotConfiguration/GetAll");
      const duplicateConfig = existingConfigs.Collection.some((config: any) => {
        const isSameConfig =
          config?.BallotLevel?.Id === ballotConfigurationData?.ballotLevelId &&
          config?.BallotType?.Id === ballotConfigurationData?.ballotTypeId &&
          config?.BallotSubType?.Id ===
            ballotConfigurationData?.ballotSubTypeId &&
          config?.RecordType?.Id === ballotConfigurationData?.recordTypeId &&
          config?.RecordSubType?.Id ===
            ballotConfigurationData?.recordSubTypeId;
        if (isEditMode) {
          return isSameConfig && config?.Id !== ballotConfigurationData?.id;
        }
        return isSameConfig;
      });
      if (duplicateConfig) {
        console.error(
          "Duplicate configuration found. You cannot save the same type of configuration."
        );
        dispatch(
          setToast({
            message: t("configuration.duplicateConfigurationError"),
            severity: "error",
          })
        );
        return; // Exit if duplicate is found
      }
      const payload = createPayload(isEditMode);
      if (isEditMode) {
        const response = await putData(
          "BallotConfiguration",
          editConfigurationId,
          payload
        );
        dispatch(
          setToast({
            message: t("configuration.configurationUpdatedSuccess"),
            severity: "success",
          })
        );
      } else {
        const response = await postData("BallotConfiguration", payload);
        dispatch(
          setToast({
            message: t("configuration.configurationAddedSuccess"),
            severity: "success",
          })
        );
      }
      resetForm();
      handleBallotConfHideShow();
      fetchDataballotConfiguration();
    } catch (error: any) {
      console.error("Error saving configuration:", error);
      if (error.response) {
        console.error("Server responded with an error:", error.response);
      } else if (error.request) {
        console.error("Network error occurred:", error.request);
      }
      dispatch(
        setToast({
          message: t("configuration.configurationSaveError"),
          severity: "error",
        })
      );
    }
  };

  return (
    <>
      <div>
        {!isBallotConfVisible && (
          <div>
            <div className="flex justify-content-end py-3 gap-3 w-full">
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleBallotConfHideShow}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("configuration.addNewConfiguration")}
                </span>
              </Button>
            </div>
            <Table
              loading={false}
              products={ballotConfigurationListing}
              columns={[
                {
                  field: "ballotLevel",
                  header: t("configuration.ballotLevel"),
                  body: (rowData: any) => rowData.ballotLevel ?? "-",
                },
                {
                  field: "ballotType",
                  header: t("configuration.ballotType"),
                  body: (rowData: any) => rowData.ballotType ?? "-",
                },
                {
                  field: "ballotSubType",
                  header: t("configuration.ballotSubType"),
                  body: (rowData: any) => rowData.ballotSubType ?? "-",
                },
                {
                  field: "recordType",
                  header: t("configuration.recordType"),
                  body: (rowData: any) => rowData.recordType ?? "-",
                },
                {
                  field: "recordSubType",
                  header: t("configuration.recordSubType"),
                  body: (rowData: any) => rowData.recordSubType ?? "-",
                },

                {
                  field: "editVoteAfterClosed",
                  header: t("configuration.editVoteAfterClosed"),
                },
                {
                  field: "editVoteBeforeClosed",
                  header: t("configuration.editVoteBeforeClosed"),
                },
                {
                  field: "firstNotification",
                  header: t("configuration.firstNotification"),
                },
                {
                  field: "secondNotification",
                  header: t("configuration.secondNotification"),
                },
                {
                  field: "thirdNotification",
                  header: t("configuration.thirdNotification"),
                },
                { field: "action", header: t("configuration.action") },
              ]}
              showCheckbox={false}
              actionItems={actionItems}
            />
          </div>
        )}
        {isBallotConfVisible && (
          <>
            <BallotConfigurationForm
              handleBallotConfHideShow={handleBallotConfHideShow}
              ballotConfigurationData={ballotConfigurationData}
              isWeightedBallot={isWeightedBallot}
              isRecirculation={isRecirculation}
              handleDropdownField={handleDropdownField}
              handleRadioButtonChange={handleRadioButtonChange}
              errors={errors}
              setBallotConfigurationData={setBallotConfigurationData}
            />
            <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("configuration.cancel")}
                className="button-md"
                severity="secondary"
                type="button"
                onClick={handleBallotConfHideShow}
              />
              <Button
                label={t("configuration.save")}
                className="button-md"
                type="button"
                onClick={saveConfiguration}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default BallotConfigurationTab;
