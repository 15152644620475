import { Committee } from "../ballotCommitte";
import { fetchData as getData } from "../../../services/apiService";
import { fetchData } from "../../../services/Ballot/apiservice";
import { formatDate } from "../../../utils/utils";
let ballotId = "";
let allBallotCommittee: any = [];
let node: any = [];
let tableParentCommitteesData: any = [];
let allCommitteeMembership: any = [];
export const fetchCommittees = async (
  ballotUid: string,
  ballotCommittees: any,
  disableColumn: boolean,
  ballotLevelSelect: any,
  ballotRecordStatusId: string,
  primaryCommitteeId: string
) => {
  try {
    ballotId = ballotUid;
    allBallotCommittee = ballotCommittees;
    const selectCommitteeData = await getData(
      `Committee/GridSearch?&PageIndex=-1`
    );
    const parentCommittees: Committee[] = selectCommitteeData.Collection.filter(
      (committee: any) =>
        committee.CommitteeId === primaryCommitteeId ||
        committee.CommitteeLevel === ballotLevelSelect.value
    );
    tableParentCommitteesData = parentCommittees;
    const formatedParentCommittee = parentCommittees.map(
      async (committee: any) => {
        let matchedCommittee = allBallotCommittee.find(
          (prev: any) => prev.CommitteeId === committee.CommitteeId
        );
        let isRevivewCommentCommittee = matchedCommittee
          ? matchedCommittee.forReviewAndComment
          : null;
        let defaultSelect = committee.CommitteeId === primaryCommitteeId;
        return {
          key: `${committee.CommitteeId}-${committee.CommitteeId}`,
          Id: committee.CommitteeId,
          data: {
            Id: committee.CommitteeId,
            Abbreviation: committee.CommitteeName,
            ParentCommitteeId: committee.CommitteeId,
            CreatedDate: committee.CreatedDate,
            ModifiedDate: committee.ModifiedDate,
            Name: committee.CommitteeName,
            Count: `${committee.MemberCount} Members`,
            Level: committee.CommitteeLevel,
            balloting:
              matchedCommittee && !disableColumn
                ? !matchedCommittee.ForReviewAndComment
                : defaultSelect
                  ? true
                  : null,
            review: matchedCommittee
              ? matchedCommittee.ForReviewAndComment
              : defaultSelect && disableColumn
                ? true
                : null,
            isDirty: false,
            isNew: true,
            ballotId: ballotId,
            committeeId: committee.CommitteeId,
            ballotRecordStatusId: ballotRecordStatusId,
            isRevivewCommentCommittee: isRevivewCommentCommittee,
            ballotCommitteeId: "00000000-0000-0000-0000-000000000000",
            isActive: true,
            isDeleted: false,
          },
          children: [],
        };
      }
    );

    const parentCommitteesData = await Promise.all(formatedParentCommittee);
    node = parentCommitteesData;
    return node;
  } catch (err) {
    console.log(err, "error fetching committees");
  }
};

const updateNode = (nodes: any, parentId: any, subCommittees: any) => {
  return nodes.map((node: any) => {
    if (node.Id === parentId) {
      return {
        ...node,
        children: subCommittees,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateNode(node.children, parentId, subCommittees),
      };
    }
    return node;
  });
};

export const BallotType = async () => {
  try {
    const response: any = await fetch(`ballotType/GetAll`);
    const data = response.Collection;
    return data;
  } catch (error) {
    console.error("Error fetching ballot types:", error);
    throw error;
  }
};

export const getAllBallotDetails = async (
  ballotDetailsId: string | undefined
) => {
  try {
    const ballotDetailsData = await fetchData(`Ballot/${ballotDetailsId}`);
    return ballotDetailsData;
  } catch (err) {
    console.log(err, "errr");
  }
};

export const queryStringHandler = (filters: any) =>
  filters
    .map((filter: any, index: any) => {
      const value = Array.isArray(filter.value)
        ? filter.value.map((val: string) => val).join(",")
        : encodeURIComponent(filter.value);
      return `Filters[${index}].Key=${filter.key}&Filters[${index}].Value=${value}`;
    })
    .join("&");

export const getTableSelectedData = (selectedRecordsData: any) => {
  return selectedRecordsData?.collection
    .filter(
      (record: any) =>
        record?.record?.recordStatus?.statusName === "Open" ||
        record?.record?.recordStatus?.statusName === "Reopen"
    )
    .map((record: any) => {
      return {
        id: record?.record?.id,
        recordNumber: record?.record?.recordNumber,
        ballotRecordId: record?.id,
        applicantName: record?.record?.userProfile?.firstname,
        applicantId: record?.record?.userProfile?.id,
        committeeAppliedFor: record?.record?.committee?.name,
        committeeAppliedId: record?.record?.committee?.id,
        positionAppliedFor: record?.record?.membershipPosition?.positionName,
        positionId: record?.record?.membershipPosition?.id,
        categoryOfInterest: record?.record?.categoryOfInterest?.categoryName,
        categoryOfInterestId: record?.record?.categoryOfInterest?.id,
        requestedOn: formatDate(record?.record?.createdDate),
        statusName: record?.record?.recordStatus?.statusName,
        createdDate: record?.createdDate,
        ModifiedDate: record?.modifiedDate,
      };
    });
};
