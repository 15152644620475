import { LOCALHOST_DOMAINNAME } from "../../../config";
import { fetchData } from "../../../services/apiService";
import { ConferenceFormData } from "./CommitteeAddConference";

interface Option {
  name: string;
  id: string;
}

export const createJsonData = (
  formData: ConferenceFormData,
  meetingTypes: Option[]
) => {
  const meetingTypeId = meetingTypes.find(
    (type) => type.name === "Meeting"
  )?.id;

  if (!meetingTypeId) {
    console.error("Meeting type 'Meeting' not found in meetingTypes.");
    return null;
  }

  const flattenedMeetings =
    formData.committeeMeetings
      ?.flatMap((day) => {
        if (!Array.isArray(day)) {
          console.error("Invalid data structure: 'day' is not an array", day);
          return []; // Skip non-array entries
        }

        return day.map((meeting) => {
          if (!meeting) return null;

          return {
            meetingName: meeting.meetingName || "",
            meetingStartTime: meeting.meetingStartTime || "",
            meetingEndTime: meeting.meetingEndTime || "",
            meetingLocation: meeting.meetingLocation || "",
            meetingAgenda: meeting.meetingAgenda || "",
            meetingNumber: meeting.meetingNumber || "",
            meetingStatusId: meeting.meetingStatusId || "",
            meetingTypeId: meetingTypeId,
            meetingCommitteeDTO:
              meeting.meetingCommitteeDTO?.map(
                (committee: { committeeId: any; userProfileIds: any }) => ({
                  committeeId: committee.committeeId || "",
                  userProfileIds: committee.userProfileIds || [],
                })
              ) || [],
          };
        });
      })
      .filter(Boolean) || [];

  return {
    conferenceName: formData.conferenceName,
    conferenceNumber: formData.conferenceNumber || "12345",
    meetingTypeId: formData.meetingTypeId,
    conferenceStartDate: formData.conferenceStartDate?.toISOString() || "",
    conferenceEndDate: formData.conferenceEndDate?.toISOString() || "",
    conferenceStatusId: null,
    committeeMeetings: flattenedMeetings,
  };
};
