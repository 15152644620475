import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { RootState } from "../../store/store";
import {
  ConfirmDialog,
  ConfirmDialogProps,
  confirmDialog,
} from "primereact/confirmdialog";
import Input from "../../utils/Input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import {
  deleteData,
  fetchData,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import "./Profile.scss";
import { InputText } from "primereact/inputtext";
import { usePermission } from "../auth/Authorization";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
interface PhoneNumber {
  IsNew: boolean;
  IsActive: true;
  IsDeleted: false;
  IsDirty?: boolean;
  Type: string;
  DialingCode: string;
  PhoneNumber: string;
  IsPreferredContact: boolean;
  UserProfileId: string;
  Id: string;
}

interface FormData {
  gender: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  isDirty?: boolean;
  isNew?: boolean;
  userId?: string;
  tenantId?: string;
  remark: string;
  userName: string;
  displayName: string;
  publishingName: string;
  LanguageId: string;
}

interface DropdownOption {
  label: string;
  value: string;
}

interface RowData {
  id: number;
  selectedType: string | null;
  selectedExtension: string | null;
  PhoneNumber: string;
  preferredContact: boolean;
}

const EditBasicDetails = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const [data, setData] = useState(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({
    gender: "",
    prefix: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const navigate = useNavigate();
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");
  const [userId, setUserId] = useState("");
  const [languageId, setLanguageId] = useState("");
  const [readOnlyEmail, setReadOnlyEmail] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const createProfileDetails = useSelector(
    (state: RootState) => state.createProfile
  );

  const profileID = localStorage.getItem("userProfileID") || "";
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");

  const genderOptions: DropdownOption[] = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const prefixOptions: DropdownOption[] = [
    { label: "Mr.", value: "Mr" },
    { label: "Mrs.", value: "Mrs" },
    { label: "Ms.", value: "Ms" },
  ];
  const typeOptions: DropdownOption[] = [
    { label: "Home", value: "home" },
    { label: "Work", value: "work" },
  ];
  const extensionOptions: DropdownOption[] = [
    { label: "+1", value: "+1" },
    { label: "+91", value: "+91" },
  ];
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber[]>([
    {
      IsNew: true,
      IsActive: true,
      IsDeleted: false,
      IsDirty: true,
      Type: "",
      DialingCode: "",
      PhoneNumber: "",
      IsPreferredContact: false,
      UserProfileId: profileID,
      Id: "",
    },
  ]);
  const [formData, setFormData] = useState<FormData>({
    gender: "",
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    remark: "",
    userName: "",
    displayName: "",
    publishingName: "",
    LanguageId: "",
  });
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const handleSaveButtonClick = () => {
    setSaveButtonClicked(true);
  };

  useEffect(() => {
    if (
      createProfileDetails &&
      createProfileDetails.createProfile &&
      createProfileDetails.createProfile.UserProfileId
    ) {
      setUserProfileId(createProfileDetails.createProfile.UserProfileId);
    } else {
      // Retrieve data from session storage if not available in Redux
      const experienceIdFromSessionStorage =
        sessionStorage.getItem("detailsID");
      setUserProfileId(experienceIdFromSessionStorage || userProfileId);
    }
  }, [createProfileDetails]);

  useEffect(() => {
    const detailsID = sessionStorage.getItem("detailsID");
    if (detailsID) {
      setReadOnlyEmail(true);
    }
  }, []);

  useEffect(() => {
    const getCurrentUserProfile = async () => {
      const responseData = await fetchData("UserProfile/GetCurrentUserProfile");
      setLanguageId(responseData.LanguageId);
    };
    getCurrentUserProfile();
  }, [languageId]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const fetchDataByID = async () => {
    try {
      // Fetch user profile data
      const userProfileData = await fetchData("UserProfile", userProfileId);

      const firstName = userProfileData.Firstname;
      const lastName = userProfileData.Lastname;
      const displayName =
        userProfileData.DisplayName || `${firstName} ${lastName}`;
      const publishingName =
        userProfileData.PublishingName || `${firstName} ${lastName}`;
      setUserId(userProfileData.UserId);
      setFormData({
        gender: userProfileData.Gender,
        prefix: userProfileData.Prefix,
        firstName: userProfileData.Firstname,
        middleName: userProfileData.MiddleName,
        lastName: userProfileData.Lastname,
        email: userProfileData.Email,
        remark: "",
        userName: userProfileData.Email,
        displayName: displayName,
        publishingName: publishingName,
        LanguageId: languageId,
      });

      // Fetch phone numbers data
      const phoneNumbersData = await getListByParentId(
        "UserPhone",
        userProfileId
      );
      if (
        phoneNumbersData.TotalCount !== undefined &&
        phoneNumbersData.TotalCount > 0
      ) {
        setPhoneNumber(phoneNumbersData.Collection);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (userProfileId) {
      fetchDataByID();
    }
  }, [userProfileId]);

  const handleDeleteButtonClick = (phoneId: any) => {
    setDeleteButtonClicked(true);
    confirmDelete(phoneId);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const onConfirm = () => {
    hideDialog();
  };

  const onCancel = () => {
    hideDialog();
  };

  const confirmDialogProps: ConfirmDialogProps = {
    header: t("profile.confirmation"),
    message: t("profile.areYouSureYouWantToProceed"),
    visible: visible,
    onHide: hideDialog,
    accept: onConfirm,
    reject: onCancel,
  };

  const addNewRow = () => {
    setPhoneNumber([
      ...phoneNumber,
      {
        IsNew: true,
        IsActive: true,
        IsDeleted: false,
        IsDirty: true,
        Type: "",
        DialingCode: "",
        PhoneNumber: "",
        IsPreferredContact: false,
        UserProfileId: profileID,
        Id: "",
      },
    ]);

    // To focus on newly added row first element "phoneType"
    setTimeout(() => {
      focusOnField(`phoneType-${phoneNumber.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const handleDeleteNumber = async (phoneId: any) => {
    try {
      if (phoneId) {
        const postDataResponse = deleteData("UserPhone", phoneId);
        navigate("/profile/");
        fetchDataByID();
        return postDataResponse;
      } else {
        console.error("No id found in parameters.");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const confirmDelete = (phoneId: any) => {
    confirmDialog({
      message: t("profile.deletePhoneMsg"),
      header: t("profile.deletePhone"),
      icon: "",
      acceptLabel: t("profile.deleteContactNor"),
      rejectLabel: t("profile.cancel"),
      acceptClassName: "button-md p-button-danger",
      rejectClassName: "button-md p-button-text p-button-plain underline",
      defaultFocus: "accept",
      accept: () => handleDeleteNumber(phoneId).then(() => {}),
      reject: () => {
        setDeleteButtonClicked(false);
      },
    });
  };

  const fieldLabels: Record<string, string> = {
    gender: t("profile.gender"),
    prefix: t("profile.prefix"),
    firstName: t("profile.firstname"),
    middleName: t("profile.middlename"),
    lastName: t("profile.lastname"),
    email: t("profile.email"),
    remark: t("profile.remark"),
    userName: t("profile.username"),
    displayName: t("profile.preferredDisplayName"),
    publishingName: t("profile.preferredPublishingName"),
  };

  const handleInputChange = (name: string, value: string) => {
    // Update form data state
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    // Update form errors state
    const updatedFormErrors = { ...formErrors };
    if (!value) {
      updatedFormErrors[name] =
        `${fieldLabels[name]} ${t("profile.isRequired")}`;
    } else {
      delete updatedFormErrors[name];
    }
    setFormErrors(updatedFormErrors);
  };

  const handlePhoneTypeChange = (index: number, value: string) => {
    const updatedPhoneNumbers = [...phoneNumber];
    updatedPhoneNumbers[index].Type = value;

    // Remove the validation error for phone type
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors[`Type-${index}`];

    setPhoneNumber(updatedPhoneNumbers);
    setFormErrors(updatedFormErrors); // Update formErrors state
  };

  const handlePhoneInputChange = (index: number, value: string) => {
    const updatedPhoneNumbers = [...phoneNumber];
    updatedPhoneNumbers[index].PhoneNumber = value;

    // Remove the validation error for phone number
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors[`phoneNumber-${index}`];

    setPhoneNumber(updatedPhoneNumbers);
    setFormErrors(updatedFormErrors); // Update formErrors state
  };

  const handlePhoneExtensionChange = (index: number, value: string) => {
    const updatedPhoneNumbers = [...phoneNumber];
    updatedPhoneNumbers[index].DialingCode = value;

    // Remove the validation error for phone type
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors[`dialingCode-${index}`];

    setPhoneNumber(updatedPhoneNumbers);
    setFormErrors(updatedFormErrors);
  };

  const handleGenderChange = (value: string) => {
    setFormData({ ...formData, gender: value });
  };

  const handlePrefixChange = (value: string) => {
    // Update form data state
    setFormData({ ...formData, prefix: value });

    // Update form errors state
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors["prefix"];
    setFormErrors(updatedFormErrors);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    if (!deleteButtonClicked && saveButtonClicked) {
      const errors: Record<string, string> = {};
      if (!formData.gender) {
        errors.gender = t("profile.gender") + " " + t("profile.isRequired");
      }
      if (!formData.prefix) {
        errors.prefix = t("profile.prefix") + " " + t("profile.isRequired");
      }
      if (!formData.firstName) {
        errors.firstName =
          t("profile.firstname") + " " + t("profile.isRequired");
      }
      if (!formData.lastName) {
        errors.lastName = t("profile.lastname") + " " + t("profile.isRequired");
      }
      if (!formData.displayName) {
        errors.displayName =
          t("profile.preferredDisplayName") + " " + t("profile.isRequired");
      }
      if (!formData.publishingName) {
        errors.publishingName =
          t("profile.preferredPublishingName") + " " + t("profile.isRequired");
      }

      if (!formData.email) {
        errors.email = t("profile.email") + " " + t("profile.isRequired");
      } else {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
          errors.email = t("profile.invalidEmailFormat");
        }
      }

      phoneNumber.forEach((phone, index) => {
        formErrors[`Type-${index}`] = "";
        formErrors[`dialingCode-${index}`] = "";
        formErrors[`phoneNumber-${index}`] = "";
        if (!phone.Type) {
          errors[`Type-${index}`] =
            t("profile.type") + " " + t("profile.isRequired");
        }
        if (!phone.DialingCode) {
          errors[`dialingCode-${index}`] =
            t("profile.dialingCode") + " " + t("profile.isRequired");
        }
        const phoneNumber = phone.PhoneNumber.trim();
        const phoneNumberRegex = /^[0-9]+$/;

        if (!phoneNumber) {
          errors[`phoneNumber-${index}`] =
            t("profile.phonenumber") + " " + t("profile.isRequired");
        } else if (!phoneNumberRegex.test(phoneNumber)) {
          errors[`phoneNumber-${index}`] = t(
            "profile.invalidFormatForPhoneNumber"
          );
        }
      });

      if (Object.keys(errors).length === 0) {
        // Submit the form
        handlePostData(formData);
        postPhoneNumberData();
      } else {
        setFormErrors(errors);
      }
    }
  };

  const handleCheckboxChange = (index: number, value: boolean) => {
    const updatedPhoneNumbers = phoneNumber.map((phone, idx) => ({
      ...phone,
      IsPreferredContact: idx === index ? value : false,
    }));

    setPhoneNumber(updatedPhoneNumbers);
  };

  const postPhoneNumberData = async () => {
    try {
      // Iterate through phone numbers and post each one
      const phonePromises = phoneNumber.map(async (phone) => {
        // Check if the phone number is new
        if (phone.IsNew) {
          const response = await postData("UserPhone", phone);
          fetchDataByID();
          navigate("/profile");
          return response;
        } else {
          // If the phone number is not new, update it
          const response = await putData("UserPhone", phone.Id, phone);
          fetchDataByID();
          navigate("/profile");
          return response;
        }
      });

      // Wait for all phone number posts/updates to complete
      await Promise.all(phonePromises);
    } catch (error) {
      console.error(`Error posting phone number data:`, error);
    }
  };

  const getDefaultLanguageId = async () => {
    const getAllLanguageId = await fetchData("Language/GetAll");
    let languageId = getAllLanguageId.Collection.filter(
      (val: any) => val.IsDefault
    )[0].Id;
    return languageId;
  };

  const handlePostData = async (formDetails: FormData) => {
    try {
      // Update formDetails object
      formDetails.isDirty = true;
      formDetails.isNew = false;
      formDetails.userName = formDetails.email;
      formDetails.LanguageId =
        languageId === "00000000-0000-0000-0000-000000000000"
          ? await getDefaultLanguageId()
          : languageId;

      // Handle user profile data
      const userProfilePromise = putData(
        "UserProfile",
        userProfileId,
        formDetails
      );

      // Combine all promises
      const allPromises = [userProfilePromise];

      // Wait for all promises to resolve
      const results = await Promise.all(allPromises);
      // Navigate to profile page
      navigate("/profile/");
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/profile/")}
              aria-label={t("profile.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("profile.editbasicdetails")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("profile.allFieldsRequired")}
              </p>
            </div>
          </div>
          <ConfirmDialog
            className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
          />
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3">
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label htmlFor="gender" className="block font-bold">
                      {t("profile.gender")} ({t("profile.optional")})
                    </label>
                    <Dropdown
                      inputId="gender"
                      name="gender"
                      value={formData.gender}
                      onChange={(e) => handleGenderChange(e.target.value)}
                      options={genderOptions}
                      optionLabel="label"
                      placeholder={t("profile.selectAnOption")}
                    />
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="prefix"
                      className={`block font-bold ${
                        formErrors?.prefix ? "p-error" : ""
                      }`}
                    >
                      {t("profile.prefix")}
                    </label>
                    <Dropdown
                      inputId="prefix"
                      aria-describedby="prefixError"
                      name="prefix"
                      value={formData.prefix}
                      onChange={(e) => handlePrefixChange(e.target.value)}
                      options={prefixOptions}
                      optionLabel="label"
                      placeholder={t("profile.selectAnOption")}
                      className={formErrors?.prefix ? "p-invalid" : ""}
                      aria-labelledby="prefix"
                    />
                    <span
                      id="prefixError"
                      className={`p-error font-bold text-capitalize ${formErrors.prefix ? "" : "error-hidden"}`}
                    >
                      {formErrors.prefix}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="firstName"
                      className={`block font-bold ${
                        formErrors?.firstName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.firstname")}
                    </label>
                    <InputText
                      id="firstName"
                      aria-describedby="firstNameError"
                      value={formData.firstName}
                      name="firstname"
                      className={`${formErrors?.firstName ? "p-invalid" : ""}`}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                      placeholder={t("profile.firstname")}
                    />
                    <span
                      id="firstNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.firstName ? "" : "error-hidden"}`}
                    >
                      {formErrors.firstName}
                    </span>
                  </div>
                  <div className="xl:col-3 lg:col-4 md:col-6 col-12">
                    <Input
                      type="text"
                      label={`${t("profile.middlename")} (${t(
                        "profile.optional"
                      )})`}
                      value={formData.middleName}
                      name="MiddleName"
                      onChange={(value) =>
                        handleInputChange("middleName", value)
                      }
                      placeholder={t("profile.middlename")}
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="lastName"
                      className={`block font-bold ${
                        formErrors?.lastName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.lastname")}
                    </label>
                    <InputText
                      id="lastName"
                      aria-describedby="lastNameError"
                      value={formData.lastName}
                      name="LastName"
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                      placeholder={t("profile.lastname")}
                      className={`${formErrors?.lastName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="lastNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.lastName ? "" : "error-hidden"}`}
                    >
                      {formErrors.lastName}
                    </span>
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="email"
                      className={`block font-bold ${
                        formErrors?.email ? "p-error" : ""
                      }`}
                    >
                      {t("profile.email")}
                    </label>
                    <InputText
                      id="email"
                      aria-describedby="emailError"
                      value={formData.email}
                      name="email"
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                      placeholder={t("profile.email")}
                      className={`${formErrors?.email ? "p-invalid" : ""} ${readOnly ? "read-only" : ""}`}
                      readOnly={readOnly}
                    />
                    <span
                      id="emailError"
                      className={`p-error font-bold text-capitalize ${formErrors.email ? "" : "error-hidden"}`}
                    >
                      {formErrors.email}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="displayName"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.displayName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.preferredDisplayName")}
                    </label>
                    <InputText
                      id="displayName"
                      aria-describedby="displayNameError"
                      value={formData.displayName}
                      name="displayName"
                      onChange={(e) =>
                        handleInputChange("displayName", e.target.value)
                      }
                      placeholder={t("profile.preferredDisplayName")}
                      className={`${formErrors?.displayName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="displayNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.displayName ? "" : "error-hidden"}`}
                    >
                      {formErrors.displayName}
                    </span>
                  </div>

                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="publishingName"
                      className={`block font-bold text-input-label capitalize ${
                        formErrors?.publishingName ? "p-error" : ""
                      }`}
                    >
                      {t("profile.preferredPublishingName")}
                    </label>
                    <InputText
                      id="publishingName"
                      aria-describedby="publishingNameError"
                      value={formData.publishingName}
                      name="publishingName"
                      onChange={(e) =>
                        handleInputChange("publishingName", e.target.value)
                      }
                      placeholder={t("profile.preferredPublishingName")}
                      className={`${formErrors?.publishingName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="publishingNameError"
                      className={`p-error font-bold text-capitalize ${formErrors.publishingName ? "" : "error-hidden"}`}
                    >
                      {formErrors.publishingName}
                    </span>
                  </div>
                </div>

                <div className="flex flex-column gap-5">
                  <div className="flex flex-column gap-2">
                    <div className="grid m-0 w-full gap-3 add-details-grid add-details-grid-lables">
                      <span className="font-bold" id="phoneType">
                        {t("profile.type")}*
                      </span>
                      <span className="font-bold" id="dialingCode">
                        {t("profile.dialingCode")}
                      </span>
                      <span className="font-bold" id="phonenumber">
                        {t("profile.phonenumber")}
                      </span>
                    </div>
                    <div className="flex flex-column gap-3 w-full">
                      {phoneNumber.length > 0 &&
                        phoneNumber.map((phone, index) => (
                          <div
                            className={`grid m-0 w-full gap-3 add-details-grid idx-0 align-items-baseline idx-${index}`}
                            key={index}
                          >
                            <div
                              className="flex flex-column gap-2 data-label"
                              data-label={t("profile.type")}
                            >
                              {/* <label 
                             htmlFor={`phoneType-${index}`}
                             className={`block font-bold ${formErrors[`Type-${index}`] ? "p-error" : ""
                               }`}
                            >
                              {t("profile.type")}
                            </label> */}
                              <Dropdown
                                inputId={`phoneType-${index}`}
                                aria-describedby={`phoneTypeError-${index}`}
                                value={phone.Type}
                                options={typeOptions}
                                optionLabel="label"
                                onChange={(e) =>
                                  handlePhoneTypeChange(index, e.target.value)
                                }
                                placeholder={t("profile.type")}
                                className={`${formErrors[`Type-${index}`] ? "p-invalid" : ""}`}
                                aria-labelledby="phoneType"
                              />
                              <span
                                id={`phoneTypeError-${index}`}
                                className={`p-error font-bold text-capitalize ${formErrors[`Type-${index}`] ? "" : "error-hidden"}`}
                              >
                                {formErrors[`Type-${index}`]}
                              </span>
                            </div>
                            <div
                              className="flex flex-column gap-2 data-label"
                              data-label={t("profile.dialingCode")}
                            >
                              {/* <label 
                             htmlFor={`dialingCode-${index}`}
                             className={`block font-bold ${formErrors[`dialingCode-${index}`] ? "p-error" : ""
                               }`}
                            >
                              {t("profile.dialingCode")}
                            </label> */}
                              <Dropdown
                                inputId={`dialingCode-${index}`}
                                aria-describedby={`dialingCodeError-${index}`}
                                value={phone.DialingCode}
                                options={extensionOptions}
                                optionLabel="label"
                                onChange={(e) =>
                                  handlePhoneExtensionChange(
                                    index,
                                    e.target.value
                                  )
                                }
                                placeholder={t("profile.selectAnOption")}
                                aria-labelledby="dialingCode"
                                className={`w-full ${formErrors[`dialingCode-${index}`] ? "p-invalid" : ""}`}
                              />
                              <span
                                id={`dialingCodeError-${index}`}
                                className={`p-error font-bold text-capitalize ${formErrors[`dialingCode-${index}`] ? "" : "error-hidden"}`}
                              >
                                {formErrors[`dialingCode-${index}`]}
                              </span>
                            </div>
                            <div
                              className="flex flex-column gap-2 data-label"
                              data-label={t("profile.phonenumber")}
                            >
                              {/* <label 
                             htmlFor={`phoneNumber-${index}`}
                             className={`block font-bold ${formErrors[`phoneNumber-${index}`] ? "p-error" : ""
                               }`}
                            >
                              {t("profile.phonenumber")}
                            </label> */}
                              <InputText
                                type="text"
                                value={phone.PhoneNumber}
                                name={`phonenumber-${index}`}
                                onChange={(e) =>
                                  handlePhoneInputChange(index, e.target.value)
                                }
                                placeholder={t("profile.phonenumber")}
                                aria-labelledby="phonenumber"
                                className={`${formErrors[`phoneNumber-${index}`] ? "p-invalid" : ""}`}
                              />
                              <span
                                id={`phonenumber-${index}`}
                                className={`p-error font-bold text-capitalize ${formErrors[`phoneNumber-${index}`] ? "" : "error-hidden"}`}
                              >
                                {formErrors[`phoneNumber-${index}`]}
                              </span>
                            </div>
                            <div className="flex align-items-center">
                              <Checkbox
                                inputId={`preferredContact-${index}`}
                                checked={phone.IsPreferredContact}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    index,
                                    (e.target as HTMLInputElement).checked
                                  )
                                }
                                className="mr-2"
                              />
                              <label
                                htmlFor={`preferredContact-${index}`}
                                className="text-base"
                              >
                                {t("profile.checkboxPhoneNumber")}
                              </label>
                            </div>
                            <div className="flex justify-content-end">
                              <Button
                                text
                                className="p-button-plain gap-2 text-delete p-0 underline"
                                onClick={() =>
                                  handleDeleteButtonClick(phone.Id)
                                }
                                disabled={phoneNumber.length === 1}
                              >
                                <FeatherIcon name="trash-2" size={20} />
                                <span>{t("profile.delete")}</span>
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  {!id && (
                    <div className="block w-full">
                      <Button
                        text
                        className="p-button-plain gap-2 pl-0 underline"
                        onClick={addNewRow}
                      >
                        <FeatherIcon name="plus" size={20} />
                        <span>{t("profile.addAnotherPhone")}</span>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
              <Button
                label={t("profile.cancel")}
                className="button-md"
                severity="secondary"
                onClick={() => navigate("/profile/")}
              />
              <Button
                label={t("profile.save")}
                className="button-md"
                onClick={handleSaveButtonClick}
              />
            </div>
          </form>
        </div>
      </div>

      <ConfirmDialog {...confirmDialogProps} />
    </>
  );
};

export default EditBasicDetails;
