import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ContributingCommitteeItem {
    CommitteeId: string; // Adjust the type as needed
    canComment?: boolean;
    mustVote?: boolean;
    canVote?: boolean;
  }
interface CreateStandardData {
  id: string;
  activeStep: number;
  basicDetail: Record<string, any>;
  otherConfiguration: Record<string, any>;
  contributingCommittee: ContributingCommitteeItem[];
  loading: boolean;
}

const initialState: CreateStandardData = {
  id:"",
  activeStep:0,
  basicDetail: {},
  otherConfiguration: {},
  contributingCommittee:[],
  loading: false,
};

const createStandardSlice = createSlice({
  name: "Standard",
  initialState,
  reducers: {
    createBasicDetail: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.basicDetail = { ...state.basicDetail, ...action.payload };
      state.loading = false;
    },
    createOtherRequest: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.otherConfiguration = {
        ...state.otherConfiguration,
        ...action.payload,
      };
      state.loading = false;
    },
    setStandardId: (state, action: PayloadAction<string>) => {
        state.id = action.payload; // Update the id in the state
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
        state.activeStep = action.payload; // Update the id in the state
    },
    setContributionCommitteeId: (state, action: PayloadAction<ContributingCommitteeItem[]>) => {
        state.contributingCommittee = action.payload; // Update the contributionCommitteeId
    },
    resetBasicDetails:(state)=>{
        state.basicDetail= {}
    },
    resetOtherDetails:(state)=>{
        state.otherConfiguration= {}
    },
    resetContributeDetails:(state)=>{
        state.contributingCommittee= []
    },
    reset: () => initialState,
  },
});

export const { createBasicDetail, createOtherRequest, setStandardId,setContributionCommitteeId,resetBasicDetails,resetOtherDetails,resetContributeDetails, setActiveStep, reset } =
  createStandardSlice.actions;
export default createStandardSlice.reducer;
