import React, { useEffect, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import CommitteeSteps from "./CommitteeSteps";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Link, useNavigate } from "react-router-dom";
import Table from "../common/Table";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { usePermission } from "../auth/Authorization";
import PreviewItem from "../common/PreviewItem";
interface Option {
  name: string;
  code: string;
}
const CommitteeTabConfiguration: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];

  const percent = 70; // Set your percentage here

  // Define the style object as a regular JavaScript object
  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };
  const [dateActivation, setDateActivation] = useState(null);
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );
  const handleEdit = () => {
    sessionStorage.setItem("editConfigCommittee", "On");
    navigate("/committee/configuration");
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.basicCommitteeConfiguration")}
            </h2>
            {userRole !== "Member" && (
              <Button
                disabled={
                  userRole === "Admin" || userRole === "Staff" ? false : true
                }
                text
                className="p-button-plain gap-2 underline"
                onClick={() => handleEdit()}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("committee.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.defaultFromParentCommittee")}
                value={
                  createCommitteeData?.configureCommittee?.defaultParent
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
              <PreviewItem
                label={t("committee.committeeType")}
                value={
                  createCommitteeData?.createCommittee?.committeeType?.name
                }
              />
              <PreviewItem
                label={t("committee.restrictedCommittee")}
                value={
                  createCommitteeData?.configureCommittee
                    ?.isNatureofCommitteeRestricted
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
              <PreviewItem
                label={t("committee.memberOfTheCommitteeAccess")}
                value={
                  createCommitteeData?.configureCommittee
                    ?.isAllowMemberOtherCommitteAccess
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
              <PreviewItem
                label={t("committee.allowApplications")}
                value={
                  createCommitteeData?.configureCommittee
                    ?.acceptApplicationWithoutOpenPositions
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeMembershipConfiguration")}
            </h2>
            {userRole !== "Member" && (
              <Button
                disabled={
                  userRole === "Admin" || userRole === "Staff" ? false : true
                }
                text
                className="p-button-plain gap-2 underline"
                onClick={() => handleEdit()}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("committee.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.numberOfMembersInTheCommittee")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.minimum")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.committeeMinMember
                    }
                  />
                  <PreviewItem
                    label={t("committee.maximum")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.committeeMaxMember
                    }
                  />
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.criteriaForMembershipEligiblity")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.subTierCommittee")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.msElig_SubTierCommitte_Months
                    }
                    extension={
                      createCommitteeData?.configureCommittee
                        ?.msElig_SubTierCommitte_Months
                        ? t("committee.months")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.subTierCommitteePositions")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.msElig_SameCommitte_Officer_Months
                    }
                    extension={
                      createCommitteeData?.configureCommittee
                        ?.msElig_SameCommitte_Officer_Months
                        ? t("committee.months")
                        : "-"
                    }
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.membershipTenure")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.tenureOfOfficerPosition")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.msExpire_Officer_Tenure_Years
                    }
                    extension={
                      createCommitteeData?.configureCommittee
                        ?.msExpire_Officer_Tenure_Years
                        ? t("committee.years")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.tenureOfMembership")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.msExpire_Member_Tenure_Years
                    }
                    extension={
                      createCommitteeData?.configureCommittee
                        ?.msExpire_Member_Tenure_Years
                        ? t("committee.years")
                        : "-"
                    }
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.notificationToStaff")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.firstNotification")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_First
                        ? createCommitteeData?.configureCommittee
                            ?.notifyStaffExpirationofMembershipDays_First
                        : ""
                    }
                    extension={
                      createCommitteeData?.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_First
                        ? t("committee.days")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.secondNotification")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_Second
                    }
                    extension={t("committee.days")}
                  />
                  <PreviewItem
                    label={t("committee.thirdNotification")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_Third
                        ? createCommitteeData?.configureCommittee
                            ?.notifyStaffExpirationofMembershipDays_Third
                        : ""
                    }
                    extension={t("committee.days")}
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.notificationToMember")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.firstNotification")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_First
                    }
                    extension={t("committee.days")}
                  />
                  <PreviewItem
                    label={t("committee.secondNotification")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_Second
                    }
                    extension={t("committee.days")}
                  />
                  <PreviewItem
                    label={t("committee.thirdNotification")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_Third
                    }
                    extension={t("committee.days")}
                  />
                  <PreviewItem
                    label={t("committee.maximumConsecutiveTerms")}
                    value={
                      createCommitteeData?.configureCommittee
                        ?.officerMaximumTermYears
                    }
                    extension={t("committee.years")}
                  />
                  <PreviewItem
                    label={t("committee.durationToReconfirm")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.applicationAutoWithdrawalMonths
                    }
                    extension={t("committee.months")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.notificationAlertsToOfficers")}
            </h2>
            {userRole !== "Member" && (
              <Button
                disabled={
                  userRole === "Admin" || userRole === "Staff" ? false : true
                }
                text
                className="p-button-plain gap-2 underline"
                onClick={() => handleEdit()}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("committee.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.numberMeetingsMissed")}
                value={
                  createCommitteeData?.configureCommittee?.numberofMeetingMissed
                }
              />
              <PreviewItem
                label={t("committee.numberBallotsMissed")}
                value={
                  createCommitteeData?.configureCommittee?.numberofBallotsMissed
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeTabConfiguration;
