import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from "../LanguageContext";
import FeatherIcon from "../common/FeatherIconComponent";
import { Badge } from "primereact/badge";
import DetailTab from "./DetailTab";
import BallotHistoryTab from "./BallotHistoryTab";
import LifeCycleTab from "./LifeCycleTab";
import OtherConfigurationTab from "./OtherConfigurationTab";
import ContributingCommitteeTab from "./ContributingCommitteeTab";
import PublishedStandardTab from "./PublishedStandardTab";
import RecordsTab from "./RecordsTab";

const StandardDetailTabs: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("standard.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title flex-wrap display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
            Standard Name (Short Name) - 2012
          </h1>
          <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p>
        </div>

        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("standard.details")}>
              <DetailTab committeeData maintainanceCycleData />
            </TabPanel>
            <TabPanel header={t("standard.otherConfiguration")}>
              <OtherConfigurationTab
                committeeData
                hierarchyData
                accreditationData
              />
            </TabPanel>
            <TabPanel header={t("standard.contributingCommittee")}>
              <ContributingCommitteeTab />
            </TabPanel>
            <TabPanel header={t("standard.publishedStandard")}>
              <PublishedStandardTab />
            </TabPanel>
            <TabPanel header={t("standard.records")}>
              <RecordsTab />
            </TabPanel>
            <TabPanel header={t("standard.ballotHistory")}>
              {t("standard.ballotHistory")}
            </TabPanel>
            <TabPanel header={t("standard.lifeCycle")}>
              {t("standard.lifeCycle")}
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default StandardDetailTabs;
