import { Button } from "primereact/button";
import React, { useCallback, useMemo } from "react";
import FeatherIcon from "../../common/FeatherIconComponent";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import BallotConfigurationWeighted from "./BallotConfigurationWeighted";
import BallotConfigurationRecirculation from "./BallotConfigurationRecirculation";
import BallotConfigurationFields from "./BasicConfigurationFields";
import { useTranslation } from "react-i18next";

interface BallotConfigurationFormProp {
  handleBallotConfHideShow?: () => void;
  ballotConfigurationData: any;
  handleDropdownField?: (e: any, field: string) => void;
  handleRadioButtonChange: (e: any, field: string) => void;
  errors?: any;
  setBallotConfigurationData: React.Dispatch<React.SetStateAction<any>>;
  isWeightedBallot?: boolean;
  isRecirculation?: boolean;
  isShowFormField?: boolean;
}

const BallotConfigurationForm: React.FC<BallotConfigurationFormProp> = ({
  handleBallotConfHideShow,
  ballotConfigurationData,
  handleDropdownField,
  handleRadioButtonChange,
  errors,
  setBallotConfigurationData,
  isShowFormField = true,
  isWeightedBallot,
  isRecirculation,
}) => {
  const [t] = useTranslation("configuration");
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const inputValue = e.target.value;
    if (/^\d{0,3}$/.test(inputValue)) {
      setBallotConfigurationData((prevConfig: any) => ({
        ...prevConfig,
        [field]: inputValue,
      }));
    }
  };

  const renderRadioButtonField = (
    fieldId: string,
    name: string,
    fieldValue: boolean | null,
    fieldKey: string,
    disabled = false
  ) => (
    <div
      className="flex flex-wrap gap-3"
      style={{ cursor: disabled ? "not-allowed" : "default" }}
    >
      <div className="flex align-items-center">
        <RadioButton
          inputId={`${fieldId}1`}
          name={name}
          value="Yes"
          onChange={(e) => handleRadioButtonChange(e, fieldKey)}
          checked={fieldValue === true || undefined}
          disabled={disabled}
        />
        <label htmlFor={`${fieldId}1`} className="ml-2">
          Yes
        </label>
      </div>
      <div className="flex align-items-center">
        <RadioButton
          inputId={`${fieldId}2`}
          name={name}
          value="No"
          onChange={(e) => handleRadioButtonChange(e, fieldKey)}
          checked={fieldValue === false || undefined}
          disabled={disabled}
        />
        <label htmlFor={`${fieldId}2`} className="ml-2">
          No
        </label>
      </div>
      {errors?.[fieldKey] && (
        <small className="p-error">{errors?.[fieldKey]}</small>
      )}
    </div>
  );
  return (
    <>
      <div className="card bg-white w-full mb-5 shadow-md">
        {isShowFormField && (
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0"
              onClick={handleBallotConfHideShow}
              aria-label={t("configuration.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.ballotConfiguration")}
            </h2>
          </div>
        )}

        <div className="p-5 cardBody">
          {isShowFormField && (
            <BallotConfigurationFields
              configurationData={ballotConfigurationData}
              handleDropdownField={handleDropdownField}
              errors={errors}
            />
          )}
          <div className="grid grid-xl">
            <div className="flex flex-column gap-3">
              <div className="flex align-items-center">
                <fieldset className="border-none p-0 ">
                  <legend className="block font-bold text-input-label p-0 mb-3">
                    {t("configuration.allowEditVoteAfterClosed")}
                  </legend>
                  {renderRadioButtonField(
                    "allowEditVoteAfterClosed",
                    "allowEditVoteAfterClosed",
                    ballotConfigurationData.enableVotingPostBallotClosed,
                    "enableVotingPostBallotClosed",
                    true
                  )}
                </fieldset>
              </div>
              <div className="flex align-items-center">
                <fieldset className="border-none p-0">
                  <legend className="block font-bold text-input-label p-0 mb-3">
                    {t("configuration.allowEditVoteBeforeClosed")}
                  </legend>
                  {renderRadioButtonField(
                    "allowEditVoteBeforeClosed",
                    "allowEditVoteBeforeClosed",
                    ballotConfigurationData.enableVotingBeforeBallotClosed,
                    "enableVotingBeforeBallotClosed"
                  )}
                </fieldset>
              </div>
              <div className="flex align-items-center">
                <fieldset className="border-none p-0">
                  <legend className="block font-bold text-input-label p-0 mb-3">
                    {t("configuration.allowVotingPostBallotEndDate")}
                  </legend>
                  {renderRadioButtonField(
                    "allowVotingPostBallotEndDate",
                    "allowVotingPostBallotEndDate",
                    ballotConfigurationData.enableVotingAfterBallotEndDate,
                    "enableVotingAfterBallotEndDate"
                  )}
                </fieldset>
              </div>
            </div>
          </div>
          <div className="grid grid-xl mt-3">
            {["First", "Second", "Third"].map((key) => (
              <div
                key={key}
                className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12"
              >
                <label
                  htmlFor={`${key}VotingNotificationDays`}
                  className="block font-bold text-input-label text-capitalize"
                >
                  {`${t(`configuration.${key.toLowerCase()}VotingNotificationDays`)}*`}
                </label>
                <InputText
                  id={`${key}VotingNotificationDays`}
                  name={`${key}VotingNotificationDays`}
                  value={
                    ballotConfigurationData[`voting${key}RemindersInDays`] || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, `voting${key}RemindersInDays`)
                  }
                  type="number"
                  min="1"
                  placeholder={t(
                    `configuration.${key.toLowerCase()}VotingNotificationDays`
                  )}
                  className="w-full"
                  onInput={(e) => {
                    const inputElement = e.target as HTMLInputElement;
                    if (inputElement.value.length > 3) {
                      inputElement.value = inputElement.value.slice(0, 3);
                    }
                  }}
                />
                {errors?.[`voting${key}RemindersInDays`] && (
                  <small className="p-error">
                    {errors?.[`voting${key}RemindersInDays`]}
                  </small>
                )}
              </div>
            ))}
          </div>
          {/* Weighted Ballot Section */}
          {isWeightedBallot && <BallotConfigurationWeighted />}
          {/* Recirculation Section */}
          {isRecirculation && <BallotConfigurationRecirculation />}
        </div>
      </div>
    </>
  );
};

export default BallotConfigurationForm;
