import React, { useEffect } from "react";
import SearchRecordForm from "../sections/SearchRecordForm";
import BallotRecordTable from "../sections/BallotRecordTable";
import useDropDownOptions from "./useDropDownOptions";

const SelectRecords: React.FC = () => {
  const { fetchParentCommittee, handleDropDownOptions } = useDropDownOptions();
  useEffect(() => {
    fetchParentCommittee();
    handleDropDownOptions();
  }, [fetchParentCommittee, handleDropDownOptions]);
  return (
    <>
      <SearchRecordForm />
      <BallotRecordTable />
    </>
  );
};

export default SelectRecords;
