import { useState, useEffect } from 'react';
import { fetchData } from '../../../services/Ballot/apiservice';

const useBallotDetails = (ballotId: string | undefined) => {
    const [ballotDetails, setBallotDetails] = useState(null);
    useEffect(() => {
        const fetchBallotDetails = async () => {
            try {
                const ballotDetailsData = await fetchData(`Ballot/${ballotId}`);
                console.log(ballotDetailsData, 'ballotDetailsData', ballotDetailsData?.IsSecreteBallot);
                setBallotDetails(ballotDetailsData);
            } catch (err) {
                console.log(err)
            }
        };

        if (ballotId) {
            fetchBallotDetails();
        }
    }, [ballotId]);

    return { ballotDetails };
};

export default useBallotDetails;
