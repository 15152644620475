import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectRecords from "../common/SelectRecords";
import BallotDetails from "../common/SelectBallotDetails";
import CreateBallotHeader from "../common/BallotHeader";
import SelectCommittee from "../common/SelectCommittee";
import { ballotDetails } from "../../../slices/ballotDetails";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { ballotCommittees } from "../../../slices/ballotCommittees";
import Preview from "../common/Preview";
import { clearAllSessionStorage } from "../../../utils/storage";
import { useParams } from "react-router-dom";
import useBallotStatusData from "../common/useBallotStatusData";

const CreateNewBallot = () => {
  const { id } = useParams();
  useEffect(() => {
    if (!id) {
      clearAllSessionStorage();
      dispatch(ballotDetails.reset());
      dispatch(ballotSelectRecords.reset());
      dispatch(ballotCommittees.reset());
    }
  }, [id]);
  const { ballotStatusList, ballotRecordStatusList } = useBallotStatusData();
  const { stepCount } = useSelector((state: any) => state.ballotSelectRecords);
  const dispatch = useDispatch();
  return (
    <>
      <CreateBallotHeader activeStep={stepCount} />
      {stepCount === 0 && <SelectRecords />}
      {stepCount === 1 && <BallotDetails />}
      {stepCount === 2 && <SelectCommittee />}
      {stepCount === 3 && <Preview />}
    </>
  );
};

export default CreateNewBallot;
