import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../services/Ballot/apiservice';
import { ballotDetails } from '../../../slices/ballotDetails';
import { BallotStatus, BallotStatusResponse, BallotRecordStatus, BallotRecordStatusResponse } from '../common/type';

const useBallotStatusData = () => {
    const dispatch = useDispatch();
    const ballotStatusList = useSelector((state: any) => state.ballotDetails.ballotStatusList);
    const ballotRecordStatusList = useSelector((state: any) => state.ballotDetails.ballotRecordStatusList);
    const getAllBallotStatus = useCallback(async () => {
        if (Object.keys(ballotStatusList).length === 0) {
            try {
                const ballotStatusResponse: BallotStatusResponse = await fetchData('BallotStatus/GetAll');
                const allBallotStatusFormatted = ballotStatusResponse.Collection.map((ballot: BallotStatus) => ({
                    id: ballot.Id,
                    name: ballot.Name,
                }));
                dispatch(ballotDetails.addBallotStatusList({ value: allBallotStatusFormatted }));
            } catch (err) {
                console.log(err, 'err');
            }
        }
    }, [ballotStatusList, dispatch]);
    const getAllBallotRecordStatus = useCallback(async () => {
        if (Object.keys(ballotRecordStatusList).length === 0) {
            try {
                const ballotRecordStatusResponse: BallotRecordStatusResponse = await fetchData('BallotRecordStatus/GetAll');
                const allBallotRecordStatusFormatted = ballotRecordStatusResponse.Collection.map((ballot: BallotRecordStatus) => ({
                    id: ballot.Id,
                    name: ballot.Name,
                }));
                dispatch(ballotDetails.addBallotRecordStatusList({ value: allBallotRecordStatusFormatted }));
            } catch (err) {
                console.log(err, 'err');
            }
        }
    }, [ballotRecordStatusList, dispatch]);
    useEffect(() => {
        getAllBallotStatus();
        getAllBallotRecordStatus();
    }, [getAllBallotStatus, getAllBallotRecordStatus]);

    return {
        ballotStatusList,
        ballotRecordStatusList,
    };
};

export default useBallotStatusData;
