import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // PrimeReact theme
import "primereact/resources/primereact.min.css"; // PrimeReact core styles
import "primeicons/primeicons.css"; // PrimeIcons
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useTranslation } from "react-i18next";
import { InputTextarea } from "primereact/inputtextarea";
import CommitteeForm from "./SelectCommitteeMember";
import CommitteeAddDocument from "./CommitteeAddDoc";

interface CommitteeAddMeetingProps {
  onDataChange: (data: any) => void; // Function to pass data to the parent component
}

const CommitteeAddMeeting: React.FC<CommitteeAddMeetingProps> = ({
  onDataChange,
}) => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  // States for form fields
  const [conferenceName, setConferenceName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [meetingLocation, setMeetingLocation] = useState("");
  const [meetingAgenda, setMeetingAgenda] = useState("");

  // Function to update the end time and end date based on the start time
  const updateEndTimeAndDate = (start: Date | null) => {
    if (!start) return;
    // Calculate the end time (start time + 15 minutes)
    const end = new Date(start);
    end.setMinutes(end.getMinutes() + 15);
    // Set the end time state
    setEndTime(end);
    // Check if the end time goes past midnight
    const isNextDay = end.getDate() !== start.getDate();
    if (isNextDay) {
      // Update the endDate to the next day
      const nextDay = new Date(end);
      nextDay.setDate(nextDay.getDate());
      setEndDate(nextDay);
    } else {
      setEndDate(start); // Keep the same day if not past midnight
    }
  };
  // Watch for changes in start time and update end time & date
  useEffect(() => {
    updateEndTimeAndDate(startTime);
  }, [startTime]);

  // Function to update the data when form changes
  const updateData = () => {
    const formData = {
      conferenceName,
      startDate,
      endDate,
      startTime,
      endTime,
      meetingLocation,
      meetingAgenda,
    };
    onDataChange(formData);
  };

  return (
    <div>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              {/* Conference Name Input */}
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingName"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingName")}
                  </label>
                  <InputText
                    id="meetingName"
                    type="text"
                    placeholder={t("committee.meetingName")}
                    className="w-full"
                    value={conferenceName}
                    onChange={(e) => {
                      setConferenceName(e.target.value);
                      updateData();
                    }} // Handle conference name change
                  />
                </div>
              </div>

              {/* Start Date */}
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startDate"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.startDate")}
                  </label>
                  <Calendar
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.value as Date);
                      updateData();
                    }}
                    minDate={new Date()}
                    dateFormat="yy/mm/dd"
                  />
                </div>

                {/* Start Time */}
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startTime"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.startTime")}
                  </label>
                  <Calendar
                    id="startTime"
                    placeholder={t("committee.startTime")}
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.value as Date | null);
                      updateData();
                    }}
                    timeOnly
                    hourFormat="12"
                    showSeconds={false}
                    stepMinute={15}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                {/* End Time */}
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="endTime"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.endTime")}
                  </label>
                  <Calendar
                    id="endTime"
                    placeholder={t("committee.endTime")}
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.value as Date | null);
                      updateData();
                    }}
                    timeOnly
                    hourFormat="12"
                    showSeconds={false}
                    stepMinute={15}
                    minDate={startTime ? new Date(startTime) : undefined}
                  />
                </div>

                {/* End Date */}
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="endDate"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.endDate")}
                  </label>
                  <Calendar value={endDate} disabled dateFormat="yy/mm/dd" />
                </div>
              </div>

              {/* Meeting Location */}
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingLocation"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingLocation")}
                  </label>
                  <InputText
                    id="meetingLocation"
                    type="text"
                    placeholder={t("committee.meetingLocation")}
                    className="w-full"
                    value={meetingLocation}
                    onChange={(e) => {
                      setMeetingLocation(e.target.value);
                      updateData();
                    }} // Handle meeting location change
                  />
                </div>
              </div>

              {/* Meeting Agenda */}
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="meetingAgenda"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.meetingAgenda")}
                  </label>
                  <InputTextarea
                    id="meetingAgenda"
                    placeholder={t("committee.meetingAgenda")}
                    rows={5}
                    cols={30}
                    value={meetingAgenda}
                    onChange={(e) => {
                      setMeetingAgenda(e.target.value);
                      updateData();
                    }} // Handle agenda change
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="pt-0 col-12">
                {/* <Button
                  text
                  className="p-button-plain gap-2 pl-0 underline"
                  // onClick={handleSubmit}
                >
                  <FeatherIcon name="paperclip" size={20} color="inherit" />
                  <span className="font-bold text-capitalize">
                    {t("committee.addAttachments")}
                  </span>
                </Button> */}
                <CommitteeAddDocument memberId={"memberId"} />
              </div>

              {/* Committee Form */}
              <div className="flex flex-column gap-2 w-full mb-5">
                {/* <CommitteeForm /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommitteeAddMeeting;
