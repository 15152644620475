import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import { useLanguageContext } from "../../LanguageContext";
import { BallotReport } from "../types/BallotReport";
import BallotCommitteeReport from "../common/BallotCommitteeReport";
import BallotReportRecord from "../common/BallotReportRecord";
import BallotReportVotingCriteria from "../common/BallotReportVotingCriteria";
import { fetchData } from "../../../services/Ballot/apiservice";

const BallotClosureReportPrint = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [ballotDetails, setBallotDetails] = useState<any>([]);
  const [ballotRecordData, setBallotRecordData] = useState<any>(null);
  const [ballotReport, setBallotReport] = useState<BallotReport | null>(null);
  const performAction = useCallback(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  useEffect(() => {
    performAction();
  }, [performAction]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [ballotReport, ballotDetails, recordList] = await Promise.all([
          fetchData(`BallotRecordVote/BallotReports/${id}`),
          fetchData(`Ballot/${id}`),
          fetchData(`BallotRecord/BallotClosureRecordlistDetails/${id}`),
        ]);
      setBallotDetails(ballotDetails);
      setBallotReport(ballotReport);
      if (recordList) {
        const sortedData = recordList.collection.map((item:  any) => ({
          recordNumber: item.recordNumber,
          committeeName: item.committeeName,
          oldStatusName: item.previousRecordStatusName,
          newStatusName: item.recordStatusName,
          recordId: item.recordId
        }));
        setBallotRecordData(sortedData);
      }
      } catch {
        console.error("Error fetching initial record vote data");
      }
    };
    fetchInitialData();
  }, []);

  return (
    <div className="text-sm" ref={ref}>
      <BallotReportVotingCriteria
        ballotTotalMember={ballotReport?.TotalMemberInvited}
        ballotVoteReceived={ballotReport?.VoteRecived}
        textSize={'text-sm'}
      />
      <BallotReportRecord
        isPrint={true}
        recordLists={ballotRecordData}
        isBallotClosure={false}
        textSize={'text-sm'}
        oldStatusFalse={true}
      />
      <div className="text-sm"  >
        <BallotCommitteeReport
          isPrint={true}
          isBallotClosure={false}
          committeeReports={ballotReport?.ballotCommitteeReports ?? []}
          primaryCommittee={ballotDetails?.Committee?.Name ?? ""}
        />
      </div>
    </div>
  );
});

export default BallotClosureReportPrint