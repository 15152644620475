import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabView, TabPanel } from "primereact/tabview";
import { Message } from "primereact/message";
import { useLanguageContext } from "../../LanguageContext";
import BallotTable from "./BallotTable";
import { fetchData } from "../../../services/Ballot/apiservice";

const Membership: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [isDefaultConfiguration, setIsDefaultConfiguration] =
    useState<boolean>(false);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const fetchBallotDeafultConfig = async () => {
    try {
      const responseData = await fetchData(
        "BallotConfiguration/GetDefaultBallotConfiguration"
      );
      if (!responseData || Object.keys(responseData).length === 0) {
        setIsDefaultConfiguration(false);
      } else {
        setIsDefaultConfiguration(true);
      }
    } catch (error) {
      console.error("Error fetching default configuration:", error);
    }
  };

  useEffect(() => {
    fetchBallotDeafultConfig();
  });
  return (
    <>
      <div>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("ballot.membershipBallots")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("ballot.myBallotHelp")}
          </p>
        </div>
        {!isDefaultConfiguration && (
          <Message
            severity="warn"
            text="Configuration has not been added for the ballot. Please contact the admin."
          />
        )}
        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("ballot.new")}>
              <BallotTable isDefaultConfiguration={isDefaultConfiguration} />
            </TabPanel>
            <TabPanel header={t("ballot.reappointment")}>
              <BallotTable isDefaultConfiguration={isDefaultConfiguration} />
            </TabPanel>
            <TabPanel header={t("ballot.termination")}>
              <BallotTable isDefaultConfiguration={isDefaultConfiguration} />
            </TabPanel>
            <TabPanel header={t("ballot.resignation")}>
              <BallotTable isDefaultConfiguration={isDefaultConfiguration} />
            </TabPanel>
            <TabPanel header={t("ballot.all")}>
              <BallotTable isDefaultConfiguration={isDefaultConfiguration} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default Membership;
