import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { fetchData } from "../../../services/apiService";
import { CommentPayload } from "./type";

interface EditCommentPopoverProps {
  isVisible: boolean;
  payloadData: CommentPayload;
  onSubmitHandler: (payload: CommentPayload) => void;
  onHideRemarks: () => void;
}
interface FormValues {
  paragraphSection: string;
  commentTypeId: string;
  commentText: string;
  proposedAction: string;
}
const EditCommentPopover = ({
  isVisible,
  payloadData,
  onSubmitHandler,
  onHideRemarks,
}: EditCommentPopoverProps) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [commentTypeOptions, setCommentTypeOptions] = useState<
    { label: string; value: string }[] | []
  >([]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      paragraphSection: payloadData?.paragraphSection || "",
      commentTypeId: payloadData?.commentTypeId || "",
      commentText: payloadData?.commentText || "",
      proposedAction: payloadData?.proposedAction || "",
    },
  });
  const onSubmit = async (data: FormValues) => {
    const updateCommentPayload = { ...payloadData };
    updateCommentPayload.commentText = data?.commentText;
    updateCommentPayload.commentTypeId = data?.commentTypeId;
    updateCommentPayload.paragraphSection = data?.paragraphSection;
    updateCommentPayload.proposedAction = data?.proposedAction;
    await onSubmitHandler(updateCommentPayload);
    reset();
    onHideRemarks();
  };
  const footerContentHold = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("ballot.cancel")}
        onClick={() => {
          onHideRemarks();
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("ballot.submit")}
        onClick={handleSubmit(onSubmit)}
        className="button-md"
      />
    </div>
  );
  const fetchCommentType = async () => {
    try {
      const commentTypeOptions = await fetchData(`CommentType/GetAll`);
      const formattedCommentType = commentTypeOptions.Collection.map(
        (item: any) => ({
          label: item.Name,
          value: item.Id,
        })
      );
      const reasonForDisapprovalLabel = "Reason for disapproval";

      const filteredCommentTypes =
        payloadData?.commentStatusName === reasonForDisapprovalLabel
          ? formattedCommentType
          : formattedCommentType.filter(
              (item: { label: string; value: string | number }) =>
                item.label !== reasonForDisapprovalLabel
            );

      setCommentTypeOptions(filteredCommentTypes);
    } catch (err) {
      console.log(err, "err");
    }
  };
  useEffect(() => {
    reset({
      paragraphSection: payloadData?.paragraphSection || "",
      commentTypeId: payloadData?.commentTypeId || "",
      commentText: payloadData?.commentText || "",
      proposedAction: payloadData?.proposedAction || "",
    });
  }, [payloadData, reset]);

  useEffect(() => {
    fetchCommentType();
  }, []);
  return (
    <>
      <Dialog
        id="visiblePositionHold"
        header={`${t("ballot.edit")}`}
        visible={isVisible}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => onHideRemarks()}
        footer={footerContentHold}
        draggable={false}
        className="custom-dialog hide-close"
        ariaCloseIconLabel="Close"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-column gap-7 w-full">
            <div className="flex flex-column gap-3">
              <fieldset className="border-none p-0">
                <legend className="block font-bold text-input-label p-0 mb-3">
                  {t("ballot.comments")}
                </legend>
                <div className="p-3 lg:p-5 cardBody flex flex-column gap-4 surface-100">
                  <div className="flex flex-column gap-2">
                    <label
                      htmlFor={`paragraphSection`}
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("ballot.paragraphSection")}*
                    </label>
                    <Controller
                      name="paragraphSection"
                      control={control}
                      rules={{ required: t("ballot.remarkRequired") }}
                      render={({ field }) => (
                        <InputTextarea
                          {...field}
                          id={`paragraphSection`}
                          placeholder={t("ballot.paragraphSection")}
                          rows={3}
                          cols={50}
                          autoResize
                        />
                      )}
                    />
                    {errors.paragraphSection && (
                      <p className="error">{errors.paragraphSection.message}</p>
                    )}
                  </div>

                  <div className="flex flex-column gap-2">
                    <label
                      htmlFor={`typeOfComment`}
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("ballot.typeOfComment")}*
                    </label>
                    <Controller
                      name="commentTypeId"
                      control={control}
                      rules={{ required: t("ballot.commentTypeRequired") }}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          inputId={`typeOfComment`}
                          options={commentTypeOptions}
                          optionLabel="label"
                          placeholder="Select an Option"
                        />
                      )}
                    />
                    {errors.commentTypeId && (
                      <p className="error">{errors.commentTypeId.message}</p>
                    )}
                  </div>

                  <div className="flex flex-column gap-2">
                    <label
                      htmlFor={`comment`}
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("ballot.comment")}
                    </label>
                    <Controller
                      name="commentText"
                      control={control}
                      render={({ field }) => (
                        <InputTextarea
                          {...field}
                          id={`commentText`}
                          placeholder={t("ballot.comment")}
                          rows={3}
                          cols={50}
                          autoResize
                        />
                      )}
                    />
                  </div>

                  <div className="flex flex-column gap-2">
                    <label
                      htmlFor={`proposedAction`}
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("ballot.proposedAction")}
                    </label>
                    <Controller
                      name="proposedAction"
                      control={control}
                      render={({ field }) => (
                        <InputTextarea
                          {...field}
                          id={`proposedAction`}
                          placeholder={t("ballot.proposedAction")}
                          rows={3}
                          cols={50}
                          autoResize
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Button
                      text
                      className="p-button-plain gap-2 pl-0 underline"
                    >
                      <FeatherIcon name="plus" size={20} color="inherit" />
                      <span className="font-bold text-capitalize">
                        {t("ballot.uploadDocument")}
                      </span>
                    </Button>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default EditCommentPopover;
