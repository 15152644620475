import React from "react";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface VotingOptionsProps {
  votingOptionsList: { label: string; value: string }[];
  selectedVoteOptionId: string;
  userRole: string;
  selectedCommittee: string | null;
  selectedMember: string | null;
  index: number;
  handleVotingOptionChange: (index: number, value: string) => void;
  IsDisable?: boolean;
  showWithdrawDisapprove?: boolean;
  handleWidrawDisapprove?: any;
  ballotStatus?: string;
  reviewAndComment?: boolean;
}

const VotingOptions: React.FC<VotingOptionsProps> = ({
  votingOptionsList,
  selectedVoteOptionId,
  userRole,
  selectedCommittee,
  selectedMember,
  index,
  handleVotingOptionChange,
  IsDisable,
  showWithdrawDisapprove,
  handleWidrawDisapprove,
  ballotStatus,
  reviewAndComment,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const isDisabled =
    IsDisable ||
    (userRole !== "Member" && (!selectedCommittee || !selectedMember));
  return (
    <div>
      <div className="flex flex-wrap column-gap-7 gap-3">
        {votingOptionsList.map(
          (option: { label: string; value: string }, optindex: number) => (
            <div
              key={optindex}
              className="flex align-items-center"
              style={{
                cursor:
                  userRole !== "Member" &&
                  (!selectedCommittee || !selectedMember)
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <RadioButton
                inputId={`votingOption${optindex + 1}`}
                name={`votingOption${index}`}
                value={option.value}
                onChange={(e) => handleVotingOptionChange(index, e.value)}
                checked={selectedVoteOptionId === option.value}
                aria-labelledby={`votingOption${optindex + 1}Label`}
                disabled={isDisabled}
              />
              <label
                htmlFor={`votingOption-${index}-${optindex}`}
                id={`votingOptionLabel-${index}-${optindex}`}
                className="ml-2"
              >
                {option.label}
              </label>
            </div>
          )
        )}
      </div>

      {showWithdrawDisapprove &&
        ballotStatus === "Closed" &&
        !reviewAndComment && (
          <Button
            onClick={handleWidrawDisapprove}
            label={t("ballot.withdrawDisapproval")}
            className="button-md mt-4"
            severity="secondary"
          />
        )}
    </div>
  );
};

export default VotingOptions;
