import { useState, useEffect } from "react";
import { fetchData } from "../services/Standard/apiServiceStandard";
interface Item {
  StandardName: string;
  StandardId: string;
  ShortName: string;
  MaintainanceCycleId: string;
  MaintainanceCycle: string;
  CommitteeName: string;
  CommitteeId: string;
  AccridationId: string;
  Accridation: string;
}
interface Data {
  Collection: Item[];
}
const useStandard = (params?: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState();
  const [totalCount, setTotalCount] = useState<number>(0);
  
  useEffect(() => {
    const fetchDataStandard = async () => {
      const updatedParams = { ...params };
      if (params.Filters) {
        params.Filters.forEach((filter: any, index: number) => {
          updatedParams[`Filters[${index}].Key`] = filter.Key;
          updatedParams[`Filters[${index}].Value`] = filter.Value;
        });
        delete updatedParams.Filters;
      }
      try {
        setLoading(true);
        const response = await fetchData(`/Standard/GridSearch`, {
          params: updatedParams,
        });
        setData(response);
        setTotalCount(response.TotalCount);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    fetchDataStandard();
  }, [JSON.stringify(params)]);
  return { data, loading, error, totalCount };
};

export default useStandard;
