import React from "react";
import Table from "../../common/Table";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
import { Dropdown } from "primereact/dropdown";
import PreviewItem from "../../common/PreviewItem";

interface BallotReportVotingCriteriaProps {
  ballotTotalMember?: number; // Allow undefined
  ballotVoteReceived?: number; // Allow undefined
  textSize?: string;
}

const BallotReportVotingCriteria: React.FC<BallotReportVotingCriteriaProps> = ({
  ballotTotalMember = 0, // Default to 0 if null or undefined
  ballotVoteReceived = 0, // Default to 0 if null or undefined
  textSize,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  return (
    <>
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="p-5 cardBody">
          <div className="flex flex-column gap-7">
            <div className="flex flex-column gap-5">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("ballot.votingCriteria")}
              </h2>
              <div className="flex flex-column gap-4">
                <PreviewItem
                  textSize={textSize}
                  label={t("ballot.approvedRecord")}
                  value={`${t("ballot.moreThan")} 60%`}
                />
                <span>Or</span>
                <PreviewItem
                  textSize={textSize}
                  label={t("ballot.disapporvedRecord")}
                  value={`${t("ballot.lessThan")} 10%`}
                />
              </div>
            </div>
            <div className="flex flex-column gap-5">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("ballot.currentVoteCountByCommitteeName")}
              </h2>
              <div className="flex flex-column gap-4">
                <PreviewItem
                  textSize={textSize}
                  label={t("ballot.totalMemberInvited")}
                  value={`${ballotTotalMember}`}
                />
                <PreviewItem
                  textSize={textSize}
                  label={t("ballot.votesRecevied")}
                  value={`${ballotVoteReceived}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BallotReportVotingCriteria;
