import React, { useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);
interface DonutChartProps {
  data: number[];
  labels: string[];
  backgroundColors: string[];
  options?: any;
  ariaLabel: string;
}

const DonutChart: React.FC<DonutChartProps> = ({ data, labels, backgroundColors, ariaLabel }) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    const canvas = chartRef.current;
    if (canvas) {
      canvas.setAttribute('aria-label', ariaLabel );
    }
  }, [ariaLabel]);
  const chartData = {
    // labels,
    datasets: [
      {
        data,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
      },
    },
  };

  return <Doughnut data={chartData} options={options} ref={(instance) => {
    if (instance) {
      chartRef.current = instance.canvas;
    }
  }} />;
};

export default DonutChart;
