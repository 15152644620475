import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useLanguageContext } from "../LanguageContext";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import {
  fetchData,
  getListByParentId,
  postData,
} from "../../services/apiService";
import { InputTextarea } from "primereact/inputtextarea";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Nullable } from "primereact/ts-helpers";
import { __String } from "typescript";
import LoadingOverlay from "../common/LoadingOverlay";

interface OpenPositionStatus {
  Id: string;
  Name: string;
}

interface Option {
  name: string;
  code: string;
}

interface OpenPosition {
  isDirty: true;
  isNew: true;
  committeeId: string;
  membershipPositionId: string;
  numberOfPositions: number;
  categoryOfInterestId: string;
  positionOpenFrom: Date;
  openPositionStatusId: string;
}

interface Committee {
  Status: {
    Name: string;
  };
}

const AddOpenPosition = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const [committees, setCommittees] = useState([]);
  const [disableControl, setDisableControl] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [memberPosition, setMemberPosition] = useState([]);
  const [comments, setComments] = useState("");
  const [date, setDate] = useState<Nullable<Date>>(null);
  const [selectedPosition, setSelectedPosition] = useState<any>(null);
  const [selectedCoi, setSelectedCoi] = useState<any>(null);
  const [selectedCommittee, setSelectedCommittee] = useState<any>(null);
  const [openPositionStatusId, setOpenPositionStatusId] = useState<string>("");
  const [noOfPositions, setNoOfPositions] = useState<number>(0);
  const [coiData, setCoiData] = useState([]);
  const toast = useRef<Toast>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const CommitteeId = location.state.id as string;

  const today = new Date();
  const [errors, setErrors] = useState({
    selectedCommittee: "",
    selectedPosition: "",
    selectedCoi: "",
    noOfPositions: "",
    date: "",
  });
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  const handleToasterMessage = (msg: any) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: msg,
      life: 3000,
    });
  };

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const validate = () => {
    const newErrors = {
      selectedCommittee: selectedCommittee
        ? ""
        : t("committee.committeeRequired"),
      selectedPosition: selectedPosition ? "" : t("committee.positionRequired"),
      selectedCoi: selectedCoi ? "" : t("committee.coiRequired"),
      noOfPositions:
        noOfPositions > 0 ? "" : t("committee.positionsGreaterThanZero"),
      date: date ? "" : t("committee.startDateRequired"),
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const fetchCommittees = async () => {
    try {
      setLoading(true);
      const response = await fetchData("Committee/GetAll");
      const committeesData = response.Collection;
      const filteredCommittees = committeesData.filter(
        (committee: Committee) => committee.Status.Name !== "Draft"
      );
      setCommittees(filteredCommittees);
      setLoading(false);
      const pathname = location.state?.pathname;
      if (pathname && pathname.includes("/committee/details/")) {
        const storedCommitteeId = sessionStorage.getItem("committeeId");
        if (storedCommitteeId) {
          const selectedCommittee = filteredCommittees.find(
            (committee: any) => committee.Id === storedCommitteeId
          );
          if (selectedCommittee) {
            setSelectedCommittee(selectedCommittee);
            fetchCategoryOfInterestByCommittee(selectedCommittee.Id);
            setLoading(false);
          } else {
            console.warn("No committee found with the stored ID.");
          }
        } else {
          sessionStorage.removeItem("committeeId");
        }
      } else {
        sessionStorage.removeItem("committeeId");
      }
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };

  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  const fetchOpenPosition = async () => {
    try {
      const openPositionStatus = await fetchData("OpenPositionStatus/GetAll");
      const response: OpenPositionStatus[] = openPositionStatus.Collection;
      const openPosition = response.find((item) => item.Name.trim() === "Open");
      if (openPosition) {
        setOpenPositionStatusId(openPosition.Id);
      } else {
        console.error("Open position status not found.");
      }
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  const fetchCategoryOfInterestByCommittee = async (committeeId: string) => {
    try {
      const response = await getListByParentId(
        "CommitteeCategoryOfInterest",
        committeeId
      );
      const categoryOfInterestData = response.Collection;
      const ids = categoryOfInterestData.map(
        (item: any) => item.CategoryOfInterestId
      );
      const COIData = await fetchData("CategoryOfInterest/GetAll");
      const data = COIData.Collection;
      const filteredData = data.filter((item: any) => ids.includes(item.Id));
      setCoiData(filteredData);
    } catch (error) {
      console.error("Error fetching COI by Committee ID:", error);
    }
  };

  const handleCommitteeChange = (e: DropdownChangeEvent) => {
    const selectedCommittee = e.value;
    setSelectedCommittee(selectedCommittee);
    clearValidationError("selectedCommittee");
    fetchCategoryOfInterestByCommittee(selectedCommittee.Id);
  };

  useEffect(() => {
    fetchCommittees();
    fetchMembershipPosition();
    fetchOpenPosition();
  }, []);

  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];

  let committeeId = sessionStorage.getItem("committeeId");
  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate()) return;

    const data = {
      committeeId: selectedCommittee.Id,
      membershipPositionId: selectedPosition.Id,
      numberOfPositions: noOfPositions,
      categoryOfInterestId: selectedCoi.Id,
      positionOpenFrom: date ? date.toISOString() : null,
      openPositionStatusId: openPositionStatusId,
    };
    try {
      const postDataResponse = await postData("OpenPosition", data);

      const responseContent =
        typeof postDataResponse.content === "string"
          ? JSON.parse(postDataResponse.content)
          : postDataResponse.content;
      const openPositionDataId = await responseContent?.Id;
      if (comments !== "") {
        const CommentData = {
          comment: comments,
          openPositionId: openPositionDataId,
          openPositionStatusId: openPositionStatusId,
        };
        const postCommentResponse = await postData(
          "OpenPositionComment",
          CommentData
        );
      }
      handleToasterMessage("Positions Opened Successfully");
      setTimeout(() => {
        if (location.state && location.state.id) {
          navigate("/committee", { state: { id: location.state.id } });
        } else if (location.state && location.state.pathname) {
          navigate(location.state.pathname, { state: { activeIndex: 6 } });
        } else {
          navigate("/positions");
        }
      }, 3000);
    } catch (error) {
      console.error(`Error posting data:`, error);
    }
  };

  useEffect(() => {
    if (CommitteeId) {
      fetchCategoryOfInterestByCommittee(CommitteeId);
    }
  }, [CommitteeId]);

  useEffect(() => {
    const setCommittee = async () => {
      if (CommitteeId) {
        const response = await fetchData("Committee/GetAll");
        const committeesData = response.Collection;
        if (CommitteeId) {
          const matchedCommittee = committeesData?.find(
            (committee: any) => committee.Id === committeeId
          );
          if (matchedCommittee) {
            setSelectedCommittee(matchedCommittee);
            setDisableControl(true);
          }
        }
      }
    };
    setCommittee();
  }, [CommitteeId]);

  const handleCancel = () => {
    if (location.state && location.state.id) {
      navigate("/committee", { state: { id: location.state.id } });
    } else if (location.state && location.state.pathname) {
      navigate(location.state.pathname, { state: { activeIndex: 6 } });
    } else {
      navigate("/positions");
    }
  };

  const clearValidationError = (field: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  return (
    <>
      <div className="">
        <Toast ref={toast} />
        {loading && <LoadingOverlay visible={loading} />}
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              aria-label={t("committee.back")}
              onClick={handleCancel}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("committee.openPositions")}
              </h1>
            </div>
          </div>
          <form onSubmit={handleSave}>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3 w-full">
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                    <label
                      htmlFor="selectCommittee"
                      className={`block font-bold text-capitalize text-input-label ${errors.selectedCommittee ? "text-red-500" : ""}`}
                    >
                      {t("committee.selectCommittee")}*
                    </label>

                    <Dropdown
                      inputId="selectCommittee"
                      name="selectCommittee"
                      value={selectedCommittee}
                      onChange={handleCommitteeChange}
                      options={committees}
                      disabled={!!committeeId || disableControl}
                      optionLabel="Name"
                      placeholder="Select an Option"
                      className={errors.selectedCommittee ? "p-invalid" : ""}
                      filter
                      filterBy="Name"
                    />
                    {errors.selectedCommittee && (
                      <small className="p-error">
                        {errors.selectedCommittee}
                      </small>
                    )}
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="selectPosition"
                      className={`block font-bold text-capitalize text-input-label ${errors.selectedPosition ? "text-red-500" : ""}`}
                    >
                      {t("committee.selectPosition")}*
                    </label>

                    <Dropdown
                      inputId="selectPosition"
                      name="selectPosition"
                      value={selectedPosition}
                      onChange={(e: DropdownChangeEvent) => {
                        setSelectedPosition(e.value);
                        clearValidationError("selectedPosition");
                      }}
                      options={memberPosition}
                      optionLabel="PositionName"
                      placeholder="Select an Option"
                      className={errors.selectedPosition ? "p-invalid" : ""}
                    />
                    {errors.selectedPosition && (
                      <small className="p-error">
                        {errors.selectedPosition}
                      </small>
                    )}
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="noOfPositions"
                      className={`block font-bold text-capitalize text-input-label ${errors.noOfPositions ? "text-red-500" : ""}`}
                    >
                      {t("committee.noOfPositions")}*
                    </label>
                    <InputText
                      id="noOfPositions"
                      name="noOfPositions"
                      value={noOfPositions.toString()}
                      onChange={(e) => {
                        setNoOfPositions(
                          e.target.value ? parseInt(e.target.value, 10) : 0
                        );
                        clearValidationError("noOfPositions");
                      }}
                      type="number"
                      placeholder={t("committee.noOfPositions")}
                      className={errors.noOfPositions ? "p-invalid" : ""}
                      min="0"
                    />
                    {errors.noOfPositions && (
                      <small className="p-error">{errors.noOfPositions}</small>
                    )}
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="categoryOfInterest"
                      className={`block font-bold text-capitalize text-input-label ${errors.selectedCoi ? "text-red-500" : ""}`}
                    >
                      {t("committee.categoryOfInterest")}*
                    </label>

                    <Dropdown
                      inputId="categoryOfInterest"
                      name="categoryOfInterest"
                      value={selectedCoi}
                      onChange={(e: DropdownChangeEvent) => {
                        setSelectedCoi(e.value);
                        clearValidationError("selectedCoi");
                      }}
                      options={coiData}
                      optionLabel="CategoryName"
                      placeholder="Select an Option"
                      className={errors.selectedCoi ? "p-invalid" : ""}
                    />
                    {errors.selectedCoi && (
                      <small className="p-error">{errors.selectedCoi}</small>
                    )}
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="startDate"
                      className={`block font-bold text-capitalize ${errors.date ? "p-error" : "text-input-label"}`}
                    >
                      {t("committee.startDate")}*
                    </label>
                    <Calendar
                      appendTo="self"
                      inputId="startDate"
                      aria-describedby="startDateError"
                      name="startDate"
                      value={date}
                      onChange={(e) => {
                        setDate(e.value);
                        clearValidationError("date");
                      }}
                      placeholder={t("committee.startDate")}
                      dateFormat="mm/dd/yy"
                      className={errors.date ? "p-invalid" : ""}
                      minDate={today}
                    />
                    <span
                      id="startDateError"
                      className={`p-error font-bold text-capitalize ${errors.date ? "" : "error-hidden"}`}
                    >
                      {errors.date}
                    </span>
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                    <label
                      htmlFor="comments"
                      className="block font-bold text-capitalize text-input-label"
                    >
                      {t("committee.comments")}
                    </label>
                    <InputTextarea
                      id="comments"
                      name="comments"
                      value={comments}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setComments(e.target.value);
                      }}
                      placeholder={t("committee.comments")}
                      maxLength={1000}
                      style={{ height: "100px" }}
                      rows={3}
                      cols={50}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 md:gap-4 justify-content-between cardFooter">
              <Button
                label={t("committee.cancel")}
                className="button-md ml-auto w-auto"
                severity="secondary"
                onClick={handleCancel}
              />
              <Button
                label={t("committee.save")}
                className="button-md w-auto"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOpenPosition;
