import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import pdfIcon from "../../assets/icons/fileIcon.svg";
import DocumentList from "./DocumentList";

interface UploadDocumentTabProps {
  standardId?: string;
  standardDocumentType?: string;
  fileChange?: (filesData: any[]) => void;
}

const DocumentUpload: React.FC<UploadDocumentTabProps> = ({
  standardId,
  standardDocumentType,
  fileChange,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [file, setFile] = useState<any | undefined>(undefined);
  const [t, i18n] = useTranslation("standard");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validExtensions = [
      ".xlsx",
      ".xls",
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".txt",
      ".pdf",
      ".csv",
    ];
    const selectedFiles = event.target.files;
    console.log("Calling change");
    console.log(event.target.files);
    if (selectedFiles) {
      let totalSize = 0;
      const filesArray: any[] = [];
      let invalidFiles = false;

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          setErrorMessage(`${t("profile.invalidFileType")}: ${file.name}`);
          invalidFiles = true;
          break;
        }
        totalSize += file.size;
        filesArray.push(file);
      }

      if (!invalidFiles) {
        const totalSizeInMB = totalSize / (1024 * 1024);
        if (totalSizeInMB <= 500) {
          setFile(filesArray);
          fileChange?.(filesArray as []);
          setErrorMessage(undefined);
        } else {
          setErrorMessage(`${t("standard.totalFileSizeExceed500")}`);
          setFile(undefined);
          fileChange?.([]);
        }
      } else {
        setFile(undefined);
        fileChange?.([]);
      }
    } else {
      setFile(undefined);
      fileChange?.([]);
      setErrorMessage(undefined);
    }
  };

  let id = standardDocumentType;

  const handleDelete = (index: number) => {
    if (file) {
      const updatedFiles = [...file];
      updatedFiles.splice(index, 1);
      setFile(updatedFiles);
    }
  };

  console.log(file);
  return (
    <>
      <div className="p-5 cardBody">
        <div className="flex flex-column gap-5">
          <div className="flex flex-column gap-2 w-full">
            <div className="flex flex-column gap-3">
              <div className="xl:col-6 lg:col-8 md:col-12 col-12">
                <h2 className="font-bold text-base">
                  {t("standard.uploadDocument")}
                </h2>
                <div className="relative md:w-16rem custom-file-upload mb-2">
                  <input
                    type="file"
                    id="fileInput1"
                    aria-labelledby="fileInput1"
                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv"
                    multiple
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="fileInput1"
                    className="flex flex-column align-items-center justify-content-center gap-2 p-3"
                  >
                    <span className="bg-brand-500 border-circle flex align-items-center justify-content-center">
                      <FeatherIcon
                        name="plus"
                        size={20}
                        color="var(--icon-white)"
                        onClick={undefined}
                      />
                    </span>
                    <span className="text-sm font-normal">
                      {t("standard.browseDosPlacehoder")} <br />
                      {t("standard.maxFileSize")}
                    </span>
                  </label>
                </div>

                {standardId && (
                  <DocumentList
                    standardDocumentType={standardDocumentType || ""}
                    StandardId={standardId}
                    showNoDataMessage={false}
                  />
                )}

                <div>
                  {errorMessage && (
                    <div key="errorMessage" className="p-error font-bold">
                      {errorMessage}
                    </div>
                  )}
                  {file && (
                    <div className="flex flex-column gap-3">
                      {file.map((fileName: any, index: number) => (
                        <div
                          key={index}
                          className="flex align-items-center gap-3"
                        >
                          <a className="m-0 flex align-items-center gap-2 underline text-charcoal">
                            <img src={pdfIcon} alt="PDF" />
                            <span>{fileName.name}</span>
                          </a>
                          <Button
                            text
                            className="p-button-plain gap-2 text-delete underline"
                            onClick={() => handleDelete(index)}
                            aria-label={t("standard.delete")}
                          >
                            <FeatherIcon
                              name="trash"
                              size={20}
                              color="inheritance"
                            />
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DocumentUpload;
