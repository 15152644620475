import React from "react";
import DetailTab from "./DetailTab";
import OtherConfigurationTab from "./OtherConfigurationTab";
import ContributingCommitteeTab from "./ContributingCommitteeTab";
import DcoumentTabs from "./DocumentTabs";
interface PerviewDetails {
  committeeData: Array<{ Name: string; Id: string }>;
  maintainanceCycleData: Array<{ Name: string; Id: string }>;
  hierarchyData: Array<{ Name: string; Id: string }>;
  accreditationData: Array<{ Name: string; Id: string }>;
}

const Preview: React.FC<PerviewDetails> = ({
  committeeData,
  maintainanceCycleData,
  hierarchyData,
  accreditationData,
}) => {
  return (
    <>
      <div>
        <DetailTab
          showEditButton={true}
          committeeData={committeeData}
          maintainanceCycleData={maintainanceCycleData}
        />
        <DcoumentTabs showEditButton={true} />
        <OtherConfigurationTab
          showEditButton={true}
          committeeData={committeeData}
          hierarchyData={hierarchyData}
          accreditationData={accreditationData}
        />
        <ContributingCommitteeTab showEditButton={true} />
      </div>
    </>
  );
};

export default Preview;
