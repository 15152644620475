import React from 'react';
import { BallotRecord } from '../../common/type';

interface RecordCardProps {
  recordData: any;
}

const RecordCard: React.FC<RecordCardProps> = ({
  recordData
}) => {


  return (
      <>
      <div className="flex flex-column gap-1">
            <span className="m-0 font-bold text-capitalize text-base">
              Applicant Name
            </span>
            <p className="m-0 font-normal text-base text-title m-0 w-full">
              {recordData?.record?.userProfile?.firstname}
            </p>
          </div>
          <div className="flex flex-column gap-1">
            <span className="m-0 font-bold text-capitalize text-base">
              Committee Applied For
            </span>
            <p className="m-0 font-normal text-base text-title m-0 w-full">
              {recordData?.record?.committee?.name}
            </p>
          </div>
          <div className="flex flex-column gap-1">
            <span className="m-0 font-bold text-capitalize text-base">
              Committee Position
            </span>
            <p className="m-0 font-normal text-base text-title m-0 w-full">
              {recordData.record?.membershipPosition?.positionName}
            </p>
          </div>
          <div className="flex flex-column gap-1">
            <span className="m-0 font-bold text-capitalize text-base">
              Category of Interest
            </span>
            <p className="m-0 font-normal text-base text-title m-0 w-full">
              {recordData?.record?.categoryOfInterest?.categoryName}
            </p>
          </div>
          <div className="flex flex-column gap-1">
            <span className="m-0 font-bold text-capitalize text-base">
              Term Years
            </span>
            <p className="m-0 font-normal text-base text-title m-0 w-full">
              {recordData.term}
            </p>
          </div>
      </>
          
  );
};

export default RecordCard;
