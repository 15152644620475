import React from 'react';
import { Badge } from 'primereact/badge';
import { Product } from './BallotListingTableType';

interface StatusBodyTemplateProps {
    rowData: Product;
    col: { field: string };
    getHeaderLabel: (field: string) => string;
}

const StatusBodyTemplate: React.FC<StatusBodyTemplateProps> = ({ rowData, col, getHeaderLabel }) => {
    let badgeClass = '';

    switch (rowData.statusName?.toLocaleLowerCase()) {
        case 'draft':
            badgeClass = 'draft';
            break;
        case 'open':
            badgeClass = 'open';
            break;
        case 'submitted':
            badgeClass = 'open';
            break;
        case 'in-progress':
        case 'converted to record':
            badgeClass = 'active';
            break;
        case 'active':
        case 'completed':
        case 'approved':
            badgeClass = 'active';
            break;
        case 'current':
            badgeClass = 'active';
            break;
        case 'created':
            badgeClass = 'active';
            break;
        case 'disbanded':
        case 'on-hold':
        case 'withdrawn':
            badgeClass = 'disbanded';
            break;
        case 'terminated':
            badgeClass = 'terminated';
            break;
        case 'deactive':
        case 'delay':
        case 'rejected':
            badgeClass = 'rejected';
            break;
        case 'closed':
            badgeClass = 'closed';
            break;
        case 'terminate':
            badgeClass = 'deactive';
            break;
        case 'retired':
            badgeClass = 'deactive';
            break;
        default:
            break;
    }

    return (
        <>
            <span className="p-column-title">{getHeaderLabel(col.field)}</span>
            <Badge value={rowData.statusName} className={`h-auto text-sm ${badgeClass}`} />
        </>
    );
};

export default StatusBodyTemplate;
