import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { fetchData } from "../../services/Ballot/apiservice";
import { BallotReport } from "./types/BallotReport";
import withLoader from "../common/LoaderIntercepter";
import BallotReportRecord from "./common/BallotReportRecord";
import BallotReportVotingCriteria from "./common/BallotReportVotingCriteria";
import BallotCommitteeReport from "./common/BallotCommitteeReport";

interface BallotTabReportProps {
  setActiveIndex: (index: number) => void;
}

const BallotTabReport: React.FC<BallotTabReportProps> = ({
  setActiveIndex,
}) => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [primaryCommittee, setPrimaryCommittee] = useState<string | null>("");
  const [ballotReport, setBallotReport] = useState<BallotReport | null>(null);
  const [recordLists, setRecordList] = useState<any | any>([]);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const getBallotHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(`Ballot/${id}`);
      setPrimaryCommittee(ballotDetailsData?.Committee?.Name);
    } catch (err) {
      console.error("Error fetching ballot details", err);
    }
  };

  const getBallotDetailsHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(
        `BallotRecordVote/BallotReports/${id}`
      );
      setBallotReport(ballotDetailsData);
    } catch (err) {
      console.error("Error fetching ballot details", err);
    }
  };

  useEffect(() => {
    const fetchBallotRecord = async () => {
      try {
        const responseData = await fetchData(
          `BallotRecord/BallotClosureRecordlistDetails/${id}`
        );
        const sortedData = responseData.collection.map((item: any) => ({
          recordNumber: item.recordNumber,
          committeeName: item.committeeName,
          currentStatusName: item.recordStatusName,
        }));
        setRecordList(sortedData);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    if (id) {
      fetchBallotRecord();
    }
  }, [id]);

  useEffect(() => {
    getBallotHandler();
    getBallotDetailsHandler();
  }, [id]);
  return (
    <>
      <div>
        <BallotReportVotingCriteria
          ballotTotalMember={ballotReport?.TotalMemberInvited}
          ballotVoteReceived={ballotReport?.VoteRecived}
        />
        <BallotReportRecord
          recordLists={recordLists}
          isBallotClosure={false}
          oldStatusFalse={false}
        />
        <BallotCommitteeReport
          isBallotClosure={false}
          committeeReports={ballotReport?.ballotCommitteeReports ?? []}
          primaryCommittee={primaryCommittee}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </>
  );
};

export default withLoader(BallotTabReport);
