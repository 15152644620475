import React, { useState, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { MultiSelect } from "primereact/multiselect";
interface Option {
  name: string;
  code: string;
}

const CommitteeTabCommitteeMeeting: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();

  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [dateActivation, setDateActivation] = useState(null);
  const actionItems: MenuItem[] = [
    {
      label: t("committee.viewEditMeeting"),
      // command: () => {
      //   navigate("/member/request-view");
      // },
    },
    {
      label: t("committee.cancelMeeting"),
    },
    {
      label: "Add minutes",
    },
  ];
  return (
    <>
      <div className="">
        <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchMeeting"
              placeholder={t("committee.searchMeeting")}
              className="w-full"
              aria-label={t("committee.searchMeeting")}
            />
          </IconField>
          <div className="flex flex-wrap md:flex-nowrap gap-3 w-full md:w-auto align-items-center">
            {/* <Dropdown
              inputId="selectMeeting"
              value={selectedOption}
              onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
              options={options}
              optionLabel="name"
              placeholder={t("committee.selectMeeting")}
              className="w-full md:w-16rem"
              aria-label={t("committee.selectMeeting")}
            /> */}
            <div className="flex gap-3 w-full md:w-auto align-items-center">
              <div className="relative">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleFilterPanel}
                  aria-label={t("committee.filter")}
                >
                  <FeatherIcon name="filter" size={20} color="inherit" />
                  <span className="font-bold text-capitalize hidden md:flex">
                    {t("committee.filter")}
                  </span>
                </Button>
                {/* OverlayPanel for Filter */}
                <OverlayPanel
                  ref={overlayPanelRef}
                  onHide={() => setShowFilterPanel(false)}
                  appendTo={"self"}
                  dismissable
                  className="p-overlaypanel-filter left-auto md:right-0 top-100"
                  aria-labelledby="filter-heading"
                  role="dialog"
                >
                  <div className="flex flex-column gap-1" tabIndex={0}>
                    <h3
                      id="filter-heading"
                      className="text-lg font-normal m-0 p-3"
                    >
                      {t("committee.filter")}
                    </h3>
                    <div className="filter-wrap">
                      <div className="">
                        <label
                          htmlFor="committeeNameFilter"
                          className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                        >
                          <Checkbox
                            inputId="committeeNameFilter"
                            className="mr-2"
                            tabIndex={0}
                            checked={true}
                          />
                          {"Meeting Type"}
                        </label>
                        <div className="pr-3 py-2 pl-6 w-full relative">
                          <MultiSelect
                            // appendTo="self"
                            // value={selectedCommittees}
                            // onChange={(e) => setSelectedCommittees(e.value)}
                            // options={committees}
                            optionLabel="Name"
                            filter
                            placeholder={"Meeting type"}
                            maxSelectedLabels={3}
                            className="w-full"
                            aria-label={"Meeting type"}
                          />
                        </div>
                      </div>
                      <div className="p-0">
                        <label
                          htmlFor="categoryOfInterestFilter"
                          className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                        >
                          <Checkbox
                            // inputId="categoryOfInterestFilter"
                            checked={true}
                            className="mr-2"
                            tabIndex={0}
                          />
                          {"Start Date"}
                        </label>
                        <div className="pr-3 py-2 pl-6 w-full relative">
                          <MultiSelect
                            // appendTo="self"
                            optionLabel="Start Date"
                            filter
                            placeholder={"Start Date"}
                            maxSelectedLabels={3}
                            className="w-full"
                            aria-label={"Start Date"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </OverlayPanel>
              </div>
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={() => navigate("/committee/add-meeting")}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("committee.newMeeting")}
                </span>
              </Button>
            </div>
          </div>
        </div>

        <Table
          loading={false}
          products={["Harshita", "Raman", "Rupali"]}
          columns={[
            { field: "meetingName", header: t("committee.meetingName") },
            { field: "meetingType", header: t("committee.meetingType") },
            { field: "location", header: t("committee.location") },
            { field: "startDateTime", header: t("committee.startDateTime") },
            { field: "endDateTime", header: t("committee.endDateTime") },
            { field: "status", header: t("committee.status") },
            { field: "action", header: t("committee.action"), sortable: false },
          ]}
          actionItems={actionItems}
        />
      </div>
    </>
  );
};

export default CommitteeTabCommitteeMeeting;
