import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import PreviewItem from "../common/PreviewItem";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setActiveStep } from "../../slices/createStandard";
import {
  getCommitteeCheck,
  getSubsequentPublishYears,
  getStandardCommiteeName,
  getMaintananceCycleName,
} from "./utills/standardUtills";

interface DetailTabProps {
  showEditButton?: boolean;
  committeeData: any;
  maintainanceCycleData: any;
}

const DetailTab: React.FC<DetailTabProps> = ({
  committeeData,
  maintainanceCycleData,
  showEditButton,
}) => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const dispatch = useDispatch();

  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );

  const basicDetails = createRequestDetail.basicDetail;

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.basicDetails")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => {
                  dispatch(setActiveStep(0));
                }}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("standard.standardCreationBasedOn")}
                value={getCommitteeCheck(basicDetails.IsBasedonCommittee)}
              />
              <PreviewItem
                label={t("standard.committee")}
                value={
                  basicDetails.WorkingCommitteeId
                    ? getStandardCommiteeName(
                        basicDetails.WorkingCommitteeId,
                        committeeData
                      )
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.meetingId")}
                value={
                  basicDetails.MeetingNumber
                    ? basicDetails.MeetingNumber
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.recordId")}
                value={
                  basicDetails.RecordNumber ? basicDetails.RecordNumber : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.ballotId")}
                value={
                  basicDetails.BallotNumber ? basicDetails.BallotNumber : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.standardName")}
                value={basicDetails.Name}
              />
              <PreviewItem
                label={t("standard.standardShortName")}
                value={basicDetails.ShortName ? basicDetails.ShortName : "N/A"}
              />
              <PreviewItem
                label={t("standard.maintenanceCycle")}
                value={
                  basicDetails.MaintainanceCycleId
                    ? getMaintananceCycleName(
                        basicDetails.MaintainanceCycleId,
                        maintainanceCycleData
                      )
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.publishingCycle")}
                value={
                  basicDetails.PublishingCycle
                    ? `${basicDetails.PublishingCycle} Years`
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.latestPublishedYear")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.nextPublishingYear")}
                value={
                  basicDetails.PublishingCycle
                    ? `${basicDetails.PublishingCycle}`
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.subsequentPublishingYears")}
                value={getSubsequentPublishYears(
                  basicDetails.SubsequentPublishingYears
                )}
              />
              <PreviewItem
                label={t("standard.previousPublishingYear")}
                value="Value"
              />
              <PreviewItem
                label={t("standard.standardAbstract")}
                value={
                  basicDetails.StandardAbstract
                    ? basicDetails.StandardAbstract
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.industryNeeds")}
                value={
                  basicDetails.IndustryNeeds
                    ? basicDetails.IndustryNeeds
                    : "N/A"
                }
              />
              <PreviewItem
                label={t("standard.proposedBenefitOfStandards")}
                value={
                  basicDetails.ProposedBenefit
                    ? basicDetails.ProposedBenefit
                    : "N/A"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailTab;
