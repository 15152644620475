import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import DataGrid from "../common/DataGrid";
import { MenuItem } from "primereact/menuitem";
import { usePermission } from "../auth/Authorization";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { formatRequestData } from "../../utils/utils";
import CustomMenu, { getStatusBadge } from "../common/customMenu";
interface ResignListProps {
  userProfileId: any;
}

const ResignMembers: React.FC<ResignListProps> = ({ userProfileId }) => {
  const [t, i18n] = useTranslation("member");
  const [committees, setRequestsList] = useState([]);
  const [search, setSearch] = useState("");
  const [userRole, setUserRole] = useState<string>("");
  //Pagination
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortField, setSortField] = useState<string>("RequestDate");

  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();

  const { selectedLanguage } = useLanguageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const handleViewMember = (id: any) => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        sessionStorage.setItem("memberShipId", id);
        navigate("/member/create");
      } else {
        sessionStorage.setItem("memberShipId", id);
        navigate("/member/request-view");
      }
    };
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const navigate = useNavigate();

  const getFilter = (): any => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: any }[] = [];
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    const sortFieldValue =
      sortField === "CreatedDate" ? "RequestDate" : sortField;
    if (userRole !== "" && userProfileId !== "") {
      switch (userRole) {
        case "Staff":
          filters.push({ Key: "StaffId", Value: userProfileId });
          break;
        case "Member":
          filters.push({ Key: "UserProfileId", Value: userProfileId });
          break;
        default:
          break;
      }
      filters.push({ Key: "IsResignRequest", Value: true });
      filters.forEach((filter, index) => {
        queryParams[`Filters[${index}].Key`] = filter.Key;
        queryParams[`Filters[${index}].Value`] = filter.Value;
      });
      queryParams["PageIndex"] = pageIndex;
      queryParams["PageSize"] = pageSize;
      queryParams["SearchText"] = debouncedSearchTerm;
      queryParams["SortBy"] = sortFieldValue;
      queryParams["SortDir"] = sortOrderValue;
      return queryParams;
    }
  };

  const resignDataFetch = async () => {
    const queryParams = getFilter();
    setLoading(true);
    const apiUrl = `${BASE_URL}/MembershipRequest/GridSearch?${new URLSearchParams(
      queryParams
    ).toString()}`;
    try {
      const response = await axiosInstance.get(apiUrl);
      if (response.status === 200) {
        const filteredData = response.data;
        setTotalCount(filteredData.TotalCount);
        const sortedData = formatRequestData(filteredData.Collection);
        setRequestsList(sortedData);
      } else {
        console.error("Failed to fetch filtered data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userRole) {
      resignDataFetch();
    }
  }, [
    userRole,
    pageIndex,
    pageSize,
    sortField,
    sortOrder,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 500); // 500ms delay
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("member.viewEditRequest"),
        command: handleViewMember(rowData.Id),
      },
    ];

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  return (
    <>
      <IconField iconPosition="left" className="w-full md:w-16rem mb-4">
        <InputIcon className="pi pi-search"> </InputIcon>
        <InputText
          id="searchCommittee"
          className="w-full"
          placeholder={"Search"}
          aria-label={t("record.searchCommittee")}
          onChange={(e) => setSearch(e.target.value)}
        />
      </IconField>
      <DataGrid
        loading={loading}
        products={committees}
        columns={[
          {
            field: "MembershipRequestNumber",
            header: t("member.request"),
            link: "/member/request-view",
          },
          { field: "MemberName", header: t("member.memberName") },
          {
            field: "CommitteeName",
            header: t("member.committeeApplied"),
          },
          {
            field: "MemberPosition",
            header: t("member.positionApplied"),
          },
          {
            field: "CategoryOfInterest",
            header: t("member.categoryofIntrest"),
          },
          {
            field: "CreatedDate",
            header: t("member.requestDate"),
            sortable: true,
          },
          {
            field: "StatusName",
            header: t("member.status"),
            body: (rowData: any) => getStatusBadge(rowData.StatusName),
          },
          {
            field: "Action",
            header: t("member.action"),
            body: actionPositionBodyTemplate,
            sortable: false,
          },
        ]}
        pageIndex={pageIndex}
        totalRecords={totalCount}
        pageSize={pageSize}
        onPageChange={pageHandler}
        sortField={sortField}
        sortOrder={sortOrder}
        onSortChange={sortHandler}
      />
    </>
  );
};

export default ResignMembers;
