export const STANDARD_TABLE_COLUMNS = (t: any) => [
  { field: "StandardName", header: t("standard.standardName") },
  { field: "ShortName", header: t("standard.shortName") },
  {
    field: "CommitteeName",
    header: t("standard.primaryCommitteeResponsible"),
  },
  { field: "MaintainanceCycle", header: t("standard.maintenanceCycle") },
  { field: "PublishedYear", header: t("standard.publishedYear") },
  { field: "Accridation", header: t("standard.accreditation") },
  { field: "action", header: t("standard.action") },
];
