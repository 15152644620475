import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { fetchData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import Table from "../common/Table";
import { CSVLink } from "react-csv"; // Import CSVLink
import { usePermission } from "../auth/Authorization";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";

interface Option {
  name: string;
  code: string;
}

const MembershipExpiration: React.FC = () => {
  const [t, i18n] = useTranslation("report");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [searchText, setSearchText] = useState('');
  const [userProfileId, setUserProfileId] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } = usePermission();
  const [committeeList, setCommitteeList] = useState<{ label: string; value: string }[]>([]);
  const [committeeValue, setCommitteeValue] = useState<string[] | null>(null);
  const [memberValue, setMembersValue] = useState<string[] | null>(null);
  const [memberList, setMemberList] = useState<{ label: string; value: string }[]>([]);
  const [userRole, setUserRole] = useState<string>("");
  const [requestList, setRequestLists] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const breadcumbItems = [
    { label: t("report.dashboard"), url: "/dashboard" },
    { label: t("report.reports") },
  ];
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  const exportData = () => {
    if (!requestList) {
      return null;
    }
    const sortedData = requestList.map((item: any) => ({
      "Committee Name": item?.CommitteeName,
      "Member Name": item?.MemberName,
      "Member Position": item?.MemberPosition,
      "Membership Expiration date": item?.MemberExpirationDate,
    }));
    const csvData = [...sortedData];
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const filename = `Membership_Expiration_Report_${day}-${month}-${year}_${hours}${minutes}${seconds}.csv`;
    return (
      <CSVLink data={csvData} filename={filename} >
        <Button className="button-md gap-1 justify-content-center w-full md:w-auto">
          <span className="font-bold text-capitalize">{t("report.export")}</span>
        </Button>
      </CSVLink>
    );
  };

  const handleChangeCommittee = (e: any) => {
    const selectedCommittee = e.value;
    setCommitteeValue(selectedCommittee);
  };

  const handleChangeMembers = (e: any) => {
    const selectedMembers = e.value;
    setMembersValue(selectedMembers);
  };

  const buildUrl = (selectedDate: any, memberValue: any, committeeValue: any, userProfileId: string) => {
    let url = 'CommitteeMembership/MembershipExpirationReport?';
    const filters: any = [];
    if (selectedDate) {
      const parsedDays = parseInt(selectedDate, 10);
      filters.push({ Key: 'noofdaystoexpiration', Value: parsedDays });
    }
    if (committeeValue && committeeValue.length > 0) {
      filters.push({ Key: 'CommitteeId', Value: committeeValue });
    }
    if (memberValue && memberValue.length > 0) {
      filters.push({ Key: 'UserProfileId', Value: memberValue });
    }
    if (userProfileId && userRole !== 'Admin') {
      filters.push({ Key: 'StaffId', Value: userProfileId });
    }
    url += filters.map((filter: any) => `Filters[${filters.indexOf(filter)}].Key=${filter.Key}&Filters[${filters.indexOf(filter)}].Value=${filter.Value}`).join('&');
    url += `&PageIndex=-1`;
    return url;
  }

  const formatDateForResponse = (dateString: any) => {
    const date = new Date(dateString);
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const fetchDataBasedOnFilters = async (url: string, setRequestLists: any, setMemberList: any) => {
    try {
      const responseData = await fetchData(url);
      const sortedData = responseData.Collection.map((item: any) => ({
        ...item,
        MemberExpirationDate: formatDateForResponse(item.MemberExpirationDate)
      }));
      setRequestLists(sortedData);
      if (!committeeValue && !memberValue) {
        const uniqueUserIds = new Set();
        const memberListData = responseData.Collection.reduce((accumulator: any, item: any) => {
          if (!uniqueUserIds.has(item.UserProfileId)) {
            accumulator.push({
              label: item.MemberName,
              value: item.UserProfileId
            });
            uniqueUserIds.add(item.UserProfileId);
          }
          return accumulator;
        }, []);
        const committeeListData = responseData.Collection.reduce((accumulator: any, item: any) => {
          if (!uniqueUserIds.has(item.CommitteeId)) {
            accumulator.push({
              label: item.CommitteeName,
              value: item.CommitteeId
            });
            uniqueUserIds.add(item.CommitteeId);
          }
          return accumulator;
        }, []);
        setCommitteeList(committeeListData);
        setMemberList(memberListData);
      }
    } catch (error) {
      console.error("Error fetching current user profile:", error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!userProfileId) return;
    const url = buildUrl(selectedDate, memberValue, committeeValue, userProfileId);
    if (url) {
      fetchDataBasedOnFilters(url, setRequestLists, setMemberList);
    }
  }, [selectedDate, memberValue, committeeValue, userProfileId]);

  const handleInputChange = (value: any) => {
    setSelectedDate(value.target.value);
  };
  return (
    <>
      <div>
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-wrap md:flex-nowrap md:align-items-center gap-3 w-full justify-content-between">
          <h1 className="text-title display-xs font-bold m-0">
            {t("report.membershipExpirations")}
          </h1>
          <InputText
            type="number"
            placeholder={t("report.numberOfDaysToExpiration")}
            aria-label={t("report.numberOfDaysToExpiration")}
            value={selectedDate}
            className="w-full md:w-18rem lg:w:20rem"
            onChange={(value) => handleInputChange(value)}
            min="1"
            onKeyDown={(e) => e.key === '-' && e.preventDefault()}
          />
        </div>
        <div>
          <div className="flex flex-wrap md:align-items-center py-3 gap-3 w-full justify-content-between">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("report.search")}
                aria-label={t("report.search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </IconField>
            <div className="flex flex-wrap gap-3 w-full md:w-auto align-items-center">
              <MultiSelect value={committeeValue} onChange={handleChangeCommittee} options={committeeList} optionLabel="label" filter display="chip"
                placeholder={t("report.selectCommittees")} aria-label={t("report.selectCommittees")} maxSelectedLabels={3} className="w-full md:w-18rem lg:w:20rem" />
              <MultiSelect value={memberValue} onChange={handleChangeMembers} options={memberList} optionLabel="label" display="chip" filter placeholder={t("report.selectMembers")} aria-label={t("report.selectMembers")} maxSelectedLabels={3} className="w-full md:w-18rem lg:w:20rem" />
              {exportData()}
            </div>
          </div>
          <Table
            loading={loading}
            products={requestList?.filter((item: any) => {
              const MemberName = "" || item.MemberName;
              const CommitteeName = "" || item.CommitteeName;
              return (
                searchText === "" ||
                CommitteeName
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                  MemberName
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })}
            columns={[
              { field: "CommitteeName", header: t("report.committeeName") },
              { field: "MemberName", header: t("report.memberName") },
              { field: "MemberPosition", header: t("report.memberPosition") },
              { field: "MemberExpirationDate", header: t("report.membershipExpirationDate") }
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default MembershipExpiration;
