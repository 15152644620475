export const getCommitteeCheck = (IsBasedonCommittee: any) => {
  const isBasedAccredited = IsBasedonCommittee ? "Committee" : "Direct";
  return isBasedAccredited;
};

export const getSubsequentPublishYears = (yearsArary: any) => {
  if (yearsArary) {
    const publishedYears = yearsArary.join(",");
    return publishedYears;
  }
};

export const getStandardCommiteeName = (
  commiteeId: string,
  commiteeData: any
) => {
  const commiteeInfo: any = commiteeData.find(
    (element: any) => element.Id === commiteeId
  );
  return commiteeInfo?.Name;
};

export const getMaintananceCycleName = (
  maintainanceCycleId: string,
  maintanceData: any
) => {
  const maintananceInfo: any = maintanceData.find(
    (element: any) => element.Id === maintainanceCycleId
  );
  return maintananceInfo?.Name;
};

export const getCommitteeName = (CommitteeIds: any, commiteeData: any) => {
  const filteredCommittees = commiteeData
    .filter((committee: any) => CommitteeIds.includes(committee.Id))
    .map((committee: any) => committee.Name)
    .join(", ");
  return filteredCommittees;
};

export const getAccreditationName = (
  accreditationId: string,
  accreditationData: []
) => {
  const accreditationInfo: any = accreditationData.find(
    (element: any) => element.Id === accreditationId
  );
  return accreditationInfo?.Name;
};

export const getSelected = (IsSelectedId: any) => {
  const isAccreditated = IsSelectedId ? "Yes" : "No";
  return isAccreditated;
};

export const getContibutionData = (ContibutionInfo: any, CommiteeData: any) => {
  const mergedCommittees = ContibutionInfo.map((vote: any) => {
    const matchedCommittee = CommiteeData.find(
      (committee: any) => committee.CommitteeId === vote.CommitteeId
    );

    if (matchedCommittee) {
      return {
        ...vote,
        CommitteeName: matchedCommittee.CommitteeName,
        StaffName: matchedCommittee.PrimaryStaff,
        CommitteeLevel: matchedCommittee.CommitteeLevel,
      };
    }

    return vote; // If no match is found, return the original object
  });

  return mergedCommittees;
};

export const getDefaultBasicFormValue = (basicDetail: any) => ({
  WorkingCommitteeId: basicDetail?.WorkingCommitteeId || "",
  MeetingNumber: basicDetail?.MeetingNumber || "",
  RecordNumber: basicDetail?.RecordNumber || "",
  BallotNumber: basicDetail?.BallotNumber || "",
  Name: basicDetail?.Name || "",
  ShortName: basicDetail?.ShortName || "",
  MaintainanceCycleId: basicDetail?.MaintainanceCycleId || "",
  PublishingCycle: basicDetail?.PublishingCycle || "",
  SubsequentPublishingYears: basicDetail?.SubsequentPublishingYears || [],
  StandardAbstract: basicDetail?.StandardAbstract || "",
  IndustryNeeds: basicDetail?.IndustryNeeds || "",
  ProposedBenefit: basicDetail?.ProposedBenefit || "",
  StandardCreationBasedOn: basicDetail?.StandardCreationBasedOn || "Committee",
});

export const getDefaultOtherFormValue = (otherDetail: any) => ({
  AccreditationBodyId: otherDetail.AccreditationBodyId || "",
  JointlyDevelopedwithInternalCommitteeIds:
    otherDetail.JointlyDevelopedwithInternalCommitteeIds || [],
  jointlyDevelopedwithExternalCommittees:
    otherDetail.jointlyDevelopedwithExternalCommittees || "",
  BoardCommitteeIds: otherDetail.BoardCommitteeIds || [],
  StandardCommitteeIds: otherDetail.StandardCommitteeIds || "",
  SubTierCommitteeIds: otherDetail.SubTierCommitteeIds || "",
  PrimaryCommitteeResponsibleCommitteeIds:
    otherDetail.PrimaryCommitteeResponsibleCommitteeIds || "",
  Accredited: otherDetail.Accredited || "No",
  IsStandardJointlyDeveloped: otherDetail.IsStandardJointlyDeveloped || "No",
});

export const getStandardDataTypes = (data: any) => {
  if (data.length > 0) {
    const dataTypes = data.find((element: any) => element.Name === "Standard");
    return dataTypes;
  }
  return {};
};

export const generateYears = (maxYears: any) => {
  return Array.from({ length: maxYears }, (_, i) => ({
    name: `${i + 1} Year${i + 1 > 1 ? "s" : ""}`,
    value: i + 1,
  }));
};

export const createStandardBasicDetails = (
  data: Record<string, any>
): Record<string, any> => {
  const cleanedData = Object.fromEntries(
    Object.entries(data).filter(
      ([_, value]) =>
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0)
    )
  );

  if (cleanedData.StandardCreationBasedOn !== "Committee") {
    delete cleanedData.WorkingCommitteeId;
  }

  return {
    ...cleanedData,
    IsBasedonCommittee: cleanedData.StandardCreationBasedOn === "Committee",
  };
};

export const createStandardOtherDetails = (
  data: Record<string, any>
): Record<string, any> => {
  const cleanedData = Object.fromEntries(
    Object.entries(data).filter(
      ([_, value]) =>
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0)
    )
  );

  if (cleanedData.Accredited === "No") {
    delete cleanedData.AccreditationBodyId;
  }

  if (cleanedData.IsStandardJointlyDeveloped === "No") {
    delete cleanedData.JointlyDevelopedwithInternalCommitteeIds;
  }

  return {
    ...cleanedData,
    IsAccreditated: cleanedData.Accredited === "Yes",
    IsJointlyDeveloped: cleanedData.IsStandardJointlyDeveloped === "Yes",
  };
};
