// Table pagination clcick scroll to table top
import { useEffect, useRef } from "react";

export const useScrollOnPaginatorClick = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handlePaginatorClick = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.closest(".p-paginator .p-paginator-element")) {
        if (tableContainerRef.current) {
          tableContainerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener("click", handlePaginatorClick);
    }

    return () => {
      if (container) {
        container.removeEventListener("click", handlePaginatorClick);
      }
    };
  }, []);

  return tableContainerRef;
};
