import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
  message: string | null;
  severity: 'success' | 'error' | 'warn' | 'info'; // Add severity
}

const initialState: ToastState = {
  message: null,
  severity: 'info', // Default severity
};


const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action: PayloadAction<{ message: string; severity: 'success' | 'error' | 'warn' | 'info' }>) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    clearToast: (state) => {
      state.message = null;
      state.severity = 'info';
    },
  },
});

export const { setToast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;