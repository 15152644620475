import React, { useEffect, useRef, useState } from "react";
import { Badge } from "primereact/badge";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Link, useNavigate } from "react-router-dom";
import pdfIcon from "../../assets/icons/pdf.svg";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchData, putData, postData } from "../../services/apiService";
import { InputTextarea } from "primereact/inputtextarea";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Menu } from "primereact/menu";
import { usePermission } from "../auth/Authorization";
import RequestListDocument from "../common/RequestListDocument";
import Table from "../common/Table";
import RecordListDocument from "../common/RecordListDocument";
import LoadingOverlay from "../common/LoadingOverlay";
import { getMembershipStatusIdByName } from "../../utils/utils";
import { ScrollPanel } from "primereact/scrollpanel";
import { Panel } from "primereact/panel";

interface OutletWrapperProps {
  selectedLanguage: string;
}
const ViewRequest: React.FC = () => {
  const [remark, setRemark] = useState("");
  const [remarkError, setRemarkError] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [withdrawnremarkError, setWithdrawnRemarkError] = useState("");
  const toast = useRef<any>(null);
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [membershipRequestId, setMembershipRequestId] = useState<any>(null);
  const [membershipRecordId, setMembershipRecordId] = useState<any>(null);
  const [alternetMemberUserProfileId, setAlternetMemberUserProfileId] =
    useState<any>(null);
  const [isShowWithdrawn, setIsShowWithdrawn] = useState<boolean>(true);
  const [memberShipData, setMemberShipData] = useState<any>(null);
  const [isConvertToRecord, setIsConvertRecord] = useState<boolean>(true);
  const [isStatusSubmitted, setStatusSubmitted] = useState<boolean>(true);
  const [rejectValue, setRejectValue] = useState("");
  const [auditHistoryList, setAuditHistorList] = useState<any>(null);
  const createCommitteeData = useSelector(
    (state: RootState) => state.createMemberRequest
  );
  const [requestBelongToStaff, setRequestBelongToStaff] = useState("");
  const [alternetMemberName, setAlternetMemberName] = useState<any>(null);
  const [hasFetched, setHasFetched] = useState(false);
  const userProfileID = localStorage.getItem("userProfileID");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    {
      label: memberShipData?.IsResignRequest
        ? t("member.resignationRequest")
        : t("member.membershipRequest"),
    },
  ];
  const [checked, setChecked] = useState<boolean>(true);
  const handleCheckboxChange = (e: any) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [showActionItem, setShowActionItem] = useState<any>([]);
  const navigate = useNavigate();
  const [visibleReject, setVisibleReject] = useState<boolean>(false);
  const [visibleWithdraw, setVisibleWithdraw] = useState<boolean>(false);
  const [value, setValue] = useState("");

  const handleReject = async () => {
    if (!rejectValue.trim()) {
      setRemarkError(`${t("member.remark")} ${t("member.isRequired")}`);
      return;
    }
    const reasonForRejection = {
      reasonForRejection: rejectValue,
    };
    const putDataResponse = await putData(
      `MembershipRequest/RejectMembershipRequest?membershipRequestId=${memberShipData.Id}`,
      undefined,
      reasonForRejection
    );

    memberShipData.MembershipStatusId =
      await getMembershipStatusIdByName("Rejected");
    await putData("MembershipRequest", memberShipData.Id, memberShipData);
    setVisibleReject(false);
    navigate("/member");
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (userRole === "Member") {
      const items = [
        {
          label: t("member.withdrawal"),
          command: handleWithdrawCondition,
        },
      ];
      setShowActionItem(items);
    } else {
      const items = [
        {
          label: t("member.reject"),
          command: handleRejectCondition,
        },
      ];
      setShowActionItem(items);
    }
  }, [userRole]);

  const converToRecord = async () => {
    setLoading(true);
    if (memberShipData?.IsResignRequest === true) {
      const data = {
        membershipRequestId: memberShipData.Id,
        membershipTerminateReasonId: null,
      };
      const postDataResponse = await postData(
        `Record/MembershipRemoveRecord`,
        data
      );
      if (postDataResponse) {
        toast.current.show({
          severity: "success",
          summary: t("member.success"),
          detail: t("member.requestCreatedAsRecord"),
        });
        setTimeout(() => {
          navigate("/member");
        }, 1000);
      }
    } else {
      const putDataResponse = await putData(
        `Record/ConvertMembershipRequesttoRecord/${memberShipData.Id}`,
        undefined,
        undefined
      );

      if (putDataResponse) {
        toast.current.show({
          severity: "success",
          summary: t("member.success"),
          detail: t("member.requestCreatedAsRecord"),
        });
        setTimeout(() => {
          navigate("/member");
        }, 1000);
        setLoading(false);
      }
    }
  };

  const footerContentReject = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("member.cancel")}
        onClick={() => {
          setVisibleReject(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("member.reject")}
        onClick={handleReject}
        className="button-md"
      />
    </div>
  );

  const handleWithdraw = async () => {
    if (!value.trim()) {
      setWithdrawnRemarkError(
        `${t("member.remark")} ${t("member.isRequired")}`
      );
      return;
    }

    const reasonForRejection = {
      reasonForRejection: value,
    };
    const putDataResponse = await putData(
      `MembershipRequest/WithdrawMembershipRequest?membershipRequestId=${memberShipData.Id}`,
      undefined,
      reasonForRejection
    );
    memberShipData.MembershipStatusId =
      await getMembershipStatusIdByName("Withdrawn");
    await putData("MembershipRequest", memberShipData.Id, memberShipData);
    setVisibleWithdraw(false);
    const redirectionPath = sessionStorage.getItem("redirectionPath");
    toast.current.show({
      severity: "success",
      summary: t("member.success"),
      detail: t("member.requestWithdrawSuccessfully"),
    });
    if (redirectionPath === "request") {
      setTimeout(() => {
        navigate(`/committee/details/${memberShipData.Committee.Id}`);
      }, 1000);
    } else {
      setTimeout(() => {
        navigate("/member");
      }, 1000);
    }
  };

  const footerContentWithdraw = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("member.cancel")}
        onClick={() => {
          setVisibleWithdraw(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("member.withdraw")}
        onClick={handleWithdraw}
        className="button-md"
      />
    </div>
  );

  const isValidMembershipRecordId = (id: string) => {
    const invalidId = "00000000-0000-0000-0000-000000000000";
    return id && id !== invalidId;
  };
  const fetchRequestData = async () => {
    try {
      const response = await fetchData(
        `MembershipRequest/IsRequestBelongToStaff?staffId=${userProfileID}&membershipRequestId=${membershipRequestId}`
      );
      setRequestBelongToStaff(response);
      setHasFetched(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (memberShipData && membershipRequestId && !hasFetched) {
      fetchRequestData();
    }
  }, [memberShipData, membershipRequestId, hasFetched]);

  React.useEffect(() => {
    const storedData = sessionStorage.getItem("memberShipId");
    setMembershipRequestId(storedData);
    if (storedData) {
      const fetchDataMemberRequest = async () => {
        try {
          const responseData = await fetchData("MembershipRequest", storedData);

          setAlternetMemberUserProfileId(
            responseData.AlternateMemberUserProfileId
          );
          setMembershipRecordId(responseData.RecordId);
          const fetchAuditHistory = async () => {
            try {
              const response = await fetchData(
                `Audit/${responseData?.Id}/MembershipRequest`
              );

              setAuditHistorList(response);
            } catch (error) {
              console.error("Error fetching committees:", error);
            }
          };
          fetchAuditHistory();
          setMemberShipData(responseData);
          if (responseData.MembershipStatus.Description !== "Submitted") {
            setStatusSubmitted(true);
          } else {
            setStatusSubmitted(false);
          }
          if (responseData.MembershipStatus.Description !== "Submitted") {
            setIsConvertRecord(false);
          }
          if (responseData.MembershipStatus.Description === "Withdrawn") {
            setIsShowWithdrawn(false);
          }
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      const fetchAlternetMemberDetails = async () => {
        try {
          const responseData = await fetchData(
            "UserProfile",
            alternetMemberUserProfileId
          );
          setAlternetMemberName(
            responseData.Firstname + " " + responseData.Lastname
          );
        } catch (error) {}
      };
      fetchDataMemberRequest();
      fetchAlternetMemberDetails();
    }
  }, [createCommitteeData, membershipRequestId, alternetMemberUserProfileId]);

  const menuBallot = useRef<Menu>(null);
  const toggleBallotMenu = (event: any) => {
    if (menuBallot.current) {
      menuBallot.current.toggle(event);
    }
  };

  const handleRejectCondition = async () => {
    setVisibleReject(true);
  };

  const handleWithdrawCondition = async () => {
    setVisibleWithdraw(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        {loading && <LoadingOverlay visible={loading} />}
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {memberShipData?.IsResignRequest
              ? t("member.resignationRequest")
              : t("member.membershipRequest")}
            : {memberShipData?.MembershipRequestNumber}
            &nbsp;
            {memberShipData?.MembershipStatus?.StatusName ===
            "Convert to Record" ? (
              <Badge
                value="Converted to record"
                className="h-auto text-sm active"
              />
            ) : memberShipData?.MembershipStatus?.StatusName === "Rejected" ? (
              <Badge value="Rejected" className="h-auto text-sm rejected" />
            ) : memberShipData?.MembershipStatus?.StatusName === "Draft" ? (
              <Badge value="Draft" className="h-auto text-sm draft" />
            ) : memberShipData?.MembershipStatus?.StatusName === "Submitted" ? (
              <Badge value="Submitted" className="h-auto text-sm open" />
            ) : memberShipData?.MembershipStatus?.StatusName === "Withdrawn" ? (
              <Badge value="Withdrawn" className="h-auto text-sm disbanded" />
            ) : null}
          </h1>
        </div>
        {requestBelongToStaff && (
          <div className="flex align-items-center py-3 gap-3 justify-content-end w-full">
            <div className="flex flex-row align-items-center gap-2 left-auto">
              {userRole !== "Member" &&
                isConvertToRecord &&
                memberShipData?.isRecordCreated === false && (
                  <Button
                    label={t("member.convertRecord")}
                    className="button-sm"
                    onClick={converToRecord}
                  />
                )}
              {!isStatusSubmitted && (
                <div>
                  <Button
                    className="button-md gap-1"
                    severity="secondary"
                    onClick={toggleBallotMenu}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        toggleBallotMenu(e);
                      }
                    }}
                  >
                    <span className="font-bold">{t("member.action")}</span>
                    <FeatherIcon
                      name="chevron-down"
                      size={20}
                      color="inherit"
                    />
                  </Button>
                  <Menu
                    model={showActionItem}
                    popup
                    ref={menuBallot}
                    id="profileMenu"
                    popupAlignment="right"
                    role="listbox"
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("member.membershipDetail")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("member.staffSecretary")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {memberShipData?.Committee?.Staff?.Firstname}{" "}
                  {memberShipData?.Committee?.Staff?.LastName}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("member.committeePosition")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {memberShipData?.MembershipPosition?.PositionName}
                </p>
              </div>
              {memberShipData?.MembershipPosition?.PositionName ===
                "Alternate Member" && (
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.alternetMemberFor")}
                  </span>
                  {alternetMemberName && (
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {alternetMemberName}
                    </p>
                  )}
                </div>
              )}
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("member.categoryOfInterest")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {memberShipData?.CategoryOfInterest?.CategoryName}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                  {t("member.remark")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {memberShipData?.Remark}
                </p>
              </div>
              {memberShipData?.IsResignRequest && (
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-base w-full md:w-18rem flex-shrink-0 text-label capitalize">
                    {t("member.reasonforResignation")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {memberShipData?.MembershipRequestResign?.Comments}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("member.professionalDetails")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <h3 className="m-0 font-bold text-base">
                {t("member.licenseDetails")}
              </h3>
              <div className="flex flex-column gap-2">
                <p className="font-normal text-base text-title m-0">
                  NHCP672287, 2019
                </p>
                <p className="m-0 font-normal">Ryan.vetrovs12@gmail.com</p>
                <p className="m-0 font-normal">South Dakota, USA</p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("member.documents")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-3">
                <h3 className="m-0 text-base">
                  {t("member.requestDoumentsLabel")}
                </h3>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  <RequestListDocument
                    documentType="RequestDocument"
                    membershipRequestID={membershipRequestId}
                    showNoDataMessage={true}
                  />
                </p>
              </div>
              {memberShipData?.MembershipPosition?.PositionName ===
                "Alternate Member" && (
                <div className="flex flex-column gap-3">
                  <h3 className="m-0 font-bold text-base">
                    {t("member.requestEvidenceDoumentsLabel")}
                  </h3>
                  <p className="m-0 flex align-items-center gap-2">
                    <RequestListDocument
                      documentType="EvidenceforAlternateMembership"
                      membershipRequestID={membershipRequestId}
                      showNoDataMessage={true}
                    />
                  </p>
                </div>
              )}
              {isValidMembershipRecordId(membershipRecordId) && (
                <div className="flex flex-column gap-3">
                  <h3 className="m-0 text-base">
                    {t("member.recordDoumentsLabel")}
                  </h3>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    <RecordListDocument
                      documentType="RecordDocument"
                      membershipRecordID={membershipRecordId}
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {!memberShipData?.IsResignRequest && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("member.termsConditions")}
              </h2>
            </div>
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="border-1 border-gray-200 p-4 p-shadow-4">
                  <div className="section">
                    <h3>1. Acceptance of Terms</h3>
                    <p>
                      By accessing or using the Site or Services, you agree to
                      be bound by these Terms and our Privacy Policy. If you do
                      not agree to these Terms or the Privacy Policy, you may
                      not access or use the Site or Services.
                    </p>
                  </div>

                  <div className="section">
                    <h3>2. Use of the Site and Services</h3>
                    <div className="list">
                      <p>
                        a. License: Subject to these Terms, [Your Company Name]
                        grants you a non-transferable, non-exclusive, revocable
                        license to use the Site and Services.
                      </p>
                      <p>b. Restrictions:</p>
                      <ul>
                        <li>
                          Use the Site or Services for any illegal purpose or in
                          violation of any local, state, national, or
                          international law;
                        </li>
                        <li>
                          Modify, adapt, translate, or reverse engineer any
                          portion of the Site or Services;
                        </li>
                        <li>
                          Attempt to gain unauthorized access to the Site or
                          Services or to any accounts or computer systems
                          connected to the Site or Services;
                        </li>
                        <li>
                          Interfere with the operation of the Site or Services
                          or any user’s enjoyment of them, including by
                          uploading or otherwise disseminating viruses, adware,
                          spyware, worms, or other malicious code;
                        </li>
                        <li>
                          Use any robot, spider, scraper, or other automated
                          means to access the Site or Services for any purpose
                          without our express written permission.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="section">
                    <h3>3. Intellectual Property</h3>
                    <div className="list">
                      <p>
                        a. Ownership: The Site and Services are owned and
                        operated by [Your Company Name]. The visual interfaces,
                        graphics, design, compilation, information, data,
                        computer code (including source code or object code),
                        products, software, services, and all other elements of
                        the Site and Services provided by [Your Company Name]
                        are protected by intellectual property and other laws.
                      </p>
                      <p>
                        b. Trademarks: [Your Company Name]’s trademarks, service
                        marks, and logos used and displayed on the Site and
                        Services are registered and unregistered trademarks or
                        service marks of [Your Company Name]. Other company,
                        product, and service names located on the Site or
                        Services may be trademarks or service marks owned by
                        others (the "Third-Party Trademarks," and, collectively
                        with [Your Company Name]’s trademarks, the
                        "Trademarks"). Nothing on the Site or Services should be
                        construed as granting, by implication, estoppel, or
                        otherwise, any license or right to use the Trademarks,
                        without our prior written permission specific for each
                        such use.
                      </p>
                    </div>
                  </div>

                  <div className="section">
                    <h3>4. Privacy</h3>
                    <p>
                      Please review our Privacy Policy, which explains how we
                      collect, use, and disclose information about you. By using
                      the Site or Services, you consent to the collection, use,
                      and disclosure of your information as described in our
                      Privacy Policy.
                    </p>
                  </div>

                  <div className="section">
                    <h3>5. Disclaimer of Warranties</h3>
                    <p>
                      THE SITE AND SERVICES ARE PROVIDED "AS IS" AND WITHOUT
                      WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE
                      FULLEST EXTENT PERMITTED BY APPLICABLE LAW, [YOUR COMPANY
                      NAME] DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                      NON-INFRINGEMENT.
                    </p>
                  </div>

                  <div className="section">
                    <h3>6. Limitation of Liability</h3>
                    <p>
                      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                      EVENT SHALL [YOUR COMPANY NAME] BE LIABLE FOR ANY
                      INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE
                      DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
                      INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
                      GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF YOUR
                      ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SITE
                      OR SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                      (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF
                      [YOUR COMPANY NAME] HAS BEEN ADVISED OF THE POSSIBILITY OF
                      SUCH DAMAGES.
                    </p>
                  </div>

                  <div className="section">
                    <h3>7. Governing Law and Dispute Resolution</h3>
                    <p>
                      These Terms are governed by and construed in accordance
                      with the laws of [Your Jurisdiction], without regard to
                      its conflict of law principles. Any dispute arising out of
                      or relating to these Terms shall be exclusively resolved
                      by the state and federal courts located in [Your
                      Jurisdiction].
                    </p>
                  </div>

                  <div className="section">
                    <h3>8. Changes to Terms</h3>
                    <p>
                      We may modify these Terms at any time. If we make changes
                      to these Terms, we will post the revised Terms on the Site
                      and update the "Last Updated" date at the top of these
                      Terms. By continuing to access or use the Site or Services
                      after we have posted revised Terms, you agree to be bound
                      by the revised Terms.
                    </p>
                  </div>

                  <div className="section">
                    <h3>9. Contact Us</h3>
                    <p>
                      If you have any questions about these Terms, please
                      contact us at [Your Contact Information].
                    </p>
                  </div>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="termsconditions"
                    onChange={handleCheckboxChange}
                    checked={checked}
                    className="mr-2"
                    disabled
                  />
                  <label
                    htmlFor="termsconditions"
                    className="text-base font-normal"
                  >
                    {t("member.iAgreeToTermsAndConditions")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="card bg-white w-full mb-5 shadow-md">
          <div className="px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("member.confidentialityAgreement")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <p className="m-0 font-normal text-base">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="m-0 font-normal text-base">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt{" "}
              </p>
              <div className="flex align-items-center">
                <Checkbox
                  inputId="confidentiality"
                  onChange={handleCheckboxChange}
                  checked={checked}
                  className="mr-2"
                  disabled
                />
                <label
                  htmlFor="confidentiality"
                  className="text-base font-normal"
                >
                  {t("member.iAgreeToConfidentialityAgreement")}
                </label>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                Audit History 
              </h2>
              <div className="flex text-sm"></div>
            </div>
            <div>
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-2">
                  <Table
                    products={auditHistoryList}
                    columns={[
                      {
                        field: "name",
                        header: t("profile.committees"),
                      },
                      {
                        field: "positionName",
                        header: t("profile.position"),
                      },
                      {
                        field: "categoryName",
                        header: t("profile.categoryOfInterest"),
                      },
                      {
                        field: "numberOfBallotMissed",
                        header: t("profile.ballotAttended"),
                      },
                      {
                        field: "numberOfMeetingMissed",
                        header: t("profile.meetingAttended"),
                      },
                      {
                        field: "startDate",
                        header: t("profile.startDate"),
                      },
                      {
                        field: "auditDate",
                        header: "Audit Date",
                      },
                      {
                        field: "action",
                        header: t("profile.action"),
                      },
                    ]}
                    showCheckbox={false}
                  />
                </div>
         

                

              </div>
            </div>
        </div> */}

        {/* <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button label={t('member.cancel')} className="button-md" severity="secondary" />
          <div className="flex md:flex-row gap-3">
            <Button
              text
              className="p-button-plain gap-1 flex align-self-center"
              label={t('member.saveasdraft')}
            ></Button>
            <Button
              label={t('member.submit')}
              className="button-md"
              onClick={() => setVisible(true)}
            />
          </div>
        </div> */}
      </div>

      <Dialog
        id="visibleRejectId"
        header={t("member.membershipRequestReject")}
        visible={visibleReject}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => setVisibleReject(false)}
        footer={footerContentReject}
        draggable={false}
        className="custom-dialog hide-close"
        ariaCloseIconLabel="Close"
      >
        <div className="flex flex-column gap-2 w-full">
          <label
            htmlFor="requestReject"
            className="block font-light text-input-label capitalize"
          >
            {t("member.reasonForRejection")}
            <span className="text-red">*</span>
          </label>
          <InputTextarea
            id="requestReject"
            value={rejectValue}
            onChange={(e) => setRejectValue(e.target.value)}
            rows={5}
            cols={30}
            autoFocus
          />
          <span
            className={`p-error font-bold text-capitalize ${remarkError ? "" : "error-hidden"}`}
          >
            {remarkError}
          </span>
        </div>
      </Dialog>
      <Dialog
        id="visibleWithdrawId"
        header={t("member.membershipRequestWithdrawl")}
        visible={visibleWithdraw}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => setVisibleWithdraw(false)}
        footer={footerContentWithdraw}
        draggable={false}
        className="custom-dialog hide-close"
        ariaCloseIconLabel="Close"
      >
        <div className="flex flex-column gap-2 w-full">
          <label
            htmlFor="requestWithdraw"
            className="block font-light text-input-label capitalize"
          >
            {" "}
            {t("member.reasonForWithdrawl")}
          </label>
          <InputTextarea
            id="requestWithdraw"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={5}
            cols={30}
          />
          <span
            className={`p-error font-bold text-capitalize ${withdrawnremarkError ? "" : "error-hidden"}`}
          >
            {withdrawnremarkError}
          </span>
        </div>
      </Dialog>
    </>
  );
};

export default ViewRequest;
