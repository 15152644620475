import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { useLanguageContext } from "../LanguageContext";
import FeatherIcon from "../common/FeatherIconComponent";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { fetchData, getListByParentId } from "../../services/apiService";
import { RootState } from "../../store/store";
import { addCommitteeInRecordRequest } from "../../slices/createRecordSlice";
import  ConfirmDialogComponent  from "../common/ConfirmDialogComponent";

interface Option {
  name: string;
  code: string;
}
interface OutletWrapperProps {
  selectedLanguage: string;
}
const RecordSubCommittee: React.FC = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const createCommitteeData = useSelector(
    (state: RootState) => state.createRecord
  );
  const [selectedCommitteeIndex, setselectedCommitteeIndex] = useState<
    number | null
  >(null);
  const [breadcumbPageItems, setbreadcumbPageItems] = useState<any>([]);
  const [parentCommittee, setParentCommittee] = useState<any>(null);
  const [parentCommitteeId, setparentCommitteeId] = useState("");
  const [selectedCommitteeId, setSelectedCommitteeId] = useState("");
  const [parentCommitteeName, setparentCommitteeName] = useState("");
  const [subCommittee, setSubCommittee] = useState([]);
  const [t, i18n] = useTranslation("record");
  const navigate = useNavigate();
  const { selectedLanguage } = useLanguageContext();
  const dispatch = useDispatch();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" }, 
    { label: t("record.record"), url: "/record" },
    { label: t("record.createNewRecord") },
  ];

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
      setSelectedCommitteeId(parentCommittee.Id);
      sessionStorage.setItem("committeeId", parentCommittee.Id);
      //dispatch(createMemberRequest({ committeeId: parentCommittee.Id}));
      dispatch(
        addCommitteeInRecordRequest({ committeeId: selectedCommitteeId })
      );
    }
  };

  React.useEffect(() => {
    const fetchCommitteeDetailById = async () => {
      try {
        const responseData = await fetchData(
          "Committee",
          createCommitteeData.addCommitteeInRecordRequest.committeeId
        );
        setParentCommittee(responseData);
        setparentCommitteeId(responseData.ParentCommitteeId);
      } catch (error) {
        console.error("Error fetching committees:", error);
        //setLoading(false);
      }
    };
    fetchCommitteeDetailById();
  }, []);

  React.useEffect(() => {
    const fetchSubCommittee = async () => {
      try {
        const responseData = await getListByParentId(
          "Committee",
          parentCommittee.Id
        );

        setSubCommittee(responseData.Collection);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    fetchSubCommittee();
  }, [parentCommitteeId]);

  const selectCommittee = (committee: any, index: number) => {
    setSelectedCommitteeId(committee.Id);
    setselectedCommitteeIndex(index);
    sessionStorage.setItem("committeeId", committee.Id);
    dispatch(addCommitteeInRecordRequest({ committeeId: committee.Id }));
  };

  const breadcumbPage = {
    label: t("record.allCommittees"),
    url: "/",
  };

  React.useEffect(() => {
    if (parentCommittee) {
      setbreadcumbPageItems([
        breadcumbPage,
        {
          label: parentCommittee?.ParentCommitteeName,
          url: "/record/committee",
          onClick: handleBreadcrumbParentClick,
        },
        { label: parentCommittee?.Name, url: "" },
      ]);
    }
  }, [parentCommittee]);

  const handleBreadcrumbParentClick = () => {
    sessionStorage.setItem("committeeId", parentCommittee.ParentCommitteeId);
    dispatch(
      addCommitteeInRecordRequest({
        committeeId: parentCommittee.ParentCommitteeId,
      })
    );
  };

  const viewDetails = async (subCommittee: any) => {
    sessionStorage.setItem("committeeId", subCommittee.Id);
    dispatch(addCommitteeInRecordRequest({ committeeId: subCommittee.Id }));
    try {
      const responseData = await fetchData("Committee", subCommittee.Id);
      setParentCommittee(responseData);
      setparentCommitteeId(responseData.ParentCommitteeId);
    } catch (error) {
      console.error("Error fetching committee details:", error);
    }

    // Call getSubCommitte
    try {
      const responseData = await getListByParentId(
        "Committee",
        subCommittee.Id
      );
      setSubCommittee(responseData.Collection);
    } catch (error: any) {
      if (error) {
        if (error?.response && error?.response?.status === 404) {
          // Handle 404 error by setting subCommittee array to empty
          setSubCommittee([]);
        } else {
          console.error("Error fetching sub-committee details:", error);
        }
      }
    }
  };

  const onhandleContinue = () => {
    if (editMode) {
      dispatch(
        addCommitteeInRecordRequest({ committeeId: selectedCommitteeId })
      );
      sessionStorage.setItem(
        "committeeId",
        JSON.stringify({ committeeId: selectedCommitteeId })
      );
    }
    navigate(`/record/details`);
  };

  const [visible, setVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setVisible(true); 
  };

  const handleConfirm = () => {
    let navigateFrom = sessionStorage.getItem("redirectionPath");
    if (navigateFrom === "record") {
      let committeeId = sessionStorage.getItem("committeeId");
      navigate(`/committee/details/${committeeId}`);
    } else {
      navigate("/member");
    }
  }

  const handleCancelDialog = () => {
    setVisible(false);
  }

  return (
    <>
      <div className="">
      <ConfirmDialogComponent
                visible={visible}
                onHide={() => setVisible(false)}
                message="Are you sure you want to exit?"
                header="Confirmation"
                onConfirm={() => handleConfirm()}
                onCancel={() => handleCancelDialog()}
            />
        <div>
          <CustomBreadcrumb
            model={breadcumbItems}
            className="bg-transparent mb-5 px-0 py-2 border-none"
            handleBreadcrumbParentClick={handleBreadcrumbParentClick}
          />
        </div>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("record.createNewRecords")}
          </h1>
          {/* <p className="text-sm font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>

        {/* <MemberRequestSteps /> */}

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-4">
                <div className="custom-breadcrumb-page">
                  <CustomBreadcrumb
                    model={breadcumbPageItems}
                    className="bg-transparent px-0 py-2 border-none"
                    separator={"/"}
                  />
                </div>
                <div className="flex flex-column gap-3">
                  <h2 className="text-title text-lg font-bold m-0">
                    {parentCommittee?.Name}
                  </h2>
                  <p className="m-0 text-base font-normal">
                    {/* Lorem ipsum dolor sit amet consectetur. Etiam faucibus
                    tristique phasellus hendrerit. Arcu tellus penatibus porta
                    bibendum. Duis dolor suspendisse risus eu. Nibh turpis nunc
                    purus pharetra aliquam nascetur ... */}
                  </p>
                </div>
              </div>

              <div className="flex align-items-center">
                <div className="px-5 py-4 bg-brand-secondary">
                  <Checkbox
                    inputId="termsconditions"
                    onChange={handleCheckboxChange}
                    checked={checked}
                    className="mr-2"
                  />
                  <label
                    htmlFor="termsconditions"
                    className="text-base font-bold"
                  >
                    {t("record.checkCommitteeMembership")}
                  </label>
                </div>
              </div>

              <div className="flex flex-column gap-3">
                <h2 className="m-0 text-lg font-bold">{t("record.subCommittees")}</h2>
                <ul className="list-none p-0 m-0 shadow-md">
                  {subCommittee.length === 0 ? (
                    <li className="py-3 text-center">
                      {t("record.noSubcommitteesAvailable")}
                    </li>
                  ) : (
                    subCommittee.map((subCommittee: any, index: number) => (
                      <li
                        key={index}
                        className={`flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 shadow-hover ${selectedCommitteeIndex === index ? "selected" : ""} ${subCommittee.Id === selectedCommitteeId ? "selected" : ""}`}
                        onClick={() => selectCommittee(subCommittee, index)}
                      >
                        <div className="flex flex-column gap-2">
                          <h3 className="m-0 text-base font-bold">
                            {subCommittee.Name}
                          </h3>
                          <p className="m-0 text-base font-normal">
                            {subCommittee.CommitteeType.TypeName}
                          </p>
                        </div>
                        <Button
                          text
                          className="p-button-plain gap-1 underline"
                          onClick={() => viewDetails(subCommittee)}
                        >
                          <span className="font-bold hidden md:flex">
                            {t("record.viewDetails")}
                          </span>
                          <FeatherIcon name="chevron-right" size={20} />
                        </Button>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("record.cancel")}
            className="button-md w-full sm:w-auto"
            severity="secondary"
            onClick={handleCancel}
          />
          {/* <Button
            label={t("record.cancel")}
            className="button-md"
            severity="secondary"
          /> */}
          <Button
            label={t("record.confirmCommitteeContinue")}
            className="button-md w-full sm:w-auto"
            onClick={onhandleContinue}
            disabled={!selectedCommitteeId}
          />
        </div>

        {/* Mobile responsive buttions */}
        <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("record.confirmCommitteeContinue")}
            className="button-md w-full"
            onClick={onhandleContinue}
            disabled={!selectedCommitteeId}
          />
          <Button
            label={t("record.cancel")}
            className="button-md w-full"
            severity="secondary"
            onClick={handleCancel}
          />
          {/* <Button
            label={t("record.cancel")}
            className="button-md"
            severity="secondary"
          /> */}
        </div>
      </div>
    </>
  );
};

export default RecordSubCommittee;
