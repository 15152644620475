  import React, { useState } from 'react';
  import { Dialog } from 'primereact/dialog';
  import { InputTextarea } from 'primereact/inputtextarea';
  import { putData } from '../../../../services/Ballot/apiservice';
  import { Button } from 'primereact/button';

  interface RemarkDialogProps {
    id?: string;
    header?: string;
    visible?: boolean;
    onHide?: any;
    onSave?: (remark: string) => void;
    footer?: React.ReactNode;
    validationError?: string;
    voteEdit: any;
    ballotRecordVoteId: any;
  }

  const RemarkDialog: React.FC<RemarkDialogProps> = ({ visible, onHide, voteEdit, ballotRecordVoteId }) => {
    const [remark, setRemark] = useState<string>('');
    const [validationError, setValidationError] = useState<string>('');

    const handleSaveRemark = async (remark: string, voteEdit: any, ballotRecordVoteId: string) => {
      setRemark(remark);
      voteEdit.remark = remark;
      try {
        const responseData = await putData(`BallotRecordVote`, `${ballotRecordVoteId}`, voteEdit);
        setRemark(''); 
        onHide();
      } catch (err) {
        console.error('Error saving remark:', err);
      }
    };
    
    const handleSave = () => {
      if (remark.trim()) {
        handleSaveRemark(remark, voteEdit, ballotRecordVoteId); // Pass the data to the save handler
        setValidationError(''); // Clear any previous validation errors
      } else {
        setValidationError('Remark cannot be empty.'); // Set validation error if input is empty
      }
    };

    return (
      <Dialog
      header={"Remark"}
      visible={visible}
      style={{ width: '50vw' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw', '400px': '98vw' }}
      onHide={onHide}
      footer={
        <div>
          <Button label="Save" onClick={handleSave} />
          <Button label="Cancel" onClick={onHide} />
        </div>
      }
      className="custom-dialog hide-close"
      ariaCloseIconLabel="Close"
    >
      <div className="flex flex-column gap-2 w-full">
        <label htmlFor="remarkInput" className={`block font-light font-bold text-capitalize ${validationError ? 'p-error' : ''}`}>
          Remark
        </label>
        <InputTextarea
          id="remarkInput"
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          rows={5}
          cols={30}
          className={validationError ? 'p-invalid' : ''}
        />
        {validationError && (
          <span className="p-error font-bold text-capitalize">
            {validationError}
          </span>
        )}
      </div>
    </Dialog>
    );
  };

  export default RemarkDialog;
