import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { useLanguageContext } from '../../LanguageContext';
import FeatherIcon from "../../common/FeatherIconComponent";

const getIdByName = (items: { id: number; value: string }[], selectedNames: string[]): number[] => {
    return selectedNames.map(name => {
        const item = items.find(item => item.value === name);
        return item ? item.id : -1;
    }).filter(id => id !== -1);
};

const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};
const BallotRecordFilter = (props: any) => {
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    const tableRecordDetails: any = useSelector((state: any) => state.ballotSelectRecords.recordTableFilterData);
    const { onFilterData } = props;
    const { ballotDetailsInfo } = useSelector((state: any) => state.ballotDetails);
    let ballotId = ballotDetailsInfo.id;
    const [tableOptionsData, setTableOptionsData] = useState<any>({
        recordId: [],
        applicatName: [],
        committeeName: [],
        positionName: [],
        InterestName: []
    });
    const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const toggleFilterPanel = (event: any) => {
        setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
        if (overlayPanelRef.current) {
            overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
        }
    };
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            recordId: [],
            applicantId: [],
            committeeId: [],
            positionId: [],
            interestId: [],
            dateActivationFrom: null,
            dateActivationTo: null,
            recordIdFilter: false,
            applicantIdFilter: false,
            committeeIdFilter: false,
            positionIdFilter: false,
            interestIdFilter: false,
            requestDateFilter: false
        }
    });
    const onSubmit = (data: any) => {
        const recordIds = getIdByName(tableOptionsData.recordId, data.recordId);
        const applicantIds = getIdByName(tableOptionsData.applicatName, data.applicantId);
        const committeeIds = getIdByName(tableOptionsData.committeeName, data.committeeId);
        const positionIds = getIdByName(tableOptionsData.positionName, data.positionId);
        const interestIds = getIdByName(tableOptionsData.InterestName, data.interestId)
        const formData = {
            ...data,
            recordData: recordIds,
            applicantData: applicantIds,
            committeeData: committeeIds,
            positionData: positionIds,
            interestData: interestIds,
        };
        const fromDateStr = formatDate(formData.dateActivationFrom);
        const toDateStr = formatDate(formData.dateActivationTo);
        const filters = [
            {
                Key: 'ballotid',
                Value: ballotId
            },
            {
                Key: 'committeeId',
                Value: tableRecordDetails[0].committeeAppliedId
            }
        ];
        if (data.recordIdFilter && data.recordId.length >= 1) filters.push({ Key: 'recordid', Value: formData.recordData });
        if (data.applicantIdFilter && data.applicantId.length >= 1) filters.push({ Key: 'userprofileid', Value: formData.applicantData });
        if (data.committeeIdFilter && data.committeeId.length >= 1) filters.push({ Key: 'committeeid', Value: formData.committeeData })
        if (data.positionIdFilter && data.positionId.length >= 1) filters.push({ Key: 'positionid', Value: formData.positionData })
        if (data.interestIdFilter && data.interestId.length >= 1) filters.push({ Key: 'categoryofinterestid', Value: formData.interestData })
        if (data.requestDateFilter && data.dateActivationFrom && data.dateActivationTo) filters.push({ Key: "requestedon", Value: `${fromDateStr},${toDateStr}` })

        const queryString = filters
            .map((filter, index) => {
                const value = Array.isArray(filter.Value)
                    ? filter.Value.map((val: string) => val).join(',')
                    : encodeURIComponent(filter.Value);
                return `Filters[${index}].Key=${filter.Key}&Filters[${index}].Value=${value}`
            })
            .join('&');
        onFilterData(queryString, true)
        if (overlayPanelRef.current && filters.length > 0) {
            overlayPanelRef.current.hide();
        }
    };
    const clearFilters = () => {
        onFilterData();
        reset();
    };
    const filterOptionValueHandler = () => {
        const removeDuplicates = (array: any[], key: string) => {
            const seen = new Set();
            return array.filter(item => {
                const uniqueValue = item[key];
                if (seen.has(uniqueValue)) {
                    return false;
                } else {
                    seen.add(uniqueValue);
                    return true;
                }
            });
        };

        const recordOptions = tableRecordDetails?.map((record: any) => ({ id: record?.id, label: record?.recordNumber, value: record?.recordNumber }));

        const applicantOptions = removeDuplicates(tableRecordDetails?.map((record: any) => ({
            id: record?.applicantId,
            label: record?.applicantName,
            value: record?.applicantName
        })), 'id');

        const committeeOptions = removeDuplicates(tableRecordDetails?.map((record: any) => ({
            id: record?.committeeAppliedId,
            label: record.committeeAppliedFor,
            value: record?.committeeAppliedFor
        })), 'id');

        const positionsOptions = removeDuplicates(tableRecordDetails?.map((record: any) => ({
            id: record?.positionId,
            label: record?.positionAppliedFor,
            value: record?.positionAppliedFor
        })), 'id');

        const interestOptions = removeDuplicates(tableRecordDetails?.map((record: any) => ({
            id: record?.categoryOfInterestId,
            label: record?.categoryOfInterest,
            value: record?.categoryOfInterest
        })), 'id');

        setTableOptionsData({
            recordId: recordOptions,
            applicatName: applicantOptions,
            committeeName: committeeOptions,
            positionName: positionsOptions,
            InterestName: interestOptions
        });
    };

    useEffect(() => {
        filterOptionValueHandler()
    }, [tableRecordDetails]);
    return (<>
        <Button
            className="button-md gap-1"
            severity="secondary"
            onClick={toggleFilterPanel}
            aria-label={t("ballot.filter")}
        >
            <FeatherIcon name="filter" size={20} color="inherit" />
            <span className="font-bold text-capitalize hidden md:flex">
                {t("ballot.filter")}
            </span>
        </Button>
        <OverlayPanel
            ref={overlayPanelRef}
            onHide={() => setShowFilterPanel(false)}
            appendTo={"self"}
            dismissable
            className="p-overlaypanel-filter left-auto md:right-0 top-100"
            aria-labelledby="filter-heading"
            role="dialog"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-column gap-1" tabIndex={0}>
                    <h3 id="filter-heading" className="text-lg font-normal m-0 p-3">
                        {t("ballot.filter")}
                    </h3>
                    <div className="filter-wrap">
                        <div className="">
                            <Controller
                                name="recordIdFilter"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <label
                                        htmlFor="recordIdFilter"
                                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                                    >
                                        <Checkbox
                                            inputId="recordIdFilter"
                                            {...field}
                                            checked={field.value}
                                            className="mr-2"
                                            tabIndex={0}
                                        />
                                        {t("ballot.recordId")}
                                    </label>
                                )}
                            />
                            <div className="pr-3 py-2 pl-6 w-full relative">
                                <Controller
                                    name="recordId"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <MultiSelect
                                            appendTo="self"
                                            {...field}
                                            options={tableOptionsData?.recordId}
                                            optionLabel="value"
                                            filter
                                            placeholder={t("ballot.recordId")}
                                            className="w-full"
                                            aria-label={t("ballot.recordId")}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-0">
                            <Controller
                                name="applicantIdFilter"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <label
                                        htmlFor="applicantIdFilter"
                                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                                    >
                                        <Checkbox
                                            inputId="applicantIdFilter"
                                            {...field}
                                            checked={field.value}
                                            className="mr-2"
                                            tabIndex={0}
                                        />
                                        {t("ballot.applicantName")}
                                    </label>
                                )}
                            />
                            <div className="pr-3 py-2 pl-6 w-full relative">
                                <Controller
                                    name="applicantId"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <MultiSelect
                                            appendTo="self"
                                            {...field}
                                            options={tableOptionsData.applicatName}
                                            optionLabel="value"
                                            filter
                                            placeholder={t("ballot.applicantName")}
                                            maxSelectedLabels={3}
                                            className="w-full"
                                            aria-label={t("ballot.applicantName")}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-0">
                            <Controller
                                name="positionIdFilter"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <label
                                        htmlFor="positionAppliedFor"
                                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                                    >
                                        <Checkbox
                                            inputId="positionAppliedFor"
                                            {...field}
                                            checked={field.value}
                                            className="mr-2"
                                            tabIndex={0}
                                        />
                                        {t("ballot.positionAppliedFor")}
                                    </label>
                                )}
                            />
                            <div className="pr-3 py-2 pl-6 w-full relative">
                                <Controller
                                    name="positionId"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <MultiSelect
                                            appendTo="self"
                                            {...field}
                                            options={tableOptionsData?.positionName}
                                            optionLabel="value"
                                            filter
                                            placeholder={t("ballot.positionAppliedFor")}
                                            maxSelectedLabels={3}
                                            className="w-full"
                                            aria-label={t("ballot.positionAppliedFor")}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-0">
                            <Controller
                                name="interestIdFilter"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <label
                                        htmlFor="categoryOfInterest"
                                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                                    >
                                        <Checkbox
                                            inputId="categoryOfInterest"
                                            {...field}
                                            checked={field.value}
                                            className="mr-2"
                                            tabIndex={0}
                                        />
                                        {t("ballot.categoryOfInterest")}
                                    </label>
                                )}
                            />
                            <div className="pr-3 py-2 pl-6 w-full relative">
                                <Controller
                                    name="interestId"
                                    control={control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <MultiSelect
                                            appendTo="self"
                                            {...field}
                                            options={tableOptionsData?.InterestName}
                                            optionLabel="value"
                                            filter
                                            placeholder={t("ballot.categoryOfInterest")}
                                            maxSelectedLabels={3}
                                            className="w-full"
                                            aria-label={t("ballot.categoryOfInterest")}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="p-0">
                            <Controller
                                name="requestDateFilter"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <label
                                        htmlFor="requestDateFilter"
                                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                                    >
                                        <Checkbox
                                            inputId="requestDateFilter"
                                            {...field}
                                            checked={field.value}
                                            className="mr-2"
                                            tabIndex={0}
                                        />
                                        {t("ballot.requestDate")}
                                    </label>
                                )}
                            />
                            <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                                <Controller
                                    name="dateActivationFrom"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <Calendar
                                            className="custom-datepicker"
                                            inputId="dateActivationFrom"
                                            {...field}
                                            value={field.value}
                                            dateFormat="mm/dd/yy"
                                            tabIndex={0}
                                            ariaLabel={t("ballot.fromDate")}
                                            placeholder={t("ballot.fromDate")}
                                        />
                                    )}
                                />
                                <Controller
                                    name="dateActivationTo"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                        <Calendar
                                            className="custom-datepicker"
                                            inputId="dateActivationTo"
                                            {...field}
                                            value={field.value}
                                            dateFormat="mm/dd/yy"
                                            tabIndex={0}
                                            ariaLabel={t("ballot.toDate")}
                                            placeholder={t("ballot.toDate")}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <Button
                        type="button"
                        className="button-md p-button-secondary justify-content-center"
                        onClick={clearFilters}
                    >
                        {t("ballot.clearFilter")}
                    </Button>
                    <Button type="submit" className="button-md w-full justify-content-center">
                        {t("ballot.applyFilter")}
                    </Button>
                </div>
            </form>
        </OverlayPanel>
    </>
    )
}

export default BallotRecordFilter