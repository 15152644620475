import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { MenuItem } from "primereact/menuitem";
import { DropdownChangeEvent } from "primereact/dropdown";
import { postData, fetchData, putData } from "../../../services/Ballot/apiservice";
import { setToast } from "../../../slices/toastSlice";
import Table from "../../common/Table";
import withLoader from "../../common/LoaderIntercepter";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import "../Configuration.scss";
import { BallotSubType } from "../ballotConfiguration/BallotConfigurationInterface";
import AdditionalConfigurationForm from "./AdditionalConfigurationForm";

const useLanguageSync = () => {
  const { selectedLanguage } = useLanguageContext();
  const [t, i18n] = useTranslation("configuration");
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  return t;
}

const AdditionConfigurationTab: React.FC = () => {
  const t = useLanguageSync();
  const [errors, setErrors] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [ballotSubTypes, setBallotSubTypes] = useState<BallotSubType[]>([]);
  const [isRecirculation, setIsRecirculation] = useState<boolean>(false);
  const [editConfigurationId, setEditConfigurationId] = useState<string>("");
  const [isBallotApprovalVisible, setIsBallotApprovalVisible] = useState(false);
  const [additionalBallotConfigurationListing, setadditionalBallotConfigurationListing] = useState<any []>([]);
  const [isCommitteeSelected, setIsCommitteeSelected] = useState(false);
  const [additionalConfigurationData, setAdditionalConfigurationData] = useState<any>(
    {
        active: true,
        isDirty: false,
        isNew: true,
        committeeId: null,
        accreditationBodyId: null,
        ballotLevelId: null,
        ballotTypeId: null,
        ballotSubTypeId: null,
        recordTypeId: null,
        recordSubTypeId: null,
        minBallotDuration: 0,
        voteOptionCategoryId: "",
        defaultVoteOptionValueId: "",
        ignoredVoteOptionValueIds: null,
        commentOptionCategoryId: "",
        durationForWithdrawDisapproval: null,
        thresholdPoints: null,
        allowUsersToAddRemoveRecords: false,
        allowUsersToAddRemoveCommittees: false,
        acOperator1: "",
        acPercentage1: "",
        acVoteOptionValueId1: "",
        acOperator2: null,
        acOperator3: null,
        acPercentage3: null,
        acVoteOptionValueId3: null,
        isConsensusApprovalApplied: false,
        consensusApprovalPercentage: null
    }
  );
  const handleBallotApprovalHideShow = () => {
    setIsEditMode(false);
    setEditConfigurationId("");
    resetForm();
    sessionStorage.removeItem('AdditionConfigurationId');
    setIsBallotApprovalVisible(!isBallotApprovalVisible);
  };

  const mapConfigurationDetails = useCallback((details: any) => ({
    id: details?.Id,
    active: details.Active,
    isDirty: !details.IsDirty ? true : false,
    isNew: details.IsDirty ? false : true,
    committeeId:  details.Committee?.Id ? details.Committee.Id : null,
    accreditationBodyId: details.AccreditationBody?.Id ? details.AccreditationBody.Id : null,
    ballotLevelId: details.BallotLevel.Id,
    ballotTypeId: details.BallotType.Id,
    ballotSubTypeId: details.BallotSubType.Id,
    recordTypeId: details.RecordType.Id,
    recordSubTypeId: details.RecordSubType.Id,
    minBallotDuration: details.MinBallotDuration,
    voteOptionCategoryId: details.VoteOptionCategory.Id,
    defaultVoteOptionValueId: details.DefaultVoteOptionValue.Id,
    ignoredVoteOptionValueIds: details.IgnoredVoteOptionValueIds 
    ? details.IgnoredVoteOptionValueIds.split(",").map((id: string) => id.trim()) 
    : [],
    commentOptionCategoryId: details.CommentOptionCategory.Id,
    durationForWithdrawDisapproval: details.DurationForWithdrawDisapproval,
    thresholdPoints: details.ThresholdPoints,
    allowUsersToAddRemoveRecords: details.AllowUsersToAddRemoveRecords,
    allowUsersToAddRemoveCommittees: details.AllowUsersToAddRemoveCommittees,
    acOperator1: details.ACOperator1,
    acPercentage1: details.ACPercentage1,
    acVoteOptionValueId1: details.ACVoteOptionValue1.Id,
    acOperator2: details.ACOperator2,
    acOperator3: details.ACOperator3,
    acPercentage3:details.ACPercentage3,
    acVoteOptionValueId3: details.ACVoteOptionValue3.Id,
    isConsensusApprovalApplied: details.isConsensusApprovalApplied,
    consensusApprovalPercentage: details.consensusApprovalPercentage ? details.consensusApprovalPercentage : null
  }), []);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchDataballotConfiguration();
    fetchBollotTypes();
  }, []);

  useEffect(() => {
    if (isEditMode && editConfigurationId) {
      fetchConfigurationById(editConfigurationId);
    }
  }, [isEditMode, editConfigurationId]);

  const actionItems: MenuItem[] = useMemo(() => [
    {
      label: t("configuration.viewEdit"),
      command: async () => {
        const formattedTableData = sessionStorage.getItem("AdditionConfigurationId");
        if (formattedTableData) {
          setIsBallotApprovalVisible(true);
          setEditConfigurationId(formattedTableData);
          setIsEditMode(true);
        } else {
          setIsEditMode(false);
        }
      },
    },
  ], [t]);

  const formatBallotConfigurationData = useMemo(() => (collection: any[]) =>
    collection.map((item: any) => ({
      ballotLevel: item.BallotLevel?.DisplayName,
      ballotType: item.BallotType?.DisplayName,
      ballotSubType: item.BallotSubType?.DisplayName,
      recordType: item.RecordType?.DisplayName,
      recordSubType: item.RecordSubType?.TypeDisplayName,
      minBallotDuration: item.MinBallotDuration,
      accreditationBody: item.AccreditationBody?.DisplayName,
      votingOption: item.DefaultVoteOptionValue?.DisplayName,
      commentOption: item.CommentOptionCategory?.DisplayName,
      sessionKey: "AdditionConfigurationId",
      Id: item.Id
    })),
    []
  );

  const fetchDataballotConfiguration = useCallback(async () => {
    try {
      const responseData = await fetchData("AdditionalBallotConfiguration/GetAll");
      const formattedConfigList = formatBallotConfigurationData(responseData.Collection);
      setadditionalBallotConfigurationListing(formattedConfigList);
    } catch (error) {
      console.error("Error fetching Ballot Configuration:", error);
    }
  }, []);

  const fetchBollotTypes = useCallback(async () => {
    try {
      const responseData = await fetchData("BallotSubType/GetAll");
      setBallotSubTypes(responseData.Collection);
    } catch (error) {
      console.error("Error fetching Ballot Configuration:", error);
    }
  }, []);

  const fetchConfigurationById = useCallback(async (ConfigurationId: string) => {
    try {
      const details = await fetchData(`AdditionalBallotConfiguration/${ConfigurationId}`);
      mapConfigurationDetails(details);
      const mappedDetails = mapConfigurationDetails(details);
      setAdditionalConfigurationData(mappedDetails);
    } catch (error) {
      console.error("Error fetching configuration by ID:", error);
    }
  }, [mapConfigurationDetails]);

  const handleRadioButtonChange = (e: any, fieldName: string) => {
    setIsCommitteeSelected(e.value === "Committee");
  };
  
  const handleDropdownField = (event: DropdownChangeEvent, field: keyof typeof additionalConfigurationData) => {
    let newValue = event.value;
    if (field === "ignoredVoteOptionValueIds" && Array.isArray(event.value)) {
      const commaSeparatedString = event.value.join(", ");
      newValue = commaSeparatedString;  // Keep it as an array
    }
  
    setAdditionalConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: newValue,
    }));
    const selectedSubType = ballotSubTypes.find((subType: any) => subType.Id === event.value);
    const isRecirculation = selectedSubType?.Name === "Recirculation";
    setIsRecirculation(isRecirculation);
  };

  const handleMultiSelect = (e: any) => {
    if (Array.isArray(e.value)) { 
      setAdditionalConfigurationData((prevState: any) => ({
        ...prevState,
        ignoredVoteOptionValueIds: e.value,
      }));
    }
  };

  const handleCheckboxButtonChange = useCallback((e: any, field: keyof typeof additionalConfigurationData) => {
    setAdditionalConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: e.checked,
    }));
  }, []);

  const handleAdditionalInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof typeof additionalConfigurationData) => {
    const value = e.target.value;
    setAdditionalConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const resetForm = () => {
    setAdditionalConfigurationData({
      active: true,
      isDirty: false,
      isNew: true,
      committeeId: null,
      accreditationBodyId: null,
      ballotLevelId: null,
      ballotTypeId: null,
      ballotSubTypeId: null,
      recordTypeId: null,
      recordSubTypeId: null,
      minBallotDuration: 0,
      voteOptionCategoryId: "",
      defaultVoteOptionValueId: "",
      ignoredVoteOptionValueIds: "",
      commentOptionCategoryId: "",
      durationForWithdrawDisapproval: null,
      thresholdPoints: null,
      allowUsersToAddRemoveRecords: true,
      allowUsersToAddRemoveCommittees: true,
      acOperator1: "",
      acPercentage1: "",
      acVoteOptionValueId1: "",
      acOperator2: null,
      acOperator3: null,
      acPercentage3: null,
      acVoteOptionValueId3: null,
      isConsensusApprovalApplied: false,
      consensusApprovalPercentage: null
    });
   };
 
   const validateBallotConfiguration = useCallback(() => {
    let isValid = true;
    let validationErrors: any = {};
  
    // Field Labels for Error Messages
    const fieldLabels: { [key: string]: string } = {
      ballotLevelId: "Ballot Level",
      ballotTypeId: "Ballot Type",
      ballotSubTypeId: "Ballot Sub-Type",
      recordTypeId: "Record Type",
      recordSubTypeId: "Record Sub-Type",
      voteOptionCategoryId:"Vote Option Category",
      defaultVoteOptionValueId: "Default Vote Option",
      ignoredVoteOptionValueIds: "Ignored Vote Option",
      commentOptionCategoryId: "Comment Option",
      acOperator1: "Approval criteria is required",
      acOperator2: "criteria",
    };
  
    // Check if required fields are present
    Object.keys(fieldLabels).forEach((field: any) => {
      if(field !== "acOperator1" && field !== "acOperator2") {
        if (!additionalConfigurationData[field]) {
          validationErrors[field] = `${fieldLabels[field]} is required.`;
          isValid = false;
        }
      }
      if (field === "acOperator1") {
        if(additionalConfigurationData.acOperator1 === null) {
          validationErrors[field] =
          "Please complete the approval criteria.";
          isValid = false;
        } else {
          if (!additionalConfigurationData.acPercentage1 || !additionalConfigurationData.acVoteOptionValueId1 ) {
            validationErrors[field] =
              "Please complete the approval criteria by filling in percentage and vote option.";
            isValid = false;
          }
        }
      }
      if (field === "acOperator2") {
        if(additionalConfigurationData.acOperator2 !== null) {
          if (!additionalConfigurationData.acOperator2 || !additionalConfigurationData.acOperator3 || !additionalConfigurationData.acPercentage3 || !additionalConfigurationData.acVoteOptionValueId3 ) {
            validationErrors[field] =
              "Please complete the approval criteria by filling one more in percentage and vote option.";
            isValid = false;
          }
        }
      }
    });
    setErrors(validationErrors);
    return isValid;
  }, [additionalConfigurationData]);

  const saveConfiguration = async () => {
    const isValid = validateBallotConfiguration();
    if (!isValid) {
      console.error("Validation failed. Please correct the errors.");
      return;
    }
    try {
      const existingConfigs = await fetchData("AdditionalBallotConfiguration/GetAll");
      if (existingConfigs?.Collection?.length > 0) {
        const duplicateConfig = existingConfigs.Collection.some((config: any) => {
          const isSameConfig =
                  config?.BallotLevel?.Id === additionalConfigurationData.ballotLevelId &&
                  config?.BallotType?.Id === additionalConfigurationData.ballotTypeId &&
                  config?.BallotSubType?.Id === additionalConfigurationData.ballotSubTypeId &&
                 config?.RecordType?.Id === additionalConfigurationData.recordTypeId &&
              config?.RecordSubType?.Id === additionalConfigurationData.recordSubTypeId;
          if (isEditMode) {
            return isSameConfig && config?.Id !== additionalConfigurationData?.id;
          }
          return isSameConfig;
        });
        if (duplicateConfig) {
          console.error("Duplicate configuration found. You cannot save the same type of configuration.");
          dispatch(setToast({
            message: t("configuration.duplicateConfigurationError"),
            severity: "error"
          }));
          return;
        }
      }
      const configurationDataToPost = {
        ...additionalConfigurationData,
        ignoredVoteOptionValueIds: additionalConfigurationData.ignoredVoteOptionValueIds?.join(", ")
      };
      if (isEditMode) {
        const response = await putData("AdditionalBallotConfiguration", editConfigurationId, configurationDataToPost);
        dispatch(setToast({
          message: t("configuration.configurationUpdatedSuccess"),
          severity: "success"
        }));
      } else {
        const response = await postData("AdditionalBallotConfiguration", configurationDataToPost);
        dispatch(setToast({
          message: t("configuration.configurationPostedSuccess"),
          severity: "success"
        }));
      }
      resetForm();
      handleBallotApprovalHideShow();
      fetchDataballotConfiguration();
    } catch (error: any) {
      console.error("Error saving configuration:", error);
      if (error.response) {
        console.error("Server responded with an error:", error.response);
      } else if (error.request) {
        console.error("Network error occurred:", error.request);
      }
      dispatch(setToast({
        message: t("configuration.configurationSaveError"),
        severity: "error"
      }));
    }
  };

  return (
    <>
      <div>
        {!isBallotApprovalVisible && (
          <div>
            <div className="flex justify-content-end py-3 gap-3 w-full">
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleBallotApprovalHideShow}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("configuration.addBallotAdditionalConfiguration")}
                </span>
              </Button>
            </div>
            <Table
              loading={false}
              products={additionalBallotConfigurationListing}
              columns={[
                {
                  field: "ballotLevel",
                  header: t("configuration.ballotLevel"),
                },
                {
                  field: "ballotType",
                  header: t("configuration.ballotType"),
                },
                {
                  field: "ballotSubType",
                  header: t("configuration.ballotSubType"),
                },
                {
                  field: "recordType",
                  header: t("configuration.recordType"),
                },
                {
                  field: "recordSubType",
                  header: t("configuration.recordSubType"),
                },
                {
                  field: "minBallotDuration",
                  header: t("configuration.minBallotDurationWeeks"),
                },
                {
                  field: "votingOption",
                  header: t("configuration.votingOption"),
                },
                {
                  field: "commentOption",
                  header: t("configuration.commentOption"),
                },
                { field: "action", header: t("configuration.action") },
              ]} 
              showCheckbox={false}
              actionItems={actionItems}
            />
          </div>
        )}
        {isBallotApprovalVisible && (
          <>
            <div className="card bg-white w-full mb-5 shadow-md">
                <AdditionalConfigurationForm handleBallotApprovalHideShow={handleBallotApprovalHideShow} additionalConfigurationData={additionalConfigurationData}  
                handleDropdownField={handleDropdownField}
                handleMultiSelect={handleMultiSelect}
                errors={errors}
                setAdditionalConfigurationData={setAdditionalConfigurationData}
                handleAdditionalInputChange={handleAdditionalInputChange}
                handleCheckboxButtonChange={handleCheckboxButtonChange} isShowFormField={true}
                handleRadioButtonChange={handleRadioButtonChange} isCommitteeSelected={isCommitteeSelected} isRecirculation={isRecirculation}
                isDefaultConfiguration={false} />
            </div>
            <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("configuration.cancel")}
                className="button-md"
                severity="secondary"
                type="button"
                onClick={handleBallotApprovalHideShow}
              />
               <Button
                  label={t("configuration.save")}
                  className="button-md"
                  type="button"
                  onClick={saveConfiguration}
                />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withLoader(AdditionConfigurationTab);