import { useState, useEffect, useCallback } from 'react';
import { fetchData } from '../services/Ballot/apiservice';

const useBallotAccess = (ballotId: string | undefined, userProfileId: string) => {
    const [accessData, setAccessData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    const getBallotAccess = useCallback(async () => {
        setLoading(true);
        try {
            const accessResponseData = await fetchData(`Ballot/GetUserBallotAccess?ballotId=${ballotId}&loggedInUserProfileId=${userProfileId}`);
            setAccessData(accessResponseData);
        } catch (err) {
            setError(err);
            console.log(err, 'error');
        } finally {
            setLoading(false);
        }
    }, [ballotId, userProfileId]);
    useEffect(() => {
        if (ballotId && userProfileId) {
            getBallotAccess();
        }
    }, [ballotId, userProfileId, getBallotAccess]);

    return { accessData, loading, error, refetch: getBallotAccess };
};

export default useBallotAccess;
