import React, { useState, useRef, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import CommitteeTabDetails from "./CommitteeTabDetails";
import CommitteeTabConfiguration from "./CommitteeTabConfiguration";
import { Badge } from "primereact/badge";
import CommitteeTabRoster from "./CommitteeTabRoster";
import CommitteeTabRequests from "./CommitteeTabRequests";
import CommitteeTabRecords from "./CommitteeTabRecords";
import CommitteeTabBallots from "./CommitteeTabBallots";
import CommitteeTabCommitteeMeeting from "./CommitteeTabCommitteeMeeting";
import CommitteeTabStandardAssociated from "./CommitteeTabStandardAssociated";
import OpenPositionsListing from "../position/OpenPositionsListing";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { getCommitteeData } from "../../utils/utils";
import {
  addMembersInCommitteeRequest,
  categoryOfInteresetRequest,
  configureCommitteeRequest,
  createCommitteeRequest,
} from "../../slices/createCommitteeSlice";
import { fetchData } from "../../services/apiService";
import CommitteeTabOpenPositions from "./CommitteeTabOpenPositions";
import withLoader from "../common/LoaderIntercepter";
interface Option {
  name: string;
  code: string;
}

interface Pathname {
  pathname: any;
}

const CommitteeTabs: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );
  const [committeeId, setCommitteeId] = useState<string | null | undefined>("");
  const [committeeNumber, setCommitteeNumber] = useState<
    string | null | undefined
  >("");
  const [committeeStatus, setCommitteeStatus] = useState<
    string | null | undefined
  >("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const [checked, setChecked] = useState<boolean>(false);
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [dateActivation, setDateActivation] = useState(null);
  const committeeDataHandler = async () => {
    let [
      committeeData,
      configurationData,
      memberData,
      categoryOfInteresetData,
    ] = await getCommitteeData(id);
    dispatch(createCommitteeRequest(committeeData));
    dispatch(configureCommitteeRequest(configurationData));
    dispatch(addMembersInCommitteeRequest(memberData));
    dispatch(categoryOfInteresetRequest(categoryOfInteresetData));
    setCommitteeId(committeeData.committeeId);
  };

  useEffect(() => {
    if (committeeId) {
      const fetchCommitteeNumber = async () => {
        try {
          const responseData = await fetchData("Committee", committeeId);
          setCommitteeNumber(responseData.CommitteeNumber);
          setCommitteeStatus(responseData.Status.Name);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchCommitteeNumber();
    }
  }, [committeeId]);

  useEffect(() => {
    committeeDataHandler();
    let path = sessionStorage.getItem("redirectionPath");
    let { activeIndex } = location.state ? location.state : false;
    if (path == "record") {
      setActiveIndex(4);
      sessionStorage.removeItem("redirectionPath");
    }
    if (path == "request") {
      setActiveIndex(3);
      sessionStorage.removeItem("redirectionPath");
    }
    if (path == "member" || path == "memberCancel") {
      setActiveIndex(2);
      sessionStorage.removeItem("redirectionPath");
    }
    if (path == "config") {
      setActiveIndex(1);
      sessionStorage.removeItem("redirectionPath");
    }
    // for open position
    if (activeIndex == 6) {
      setActiveIndex(activeIndex);
    }
  }, []);

  return (
    <>
      <div className="">
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/committee")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("committee.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title flex-wrap display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
            {createCommitteeData.createCommittee?.committeeName}{" "}
            <span>({committeeNumber})</span>
            <Badge
              value={committeeStatus}
              className={committeeStatus === "Created" ? "active" : "draft"}
            />
          </h1>
          {/* <h2 className="text-title display-xs font-bold text-capitalize m-0">{committeeNumber}</h2> */}
          {/* <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>

        <div className="tabs-wrap">
          <TabView
            scrollable
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header={t("committee.details")}>
              <CommitteeTabDetails />
            </TabPanel>
            <TabPanel header={t("committee.configuration")}>
              <CommitteeTabConfiguration />
            </TabPanel>
            <TabPanel header={t("committee.roster")}>
              <CommitteeTabRoster />
            </TabPanel>
            <TabPanel header={t("committee.request")}>
              <CommitteeTabRequests />
            </TabPanel>
            <TabPanel header={t("committee.records")}>
              <CommitteeTabRecords />
            </TabPanel>
            <TabPanel header={t("committee.ballot")}>
              <CommitteeTabBallots />
            </TabPanel>
            {/* <TabPanel header={t("committee.standardAssociated")}>
              <CommitteeTabStandardAssociated />
            </TabPanel> */}
            <TabPanel header={t("committee.openPositions")}>
              {/* <CommitteeTabOpenPositions /> */}
              <OpenPositionsListing />
            </TabPanel>
            <TabPanel header={t("committee.commmitteeMeeting")}>
              <CommitteeTabCommitteeMeeting />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default withLoader(CommitteeTabs);
