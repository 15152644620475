import { LOCALHOST_DOMAINNAME } from "../config";
import { fetchData } from "../services/apiService";

interface CommitteeConfigurationObject {
  defaultParent: boolean;
  isNatureofCommitteeRestricted: boolean;
  isBalanceofInterestRequiredtoMaintained: boolean;
  acceptApplicationWithoutOpenPositions: boolean;
  allowApplicationsWithoutOpenPositions: boolean;
  isAllowMemberOtherCommitteAccess: boolean;
  balanceofInterest: string;
  committeeMinMember: string;
  committeeMaxMember: string;
  organisationMinMember: string;
  organisationMaxMember: string;
  organisationMemberThreshold: string;
  msElig_SubTierCommitte_Months: string;
  msElig_SameCommitte_Officer_Months: string;
  msExpire_Officer_Tenure_Years: string;
  msExpire_Member_Tenure_Years: string;
  sendNotificationforMembershipExpiration: boolean;
  notifyStaffExpirationofMembership: boolean;
  notifyStaffExpirationofMembershipDays_First: string;
  notifyStaffExpirationofMembershipDays_Second: string;
  notifyStaffExpirationofMembershipDays_Third: string;
  notifyMemberExpirationofMembership: boolean;
  notifyMemberExpirationofMembershipDays_First: string;
  notifyMemberExpirationofMembershipDays_Second: string;
  notifyMemberExpirationofMembershipDays_Third: string;
  officerMaximumTermYears: string;
  applicationAutoWithdrawalMonths: string;
  notifyMemberLackofParticipation: boolean;
  numberofMeetingMissed: string;
  numberofBallotsMissed: string;
  totalMeetingMisssedPercentage: string;
  totalBallotMisssedPercentage: string;
  staffId?: string | null;
  staffName?: string | null;
  isBalanced?: string | null;
  createdDate?: string | null;
  membershipExpiresOn_All: string;
  minOverallThreshold?: string;
  maxOverallThreshold?: string;
  thresholdPercentage?: string;
  IsBalanceBasedOnCategoryofInterests: boolean;
  IsBalanceBasedOnOverAllThreshold: boolean;
  IsBalanceBasedOnRepresentationByOrg: boolean;
}

const CreateApiData: any = {
  isDirty: true,
  isNew: true,
  name: "",
  abbreviation: "",
  parentCommitteeId: "",
  hierarchyLevelId: "",
  activationDate: "",
  committeeTypeId: "",
  isNatureofCommitteeRestricted: false,
  isAllowMemberOtherCommitteAccess: false,
  isBalanceofInterestRequiredtoMaintained: false,
  acceptApplicationWithoutOpenPositions: false,
  balanceofInterest: null,
  committeeMinMember: null,
  committeeMaxMember: null,
  organisationMinMember: null,
  organisationMaxMember: null,
  organisationMemberThreshold: null,
  msElig_SubTierCommitte_Months: null,
  msElig_SameCommitte_Officer_Months: null,
  msExpire_Officer_Tenure_Years: null,
  msExpire_Member_Tenure_Years: null,
  membershipExpiresOn_All: "2024-02-20T11:37:06.396Z",
  sendNotificationforMembershipExpiration: true,
  notifyStaffExpirationofMembership: false,
  notifyStaffExpirationofMembershipDays_First: 0,
  notifyStaffExpirationofMembershipDays_Second: 0,
  notifyStaffExpirationofMembershipDays_Third: 0,
  notifyMemberExpirationofMembership: false,
  notifyMemberExpirationofMembershipDays_First: 0,
  notifyMemberExpirationofMembershipDays_Second: 0,
  notifyMemberExpirationofMembershipDays_Third: 0,
  officerMaximumTermYears: null,
  applicationAutoWithdrawalMonths: null,
  statusId: "",
  staffId: null, //userId
  notifyMemberLackofParticipation: false,
  numberofMeetingMissed: null,
  numberofBallotsMissed: null,
  totalMeetingMisssedPercentage: null,
  totalBallotMisssedPercentage: null,
  disbandedDate: "2024-02-20T11:37:06.396Z",
  isDefaultParent: false,
  IsBalanceBasedOnCategoryofInterests: false,
  IsBalanceBasedOnOverAllThreshold: false,
  IsBalanceBasedOnRepresentationByOrg: false,
};

const createCharterStatusApi = {
  isDirty: true,
  isNew: true,
  charter: "",
  charterStatusId: "",
  charterApprovedRecordNumber: "",
  committeeId: "",
};

export const formatCommitteeCharterData = (
  charterStatusId: any,
  committeeId: string,
  recordId: string,
  charterName?: string,
  isNew?: boolean
) => {
  createCharterStatusApi["charterStatusId"] = charterStatusId.id;
  createCharterStatusApi["charter"] = charterName ? charterName : "";
  createCharterStatusApi["committeeId"] = committeeId;
  createCharterStatusApi["charterApprovedRecordNumber"] = recordId;
  createCharterStatusApi["isNew"] = isNew ? isNew : true;
  return createCharterStatusApi;
};

export const formatCreateCommitteeData = async (data: any) => {
  let activationDate = data["activationDate"]
    ? new Date(data["activationDate"])
    : null;
  CreateApiData["name"] = data.committeeName;
  CreateApiData["abbreviation"] = data.committeeAbbreviation;
  CreateApiData["activationDate"] = activationDate
    ? activationDate?.toISOString()
    : "";
  CreateApiData["committeeTypeId"] = data.committeeType.id;
  CreateApiData["hierarchyLevelId"] = data.hierarchyLevel.id;
  CreateApiData["parentCommitteeId"] = data.parentCommittee.id;
  CreateApiData["staffId"] = data.staffContact.id;
  CreateApiData["statusId"] = await getStatusIdByName("Draft");
  return CreateApiData;
};

const getStatusIdByName = async (name: string) => {
  const getAllStatus = await fetchData("Status/GetAll");
  let statusID = getAllStatus?.Collection.filter(
    (val: any) => val.Name.toLowerCase() === name.toLocaleLowerCase()
  )[0].Id;
  return statusID;
};

export const formatCongigurationCommitteeData = async (
  data: any,
  createCommitteeData: any,
  committeeStatus?: boolean,
  saveAsDraft?: string
) => {
  let activationDate = createCommitteeData.activationDate
    ? new Date(createCommitteeData.activationDate)
    : null;
  const today = new Date();
  const isFutureDate = activationDate && activationDate > today;
  CreateApiData["isDirty"] = true;
  CreateApiData["isNew"] = false;
  CreateApiData["name"] = createCommitteeData.committeeName;
  CreateApiData["abbreviation"] = createCommitteeData.committeeAbbreviation;
  CreateApiData["parentCommitteeId"] = createCommitteeData.parentCommittee.id;
  CreateApiData["hierarchyLevelId"] = createCommitteeData.hierarchyLevel.id;
  CreateApiData["activationDate"] =
    createCommitteeData.activationDate?.toISOString();
  CreateApiData["committeeTypeId"] = createCommitteeData.committeeType.id;
  CreateApiData["isNatureofCommitteeRestricted"] =
    data.isNatureofCommitteeRestricted;
  CreateApiData["isBalanceofInterestRequiredtoMaintained"] =
    data.isBalanceofInterestRequiredtoMaintained;
  CreateApiData["acceptApplicationWithoutOpenPositions"] =
    data.acceptApplicationWithoutOpenPositions;
  CreateApiData["isAllowMemberOtherCommitteAccess"] =
    data.isAllowMemberOtherCommitteAccess;
  CreateApiData["balanceofInterest"] = data.balanceofInterest || null;
  CreateApiData["committeeMinMember"] =
    (data.committeeMinMember && parseInt(data.committeeMinMember)) || null;
  CreateApiData["committeeMaxMember"] = data.committeeMaxMember || null;
  CreateApiData["organisationMinMember"] = data.organisationMinMember || 0;
  CreateApiData["organisationMaxMember"] = data.organisationMaxMember || 0;
  CreateApiData["organisationMemberThreshold"] =
    data.organisationMemberThreshold || 0;
  CreateApiData["msElig_SubTierCommitte_Months"] =
    data.msElig_SubTierCommitte_Months || null;
  CreateApiData["msElig_SameCommitte_Officer_Months"] =
    data.msElig_SameCommitte_Officer_Months || null;
  CreateApiData["msExpire_Officer_Tenure_Years"] =
    data.msExpire_Officer_Tenure_Years || null;
  CreateApiData["msExpire_Member_Tenure_Years"] =
    data.msExpire_Member_Tenure_Years || null;
  CreateApiData["sendNotificationforMembershipExpiration"] =
    data.sendNotificationforMembershipExpiration;
  CreateApiData["notifyStaffExpirationofMembership"] =
    data.notifyStaffExpirationofMembership;
  CreateApiData["notifyStaffExpirationofMembershipDays_First"] =
    data.notifyStaffExpirationofMembershipDays_First || null;
  CreateApiData["notifyStaffExpirationofMembershipDays_Second"] =
    data.notifyStaffExpirationofMembershipDays_Second || null;
  CreateApiData["notifyStaffExpirationofMembershipDays_Third"] =
    data.notifyStaffExpirationofMembershipDays_Third || null;
  CreateApiData["notifyMemberExpirationofMembership"] =
    data.notifyMemberExpirationofMembership;
  CreateApiData["notifyMemberExpirationofMembershipDays_First"] =
    data.notifyMemberExpirationofMembershipDays_First || null;
  CreateApiData["notifyMemberExpirationofMembershipDays_Second"] =
    data.notifyMemberExpirationofMembershipDays_Second || null;
  CreateApiData["notifyMemberExpirationofMembershipDays_Third"] =
    data.notifyMemberExpirationofMembershipDays_Third || null;
  CreateApiData["officerMaximumTermYears"] =
    data.officerMaximumTermYears || null;
  CreateApiData["applicationAutoWithdrawalMonths"] =
    data.applicationAutoWithdrawalMonths || null;
  CreateApiData["notifyMemberLackofParticipation"] =
    data.notifyMemberLackofParticipation;
  CreateApiData["numberofMeetingMissed"] = data.numberofMeetingMissed || null;
  CreateApiData["numberofBallotsMissed"] = data.numberofBallotsMissed || null;
  CreateApiData["totalMeetingMisssedPercentage"] =
    data.totalMeetingMisssedPercentage || null;
  CreateApiData["totalBallotMisssedPercentage"] =
    data.totalBallotMisssedPercentage || null;
  CreateApiData["staffId"] = createCommitteeData.staffContact.id
    ? createCommitteeData.staffContact.id
    : null;
  CreateApiData["isDefaultParent"] = data.defaultParent;
  CreateApiData["membershipExpiresOn_All"] =
    data.membershipExpiresOn_All ||
    createCommitteeData.activationDate?.toISOString();
  CreateApiData["minOverallThreshold"] = data.minOverallThreshold || 0;
  CreateApiData["maxOverallThreshold"] = data.maxOverallThreshold || 0;
  CreateApiData["thresholdPercentage"] = data.thresholdPercentage || 0;
  CreateApiData["IsBalanceBasedOnCategoryofInterests"] =
    data.IsBalanceBasedOnCategoryofInterests;
  CreateApiData["IsBalanceBasedOnOverAllThreshold"] =
    data.IsBalanceBasedOnOverAllThreshold;
  CreateApiData["IsBalanceBasedOnRepresentationByOrg"] =
    data.IsBalanceBasedOnRepresentationByOrg;
  if (saveAsDraft === "draft") {
    CreateApiData["statusId"] = await getStatusIdByName("Draft");
  } else if (isFutureDate) {
    CreateApiData["statusId"] = await getStatusIdByName("Draft");
  } else {
    CreateApiData["statusId"] = await getStatusIdByName("Created");
  }

  return CreateApiData;
};
export const setOptionsFromResponse = (
  data: any,
  key: string,
  isNotCollection?: boolean
) => {
  const optionObjectCollection: any = [];
  if (key === "Email") {
    data?.forEach((val: any, index: number) => {
      optionObjectCollection[index] = {
        name: `${val.Firstname || ""} ${val.MiddleName ? val.MiddleName : ""} ${val.Lastname || ""}`.trim(),
        id: val.Id,
      };
    });
  } else if (isNotCollection) {
    data?.forEach((val: any, index: number) => {
      optionObjectCollection[index] = { name: val[key], id: val.Id };
    });
  } else {
    data.Collection?.forEach((val: any, index: number) => {
      optionObjectCollection[index] = { name: val[key], id: val.Id };
    });
  }

  return optionObjectCollection;
};

export function isNumberKey(evt: any) {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
}

export const ConfigureCommitteeResponseFormter = (data: any) => {
  let formatedData: CommitteeConfigurationObject = {
    defaultParent: data.isDefaultParent || data.IsDefaultParent,
    isNatureofCommitteeRestricted: data.IsNatureofCommitteeRestricted,
    isAllowMemberOtherCommitteAccess: data.IsAllowMemberOtherCommitteAccess,
    isBalanceofInterestRequiredtoMaintained:
      data.IsBalanceofInterestRequiredtoMaintained,
    acceptApplicationWithoutOpenPositions:
      data.AcceptApplicationWithoutOpenPositions,
    allowApplicationsWithoutOpenPositions:
      data.AllowApplicationsWithoutOpenPositions,
    balanceofInterest: data.BalanceofInterest || "",
    committeeMinMember: data.CommitteeMinMember || "",
    committeeMaxMember: data.CommitteeMaxMember || "",
    organisationMinMember: data.OrganisationMinMember || "",
    organisationMaxMember: data.OrganisationMaxMember || "",
    organisationMemberThreshold: data.OrganisationMemberThreshold || "",
    msElig_SubTierCommitte_Months: data.MSElig_SubTierCommitte_Months
      ? data.MSElig_SubTierCommitte_Months
      : null,
    msElig_SameCommitte_Officer_Months: data.MSElig_SameCommitte_Officer_Months
      ? data.MSElig_SameCommitte_Officer_Months
      : null,
    msExpire_Officer_Tenure_Years: data.MSExpire_Officer_Tenure_Years
      ? data.MSExpire_Officer_Tenure_Years
      : null,
    msExpire_Member_Tenure_Years: data.MSExpire_Member_Tenure_Years
      ? data.MSExpire_Member_Tenure_Years
      : null,
    sendNotificationforMembershipExpiration:
      data.SendNotificationforMembershipExpiration,
    notifyStaffExpirationofMembership: data.NotifyStaffExpirationofMembership,
    notifyStaffExpirationofMembershipDays_First:
      data.NotifyStaffExpirationofMembershipDays_First || "",
    notifyStaffExpirationofMembershipDays_Second:
      data.NotifyStaffExpirationofMembershipDays_Second || "",
    notifyStaffExpirationofMembershipDays_Third:
      data.NotifyStaffExpirationofMembershipDays_Third || "",
    notifyMemberExpirationofMembership: data.NotifyMemberExpirationofMembership,
    notifyMemberExpirationofMembershipDays_First:
      data.NotifyMemberExpirationofMembershipDays_First || "",
    notifyMemberExpirationofMembershipDays_Second:
      data.NotifyMemberExpirationofMembershipDays_Second || "",
    notifyMemberExpirationofMembershipDays_Third:
      data.NotifyMemberExpirationofMembershipDays_Third || "",
    officerMaximumTermYears: data.OfficerMaximumTermYears || "",
    applicationAutoWithdrawalMonths: data.ApplicationAutoWithdrawalMonths || "",
    notifyMemberLackofParticipation: data.NotifyMemberLackofParticipation,
    numberofMeetingMissed: data.NumberofMeetingMissed || "",
    numberofBallotsMissed: data.NumberofBallotsMissed || "",
    totalMeetingMisssedPercentage: data.TotalMeetingMisssedPercentage || "",
    totalBallotMisssedPercentage: data.TotalBallotMisssedPercentage || "",
    staffName: data.Staff
      ? `${data.Staff.Firstname && data.Staff.Firstname} ${data.Staff.MiddleName && data.Staff.MiddleName} ${data.Staff.Lastname && data.Staff.Lastname}`
      : null,
    isBalanced: data.IsBalanced ? "Yes" : "No",
    createdDate: data.CreatedDate,
    membershipExpiresOn_All: data.MembershipExpiresOn_All,
    minOverallThreshold: data.MinOverallThreshold || "",
    maxOverallThreshold: data.MaxOverallThreshold || "",
    thresholdPercentage: data.ThresholdPercentage || "",
    IsBalanceBasedOnCategoryofInterests:
      data.IsBalanceBasedOnCategoryofInterests || false,
    IsBalanceBasedOnOverAllThreshold:
      data.IsBalanceBasedOnOverAllThreshold || false,
    IsBalanceBasedOnRepresentationByOrg:
      data.IsBalanceBasedOnRepresentationByOrg || false,
  };
  return formatedData;
};

export const CommitteeMemberResponseFormter = (data: any) => {
  let formatedData = data?.map((details: any) => {
    return {
      membershipPositionId: {
        name: details?.membershipPosition?.positionName,
        id: details?.membershipPosition?.id,
      },
      categoryOfInterestId: {
        name: details?.categoryOfInterest?.categoryName,
        id: details?.categoryOfInterest?.id,
      },
      tenureLength: details?.tenureLength,
      name: {
        name: `${details?.userProfile?.firstname ? details?.userProfile?.firstname : ""} ${details?.userProfile?.middleName ? details?.userProfile?.middleName : ""} ${details?.userProfile?.lastname ? details?.userProfile?.lastname : ""}`,
        id: details?.userProfile?.id
          ? details?.userProfile?.id
          : details?.UserProfileId,
      },
      endDate: details?.endDate,
      startDate: details?.startDate,
      memberId: details?.id,
      UserProfileId: details?.userProfile?.id
        ? details?.userProfile?.id
        : details?.UserProfileId,
      Id: details?.id,
      membershipStatusId: details?.membershipStatusId,
      isMembershipExpiring: details?.isMembershipExpiring,
      committeeID: details?.committeeId,
    };
  });
  return formatedData;
};
export const formatCategoryOfInterest = (
  data: any,
  committeeId: string,
  isDeleted?: boolean
) => {
  let formatCategoryOfInterestData = data?.map((details: any) => {
    return {
      isDirty: true,
      isNew: details.CategoryOfInterestId ? false : true,
      committeeId: committeeId,
      categoryOfInterestId: details.categoryOfInterestValue.id,
      minMember: parseInt(details.min),
      maxMember: parseInt(details.max),
      threshold: parseInt(details.threshold),
      committeeCategoryOfInterestId: details.CategoryOfInterestId
        ? details.CategoryOfInterestId
        : "00000000-0000-0000-0000-000000000000",
      isActive: isDeleted ? false : true,
      isDeleted: isDeleted ? true : false,
    };
  });
  return formatCategoryOfInterestData;
};

const calculateEndDate = (tenure: number) => {
  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDate();
  let endDate = new Date(year + tenure, month, day);
  return endDate.toISOString();
};

export const getMembershipStatusIdByName = async (name: string) => {
  let allMembershipStatus = await fetchData("MembershipStatus/GetAll");
  let membershipStatusID = allMembershipStatus.Collection.filter(
    (val: any) =>
      val.StatusName.toLocaleLowerCase() === name.toLocaleLowerCase()
  )[0].Id;
  return membershipStatusID;
};

const normalizeDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const formatLocalDateTime = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const formatMembershipRequest = async (
  data: any,
  committeeId: string,
  forAPIRequest?: boolean,
  isDeleted?: boolean
) => {
  let formatedMembershipData = await Promise.all(
    data?.map(async (details: any) => {
      let tenure = details.tenureLength;
      if (details.tenureLength) {
        tenure = parseInt(String(details.tenureLength).split("")[0], 10);
      }

      let membershipStatusId;
      const today = normalizeDate(new Date());
      const startDate = normalizeDate(new Date(details?.startDate));
      const endDate = normalizeDate(new Date(details?.endDate));

      if (endDate < today || startDate.getTime() === endDate.getTime()) {
        membershipStatusId = await getMembershipStatusIdByName("Retired");
      } else if (startDate > today) {
        membershipStatusId = await getMembershipStatusIdByName("Pending");
      } else {
        membershipStatusId = await getMembershipStatusIdByName("Current");
      }
      return {
        isDirty: true,
        isNew: details?.Id ? false : true,
        userProfileId: details?.name?.id,
        committeeId: committeeId,
        membershipPositionId: details.membershipPositionId.id,
        startDate: details?.startDate?.toISOString(),
        endDate: details?.endDate?.toISOString(),
        categoryOfInterestId: details.categoryOfInterestId.id,
        tenureLength: forAPIRequest
          ? tenure
          : { name: `${details?.tenureLength} year` },
        membershipStatusId: membershipStatusId,
        committeeMembershipId: details?.Id
          ? details?.Id
          : "00000000-0000-0000-0000-000000000000",
        recordId: null,
        isActive: isDeleted ? false : true,
        isDeleted: isDeleted ? true : false,
      };
    })
  );
  let result = formatedMembershipData;
  return result;
};

export const formatApproveMembershipRequest = (data: any) => {
  const startDateString = data?.startDate;
  const endDateString = data?.endDate;

  if (!startDateString || !endDateString) {
    console.error(
      "Invalid startDate or endDate:",
      startDateString,
      endDateString
    );
    return null;
  }

  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error("Invalid startDate or endDate:", startDate, endDate);
    return null;
  }

  return {
    name: data?.name || "",
    membershipPositionId: data?.membershipPositionId || "",
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    categoryOfInterestId: data?.categoryOfInterestId || "",
    committeeMaxMember: { name: `${data?.committeeMaxMember} year` },
    approveMembershipRemark: data?.approveMembershipRemark || "",
    committeeId: data?.committeeId,
    memberName: data?.CommitteeMaxMember || "",
    tenureLength: data?.tenureLength.id,
  };
};

export const formatAllMembershipResponse = (data: any) => {
  let formatHistoryResponse = data?.map((details: any) => {
    const userProfileID = localStorage.getItem("userProfileID");
    return {
      Id: details.Id,
      MemberName: details.MemberName,
      CommitteeName: details.CommitteeName,
      MemberPosition: details.MemberPosition,
      CategoryOfInterest: details.CategoryOfInterest,
      BallotAttended: details.BallotAttended,
      MeetingAttended: details.MeetingAttended,
      StatusName: details.StatusName,
      StartDate: details.StartDate
        ? new Date(details.StartDate).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
      EndDate: details.EndDate
        ? new Date(details.EndDate).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
      sessionKey: "committeeMembershipId",
      action: "Action",
      committeeID: details.CommitteeId,
      userProfileID: userProfileID,
    };
  });
  formatHistoryResponse = sortByStartDate(formatHistoryResponse);
  return formatHistoryResponse;
};

export const formatOpenPositionResponse = (data: any) => {
  let formatOpenPositionListingResponse = data?.map((details: any) => {
    return {
      CommitteeName: details.CommitteeName || details.committeeName,
      StaffName: details.StaffName || details.staffName,
      CategoryOfInterest:
        details.CategoryOfInterest || details.categoryOfInterest,
      OpenPositions: details.PositionName || details.positionName,
      PositionOpenfrom:
        details.PositionOpenfrom || details.positionOpenfrom
          ? new Date(
              details.PositionOpenfrom || details.positionOpenfrom
            ).toLocaleDateString()
          : null,
      NumberOfPositions: details.NumberOfPositions || details.numberOfPositions,
      StatusName: details.StatusName || details.statusName,
      Action: "Action",
      StaffId: details.StaffId || details.staffId,
      sessionKey: "OpenPositionId",
      OpenPositionId: details.OpenPositionId || details.openPositionId,
      CommitteeId: details.CommitteeId || details.committeeId,
      CategoryOfInterestId:
        details.CategoryOfInterestId || details.categoryOfInterestId,
      PositionFilled:
        details.PositionFilledCount ?? details.positionFilledCount ?? 0,
      MembershipPositionId:
        details.MembershipPositionId || details.membershipPositionId,
      IsMembershipRequestCreated:
        details.isMembershipRequestCreated ||
        details.isMembershipRequestCreated,
    };
  });
  return formatOpenPositionListingResponse;
};

export const formatMembershipHistoryResponse = (data: any) => {
  let formatHistoryResponse = data?.map((details: any) => {
    let statusName = details.MembershipStatus.StatusName;
    if (statusName.toLowerCase() === "terminate") {
      statusName = "Terminated";
    } else if (statusName.toLowerCase() === "active") {
      statusName = "Activated";
    } else if (statusName.toLowerCase() === "resign") {
      statusName = "Resigned";
    } else {
      statusName = details.MembershipStatus.StatusName;
    }
    return {
      name: details.Committee.Name,
      positionName: details.MembershipPosition.PositionName,
      categoryName: details.CategoryOfInterest.CategoryName,
      numberOfBallotMissed: "0",
      numberOfMeetingMissed: "0",
      startDate: details.StartDate
        ? new Date(details.StartDate).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
      endDate: details.EndDate
        ? new Date(details.EndDate).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
      sessionKey: "committeeMembershipId",
      action: "Action",
      StatusName: statusName,
      committeeMembershipId: details.Id,
      recordID: details.RecordId,
    };
  });
  return formatHistoryResponse;
};

export const formatStaffNoteResponse = (data: any) => {
  //new
  let formatStaffNoteResponse = data?.map((details: any) => {
    let fullName = `${details.staff.firstname} ${details.staff.lastname}`;
    return {
      StaffName: fullName,
      UserProfileStaffNotes: details.userProfileStaffNotes,
      createdDate: details.createdDate
        ? new Date(details.createdDate).toLocaleDateString()
        : "",
      committeeName: details.committee.name,
    };
  });
  return formatStaffNoteResponse;
};

export const formatMembershipResponse = (data: any) => {
  let formatMembershipResponse = data?.map((details: any) => {
    return {
      Id: details.CommitteeMembershipId,
      name: details.CommitteeName,
      positionName: details.MemberPosition,
      categoryName: details.CategoryOfInterest,
      numberOfBallotMissed: "0",
      numberOfMeetingMissed: "0",
      startDate: details.StartDate
        ? new Date(details.StartDate).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
      endDate: details.EndDate
        ? new Date(details.EndDate).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
      sessionKey: "committeeMembershipId",
      action: "Action",
      StatusName: details.StatusName,
      isMembershipExpiring: details.IsMembershipExpiring,
      committeeID: details.CommitteeId,
      userProfileID: details.UserProfileId,
    };
  });
  return formatMembershipResponse;
};

export const formatMembershipRecordResponse = (data: any) => {
  let formatedMembershipRecordData = data?.map((details: any) => {
    return {
      Id: details.Id,
      recordId:
        details?.RecordNumber === "null" || !details?.RecordNumber
          ? ""
          : details?.RecordNumber,
      memberName:
        details.UserProfile.Firstname + " " + details.UserProfile.Lastname,
      typeName: details.RecordType.TypeName,
      subType: "New",
      statusName: details.RecordStatus.StatusName,
      createdOn: details.CreatedDate
        ? new Date(details.CreatedDate).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : "",
      sessionKey: "recordId",
      action: "Action",
    };
  });
  formatedMembershipRecordData = sortByStartDate(formatedMembershipRecordData);
  return formatedMembershipRecordData;
};

export const formatCommitteeResponse = (
  data: any,
  memberObj?: any,
  allCommittee?: any
) => {
  // const user;
  const memberCount = (Id: string) => {
    const count = memberObj.filter((val: any) => val.CommitteeId === Id).length;
    return count;
  };
  let formatedCommitteeData = data?.map((details: any) => {
    return {
      Id: details.Id,
      committeeNumber: details.CommitteeNumber,
      name: details.Name,
      level: details.HierarchyLevel.Name,
      parentCommittee: allCommittee
        ? findParentName(allCommittee, details.ParentCommitteeId)
        : findParentName(data, details.ParentCommitteeId),
      //ballotCount: "261",
      inBalance: details.IsBalanced ? "Yes" : "No",
      member: memberObj ? memberCount(details.Id) : "",
      StatusName: details.Status?.Name,
      createdOn: details.CreatedDate
        ? new Date(details.CreatedDate).toLocaleDateString()
        : "",
      sessionKey: "committeeId",
      createdBy: "Ayush",
      action: "Action",
      staffContact: `${details.Staff?.Firstname ? details.Staff?.Firstname : ""} ${details.Staff?.Lastname ? details.Staff?.Lastname : ""}`,
    };
  });
  formatedCommitteeData = sortByStartDate(formatedCommitteeData);
  return formatedCommitteeData;
};

const findParentName = (data: any, Id: string, member?: boolean) => {
  let parentName = "";
  if (member) {
    parentName = data.find((committeeObj: any) => committeeObj.id === Id)?.name;
  } else {
    parentName = data.find((committeeObj: any) => committeeObj.Id === Id)?.Name;
  }

  return parentName;
};
const getCharterStatusById = async (Id: any) => {
  if (Id) {
    const charterStatusResponse = await fetchData(`CharterStatus/${Id}`);
    return charterStatusResponse.StatusName;
  }
  return "";
};

export const setReduxDataForCreateCommittee = async (data: any) => {
  let charterName = await getCharterStatusById(
    data?.CommitteeCharters[0]?.CharterStatusId
  );

  const createCommitteeData = {
    committeeName: data?.Name,
    committeeAbbreviation: data?.Abbreviation,
    committeeType: {
      name: data?.CommitteeType.TypeName,
      id: data?.CommitteeType.Id,
    },
    parentCommittee: {
      name: data?.ParentCommitteeName,
      id: data?.ParentCommitteeId,
    },
    hierarchyLevel: {
      name: data?.HierarchyLevel.Name,
      id: data?.HierarchyLevel.Id,
    },
    activationDate: new Date(data?.ActivationDate),
    committeeId: data?.Id,
    charterStatus: charterName
      ? {
          name: charterName,
          id: data?.CommitteeCharters[0]?.CharterStatusId,
        }
      : "",
    staffContact: {
      name: `${data.Staff?.Firstname && data.Staff?.Firstname} ${data.Staff?.MiddleName && data.Staff.MiddleName} ${data.Staff?.Lastname && data.Staff.Lastname} (${data.Staff?.Email})`,
      id: data.StaffId,
    },
    committeeCharter: data?.CommitteeCharters[0]?.Id,
    recordId: data?.CommitteeCharters[0]?.CharterApprovedRecordNumber
      ? data?.CommitteeCharters[0]?.CharterApprovedRecordNumber
      : "",
    charterName: data?.CommitteeCharters[0]?.Charter
      ? data?.CommitteeCharters[0]?.Charter
      : "",
    status: data?.Status?.Name,
  };
  return createCommitteeData;
};

export const setCategoryOfInterestData = (
  data: any,
  isDefaultParent: boolean = false
) => {
  let formatedData = [
    {
      categoryOfInterestValue: "",
      min: "",
      max: "",
      threshold: "",
    },
  ];
  if (data.length > 0) {
    formatedData = data?.map((details: any) => {
      return {
        min: parseInt(details?.MinMember),
        max: parseInt(details?.MaxMember),
        threshold: parseInt(details?.Threshold),
        CategoryOfInterestId:
          details?.Id && !isDefaultParent ? details?.Id : "",
        categoryOfInterestValue: {
          name: details?.CategoryOfInterest?.CategoryName,
          id: details?.CategoryOfInterest?.Id,
        },
      };
    });
  }

  return formatedData;
};

export const getCommitteeData = async (id?: string) => {
  let committeeId;
  if (id) {
    committeeId = id;
  } else {
    committeeId = sessionStorage.getItem("committeeId");
  }
  if (committeeId) {
    const committeeDataById = await fetchData(`Committee/${committeeId}`);
    let FormatCommitteeMember = [];
    try {
      const CommitteeMemberResponse = await fetchData(
        `CommitteeMembership/GetListByParentId?parentId=${committeeId}`
      );
      FormatCommitteeMember = CommitteeMemberResponseFormter(
        CommitteeMemberResponse?.collection
      );
    } catch (error) {
      console.log(error);
    }
    const AdminResponse = await fetchData("CommitteeConfiguration/GetAll");
    const AdminResponseforCommittee = AdminResponse?.Collection?.[0];

    const FormatCreateCommittee =
      await setReduxDataForCreateCommittee(committeeDataById);
    const FormatConfigureCommittee =
      ConfigureCommitteeResponseFormter(committeeDataById);
    let FormatCategoryOfIntereset = [
      {
        categoryOfInterestValue: "",
        min: "",
        max: "",
        threshold: "",
      },
    ];
    if (committeeDataById?.CommitteCategoryofInterests) {
      FormatCategoryOfIntereset = setCategoryOfInterestData(
        committeeDataById?.CommitteCategoryofInterests
      );
    }

    return [
      FormatCreateCommittee,
      FormatConfigureCommittee,
      FormatCommitteeMember,
      FormatCategoryOfIntereset,
      AdminResponseforCommittee,
    ];
  } else {
    return [];
  }
};

export const sortByStartDate = (data: any, key?: string) => {
  let sortedDataByDate;
  if (key) {
    sortedDataByDate = data?.sort(
      (a: any, b: any) =>
        new Date(b[key]).getTime() - new Date(a[key]).getTime()
    );
  } else {
    sortedDataByDate = data?.sort(
      (a: any, b: any) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
    );
  }

  return sortedDataByDate;
};

export function deepClone(obj: any) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return primitive types and null as-is
  }

  let clone: any = Array.isArray(obj) ? [] : {}; // Determine if obj is array or object

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepClone(obj[key]);
    }
  }

  return clone;
}

export function getTenantNameFromDomain(): string {
  const hostname = window.location.host;
  let tenantName: any = LOCALHOST_DOMAINNAME;
  if (!hostname.includes("localhost")) {
    return hostname;
  }
  return tenantName;
}

export function loadTheme() {
  const savedTheme = localStorage.getItem("appTheme") || "stanza-red";
  const themeLink: any = document.getElementById("theme-link");

  if (themeLink) {
    themeLink.href = `${process.env.PUBLIC_URL}/primereact-theme/themes/${savedTheme}/theme.css`;
  } else {
    const link = document.createElement("link");
    link.id = "theme-link";
    link.rel = "stylesheet";
    link.href = `${process.env.PUBLIC_URL}/primereact-theme/themes/${savedTheme}/theme.css`;
    document.head.appendChild(link);
  }
}
// BuildqueryParams
export const buildQueryParams = (params: any) => {
  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  return query;
};
// Ballot Format Date
export const formatDate = (isoString: string) => {
  const date = new Date(isoString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};
//format committee data
export const formatCommitteeData = (data: any) => {
  let formatedCommitteeData = data?.map((data: any) => {
    return {
      CommitteeId: data.CommitteeId,
      Id: data.CommitteeId,
      CommitteeNumber: data.CommitteeNumber,
      CommitteeName: data.CommitteeName,
      CommitteeLevel: data.CommitteeLevel,
      ParentCommitteeName: data.ParentCommitteeName
        ? data.ParentCommitteeName
        : "",
      IsBalanced: data.IsBalanced ? "Yes" : "No",
      MemberCount: data.MemberCount,
      StatusName: data.StatusName,
      CreatedDate: data.CreatedDate
        ? new Date(data.CreatedDate).toLocaleDateString()
        : "",
      action: "Action",
      PrimaryStaff: data.PrimaryStaff,
      sessionKey: "committeeId",
    };
  });

  return formatedCommitteeData;
};

//format committee data
export const formatedRecordData = (data: any) => {
  let formatedRecords = data?.map((record: any) => {
    return {
      RecordNumber: record.RecordNumber,
      Id: record.RecordId,
      recordId: record.RecordId, //new
      MemberName: record.MemberName,
      sessionKey: "recordId",
      statusName: record.Status,
      Status: record.Status,
      sessionKeyStatus: "statusName",
      CommitteeName: record?.CommitteeName,
      RecordSubType: record?.RecordSubType,
      Position: record?.Position,
      CategoryOfInterest: record?.CategoryOfInterest,
      CreatedOn: record.CreatedOn
        ? new Date(record.CreatedOn).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : "",
    };
  });

  return formatedRecords;
};

export const formatRequestData = (responseData: any) => {
  const sortedData = responseData.map((item: any) => ({
    Id: item.MembershipRequestId,
    MembershipRequestId: item.MembershipRequestId,
    MembershipRequestNumber: item.MembershipRequestNumber,
    MemberPosition: item.MemberPosition,
    CommitteeName: item.CommitteeName ? item.CommitteeName : null,
    CategoryOfInterest: item.CategoryOfInterest
      ? item.CategoryOfInterest
      : null,
    RequestDate: item.RequestDate
      ? new Date(item.RequestDate).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
      : null,
    CreatedDate: item.RequestDate
      ? new Date(item.RequestDate).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
      : null,
    StatusName: item.StatusName ? item.StatusName : null,
    sessionKey: "memberShipId",
    sessionKeyStatus: "statusName",
    MemberName: item?.MemberName,
  }));

  return sortedData;
};
export const getYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 10 }, (_, i) => {
    const year = currentYear - i;
    return { name: year.toString(), value: year }; // Each item has `name` and `value`
  });
};

export const getStandardCommitee = (
  allCommitee: any,
  hierearchyLevelData: any
) => {
  const standarCommitee = hierearchyLevelData.find(
    (row: any) => row.Name === "Standard"
  );
  const standarCommiteeList = allCommitee.filter(
    (row: any) => row.HierarchyLevelId === standarCommitee.Id
  );
  return standarCommiteeList;
};

export const getBoardCommitee = (
  allCommitee: any,
  hierearchyLevelData: any
) => {
  const boardCommitee = hierearchyLevelData.find(
    (row: any) => row.Name === "Board"
  );
  const boardCommiteeList = allCommitee.filter(
    (row: any) => row.HierarchyLevelId === boardCommitee.Id
  );
  return boardCommiteeList;
};

export const getSubtierCommitee = (
  allCommitee: any,
  hierearchyLevelData: any
) => {
  const subtierCommitee = hierearchyLevelData.find(
    (row: any) => row.Name === "Sub Tier"
  );
  const subtierCommiteeList = allCommitee.filter(
    (row: any) => row.HierarchyLevelId === subtierCommitee.Id
  );
  return subtierCommiteeList;
};
