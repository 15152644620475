import React, { useState, useEffect, useRef } from "react";
import FeatherIcon from "./FeatherIconComponent";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { useParams } from "react-router-dom";
import { fetchData } from "../../services/apiService";

interface Collection {
  StandardName: string;
  StandardId: string;
  ShortName: string;
  PublishedYear: string;
  MaintainanceCycleId: string;
  MaintainanceCycle: string;
  CommitteeName: string;
  CommitteeId: string;
  AccridationId: string;
  Accridation: string;
}

interface StandardFilterProps {
  onFilterApply: (filters: any) => void;
}

const StandardFilter: React.FC<StandardFilterProps> = ({ onFilterApply }) => {
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [heirarchyLevelCheckboxChecked, setHeirarchyLevelCheckboxChecked] =
    useState<boolean>(false);
  const [publishedYearCheckboxChecked, setPublishedYearCheckboxChecked] =
    useState<boolean>(false);
  const [selectedHeirarchyLevels, setSelectedHeirarchyLevels] = useState<any[]>(
    []
  );
  const [publishedYear, setPublishedYear] = useState<Date | null>(null); // State for year picker
  const [heirarchylevels, setHeirarchyLevels] = useState<Collection[]>([]);

  const [t] = useTranslation("standard");
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    const fetchCommittees = async () => {
      try {
        const response = await fetchData("Committee/GetAll");
        const committeesData = response.Collection;
        setHeirarchyLevels(committeesData);
      } catch (error) {
        console.error("Error fetching committees:", error);
      }
    };
    fetchCommittees();
  }, []);

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event);
    }
  };

  const handleCheckboxChange =
    (checkbox: string) => (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "primaryCommitteeResponsible":
          setHeirarchyLevelCheckboxChecked(isChecked);
          break;
        case "publishedYear":
          setPublishedYearCheckboxChecked(isChecked);
          break;
        default:
          break;
      }
    };

  const clearFilters = () => {
    setSelectedHeirarchyLevels([]);
    setPublishedYear(null);
  };

  const fetchFilteredData = () => {
    const filters = [];
    if (heirarchyLevelCheckboxChecked) {
      const heirarchylevelIds = selectedHeirarchyLevels.map(
        (heirarchy, i) => heirarchy.Id
      );
      filters.push({
        Key: "CommitteeId",
        Value: heirarchylevelIds.join(" , "),
      });
    }
    if (publishedYearCheckboxChecked && publishedYear) {
      filters.push({
        Key: "PublishedYear",
        Value: publishedYear.getFullYear().toString(),
      });
    }
    onFilterApply(filters);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.hide();
    }
  };

  return (
    <>
      <Button
        className="button-md gap-1"
        severity="secondary"
        onClick={toggleFilterPanel}
        aria-label={t("standard.filter")}
      >
        <FeatherIcon name="filter" size={20} color="inherit" />
        <span className="font-bold text-capitalize hidden md:flex">
          {t("standard.filter")}
        </span>
      </Button>
      <OverlayPanel
        ref={overlayPanelRef}
        onHide={() => setShowFilterPanel(false)}
        appendTo={"self"}
        dismissable
        className="p-overlaypanel-filter left-auto md:right-0 top-100"
        aria-labelledby="filter-heading"
        role="dialog"
      >
        <div className="flex flex-column gap-1" tabIndex={0}>
          <p id="filter-heading" className="text-lg font-bold m-0 p-3">
            {t("standard.filter")}
          </p>
          <div className="filter-wrap">
            <div className="filter-wrap">
              <div>
                <label
                  htmlFor="primaryCommitteeResponsible"
                  className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                >
                  <Checkbox
                    inputId="hierarchylevelFilter"
                    onChange={handleCheckboxChange(
                      "primaryCommitteeResponsible"
                    )}
                    checked={heirarchyLevelCheckboxChecked}
                    className="mr-2"
                    tabIndex={0}
                  />
                  {t("standard.primaryCommitteeResponsible")}
                </label>
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <MultiSelect
                    value={selectedHeirarchyLevels}
                    onChange={(e: any) => setSelectedHeirarchyLevels(e.value)}
                    options={heirarchylevels}
                    optionLabel="Name"
                    filter
                    placeholder={t("standard.primaryCommitteeResponsible")}
                    maxSelectedLabels={3}
                    className="w-full"
                    aria-label={t("standard.primaryCommitteeResponsible")}
                  />
                </div>
              </div>
              <div className="p-0">
                <label
                  htmlFor="publishedYearFilter"
                  className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                >
                  <Checkbox
                    inputId="publishedYearFilter"
                    onChange={handleCheckboxChange("publishedYear")}
                    checked={publishedYearCheckboxChecked}
                    className="mr-2"
                    tabIndex={0}
                  />
                  {t("standard.publishedYear")}
                </label>
                <div className="pr-3 py-2 pl-6 w-full relative">
                  <Calendar
                    value={publishedYear}
                    onChange={(e: any) => setPublishedYear(e.value)}
                    view="year"
                    dateFormat="yy"
                    placeholder={t("standard.publishedYear")}
                    className="w-full"
                    aria-label={t("standard.publishedYear")}
                  />
                </div>
              </div>
            </div>
          </div>
          <Button
            type="button"
            className="button-md p-button-secondary justify-content-center"
            onClick={clearFilters}
          >
            {t("standard.clearFilter")}
          </Button>
          <Button
            className="button-md w-full justify-content-center"
            onClick={fetchFilteredData}
          >
            {t("standard.applyFilter")}
          </Button>
        </div>
      </OverlayPanel>
    </>
  );
};

export default StandardFilter;
