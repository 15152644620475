import React from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
import BasicBallotDetails from "../sections/BasicBallotDetails";
import AddBallotDetails from "../sections/AddBallotDetails";

const SelectBallotDetails: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <BasicBallotDetails />
      <AddBallotDetails />
    </>
  );
};

export default SelectBallotDetails;
