import { useRef, useState } from "react";
import { Menu, Menu as PrimeMenu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Badge } from "primereact/badge";

export interface CustomMenuProps {
  actionItems: MenuItem[];
  rowData: any;
  disabled?: boolean;
}

export const getStatusBadge = (statusName: string) => {
  let badgeClass = "";

  // Adjust the status name for display purposes
  if (statusName === "Convert to Record") {
    statusName = "Converted to record";
  }

  // Determine the badge class based on the status
  switch (statusName?.toLowerCase()) {
    case "draft":
      badgeClass = "draft";
      break;
    case "open":
      badgeClass = "open";
      break;
    case "submitted":
      badgeClass = "open"; // Assuming submitted has the same styling as open
      break;
    case "in-progress":
    case "converted to record":
    case "active":
    case "completed":
    case "approved":
    case "current":
    case "created":
      badgeClass = "active";
      break;
    case "disbanded":
    case "on-hold":
    case "withdrawn":
      badgeClass = "disbanded";
      break;
    case "terminated":
      badgeClass = "terminated";
      break;
    case "deactive":
    case "delay":
    case "rejected":
      badgeClass = "rejected";
      break;
    case "closed":
      badgeClass = "closed";
      break;
    case "terminate":
    case "retired":
      badgeClass = "deactive";
      break;
    default:
      badgeClass = ""; // No class if status doesn't match
      break;
  }

  // Return the badge component
  return (
    <Badge value={statusName} className={`h-auto text-sm ${badgeClass}`} />
  );
};

const CustomMenu: React.FC<CustomMenuProps> = ({
  actionItems,
  rowData,
  disabled,
}) => {
  const menu = useRef<PrimeMenu>(null);
  const location = useLocation();
  const [t, i18n] = useTranslation("committee");
  const toggleMenu = (event: any, rowData?: any) => {
    // Handle session key for committeeMembershipId
    if (rowData.sessionKey === "committeeMembershipId") {
      sessionStorage.setItem("committeeId", rowData.committeeId || "");
    }

    // Handle reappointment condition
    if (
      rowData.isMembershipExpiring === true ||
      rowData.IsMembershipExpiring === true ||
      rowData.sessionKey === "committeeMembershipId"
    ) {
      sessionStorage.setItem("reappointment", "true");
    } else {
      sessionStorage.removeItem("reappointment"); // Clear reappointment if conditions are not met
    }

    const Reappointment = sessionStorage.getItem("reappointment");

    // Menu toggle logic
    if (menu.current) {
      menu.current.toggle(event);
    }

    // Handle Reappointment case
    if (Reappointment) {
      if (rowData.committeeID) {
        sessionStorage.setItem("committeeId", rowData.committeeID);
      } else {
        console.warn("committeeID is null or undefined");
      }
      sessionStorage.setItem(
        "reappointmentUserProfileId",
        rowData.userProfileID
      );
    } else {
      // Only set committeeId if reappointment is not active
      sessionStorage.setItem("committeeId", rowData.Id);
    }
  };

  return (
    <>
      <Menu model={actionItems} popup ref={menu} />
      <Button
        text
        className="text-body gap-1"
        aria-haspopup="true"
        aria-label={t("committee.action")}
        onClick={(e) => toggleMenu(e, rowData)}
        disabled={disabled}
      >
        <FeatherIcon name="settings" size={20} />
        <span className="font-normal">{t("committee.action")}</span>
      </Button>
    </>
  );
};

export default CustomMenu;
