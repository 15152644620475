import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../../services/Ballot/apiservice";
import { useLanguageContext } from "../../LanguageContext";
import CommentNewReply from "./CommentNewReply";
import CommentSection from "./CommentSection";
import { CommentReplyProps, ReplyComment } from "./type";
import useBallotAccess from "../../../hooks/useBallotAccess";
import useUserData from "../../../hooks/useUserData";
import { useParams } from "react-router-dom";

const CommentReply: React.FC<CommentReplyProps> = ({
  ballotCommentId,
  showcomment,
  onChildCommentSubmit,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [replyComments, setReplyComments] = useState<ReplyComment[]>([]);
  const [replyIndex, setReplyIndex] = useState<number | null>(null);
  const { id: ballotId } = useParams();
  const { userProfileId } = useUserData();
  const {
    accessData: { CanRespondToComment },
  } = useBallotAccess(ballotId, userProfileId);
  const handleReplyClick = (index: number | null, commentPosted: boolean) => {
    setReplyIndex(index === replyIndex ? null : index);
    if (commentPosted) {
      ballotReplyComments();
    }
  };
  const ballotReplyComments = async () => {
    try {
      const ballotReplyResponses = await fetchData(
        `BallotCommentResponses/GetBallotCommentResponsesByCommentId?ballotcommentId=${ballotCommentId}`
      );
      setReplyComments(ballotReplyResponses.Collection);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    ballotReplyComments();
  }, [showcomment]);
  return (
    <div key={"data"}>
      {replyComments.map((comment, index) => {
        return (
          <div
            key={index}
            className="p-4 pl-4 md:pl-7 flex flex-column gap-3 surface-100"
          >
            <CommentSection comment={comment} />
            <div>
              <Button
                text
                className="p-button-plain gap-2 p-0 underline"
                disabled={!CanRespondToComment}
                onClick={() => handleReplyClick(index, false)}
              >
                <span className="font-normal text-capitalize">
                  {t("ballot.reply")}
                </span>
              </Button>
            </div>
            {replyIndex === index && (
              <CommentNewReply
                key={replyIndex}
                ballotCommentId={ballotCommentId}
                parentResponseId={comment?.Id}
                onSubmitHandler={handleReplyClick}
                parentHierarchyLevel={false}
              />
            )}
            <div className="p-4 pl-4 md:pl-7 flex flex-column gap-3 surface-100">
              {comment?.ChildResponsesList.map((comment) => (
                <CommentSection comment={comment} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommentReply;
