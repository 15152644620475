import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { setActiveStep } from "../../slices/createStandard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import DocumentList from "./DocumentList";

interface DocumentTabProps {
  showEditButton?: boolean;
}

const DcoumentTabs: React.FC<DocumentTabProps> = ({ showEditButton }) => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const dispatch = useDispatch();

  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.publishedStandardDocuments")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => {
                  dispatch(setActiveStep(0));
                }}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <DocumentList
              StandardId={createRequestDetail.id}
              standardDocumentType="Standard"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DcoumentTabs;
