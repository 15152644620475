import React, { useState, useEffect, useRef } from "react";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData, putMeberData } from "../../services/Ballot/apiservice";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useScrollOnPaginatorClick } from "../common/useScrollOnPaginatorClick";

const MemberRolesTab: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  interface MemberPosition {
    id: string;
    memberPositionId: string;
    memberType: string;
    canVote: boolean;
    canComment: boolean;
    canManageBallot: boolean;
    isDirty?: boolean;
    canManageChildCommittee: boolean;
    canManageRecord: boolean;
    canManageRequest: boolean;
    canRespondToComment: boolean;
    readOnly: boolean;
  }

  const [memberPosition, setMemberPosition] = useState<MemberPosition[]>([]);
  const [payload, setPayload] = useState<any>([]);
  const toast = useRef<Toast>(null);
  const fetchMemberPositionData = async () => {
    try {
      const data = await fetchData("MemberPositionVotingRight/GetAll");
      const transformedData: MemberPosition[] = data.Collection.map(
        (item: any) => ({
          id: item.Id,
          memberPositionId: item.MemberPositionID,
          memberType: item.PositionName,
          canVote: item.CanVote,
          canComment: item.CanComment,
          canManageBallot: item.CanManageBallot,
          canManageChildCommittee: item.CanManageChildCommittee,
          canManageRecord: item.CanManageRecord,
          canManageRequest: item.CanManageRequest,
          canRespondToComment: item.CanRespondToComment,
          readOnly: item.ReadOnly,
        })
      );

      setMemberPosition(transformedData);
    } catch (error) {
      console.error("Error fetching ballot level data:", error);
    }
  };

  useEffect(() => {
    fetchMemberPositionData();
  }, []);

  const handleCheckboxChange = (
    rowData: MemberPosition,
    field: string,
    value: boolean
  ) => {
    setMemberPosition((prevMemberPosition) =>
      prevMemberPosition.map((item) =>
        item.memberPositionId === rowData.memberPositionId
          ? field === "readOnly" && value
            ? {
                ...item,
                readOnly: true,
                canVote: false,
                canComment: false,
                canManageBallot: false,
                canManageChildCommittee: false,
                canManageRecord: false,
                canManageRequest: false,
                canRespondToComment: false,
              }
            : { ...item, [field]: value }
          : item
      )
    );
    setPayload((prevPayload: any) => {
      const existingIndex = prevPayload.findIndex(
        (item: any) => item.membershipPositionId === rowData.memberPositionId
      );
      let updatedEntry = {
        ...prevPayload[existingIndex],
        ...rowData,
        active: true,
        isDirty: true,
        isNew: false,
        membershipPositionId: rowData.memberPositionId,
        readOnly:
          field === "readOnly"
            ? value
            : prevPayload[existingIndex]?.readOnly || rowData.readOnly,
        canVote:
          field === "readOnly" && value
            ? false
            : field === "canVote"
              ? value
              : prevPayload[existingIndex]?.canVote || rowData.canVote,
        canComment:
          field === "readOnly" && value
            ? false
            : field === "canComment"
              ? value
              : prevPayload[existingIndex]?.canComment || rowData.canComment,
        canManageBallot:
          field === "readOnly" && value
            ? false
            : field === "canManageBallot"
              ? value
              : prevPayload[existingIndex]?.canManageBallot ||
                rowData.canManageBallot,
        canManageChildCommittee:
          field === "readOnly" && value
            ? false
            : field === "canManageChildCommittee"
              ? value
              : prevPayload[existingIndex]?.canManageChildCommittee ||
                rowData.canManageChildCommittee,
        canManageRecord:
          field === "readOnly" && value
            ? false
            : field === "canManageRecord"
              ? value
              : prevPayload[existingIndex]?.canManageRecord ||
                rowData.canManageRecord,
        canManageRequest:
          field === "readOnly" && value
            ? false
            : field === "canManageRequest"
              ? value
              : prevPayload[existingIndex]?.canManageRequest ||
                rowData.canManageRequest,
        canRespondToComment:
          field === "readOnly" && value
            ? false
            : field === "canRespondToComment"
              ? value
              : prevPayload[existingIndex]?.canRespondToComment ||
                rowData.canRespondToComment,
      };
      if (existingIndex !== -1) {
        const updatedPayload = [...prevPayload];
        updatedPayload[existingIndex] = updatedEntry;
        return updatedPayload;
      } else {
        return [...prevPayload, updatedEntry];
      }
    });
  };
  const handleSave = async () => {
    try {
      await Promise.all(
        payload.map(async (row: any) => {
          const dataToSend = {
            canVote: row.canVote,
            canComment: row.canComment,
            canManageBallot: row.canManageBallot,
            membershipPositionId: row.memberPositionId,
            canManageChildCommittee: row.canManageChildCommittee,
            canManageRecord: row.canManageRecord,
            canManageRequest: row.canManageRequest,
            canRespondToComment: row.canRespondToComment,
            readOnly: row.readOnly,
          };
          await putMeberData("MemberPositionVotingRight", row.id, dataToSend);
        })
      );
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Data updated successfully",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating data",
          life: 3000,
        });
      }
    }
  };

  // Datatable reference
  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (memberPosition !== null && memberPosition !== undefined) {
    const isEmpty = memberPosition.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }
  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.MemberRoles")}
            </h2>
          </div>

          <div className="p-5 cardBody" ref={tableContainerRef}>
            <DataTable className={tableClass} value={memberPosition}>
              <Column
                field="memberType"
                header={t("configuration.memberType")}
                sortable={true}
                body={(rowData) => (
                  <>
                    <span className="p-column-title">
                      {t("configuration.memberType")}
                    </span>
                    {rowData.memberType}
                  </>
                )}
              />
              <Column
                field="readOnly"
                header={t("configuration.readOnly")}
                body={(rowData: MemberPosition) => (
                  <>
                    <Checkbox
                      checked={rowData.readOnly}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "readOnly",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canVote-${rowData.id}`}
                    />
                  </>
                )}
              />
              <Column
                field="canVote"
                header={t("configuration.canVote")}
                body={(rowData: MemberPosition) => (
                  <>
                    <span className="p-column-title">
                      {t("configuration.canVote")}
                    </span>
                    <Checkbox
                      checked={rowData.canVote}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canVote",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canVote-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
              <Column
                field="canComment"
                header={t("configuration.canComment")}
                body={(rowData: MemberPosition) => (
                  <>
                    <span className="p-column-title">
                      {t("configuration.canVote")}
                    </span>
                    <Checkbox
                      checked={rowData.canComment}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canComment",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canComment-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
              <Column
                field="canManageBallot"
                header={t("configuration.canManageBallot")}
                body={(rowData: MemberPosition) => (
                  <>
                    <span className="p-column-title">
                      {t("configuration.canVote")}
                    </span>
                    <Checkbox
                      checked={rowData.canManageBallot}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canManageBallot",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canManageBallot-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
              <Column
                field="canRespondToComment"
                header={t("configuration.canRespondToComment")}
                body={(rowData: MemberPosition) => (
                  <>
                    <Checkbox
                      checked={rowData.canRespondToComment}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canRespondToComment",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canRespondToComment-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
              <Column
                field="canManageChildCommittee"
                header={t("configuration.canManageChildCommittee")}
                body={(rowData: MemberPosition) => (
                  <>
                    <Checkbox
                      checked={rowData.canManageChildCommittee}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canManageChildCommittee",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canManageChildCommittee-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
              <Column
                field="canManageRecord"
                header={t("configuration.canManageRecord")}
                body={(rowData: MemberPosition) => (
                  <>
                    <Checkbox
                      checked={rowData.canManageRecord}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canManageRecord",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canManageRecord-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
              <Column
                field="canManageRequest"
                header={t("configuration.canManageRequest")}
                body={(rowData: MemberPosition) => (
                  <>
                    <Checkbox
                      checked={rowData.canManageRequest}
                      onChange={(e) =>
                        handleCheckboxChange(
                          rowData,
                          "canManageRequest",
                          e.checked ?? false
                        )
                      }
                      aria-label={`Checkbox-canManageRequest-${rowData.id}`}
                      disabled={rowData.readOnly}
                    />
                  </>
                )}
              />
            </DataTable>
          </div>

          <div className="flex align-items-center justify-content-end p-5 border-top-1 border-gray-200">
            <Button label="Save" onClick={handleSave} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberRolesTab;
