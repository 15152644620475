import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { useLanguageContext } from "../LanguageContext";
import {
  getStandardCommitee,
  getBoardCommitee,
  getSubtierCommitee,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { createOtherRequest, setActiveStep } from "../../slices/createStandard";
import { putData } from "../../services/Standard/apiServiceStandard";
import { setToast } from "../../slices/toastSlice";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  getDefaultOtherFormValue,
  createStandardOtherDetails,
} from "./utills/standardUtills";
interface Option {
  name: string;
  code: string;
}
interface OtherConfigurationProps {
  commiteeData: Array<{ Name: string; Id: string }>;
  hierarchyData?: Array<{ Name: string; Id: string }>;
  accreditationData: Array<{ Name: string; Id: string }>;
  onNext: (data: Record<string, any>) => void;
}
export interface OtherConfigurationRef {
  submit: () => void;
}

const OtherConfiguration = forwardRef<
  OtherConfigurationRef,
  OtherConfigurationProps
>(({ commiteeData, hierarchyData, accreditationData, onNext }, ref) => {
  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );

  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [accredited, setaccredited] = useState<string>(
    createRequestDetail.otherConfiguration.IsAccreditated ? "Yes" : "No"
  );

  const [isStandardJointlyDeveloped, setisStandardJointlyDeveloped] =
    useState<string>(
      createRequestDetail.otherConfiguration.IsJointlyDeveloped ? "Yes" : "No"
    );

  const standCommiteeData = getStandardCommitee(commiteeData, hierarchyData);
  const boardCommiteeData = getBoardCommitee(commiteeData, hierarchyData);
  const getSubtierList = getSubtierCommitee(commiteeData, hierarchyData);
  const [loading, setLoading] = useState(false);

  const defaultFormValues = useMemo(
    () => getDefaultOtherFormValue(createRequestDetail?.otherConfiguration),
    [createRequestDetail?.otherConfiguration]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: defaultFormValues,
  });

  const isAccredited = watch("Accredited");
  const standardDeveloped = watch("IsStandardJointlyDeveloped");

  useEffect(() => {
    reset(defaultFormValues);
  }, [createRequestDetail?.otherConfiguration, reset]);

  const onSubmit = async (data: Record<string, any>) => {
    const requestBody = createStandardOtherDetails(data);
    dispatch(createOtherRequest(requestBody));
    setLoading(true);

    try {
      let updateStandard = await putData(
        "Standard/UpdateOtherConfiguration",
        createRequestDetail.id,
        requestBody
      );
      dispatch(
        setToast({
          message: createRequestDetail.id
            ? t("standard.standardOtherConfigurationUpdate")
            : t("standard.standardOtherConfigurationSuccess"),
          severity: "success",
        })
      );
      dispatch(setActiveStep(2));
      onNext(data);
    } catch (error: any) {
      dispatch(setToast({ message: error?.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit(onSubmit)();
    },
  }));

  return (
    <>
      {loading && <LoadingOverlay visible={loading} />}
      <form>
        <div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold m-0">
                {t("standard.otherConfiguration")}
              </h2>
            </div>

            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3">
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-3">
                    <fieldset className="border-none p-0">
                      <legend className="block font-bold text-input-label p-0 mb-3">
                        {t("standard.accredited")}
                      </legend>
                      <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                          <Controller
                            name="Accredited"
                            control={control}
                            render={({ field }) => (
                              <>
                                <RadioButton
                                  inputId="Accredited1"
                                  name="Accredited"
                                  value="Yes"
                                  checked={field.value === "Yes"}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    setaccredited(e.value);
                                  }} // Bind to react-hook-form
                                />
                                <label htmlFor="Accredited1" className="ml-2">
                                  {t("standard.yes")}
                                </label>
                              </>
                            )}
                          />
                        </div>
                        <div className="flex align-items-center">
                          <Controller
                            name="Accredited"
                            control={control}
                            render={({ field }) => (
                              <>
                                <RadioButton
                                  inputId="Accredited2"
                                  name="Accredited"
                                  value="No"
                                  checked={field.value === "No"}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    setaccredited(e.value);
                                  }} // Bind to react-hook-form
                                />
                                <label htmlFor="Accredited2" className="ml-2">
                                  {t("standard.no")}
                                </label>
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                {isAccredited == "Yes" && (
                  <div className="grid grid-xl align-items-end">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="AccreditationBodyId"
                        className={`block font-bold text-capitalize ${errors.AccreditationBodyId ? " p-error" : "text-input-label"}`}
                      >
                        {t("standard.applicableAccreditationBody")}*
                      </label>
                      <Controller
                        name="AccreditationBodyId"
                        control={control}
                        rules={{
                          required: `${t("standard.applicableAccreditationBody")} ${t("standard.isRequired")}`,
                        }}
                        render={({ field }) => (
                          <Dropdown
                            inputId="AccreditationBodyId"
                            aria-describedby="applicableAccreditationBodyError"
                            {...field}
                            value={field.value}
                            options={accreditationData}
                            optionLabel="Name"
                            optionValue="Id"
                            placeholder={t("standard.select")}
                            className={`w-full ${errors.AccreditationBodyId ? "p-invalid" : ""}`}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                          />
                        )}
                      />
                      <span
                        id="applicableAccreditationBodyError"
                        className={`p-error font-bold text-capitalize ${errors.AccreditationBodyId ? "" : "error-hidden"}`}
                      >
                        {`${errors.AccreditationBodyId?.message}`}
                      </span>
                    </div>
                  </div>
                )}

                <div className="grid grid-xl">
                  <div className="flex flex-column gap-3">
                    <fieldset className="border-none p-0">
                      <legend className="block font-bold text-input-label p-0 mb-3">
                        {t("standard.isStandardJointlyDeveloped")}?
                      </legend>
                      <div className="flex flex-wrap gap-3">
                        <div className="flex align-items-center">
                          <Controller
                            name="IsStandardJointlyDeveloped"
                            control={control}
                            render={({ field }) => (
                              <>
                                <RadioButton
                                  inputId="IsStandardJointlyDeveloped1"
                                  name="IsStandardJointlyDeveloped"
                                  value="Yes"
                                  checked={field.value === "Yes"}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    setisStandardJointlyDeveloped(e.value);
                                  }} // Bind to react-hook-form
                                />
                                <label
                                  htmlFor="IsStandardJointlyDeveloped1"
                                  className="ml-2"
                                >
                                  {t("standard.yes")}
                                </label>
                              </>
                            )}
                          />
                        </div>
                        <div className="flex align-items-center">
                          <Controller
                            name="IsStandardJointlyDeveloped"
                            control={control}
                            render={({ field }) => (
                              <>
                                <RadioButton
                                  inputId="IsStandardJointlyDeveloped2"
                                  name="IsStandardJointlyDeveloped"
                                  value="No"
                                  checked={field.value === "No"}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    setisStandardJointlyDeveloped(e.value);
                                  }} // Bind to react-hook-form
                                />
                                <label
                                  htmlFor="IsStandardJointlyDeveloped2"
                                  className="ml-2"
                                >
                                  {t("standard.no")}
                                </label>
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                {standardDeveloped == "Yes" && (
                  <div className="grid grid-xl align-items-end">
                    <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                      <label
                        htmlFor="withInternalCommittees"
                        className={`block font-bold text-capitalize text-input-label`}
                      >
                        {t("standard.withInternalCommittees")}
                      </label>
                      <Controller
                        name="JointlyDevelopedwithInternalCommitteeIds"
                        control={control}
                        render={({ field }) => (
                          <MultiSelect
                            filter
                            inputId="JointlyDevelopedwithInternalCommitteeIds"
                            aria-describedby="withInternalCommitteesError"
                            {...field}
                            value={field.value}
                            options={commiteeData}
                            optionValue="Id"
                            optionLabel="Name"
                            placeholder={t("standard.select")}
                            className={`w-full ${errors.JointlyDevelopedwithInternalCommitteeIds ? "p-invalid" : ""}`}
                            onChange={(e) => {
                              field.onChange(e.value);
                              //setisStandardJointlyDeveloped(e.value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                    <label
                      htmlFor="jointlyDevelopedwithExternalCommittees"
                      className={`block font-bold text-capitalize text-input-label`}
                    >
                      {t("standard.withExternalCommittees")}
                    </label>
                    <Controller
                      name="jointlyDevelopedwithExternalCommittees"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          id="jointlyDevelopedwithExternalCommittees"
                          {...field}
                          placeholder={t("standard.withExternalCommittees")}
                          className={`w-full`}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold m-0">
                {t("standard.responsibleCommittees")}
              </h2>
            </div>

            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="board"
                      className={`block font-bold text-capitalize text-input-label`}
                    >
                      {t("standard.board")}
                    </label>
                    <Controller
                      name="BoardCommitteeIds"
                      rules={{
                        required: `${t("standard.board")} ${t("standard.isRequired")}`,
                      }}
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          filter
                          inputId="BoardCommitteeIds"
                          aria-describedby="boardError"
                          {...field}
                          value={field.value}
                          options={boardCommiteeData}
                          optionValue="Id"
                          optionLabel="Name"
                          placeholder={t("standard.select")}
                          className={`w-full ${errors.BoardCommitteeIds ? "p-invalid" : ""}`}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                      )}
                    />

                    <span
                      id="boardError"
                      className={`p-error font-bold text-capitalize ${errors.BoardCommitteeIds ? "" : "error-hidden"}`}
                    >
                      {`${errors.BoardCommitteeIds?.message}`}
                    </span>
                  </div>
                </div>

                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="standardCommittee"
                      className={`block font-bold text-capitalize text-input-label"}`}
                    >
                      {t("standard.standardCommittee")}
                    </label>
                    <Controller
                      name="StandardCommitteeIds"
                      control={control}
                      rules={{
                        required: `${t("standard.standardCommittee")} ${t("standard.isRequired")}`,
                      }}
                      render={({ field }) => (
                        <MultiSelect
                          filter
                          inputId="StandardCommitteeIds"
                          aria-describedby="standardCommitteeError"
                          {...field}
                          value={field.value}
                          options={standCommiteeData}
                          optionValue="Id"
                          optionLabel="Name"
                          placeholder={t("standard.select")}
                          className={`w-full ${errors.StandardCommitteeIds ? "p-invalid" : ""}`}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                      )}
                    />
                    <span
                      id="standardCommitteeError"
                      className={`p-error font-bold text-capitalize ${errors.StandardCommitteeIds ? "" : "error-hidden"}`}
                    >
                      {`${errors.StandardCommitteeIds?.message}`}
                    </span>
                  </div>
                </div>

                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="subTier"
                      className={`block font-bold text-capitalize text-input-label`}
                    >
                      {t("standard.subTier")}
                    </label>
                    <Controller
                      name="SubTierCommitteeIds"
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          filter
                          inputId="SubTierCommitteeIds"
                          aria-describedby="subTierError"
                          {...field}
                          value={field.value}
                          options={getSubtierList}
                          optionValue="Id"
                          optionLabel="Name"
                          placeholder={t("standard.select")}
                          className={`w-full ${errors.SubTierCommitteeIds ? "p-invalid" : ""}`}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="primaryResponsibleCommittee"
                      className={`block font-bold text-capitalize text-input-label"}`}
                    >
                      {t("standard.primaryResponsibleCommittee")}
                    </label>
                    <Controller
                      name="PrimaryCommitteeResponsibleCommitteeIds"
                      control={control}
                      rules={{
                        required: `${t("standard.primaryResponsibleCommittee")} ${t("standard.isRequired")}`,
                      }}
                      render={({ field }) => (
                        <Dropdown
                          filter
                          inputId="PrimaryCommitteeResponsibleCommitteeIds"
                          aria-describedby="primaryCommitteeError"
                          {...field}
                          value={field.value}
                          options={commiteeData}
                          optionLabel="Name"
                          optionValue="Id"
                          placeholder={t("standard.select")}
                          className={`w-full`}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                        />
                      )}
                    />
                    <span
                      id="primaryCommitteeError"
                      className={`p-error font-bold text-capitalize ${errors.PrimaryCommitteeResponsibleCommitteeIds ? "" : "error-hidden"}`}
                    >
                      {`${errors.PrimaryCommitteeResponsibleCommitteeIds?.message}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
});

export default OtherConfiguration;
