import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import { MenuItem } from "primereact/menuitem";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { TabPanel, TabView } from "primereact/tabview";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { fetchData, postData, putData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import DataGrid from "../common/DataGrid";
import { formatAllMembershipResponse } from "../../utils/utils";
import { usePermission } from "../auth/Authorization";
import { useLanguageContext } from "../LanguageContext";
import CustomMenu, { getStatusBadge } from "../common/customMenu";

interface MemberList {
  [x: string]: string;
  id: string;
  Committee: string;
  MembershipPosition: string;
  CategoryOfInterest: string;
  NumberofBallotsMissed: string;
  NumberofMeetingMissed: string;
  StartDate: string;
  EndDate: string;
  StatusName: string;
}

const AllMembership: React.FC = () => {
  const [t, i18n] = useTranslation("profile");
  const { selectedLanguage } = useLanguageContext();
  const [selectedReason, setSelectedReason] = useState("");
  const [ballotOrMeetingNumber, setBallotOrMeetingNumber] = useState("");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [loading, setLoading] = useState<boolean>(true);
  const [terminatedThroughValue, setTerminatedThroughValue] = useState<
    string | null
  >(null);
  const navigate = useNavigate();
  const [allMember, setAllMember] = useState<MemberList[]>([]);
  const [search, setSearch] = useState("");
  const userProfileID = localStorage.getItem("userProfileID");
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [actionItems, setActionItems] = useState<MenuItem[]>();
  const [visibleTerminate, setVisibleTerminate] = useState<boolean>(false);
  const [resignValue, setResignValue] = useState("");
  const [validationError, setValidationError] = useState("");
  const [visibleResign, setVisibleResign] = useState<boolean>(false);
  const [memberShipId, setMemberShipId] = useState("");
  const [activeTabIndex, setActiveIndex] = useState<number>(0);
  const toast = useRef<Toast>(null);
  const [terminateValue, setTerminateValue] = useState("");
  const [isTerminateDisabled, setIsTerminateDisabled] = useState(false);
  const [reappointmentData, setReappointmentData] = useState<MemberList[]>([]);
  const [validationErrors, setValidationErrors] = useState({
    terminatedThroughError: "",
    ballotOrMeetingNumberError: "",
    selectedReasonError: "",
    terminateValueError: "",
    remarkError: "",
  });
  //Pagination
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(1);
  const [sortField, setSortField] = useState<string>("");
  const breadcumbItems = [
    { label: t("profile.dashboard"), url: "/dashboard" },
    {
      label:
        userRole === "Member"
          ? t("profile.myMemberships")
          : t("profile.memberships"),
    },
  ];

  const dropdownOptions = [
    { label: "Direct Termination", value: "DirectTermination" },
    { label: "Meeting", value: "Meeting" },
    { label: "Ballot", value: "Ballot" },
  ];
  const { id: expireationTrue } = useParams();
  const committeeMembershipId =
    sessionStorage.getItem("committeeMembershipId") || "";

  const showSuccessTermination = () => {
    toast.current?.show({
      severity: "success",
      summary: t("profile.success"),
      detail: t("profile.memberTerminateSuccessfully"),
      life: 3000,
    });
  };
  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: t("profile.success"),
      detail: t("profile.memberResignSuccessfully"),
      life: 3000,
    });
  };

  useEffect(() => {
    const storedValue = expireationTrue;
    if (storedValue === "expired-True") {
      setActiveIndex(1);
    } else {
      setActiveIndex(0);
    }
  }, []);

  const actionPositionBodyTemplateForAllMembership = (rowData: any) => {
    const isResignDisabled =
      userRole === "Admin" ||
      userRole === "Staff" ||
      rowData.StatusName === "Retired" ||
      rowData.StatusName === "Pending" ||
      rowData.StatusName === "Resigned" ||
      rowData.StatusName === "Terminated";

    const isTerminateDisabled =
      userRole === "Member" ||
      rowData.StatusName === "Retired" ||
      rowData.StatusName === "Pending" ||
      rowData.StatusName === "Resigned" ||
      rowData.StatusName === "Terminated";
    const actionItems: MenuItem[] = [
      {
        label: t("profile.resign"),
        command: () => {
          handleResignButtonClick(rowData.Id);
        },
        disabled: isResignDisabled,
      },
    ];
    let terminationButton = {
      label: t("profile.terminate"),
      command: () => {
        handleTerminateButtonClick(rowData?.Id);
      },
      disabled: isTerminateDisabled,
    };
    if (!isTerminateDisabled) {
      actionItems.push(terminationButton);
    }

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("profile.requestReappointment"),
        command: () => {
          sessionStorage.setItem("reappointment", "true");
          navigate("/member/create");
        },
      },
    ];

    const isActionDisabled =
      userRole === "Admin" ||
      userRole === "Staff" ||
      rowData.StatusName === "Retired" ||
      rowData.StatusName === "Pending" ||
      rowData.StatusName === "Resigned" ||
      rowData.StatusName === "Terminated";

    return (
      <CustomMenu
        actionItems={actionItems}
        rowData={rowData}
        disabled={isActionDisabled}
      />
    );
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
      if (roleName === "Member") {
        setIsTerminateDisabled(true);
      }
    }
  }, [userPermissions]);

  const fetchReappointment = async () => {
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    try {
      let postResponseData;
      switch (userRole) {
        case "Member":
          postResponseData = await fetchData(
            `CommitteeMembership/GridSearch?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileID}&Filters[1].Key=IsExpiring&Filters[1].Value=true&PageIndex=${pageIndex}&PageSize=${pageSize}&SearchText=${debouncedSearchTerm}&SortBy=${sortField}&SortDir=${sortOrderValue}`
          );
          break;
        case "Staff":
          postResponseData = await fetchData(
            `CommitteeMembership/GridSearch?Filters[0].Key=StaffId&Filters[0].Value=${userProfileID}&Filters[1].Key=IsExpiring&Filters[1].Value=true&PageIndex=${pageIndex}&PageSize=${pageSize}&SearchText=${debouncedSearchTerm}&SortBy=${sortField}&SortDir=${sortOrderValue}`
          );
          break;
        case "Admin":
          postResponseData = await fetchData(
            `CommitteeMembership/GridSearch?PageIndex=${pageIndex}&Filters[0].Key=IsExpiring&Filters[0].Value=true&PageSize=${pageSize}&SearchText=${debouncedSearchTerm}&SortBy=${sortField}&SortDir=${sortOrderValue}`
          );
          break;
        default:
          // Handle default case if necessary
          break;
      }
      setReappointmentData(
        formatAllMembershipResponse(postResponseData.Collection)
      );
      setTotalCount(postResponseData.TotalCount);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const setError = useCallback(
    (field: any, errorMessage: any) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage,
      }));
    },
    [validationErrors]
  );

  const handleTerminatedThroughChange = (e: any) => {
    const value = e.value;
    setTerminatedThroughValue(value);
    setBallotOrMeetingNumber("");
    if (value === "Meeting" || value === "Ballot") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        ballotOrMeetingNumberError: "",
        terminatedThroughError: "",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        terminatedThroughError: "",
      }));
    }
  };

  const handleTerminateButtonClick = async (committeeMembershipId: string) => {
    setVisibleTerminate(true);
    setValidationError("");
    setMemberShipId(committeeMembershipId);
  };

  const handleResignButtonClick = async (committeeMembershipId: string) => {
    setVisibleResign(true);
    setValidationError("");
    setMemberShipId(committeeMembershipId);
  };

  const fetchAllMembership = async () => {
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    try {
      let postResponseData;
      switch (userRole) {
        case "Member":
          postResponseData = await fetchData(
            `CommitteeMembership/GridSearch?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileID}&PageIndex=${pageIndex}&PageSize=${pageSize}&SearchText=${debouncedSearchTerm}&SortBy=${sortField}&SortDir=${sortOrderValue}`
          );
          break;
        case "Staff":
          postResponseData = await fetchData(
            `CommitteeMembership/GridSearch?Filters[0].Key=StaffId&Filters[0].Value=${userProfileID}&PageIndex=${pageIndex}&PageSize=${pageSize}&SearchText=${debouncedSearchTerm}&SortBy=${sortField}&SortDir=${sortOrderValue}`
          );
          break;
        case "Admin":
          postResponseData = await fetchData(
            `CommitteeMembership/GridSearch?PageIndex=${pageIndex}&PageSize=${pageSize}&SearchText=${debouncedSearchTerm}&SortBy=${sortField}&SortDir=${sortOrderValue}`
          );
          break;
        default:
          // Handle default case if necessary
          break;
      }
      if (postResponseData) {
        const historyData = formatAllMembershipResponse(
          postResponseData.Collection
        );
        setTotalCount(postResponseData.TotalCount);
        setAllMember(historyData);
      }
      return postResponseData;
    } catch (error) {
      console.error("Error while fetching membership history", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTabIndex) {
      fetchReappointment();
    } else {
      fetchAllMembership();
    }
  }, [
    userRole,
    pageIndex,
    pageSize,
    debouncedSearchTerm,
    sortOrder,
    sortField,
    activeTabIndex,
  ]);

  const handleTerminate = useCallback(async () => {
    let hasError = false;

    if (!terminateValue.trim()) {
      setError("remarkError", t("profile.reasonForTermination"));
      hasError = true;
    } else {
      setError("remarkError", "");
    }
    if (!terminatedThroughValue) {
      setError(
        "terminatedThroughError",
        t("profile.terminatedThroughRequired")
      );
      hasError = true;
    } else {
      setError("terminatedThroughError", "");
    }

    if (
      (terminatedThroughValue === "Ballot" ||
        terminatedThroughValue === "Meeting") &&
      !ballotOrMeetingNumber.trim()
    ) {
      setError(
        "ballotOrMeetingNumberError",
        terminatedThroughValue === "Meeting"
          ? t("profile.meetingNumberRequired")
          : t("profile.ballotNumberRequired")
      );
      hasError = true;
    } else {
      setError("ballotOrMeetingNumberError", "");
    }

    if (!selectedReason) {
      setError("selectedReasonError", t("profile.selectReasonForTermination"));
      hasError = true;
    } else {
      setError("selectedReasonError", "");
    }

    if (hasError) return;
    const membershipTerminateBallotNumber =
      terminatedThroughValue === "Ballot" ? ballotOrMeetingNumber : null;
    const membershipTerminateMeetingNumber =
      terminatedThroughValue === "Meeting" ? ballotOrMeetingNumber : null;

    const reasonForTermination = {
      reasonForTerminate: terminateValue,
      membershipTerminateBallotNumber: membershipTerminateBallotNumber,
      membershipTerminateMeetingNumber: membershipTerminateMeetingNumber,
      membershipTerminatIsLackOfParticipation:
        selectedReason === "lackOfParticipation",
      membershipTerminatIsDisciplinaryAction:
        selectedReason === "disciplinaryAction",
    };
    const putDataResponse = await putData(
      `CommitteeMembership/TerminateCommitteeMembership?committeeMembershipId=${memberShipId}`,
      undefined,
      reasonForTermination
    );
    setVisibleTerminate(false);
    setValidationErrors({
      terminatedThroughError: "",
      ballotOrMeetingNumberError: "",
      selectedReasonError: "",
      terminateValueError: "",
      remarkError: "",
    });
    setSelectedReason("");
    setBallotOrMeetingNumber("");
    setTerminatedThroughValue("");
    setTerminateValue("");
    setTimeout(() => {
      showSuccessTermination();
    }, 1000);
    navigate("/all-membership");
    fetchAllMembership();
  }, [
    terminateValue,
    terminatedThroughValue,
    ballotOrMeetingNumber,
    selectedReason,
  ]);

  const handleResign = async () => {
    if (!resignValue.trim()) {
      setValidationError(`${t("profile.reasonForResignation")}`);
      return;
    }

    const data = {
      committeeMembershipId: memberShipId,
      comments: resignValue,
    };
    const putDataResponse = await postData(
      `MembershipRequest/MembershipRequestResign`,
      data
    );
    setVisibleResign(false);
    setTimeout(() => {
      showSuccess();
    }, 1000);
    navigate("/all-membership");
    fetchAllMembership();
  };

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 500); // 500ms delay
    // Clear the timeout if searchTerm changes (i.e. user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const changeTab = (e: any) => {
    setActiveIndex(e.index);
    setPageIndex(1);
  };

  const footerContentTerminate = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("profile.cancel")}
        onClick={() => {
          setVisibleTerminate(false);
          setValidationErrors({
            terminatedThroughError: "",
            ballotOrMeetingNumberError: "",
            selectedReasonError: "",
            terminateValueError: "",
            remarkError: "",
          });
          setSelectedReason("");
          setBallotOrMeetingNumber("");
          setTerminatedThroughValue("");
          setTerminateValue("");
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("profile.terminate")}
        onClick={handleTerminate}
        className="button-md"
      />
    </div>
  );
  const footerContentResign = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("profile.cancel")}
        onClick={() => {
          setVisibleResign(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("profile.resign")}
        onClick={handleResign}
        className="button-md"
      />
    </div>
  );
  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {userRole === "Member"
              ? t("profile.myMemberships")
              : t("profile.memberships")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("profile.myMembershipHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView
            activeIndex={activeTabIndex}
            onTabChange={changeTab}
            scrollable
          >
            <TabPanel
              header={
                userRole === "Member"
                  ? t("profile.myMemberships")
                  : t("profile.memberships")
              }
            >
              <div className="flex">
                <IconField iconPosition="left">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t("profile.keywordSearch")}
                    aria-label={t("profile.keywordSearch")}
                  />
                </IconField>
              </div>
              <div className="flex flex-column gap-2 mt-5">
                <DataGrid
                  loading={loading}
                  products={allMember}
                  columns={[
                    {
                      field: "CommitteeName",
                      header: t("profile.committees"),
                    },
                    {
                      field: "MemberName",
                      header: t("profile.memberName"),
                    },
                    {
                      field: "MemberPosition",
                      header: t("profile.position"),
                    },
                    {
                      field: "CategoryOfInterest",
                      header: t("profile.categoryOfInterest"),
                    },
                    {
                      field: "BallotAttended",
                      header: t("profile.ballotAttended"),
                    },
                    {
                      field: "MeetingAttended",
                      header: t("profile.meetingAttended"),
                    },
                    {
                      field: "StartDate",
                      header: t("profile.startDate"),
                    },
                    {
                      field: "EndDate",
                      header: t("profile.endDate"),
                    },
                    {
                      field: "StatusName",
                      header: t("profile.status"),
                      body: (rowData: any) =>
                        getStatusBadge(rowData.StatusName),
                    },
                    {
                      field: "Action",
                      header: t("profile.action"),
                      body: actionPositionBodyTemplateForAllMembership,
                      sortable: false,
                    },
                  ]}
                  showCheckbox={false}
                  actionItems={actionItems}
                  totalRecords={totalCount}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  onPageChange={pageHandler}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortChange={sortHandler}
                />
                <Dialog
                  id="visibleWithdrawId"
                  header={t("profile.wantToResign")}
                  visible={visibleResign}
                  style={{ width: "50vw" }}
                  breakpoints={{
                    "960px": "75vw",
                    "641px": "90vw",
                    "400px": "98vw",
                  }}
                  onHide={() => setVisibleResign(false)}
                  footer={footerContentResign}
                  draggable={false}
                  className="custom-dialog hide-close"
                >
                  <div className="flex flex-column gap-2 w-full">
                    <label
                      htmlFor="requestResign"
                      className={`block font-light font-bold text-capitalize ${
                        validationError ? "p-error" : ""
                      }`}
                    >
                      {" "}
                      {t("profile.reasonForResign")}
                    </label>
                    <InputTextarea
                      id="requestResign"
                      value={resignValue}
                      onChange={(e) => setResignValue(e.target.value)}
                      rows={5}
                      cols={30}
                      className={validationError ? "p-invalid" : ""}
                    />
                    <span
                      className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
                    >
                      {validationError}
                    </span>
                  </div>
                </Dialog>

                <Dialog
                  id="wantToTerminate"
                  header={t("profile.wantToTerminate")}
                  visible={visibleTerminate}
                  style={{ width: "50vw" }}
                  breakpoints={{
                    "960px": "75vw",
                    "641px": "90vw",
                    "400px": "98vw",
                  }}
                  draggable={false}
                  onHide={() => {
                    setValidationErrors({
                      terminatedThroughError: "",
                      ballotOrMeetingNumberError: "",
                      selectedReasonError: "",
                      terminateValueError: "",
                      remarkError: "",
                    });
                    setSelectedReason("");
                    setBallotOrMeetingNumber("");
                    setTerminatedThroughValue("");
                    setTerminateValue("");
                    setVisibleTerminate(false);
                  }}
                  footer={footerContentTerminate}
                  className="custom-dialog hide-close"
                  ariaCloseIconLabel="Close"
                >
                  <div className="flex flex-column gap-3 w-full">
                    {/* Terminated Through Dropdown */}
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 sm:col-6 col-12">
                        <label
                          htmlFor="terminatedThrough"
                          className={`block font-light font-bold text-capitalize ${
                            validationErrors.terminatedThroughError
                              ? "p-error"
                              : ""
                          }`}
                        >
                          {t("profile.terminatedThrough")}
                        </label>
                        <Dropdown
                          inputId="terminatedThrough"
                          value={terminatedThroughValue}
                          options={dropdownOptions}
                          onChange={handleTerminatedThroughChange}
                          placeholder={t("profile.selectTerminatedThrough")}
                          className={`w-full ${validationErrors.terminatedThroughError ? "p-invalid" : ""}`}
                        />
                        {validationErrors.terminatedThroughError && (
                          <small className="p-error font-bold">
                            {validationErrors.terminatedThroughError}
                          </small>
                        )}
                      </div>

                      {(terminatedThroughValue === "Meeting" ||
                        terminatedThroughValue === "Ballot") && (
                        <div className="flex flex-column gap-2 sm:col-6 col-12">
                          <label
                            htmlFor="ballotOrMeetingNumber"
                            className={`block font-light font-bold text-capitalize ${
                              validationErrors.ballotOrMeetingNumberError
                                ? "p-error"
                                : ""
                            }`}
                          >
                            {terminatedThroughValue === "Meeting"
                              ? t("profile.meetingNumber")
                              : t("profile.ballotNumber")}
                          </label>
                          <InputText
                            id="ballotOrMeetingNumber"
                            value={ballotOrMeetingNumber}
                            onChange={(e) => {
                              setBallotOrMeetingNumber(e.target.value);
                              setValidationErrors((prevErrors) => ({
                                ...prevErrors,
                                ballotOrMeetingNumberError: "",
                              }));
                            }}
                            className={`w-full ${validationErrors.ballotOrMeetingNumberError ? "p-invalid" : ""}`}
                          />
                          {validationErrors.ballotOrMeetingNumberError && (
                            <small className="p-error font-bold">
                              {validationErrors.ballotOrMeetingNumberError}
                            </small>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Radio Buttons */}
                    <div className="grid grid-xl">
                      <div className="col-12">
                        <label
                          htmlFor="requestTerminate"
                          className={`block font-light font-bold text-capitalize ${
                            validationErrors.selectedReasonError
                              ? "p-error"
                              : ""
                          }`}
                        >
                          {t("profile.reasonForTerminate")}
                        </label>
                      </div>
                      <div className="flex col-12">
                        <fieldset className="border-none p-0">
                          <div className="flex flex-wrap gap-3 column-gap-5">
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="lackOfParticipation"
                                value="lackOfParticipation"
                                checked={
                                  selectedReason === "lackOfParticipation"
                                }
                                onChange={(e) => {
                                  setSelectedReason(e.value);
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    selectedReasonError: "",
                                  }));
                                }}
                              />
                              <label
                                htmlFor="lackOfParticipation"
                                className="ml-2 font-bold"
                              >
                                {t("profile.basedOnLackOfParticipation")}
                              </label>
                            </div>
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="disciplinaryAction"
                                value="disciplinaryAction"
                                checked={
                                  selectedReason === "disciplinaryAction"
                                }
                                onChange={(e) => {
                                  setSelectedReason(e.value);
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    selectedReasonError: "",
                                  }));
                                }}
                              />
                              <label
                                htmlFor="disciplinaryAction"
                                className="ml-2 font-bold"
                              >
                                {t("profile.basedOnDisciplinaryAction")}
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      {validationErrors.selectedReasonError && (
                        <div className="col-12">
                          <small className="p-error font-bold">
                            {validationErrors.selectedReasonError}
                          </small>
                        </div>
                      )}
                    </div>

                    {/* Reason for Termination Label and Textarea */}
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 col-12">
                        <label
                          htmlFor="requestTerminate"
                          className={`block font-light font-bold text-capitalize ${
                            validationErrors.remarkError ? "p-error" : ""
                          }`}
                        >
                          {t("profile.remarks")}
                        </label>
                        <InputTextarea
                          id="requestTerminate"
                          value={terminateValue}
                          onChange={(e) => {
                            setTerminateValue(e.target.value);
                            setValidationErrors((prevErrors) => ({
                              ...prevErrors,
                              remarkError: "",
                            }));
                          }}
                          className={
                            validationErrors.remarkError ? "p-invalid" : ""
                          }
                          rows={5}
                          cols={30}
                        />
                        <span
                          className={`p-error font-bold text-capitalize ${
                            validationErrors.remarkError ? "" : "error-hidden"
                          }`}
                        >
                          {validationErrors.remarkError}
                        </span>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </TabPanel>
            <TabPanel header={t("profile.pendingReappointment")}>
              <div className="flex">
                <IconField iconPosition="left">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t("profile.keywordSearch")}
                    aria-label={t("profile.keywordSearch")}
                  />
                </IconField>
              </div>
              <div className="flex flex-column gap-2 mt-5">
                <DataGrid
                  loading={loading}
                  products={reappointmentData}
                  columns={[
                    {
                      field: "CommitteeName",
                      header: t("profile.committees"),
                    },
                    {
                      field: "MemberName",
                      header: t("profile.memberName"),
                    },
                    {
                      field: "MemberPosition",
                      header: t("profile.position"),
                    },
                    {
                      field: "CategoryOfInterest",
                      header: t("profile.categoryOfInterest"),
                    },
                    {
                      field: "BallotAttended",
                      header: t("profile.ballotAttended"),
                    },
                    {
                      field: "MeetingAttended",
                      header: t("profile.meetingAttended"),
                    },
                    {
                      field: "StartDate",
                      header: t("profile.startDate"),
                    },
                    {
                      field: "EndDate",
                      header: t("profile.endDate"),
                    },
                    {
                      field: "StatusName",
                      header: t("profile.status"),
                      body: (rowData: any) =>
                        getStatusBadge(rowData.StatusName),
                    },
                    {
                      field: "Action",
                      header: t("profile.action"),
                      body: actionPositionBodyTemplate,
                      sortable: false,
                    },
                  ]}
                  showCheckbox={false}
                  pageIndex={pageIndex}
                  totalRecords={totalCount}
                  pageSize={pageSize}
                  onPageChange={pageHandler}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortChange={sortHandler}
                />
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default AllMembership;
