import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import DataGrid from "../common/DataGrid";
import { setActiveStep } from "../../slices/createStandard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface ContributingCommitteeTabProps {
  showEditButton?: boolean;
}

const ContributingCommitteeTab: React.FC<ContributingCommitteeTabProps> = ({
  showEditButton,
}) => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();

  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );

  const ContibutingCommitee = createRequestDetail.contributingCommittee;

  const updatedContributeInfo = ContibutingCommitee.map((element: any) => {
    return {
      CommitteeName: element.CommitteeName,
      StaffName: element.StaffName,
      CommitteeLevel: element.CommitteeLevel,
      CanVote: element.CanVote ? "Yes" : "No",
      MustVote: element.MustVote ? "Yes" : "No",
      CanComment: element.CanComment ? "Yes" : "No",
    };
  });

  const dispatch = useDispatch();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const columns = useMemo(
    () => [
      {
        field: "CommitteeName",
        header: t("standard.committeeName"),
        sortable: false,
      },
      {
        field: "StaffName",
        header: t("standard.primaryStaff"),
        sortable: false,
      },
      {
        field: "CommitteeLevel",
        header: t("standard.committeeLevel"),
        sortable: false,
      },
      { field: "CanVote", header: t("standard.canVote"), sortable: false },
      { field: "MustVote", header: t("standard.mustVote"), sortable: false },
      {
        field: "CanComment",
        header: t("standard.canComment"),
        sortable: false,
      },
    ],
    [t]
  );

  return (
    <>
      <div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("standard.contributingCommittee")}
            </h2>
            {showEditButton && (
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => {
                  dispatch(setActiveStep(2));
                }}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("standard.edit")}</span>
              </Button>
            )}
          </div>

          <div className="p-5 cardBody">
            <DataTable
              value={updatedContributeInfo}
              tableStyle={{ minWidth: "50rem" }}
            >
              {columns.map((col: any, i: any) => (
                <Column key={col.field} field={col.field} header={col.header} />
              ))}
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributingCommitteeTab;
