import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { BallotCommitteeReports } from "../types/BallotReport";
import BallotReportCommitteeCard from "./BallotReportCommitteeCard";

interface BallotReportRecordProps {
  committeeReports: BallotCommitteeReports[] | [];
  isBallotClosure: boolean;
  primaryCommittee: string | null | undefined;
  ballotCommitteeStatusOptions?: any[];
  selectedCommitteeBallotStatus?: any;
  setActiveIndex?: any;
  handleCommitteeStatusChange?: (
    BallotRecordId: string,
    BallotCommitteeId: string,
    value: string,
    RecordId: string,
    CommitteeId: string
  ) => void;
  isPrint?: boolean;
  isReport?: boolean;
}

const BallotCommitteeReport: React.FC<BallotReportRecordProps> = ({
  committeeReports = [],
  primaryCommittee = "",
  isBallotClosure,
  ballotCommitteeStatusOptions,
  handleCommitteeStatusChange,
  selectedCommitteeBallotStatus = [],
  setActiveIndex,
  isPrint = false,
  isReport = false,
}) => {
  const [t] = useTranslation("ballot");
  const getRecordStatusId = useCallback(
    (RecordId: string, CommitteeId: string) => {
      if (selectedCommitteeBallotStatus.length > 0) {
        const reversedStatuses = [...selectedCommitteeBallotStatus].reverse();
        const status = reversedStatuses.find(
          (status: any) =>
            status.recordId === RecordId && status.committeeId === CommitteeId
        );
        return status ? status.ballotCommitteeRecordStatusId : null;
      }
      return null;
    },
    [selectedCommitteeBallotStatus]
  );

  // Refactor Dropdown logic into a separate function
  const renderCommitteeBallotStatusDropdown = useCallback(
    (rowData: any) => (
      <div className="card flex justify-content-center">
        <Dropdown
          value={getRecordStatusId(rowData.RecordId, rowData.CommitteeId)}
          options={ballotCommitteeStatusOptions}
          optionLabel="name"
          placeholder="Select a status"
          className="w-full md:w-14rem"
          onChange={(e) =>
            handleCommitteeStatusChange?.(
              rowData.BallotRecordId,
              rowData.BallotCommitteeId,
              e.value,
              rowData.RecordId,
              rowData.CommitteeId
            )
          }
        />
      </div>
    ),
    [
      getRecordStatusId,
      ballotCommitteeStatusOptions,
      handleCommitteeStatusChange,
    ]
  );

  const columns = useMemo(() => {
    return [
      ...(isBallotClosure && handleCommitteeStatusChange
        ? [
            {
              field: "newCommitteeballotStatus",
              header: `${t("ballot.approved")} ?`,
              body: renderCommitteeBallotStatusDropdown,
            },
          ]
        : []),
      { field: "RecordNumber", header: t("ballot.recordId"), sortable: false },
      {
        field: "ApplicantName",
        header: t("ballot.applicantName"),
        sortable: false,
      },
      {
        field: "Cirteriameet",
        header: t("ballot.criteriaMet"),
        body: (rowData: any) => rowData.Cirteriameet ?? "-",
        sortable: false,
      },
      {
        field: "BallotPrimaryCommitteeName",
        header: t("ballot.committeeAppliedFor"),
        sortable: false,
      },
      {
        field: "AppliedPosition",
        header: t("ballot.positionAppliedFor"),
        sortable: false,
      },
      {
        field: "Term",
        header: t("ballot.termYear"),
        body: (rowData: any) => rowData.Term ?? "0",
        sortable: false,
      },
      {
        field: "Approved",
        header: t("ballot.approved"),
        body: (rowData: any) => rowData.Approved ?? "-",
        sortable: false,
      },
      {
        field: "DisApproved",
        header: t("ballot.disapproved"),
        body: (rowData: any) => rowData.DisApproved ?? "-",
        sortable: false,
      },
      {
        field: "Abstained",
        header: t("ballot.abstained"),
        body: (rowData: any) => rowData.Abstained ?? "-",
        sortable: false,
      },
      {
        field: "NotReturn",
        header: t("ballot.notReturn"),
        body: (rowData: any) => rowData.NotReturn ?? "-",
        sortable: false,
      },
      {
        field: "No_Comment",
        header: t("ballot.commentsResponded"),
        body: (rowData: any) => rowData.No_Comment ?? "-",
        sortable: false,
      },
      ...(isReport
        ? [
            {
              field: "IsApproved",
              header: t("ballot.approved"),
              body: (rowData: any) => rowData.IsApproved ?? "-",
              sortable: false,
            },
          ]
        : []),
    ];
  }, [
    isBallotClosure,
    handleCommitteeStatusChange,
    selectedCommitteeBallotStatus,
    renderCommitteeBallotStatusDropdown,
  ]);

  const columnsReviewcomment = useMemo(() => {
    return [
      ...(isBallotClosure && handleCommitteeStatusChange
        ? [
            {
              field: "newCommitteeballotStatus",
              header: `${t("ballot.approved")} ?`,
              body: renderCommitteeBallotStatusDropdown,
              sortable: false,
            },
          ]
        : []),
      { field: "RecordNumber", header: t("ballot.recordId"), sortable: false },
      {
        field: "ApplicantName",
        header: t("ballot.applicantName"),
        sortable: false,
      },
      {
        field: "Cirteriameet",
        header: t("ballot.criteriaMet"),
        body: (rowData: any) => rowData.Cirteriameet ?? "-",
        sortable: false,
      },
      {
        field: "BallotPrimaryCommitteeName",
        header: t("ballot.committeeAppliedFor"),
        sortable: false,
      },
      {
        field: "AppliedPosition",
        header: t("ballot.positionAppliedFor"),
        sortable: false,
      },
      {
        field: "Term",
        header: t("ballot.termYear"),
        body: (rowData: any) => rowData.Term ?? "0",
        sortable: false,
      },
      {
        field: "Substaintive",
        header: t("ballot.substantive"),
        body: (rowData: any) => rowData.Substaintive ?? "-",
        sortable: false,
      },
      {
        field: "Non_Substaintive",
        header: t("ballot.nonSubstantive"),
        body: (rowData: any) => rowData.Non_Substaintive ?? "-",
        sortable: false,
      },
      {
        field: "No_Comment",
        header: t("ballot.commentsResponded"),
        body: (rowData: any) => rowData.No_Comment ?? "-",
        sortable: false,
      },
      {
        field: "NotReturn",
        header: t("ballot.notReturn"),
        body: (rowData: any) => rowData.NotReturn ?? "-",
        sortable: false,
      },
      ...(isReport
        ? [
            {
              field: "IsApproved",
              header: t("ballot.approved"),
              body: (rowData: any) => rowData.IsApproved ?? "-",
              sortable: false,
            },
          ]
        : []),
    ];
  }, [
    isBallotClosure,
    handleCommitteeStatusChange,
    selectedCommitteeBallotStatus,
    renderCommitteeBallotStatusDropdown,
  ]);

  const reviewAndCommentReports = committeeReports.filter(
    (report) => report.ForReviewAndComment
  );
  return (
    <>
      <div>
        <BallotReportCommitteeCard
          title={t("ballot.committeeForBalloting")}
          committeeReports={committeeReports}
          filterCondition={(report) => !report.ForReviewAndComment}
          primaryCommittee={primaryCommittee}
          columns={columns}
          setActiveIndex={setActiveIndex}
          isPrint={isPrint}
        />
        {reviewAndCommentReports.length > 0 && (
          <BallotReportCommitteeCard
            title={t("ballot.committeeForReviewingAndCommenting")}
            committeeReports={committeeReports}
            filterCondition={(report) => report.ForReviewAndComment}
            primaryCommittee={primaryCommittee}
            columns={columnsReviewcomment}
            setActiveIndex={setActiveIndex}
            isPrint={isPrint}
          />
        )}
      </div>
    </>
  );
};

export default BallotCommitteeReport;
