import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { buildQueryParams, formatDate } from "../../../utils/utils";
import { fetchData, postData } from "../../../services/Ballot/apiservice";
import { ballotDetails } from "../../../slices/ballotDetails";
import { BALLOT_CLOSE, BALLOT_RECIRCULATION } from "../common/constants";
import { getTableSelectedData, queryStringHandler } from "../common/utils";
import { BallotDetails, DropdownOption } from "../common/type";
import { setToast } from "../../../slices/toastSlice";

type HandleChangeType = (value: string, label: string) => void;
type OnSubmitType = (data: { [key: string]: any }) => void;

const SearchRecordForm = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const toast = useRef<Toast>(null);
  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const dispatch = useDispatch();
  const {
    primaryCommitteOptions,
    ballotTypeOptions,
    ballotSubTypeOptions,
    ballotIdOptions,
    ballotLevelOptions,
    recordTypeOptions,
    recordSubTypeOptions,
    positionAppliedOptions,
    isEdit,
    selectedRecordValues,
  } = useSelector((state: any) => state.ballotSelectRecords);
  const {
    accreditationBodySelect,
    primaryCommitteSelect,
    ballotTypeSelect,
    ballotSubTypeSelect,
    ballotIdSelect,
    ballotLevelSelect,
    recordTypeSelect,
    recordSubTypeSelect,
    positionAppliedSelect,
  } = useSelector(
    (state: any) => state.ballotSelectRecords.selectedRecordValues
  );
  const { ballotStatusList, ballotDetailsInfo } = useSelector(
    (state: any) => state.ballotDetails
  );
  let ballotId = ballotDetailsInfo.id;
  const [ballotCreated, setBallotCreated] = useState<boolean>(false);
  const [isDummyOptionSelected, setIsDummyOptionSelected] =
    useState<boolean>(false);
  const [filters, setFilters] = useState<any>([]);
  let ballotRecirculationId = ballotDetailsInfo.recirculationBallotId;
  let isdisabled = ballotRecirculationId ? true : false;
  const queryParamaHandler = () => {
    const filterOptions = [
      { key: "accreditationbodyid", value: accreditationBodySelect?.id },
      { key: "primarycommitteeid", value: primaryCommitteSelect?.id },
      { key: "ballottypeid", value: ballotTypeSelect?.id },
      { key: "ballotlevelid", value: ballotLevelSelect?.id },
      { key: "recordtypeid", value: recordTypeSelect?.id },
      { key: "recordsubtypeid", value: recordSubTypeSelect?.id },
      { key: "membershippositionid", value: positionAppliedSelect?.id },
    ];
    filterOptions.forEach(({ key, value }) => {
      if (value) {
        setFilters((prevState: any) => {
          const existingIndex = prevState.findIndex(
            (filter: any) => filter.key === key
          );

          if (existingIndex !== -1) {
            // Update existing key
            const updatedFilters = [...prevState];
            updatedFilters[existingIndex].value = value;
            return updatedFilters;
          } else {
            // Add new key-value pair
            return [...prevState, { key, value }];
          }
        });
      }
    });
  };
  const getRecirculationBallotOptions = async (querystr: string) => {
    try {
      const ballotIdOptions = await fetchData(
        `Ballot/GetBallotEligibleForRecurculation?${querystr}`
      );
      if (ballotRecirculationId) {
        ballotIdOptions.Collection.push({
          BallotId: ballotRecirculationId,
          BallotNumber: ballotDetailsInfo.recirculationBallotNumber,
        });
      }
      dispatch(
        ballotSelectRecords.initialRecordValues({
          options: ballotIdOptions,
          label: "ballotid",
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      accreditationBodyValue: accreditationBodySelect?.value,
      primaryCommitteeValue: primaryCommitteSelect?.value,
      ballotTypeValue: ballotTypeSelect?.value,
      ballotSubTypeValue: ballotSubTypeSelect?.value,
      ballotIdValue: ballotIdSelect?.value,
      ballotLevelValue: ballotLevelSelect?.value,
      recordTypeValue: recordTypeSelect?.value,
      recordSubTypeValue: recordSubTypeSelect?.value,
      positionAppliedValue: positionAppliedSelect?.value,
    },
  });
  const selectedBallotIdValue = watch("ballotIdValue");
  const handleChange: HandleChangeType = (value, label) => {
    dispatch(
      ballotSelectRecords.selectedRecordValues({ target: value, label })
    );
  };
  const onSubmit: OnSubmitType = (data) => {
    for (const [key, value] of Object.entries(data)) {
      handleChange(value as string, key);
    }
    setBallotCreated(true);
  };
  // Search Query Params
  const paramsFilters = [
    { key: "ballotid", value: ballotId },
    { key: "committeeid", value: primaryCommitteSelect },
    { key: "recordtypeid", value: recordTypeSelect },
    { key: "positionid", value: positionAppliedSelect },
  ];
  let idx = 0;
  const params = paramsFilters.reduce((acc: any, filter: any) => {
    if (filter?.value?.id) {
      acc[`Filters[${idx}].Key`] = filter.key;
      acc[`Filters[${idx}].Value`] = filter.value.id;
      idx++;
    }
    return acc;
  }, {});
  const queryString = buildQueryParams(params);
  const searchRecordhandler = useCallback(
    async (filterOptionUpdate = false) => {
      try {
        const apiUrl = `BallotRecordsSearch?${queryString}&PageIndex=1`;
        const searchRecordData = await fetchData("BallotRecord", apiUrl);
        let selectedRecordsData = { collection: [] };
        try {
          if (ballotId) {
            selectedRecordsData = await fetchData(
              `BallotRecord/GetListByParentId?parentId=${ballotId}`
            );
          }
        } catch (error) {
          console.log(error);
          selectedRecordsData = { collection: [] };
        }
        const tableProducts = searchRecordData.Collection.filter(
          (record: any) =>
            record?.RecordStatusDisplayName === "Open" ||
            record?.RecordStatusDisplayName === "Reopen"
        ).map((record: any) => {
          return {
            id: record?.RecordId,
            recordNumber: record?.RecordNumber,
            applicantName: record?.RecordUserProfileDisplayName,
            applicantId: record?.RecordUserProfileId,
            committeeAppliedFor: record?.RecordCommitteeName,
            committeeAppliedId: record?.RecordCommitteeId,
            positionAppliedFor: record?.RecordMemberPositionDisplayName,
            positionId: record?.RecordMemberPositionId,
            categoryOfInterest: record?.RecordCategoryOfInterestDisplayName,
            categoryOfInterestId: record?.RecordCategoryOfInterestId,
            requestedOn: formatDate(record?.CreatedDate),
            statusName: record?.RecordStatusDisplayName,
            createdDate: record?.CreatedDate,
            ModifiedDate: record?.ModifiedDate,
          };
        });

        const tableSelectedData = getTableSelectedData(selectedRecordsData);
        const formattedData = tableProducts.map((record: any) => {
          const matchedObj = tableSelectedData?.find(
            (recordSelect: any) => recordSelect.id === record.id
          );
          return {
            id: record?.id,
            recordNumber: record?.recordNumber,
            ballotRecordId: matchedObj ? matchedObj.ballotRecordId : null,
            applicantName: record.applicantName,
            applicantId: record?.applicantId,
            committeeAppliedFor: record?.committeeAppliedFor,
            committeeAppliedId: record?.committeeAppliedId,
            positionAppliedFor: record?.positionAppliedFor,
            positionId: record?.positionId,
            categoryOfInterest: record?.categoryOfInterest,
            categoryOfInterestId: record?.categoryOfInterestId,
            requestedOn: formatDate(record?.requestedOn),
            statusName: record?.statusName,
            createdDate: record?.createdDate,
            ModifiedDate: record?.ModifiedDate,
          };
        });
        dispatch(
          ballotSelectRecords.addRecordTableData({
            rowTableData: formattedData,
          })
        );
        if (!filterOptionUpdate) {
          dispatch(
            ballotSelectRecords.addRecordTableFilterData({
              rowTableData: formattedData,
            })
          );
        }
        updateRecordSelectionHandler(tableSelectedData);
      } catch (err) {
        console.log(err, "err");
      }
    },
    [queryString, dispatch]
  );
  const onRecirculationRecordhandler = async () => {
    try {
      const apiUrl = `BallotRecordsSearch?${queryString}&PageIndex=1`;
      const searchRecordData = await fetchData("BallotRecord", apiUrl);
      const selectedRecordsData = await fetchData(
        `BallotRecord/GetListByParentId?parentId=${ballotRecirculationId}`
      );
      const tableProducts = searchRecordData.Collection.filter(
        (record: any) =>
          record?.RecordStatusDisplayName === "Open" ||
          record?.RecordStatusDisplayName === "Reopen"
      ).map((record: any) => {
        return {
          id: record.RecordId,
          recordNumber: record.RecordNumber,
          applicantName: record.RecordUserProfileDisplayName,
          positionAppliedFor: record.RecordMemberPositionDisplayName,
          committeeAppliedFor: record.RecordCommitteeName,
          categoryOfInterest: record.RecordCategoryOfInterestDisplayName,
          requestedOn: formatDate(record.CreatedDate),
          statusName: record.RecordStatusDisplayName,
          createdDate: record.CreatedDate,
          ModifiedDate: record.ModifiedDate,
        };
      });

      const tableSelectedData = selectedRecordsData.collection
        .filter(
          (record: any) =>
            record?.record?.recordStatus?.statusName === "Open" ||
            record?.record?.recordStatus?.statusName === "Reopen"
        )
        .map((record: any) => {
          return {
            id: record.record?.id,
            recordNumber: record.record?.recordNumber,
            ballotRecordId: null,
            applicantName: record.record?.userProfile?.firstname,
            committeeAppliedFor: record.record?.committee?.name,
            positionAppliedFor: record.record?.membershipPosition?.positionName,
            categoryOfInterest: record.record?.categoryOfInterest?.categoryName,
            requestedOn: formatDate(record.record?.createdDate),
            statusName: record.record?.recordStatus?.statusName,
            createdDate: record.createdDate,
            ModifiedDate: record.modifiedDate,
          };
        });
      const filteredRecordData = tableSelectedData.filter(
        (item: any) =>
          item.statusName === "Open" || item.statusName === "Reopen"
      );
      const formattedData = tableProducts.map((record: any) => {
        const matchedObj = filteredRecordData.find(
          (recordSelect: any) => recordSelect.id === record.id
        );
        return {
          id: record.id,
          recordNumber: record.recordNumber,
          ballotRecordId: matchedObj ? matchedObj.ballotRecordId : null,
          applicantName: record.applicantName,
          committeeAppliedFor: record.committeeAppliedFor,
          positionAppliedFor: record.positionAppliedFor,
          categoryOfInterest: record.categoryOfInterest,
          requestedOn: formatDate(record.requestedOn),
          statusName: record.statusName,
          createdDate: record.createdDate,
          ModifiedDate: record.ModifiedDate,
        };
      });
      if (ballotRecirculationId) {
        dispatch(
          ballotSelectRecords.addRecordTableData({
            rowTableData: formattedData,
          })
        );
      }
      updateRecordSelectionHandler(filteredRecordData);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const updateRecordSelectionHandler = (tableRecordData: any) => {
    tableRecordData.forEach((data: any) => {
      dispatch(
        ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
      );
    });
  };
  useEffect(() => {
    if (ballotCreated) {
      (async () => {
        await searchRecordhandler();
        if (!isEdit && ballotRecirculationId) {
          await onRecirculationRecordhandler();
        }
      })();
    }
  }, [queryString, ballotCreated]);
  const generateBallotPayload = (ballotId: string | null) => {
    return {
      accreditationBodyId: accreditationBodySelect?.id,
      ballotLevelId: ballotLevelSelect?.id,
      ballotTypeId: ballotTypeSelect?.id,
      ballotSubTypeId: ballotSubTypeSelect?.id,
      ballotStatusId:
        ballotDetailsInfo?.ballotStatusId ?? ballotStatusList?.Draft?.id,
      recordSubTypeId: recordSubTypeSelect?.id,
      recordTypeId: recordTypeSelect?.id,
      committeeId: primaryCommitteSelect?.id,
      membershipPositionId: positionAppliedSelect?.id,
      note: "",
      noOfRecords: 0,
      explaination: "",
      description: "",
      daysToIssue: 0,
      openDate: null,
      closeDate: null,
      ballotNumber: "string",
      isSecreteBallot: false,
      recirculationBallotId: ballotIdSelect?.id,
      recirculationBallotNumber: ballotIdSelect?.value,
      isDirty: ballotId ? true : false,
      isNew: ballotId ? false : true,
    };
  };
  const createBallotHandler = async () => {
    const payload = generateBallotPayload(ballotId);
    try {
      const createBallot = await postData(`Ballot`, payload);
      const formattedBallotDetails = JSON.parse(createBallot.content);
      dispatch(
        ballotDetails.addBallotDetails({
          target: formattedBallotDetails.Id,
          label: "ballotid",
        })
      );
      dispatch(
        ballotDetails.addBallotDetails({
          target: formattedBallotDetails.BallotNumber,
          label: "ballotnumber",
        })
      );
      dispatch(
        ballotDetails.addBallotDetails({
          target: formattedBallotDetails.RecirculationBallotId,
          label: "recirculationBallotId",
        })
      );
      dispatch(
        ballotDetails.addBallotDetails({
          target: formattedBallotDetails.RecirculationBallotNumber,
          label: "recirculationBallotNumber",
        })
      );
    } catch (err: any) {
      if (
        err.response?.data?.errors?.requestModel[0]?.includes(
          "limit has reached"
        )
      ) {
        errorMessageToast("Allowed No. of Ballot limit has reached");
      }
    }
  };

  const getBallotDetailsHandler = async () => {
    try {
      const allBallotDetails = await fetchData(`Ballot/${ballotId}`);
      onEditBallotHandler(allBallotDetails);
    } catch (err) {
      console.log(err, "errr");
    }
  };
  const onEditBallotHandler = (ballotDetailsData: BallotDetails) => {
    const updateRecordValues = (data: any, label: string) => {
      if (data) {
        dispatch(
          ballotSelectRecords.selectedRecordValues({ target: data, label })
        );
      } else {
        console.warn(`No data found for ${label}`);
      }
    };
    const updateBallotDetails = (data: any, label: string) => {
      if (data) {
        dispatch(ballotDetails.addBallotDetails({ target: data, label }));
      } else {
        console.warn(`No data found for ${label}`);
      }
    };
    // Ballot record section 1
    const ballotRecordMap = [
      {
        data: ballotDetailsData?.AccreditationBody?.Name,
        label: "accreditationBodyValue",
      },
      {
        data: ballotDetailsData?.Committee?.Name,
        label: "primaryCommitteeValue",
      },
      { data: ballotDetailsData?.BallotType?.Name, label: "ballotTypeValue" },
      {
        data: ballotDetailsData?.BallotSubType?.Name,
        label: "ballotSubTypeValue",
      },
      { data: ballotDetailsData?.BallotNumber, label: "ballotIdValue" },
      { data: ballotDetailsData?.BallotLevel?.Name, label: "ballotLevelValue" },
      {
        data: ballotDetailsData?.RecordType?.TypeName,
        label: "recordTypeValue",
      },
      {
        data: ballotDetailsData?.RecordSubType?.Type,
        label: "recordSubTypeValue",
      },
      {
        data: ballotDetailsData?.MembershipPosition?.Description,
        label: "positionAppliedValue",
      },
    ];
    ballotRecordMap.forEach(({ data, label }) =>
      updateRecordValues(data, label)
    );
    // Ballot details section 2
    const ballotDetailsMap = [
      { data: ballotDetailsData?.Id, label: "ballotid" },
      { data: ballotDetailsData?.OpenDate, label: "opendate" },
      { data: ballotDetailsData?.CloseDate, label: "enddate" },
      { data: ballotDetailsData?.Description, label: "description" },
      { data: ballotDetailsData?.Explaination, label: "explaination" },
      { data: ballotDetailsData?.Note, label: "staffnote" },
      { data: ballotDetailsData?.BallotNumber, label: "ballotnumber" },
    ];
    ballotDetailsMap.forEach(({ data, label }) =>
      updateBallotDetails(data, label)
    );
  };

  const getRecirculationBallotDetails = async () => {
    try {
      const allBallotDetails = await fetchData(
        `Ballot/${ballotRecirculationId}`
      );
      const updateField = (data: string | undefined, label: any) => {
        if (data) {
          dispatch(
            ballotSelectRecords.selectedRecordValues({ target: data, label })
          );
          setValue(label, data);
        } else {
          console.warn(`No data found for ${label}`);
        }
      };
      const ballotDetailsMap = [
        {
          data: allBallotDetails?.Committee?.Name,
          label: "primaryCommitteeValue",
        },
        { data: allBallotDetails?.BallotType?.Name, label: "ballotTypeValue" },
        {
          data: allBallotDetails?.BallotLevel?.Name,
          label: "ballotLevelValue",
        },
        {
          data: allBallotDetails?.RecordType?.TypeName,
          label: "recordTypeValue",
        },
        {
          data: allBallotDetails?.RecordSubType?.Type,
          label: "recordSubTypeValue",
        },
        {
          data: allBallotDetails?.MembershipPosition?.DisplayName,
          label: "positionAppliedValue",
        },
      ];
      ballotDetailsMap.forEach(({ data, label }) => updateField(data, label));
      trigger();
    } catch (err) {
      console.error("Failed to fetch recirculation ballot details: ", err);
    }
  };
  const handleDropdownChange = (
    e: {
      value:
        | string
        | { label: string | null; value: string | null; id: string };
      id?: string;
    },
    fieldOnChange: (value: string | null) => void
  ) => {
    // Check if the selected option is the placeholder
    const isPlaceholder =
      e.value && typeof e.value === "object" && e.value.id === "placeholder";

    if (isPlaceholder) {
      fieldOnChange(null);
      handleChange("", "ballotIdValue");
      dispatch(
        ballotDetails.clearBallotDetails({
          label: "recirculationBallotId",
        })
      );
      dispatch(
        ballotDetails.clearBallotDetails({
          label: "recirculationBallotNumber",
        })
      );
    } else {
      const selectedValue =
        typeof e.value === "string" ? e.value : e.value.value; // Handle both cases

      const selectedOption = ballotIdOptions.find(
        (option: DropdownOption) => option.value === selectedValue
      );
      fieldOnChange(selectedValue);

      if (selectedOption) {
        handleChange(selectedOption.value, "ballotIdValue");
        dispatch(
          ballotDetails.addBallotDetails({
            target: selectedOption.id,
            label: "recirculationBallotId",
          })
        );
        dispatch(
          ballotDetails.addBallotDetails({
            target: selectedOption.RecirculationBallotNumber,
            label: "recirculationBallotNumber",
          })
        );
      }
    }
  };
  const handleToast = (count: number) => {
    if (count === 0) {
      dispatch(
        setToast({
          message: `The selected committee has a ${count} member count`,
          severity: "warn",
        })
      );
    }
  };

  useEffect(() => {
    if (isEdit) {
      getBallotDetailsHandler();
    }
  }, [isEdit]);
  useEffect(() => {
    if (!ballotCreated) {
      queryParamaHandler();
    }
  }, [selectedRecordValues, ballotCreated]);
  useEffect(() => {
    const queryParamas = queryStringHandler(filters);
    getRecirculationBallotOptions(queryParamas);
  }, [filters]);
  useEffect(() => {
    getRecirculationBallotDetails();
  }, [ballotRecirculationId]);
  useEffect(() => {
    if (selectedBallotIdValue?.value === null) {
      setIsDummyOptionSelected(true);
    } else {
      setIsDummyOptionSelected(false);
    }
  }, [selectedBallotIdValue]);
  return (
    <>
      <Toast ref={toast} />
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
          <div className="flex flex-column gap-1">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("ballot.enterTheBasicBallotDetailsAndSearchRecords")}
            </h2>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl align-items-end">
                {/* <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                                <label
                                    htmlFor="accreditationBodyValue"
                                    className={`block font-bold text-capitalize ${errors.accreditationBodyValue ? " p-error" : "text-input-label"
                                        }`}
                                >
                                    {t("ballot.accreditationBody")}*
                                </label>
                                <Controller
                                    name="accreditationBodyValue"
                                    control={control}
                                    rules={{ required: `${t("ballot.accreditationBody")} ${t("ballot.isRequired")}` }}
                                    render={({ field }) => (
                                        <Dropdown
                                            inputId="accreditationBodyValue"
                                            aria-describedby="accreditationBodyValue"
                                            {...field}
                                            value={field.value || accreditationBodySelect?.value}
                                            options={accreditationBodyOptions}
                                            optionLabel="label"
                                            placeholder="Select an Option"
                                            className={`w-full ${errors.accreditationBodyValue ? "p-invalid" : ""}`}
                                        />)}
                                />
                                {errors.accreditationBodyValue && (
                                    <span
                                        className="p-error font-bold text-capitalize"
                                    >
                                        {`${errors.accreditationBodyValue.message} `}
                                    </span>
                                )}
                            </div> */}
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="primaryCommitteeValue"
                    className={`block font-bold text-capitalize ${
                      errors.primaryCommitteeValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.primaryCommitteeResponsible")}*
                  </label>
                  <Controller
                    name="primaryCommitteeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.primaryCommitteeResponsible")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        filter
                        inputId="primaryCommitteeValue"
                        aria-describedby="primaryCommitteeValue"
                        {...field}
                        value={field.value || primaryCommitteSelect?.value}
                        options={primaryCommitteOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.primaryCommitteeValue ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          const selectedOption = primaryCommitteOptions.find(
                            (option: any) => option.value === e.value
                          );
                          handleToast(selectedOption?.count);
                          field.onChange(e.value);
                          handleChange(e.value, "primaryCommitteeValue");
                        }}
                        disabled={isdisabled}
                      />
                    )}
                  />
                  {errors.primaryCommitteeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.primaryCommitteeValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.ballotTypeValue ? " p-error" : "text-input-label"
                    }`}
                  >
                    {t("ballot.ballotType")}*
                  </label>
                  <Controller
                    name="ballotTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.ballotType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="ballotTypeValue"
                        aria-describedby="ballotTypeValue"
                        {...field}
                        value={field.value || ballotTypeSelect?.value}
                        options={ballotTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.ballotTypeValue ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          handleChange(e.value, "ballotTypeValue");
                        }}
                        disabled={isdisabled}
                      />
                    )}
                  />
                  {errors.ballotTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.ballotTypeValue.message} `}
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotSubTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.ballotSubTypeValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.ballotSubType")}*
                  </label>
                  <Controller
                    name="ballotSubTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.ballotSubType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="ballotSubTypeValue"
                        aria-describedby="ballotSubTypeValue"
                        {...field}
                        value={field.value || ballotSubTypeSelect?.value}
                        options={ballotSubTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.ballotSubTypeValue ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          handleChange(e.value, "ballotSubTypeValue");
                        }}
                        disabled={isdisabled}
                      />
                    )}
                  />
                  {errors.ballotSubTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.ballotSubTypeValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                {ballotSubTypeSelect?.value === BALLOT_RECIRCULATION && (
                  <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="ballotId"
                      className={`block font-bold text-capitalize text-input-label`}
                    >
                      {t("ballot.ballotId")}*
                    </label>
                    <Controller
                      name="ballotIdValue"
                      control={control}
                      rules={{
                        required:
                          ballotSubTypeSelect?.value === BALLOT_RECIRCULATION
                            ? `${t("ballot.ballotId")} ${t("ballot.isRequired")}`
                            : false,
                      }}
                      render={({ field }) => (
                        <Dropdown
                          filter
                          inputId="ballotIdValue"
                          aria-describedby="ballotIdValue"
                          {...field}
                          value={field.value || ballotIdSelect?.value}
                          options={[
                            {
                              label: "Select an option",
                              value: null,
                              id: "placeholder",
                            },
                            ...ballotIdOptions,
                          ]}
                          optionLabel="label"
                          placeholder="Select an Option"
                          className={`w-full ${
                            errors.ballotIdValue ? "p-invalid" : ""
                          }`}
                          onChange={(e) =>
                            handleDropdownChange(e, field.onChange)
                          }
                        />
                      )}
                    />
                    {errors.ballotIdValue && (
                      <span
                        id="ballotIdError"
                        className="p-error font-bold text-capitalize"
                      >
                        {`${errors.ballotIdValue.message} `}
                      </span>
                    )}
                  </div>
                )}
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotLevelValue"
                    className={`block font-bold text-capitalize ${
                      errors.ballotLevelValue ? " p-error" : "text-input-label"
                    }`}
                  >
                    {t("ballot.ballotLevel")}*
                  </label>
                  <Controller
                    name="ballotLevelValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.ballotLevel")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="ballotLevelValue"
                        aria-describedby="ballotLevelValue"
                        {...field}
                        value={field.value || ballotLevelSelect?.value}
                        options={ballotLevelOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.ballotLevelValue ? "p-invalid" : ""}`}
                        disabled={isdisabled}
                        onChange={(e) => {
                          field.onChange(e.value);
                          handleChange(e.value, "ballotLevelValue");
                        }}
                      />
                    )}
                  />
                  {errors.ballotLevelValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.ballotLevelValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="recordTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.recordTypeValue ? " p-error" : "text-input-label"
                    }`}
                  >
                    {t("ballot.recordType")}*
                  </label>
                  <Controller
                    name="recordTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.recordType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="recordTypeValue"
                        aria-describedby="recordTypeValue"
                        {...field}
                        value={field.value || recordTypeSelect?.value}
                        options={recordTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.recordTypeValue ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          handleChange(e.value, "recordTypeValue");
                        }}
                        disabled={isdisabled}
                      />
                    )}
                  />
                  {errors.recordTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.recordTypeValue.message} `}
                    </span>
                  )}
                </div>
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="recordSubTypeValue"
                    className={`block font-bold text-capitalize ${
                      errors.recordSubTypeValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.recordSubType")}*
                  </label>
                  <Controller
                    name="recordSubTypeValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.recordSubType")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="recordSubTypeValue"
                        aria-describedby="recordSubTypeValue"
                        {...field}
                        value={field.value || recordSubTypeSelect?.value}
                        options={recordSubTypeOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.recordSubTypeValue ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          handleChange(e.value, "recordSubTypeValue");
                        }}
                        disabled={isdisabled}
                      />
                    )}
                  />
                  {errors.recordSubTypeValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.recordSubTypeValue.message} `}
                    </span>
                  )}
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-4 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="positionAppliedValue"
                    className={`block font-bold text-capitalize ${
                      errors.positionAppliedValue
                        ? " p-error"
                        : "text-input-label"
                    }`}
                  >
                    {t("ballot.positionAppliedFor")}*
                  </label>
                  <Controller
                    name="positionAppliedValue"
                    control={control}
                    rules={{
                      required: `${t("ballot.positionAppliedFor")} ${t("ballot.isRequired")}`,
                    }}
                    render={({ field }) => (
                      <Dropdown
                        inputId="positionAppliedValue"
                        aria-describedby="positionAppliedValue"
                        {...field}
                        value={field.value || positionAppliedSelect?.value}
                        options={positionAppliedOptions}
                        optionLabel="label"
                        placeholder="Select an Option"
                        className={`w-full ${errors.positionAppliedValue ? "p-invalid" : ""}`}
                        onChange={(e) => {
                          field.onChange(e.value);
                          handleChange(e.value, "positionAppliedValue");
                        }}
                        disabled={isdisabled}
                      />
                    )}
                  />
                  {errors.positionAppliedValue && (
                    <span className="p-error font-bold text-capitalize">
                      {`${errors.positionAppliedValue.message} `}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 cardBody">
            <Button
              className="button-md gap-2"
              severity="secondary"
              type="submit"
              disabled={!isValid || isDummyOptionSelected}
            >
              <FeatherIcon name="search" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("ballot.searchRecords")}
              </span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchRecordForm;
