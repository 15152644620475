import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import Input from "../../utils/Input";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import "./Configuration.scss";
import {
  fetchData,
  postData,
  putData,
  deleteData,
  getListByParentId,
} from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";

const MasterData: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridHeaders, setGridHeaders] = useState<string[]>([]);
  const [row, setRow] = useState([
    {
      TypeName: "",
      Description: "",
      checkbox: false,
      CategoryName: "",
      StatusName: "",
      PositionName: "",
      RecordTypeId: "",
      Active: false,
      id: "",
      isNew: true,
    },
  ]);

  useEffect(() => {
    if (selectedValue) {
      fetchGridData(selectedValue);
    }
  }, [selectedValue]);

  const fetchGridData = async (selectedValue: any) => {
    try {
      const data = await fetchData(`${selectedValue}/GetAll`);
      const dataArray = data.Collection || [];
      setRow(dataArray.map((item: { Id: any }) => ({ ...item, id: item.Id })));
      const firstObjectKeys = Object.keys(dataArray[0] || {});
      const headers = [...firstObjectKeys.slice(0, 2), "Active"];
      setGridHeaders(headers);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDropdownChange = (selectedItem: any) => {
    setSelectedValue(selectedItem.value);
    setRow([]);
    setGridHeaders([]);
  };

  const handleAdd = () => {
    setRow([
      ...row,
      {
        TypeName: "",
        Description: "",
        CategoryName: "",
        StatusName: "",
        PositionName: "",
        RecordTypeId: "",
        checkbox: false,
        Active: true,
        id: "",
        isNew: true,
      },
    ]);
  };

  const handleCancel = () => {
    setSelectedValue(null);
    setRow([]);
    setGridHeaders([]);
  };

  const confirmDelete = (rowId: string | undefined, rowIndex: number) => {
    confirmDialog({
      header: t("configuration.confirmation"),
      message: t("configuration.areYouSureYouWantToDelete"),
      icon: "",
      acceptLabel: t("configuration.yes"),
      rejectLabel: t("configuration.no"),
      defaultFocus: "accept",
      accept: () => handleDeleteRecord(selectedValue, rowId, rowIndex),
      reject: () => navigate("/configuration"),
    });
  };

  const handleDeleteRecord = async (
    selectedValue: any,
    rowId: string | undefined,
    rowIndex: number
  ) => {
    try {
      if (!rowId) {
        const updatedRow = [...row];
        updatedRow.splice(rowIndex, 1);
        setRow(updatedRow);
        return;
      }

      await deleteData(selectedValue, rowId);
      const updatedRows = row.filter((row) => row.id !== rowId);
      setRow(updatedRows);
      toast.current?.show({
        severity: "success",
        summary: "Success",
        detail: t("configuration.deleteMsg"),
        life: 3000,
      });
    } catch (error: any) {
      if (
        error?.response &&
        error?.response.status === 400 &&
        error?.response?.data.includes(
          "is associated with a committee and cannot be deleted"
        )
      ) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: `${t("configuration.coiErrorMessage")}`,
          life: 3000,
        });
      }
      console.error("Error deleting data:", error);
    }
  };

  const handleUpdate = async (selectedValue: any) => {
    if (loading) return;
    setLoading(true);

    try {
      const newRows = row.filter((item) => !item.id);
      const existingRows = row.filter((item) => item.id);
      let added = false;
      for (const newRow of newRows) {
        const missingFields = handleValidation(newRow);

        if (missingFields.length > 0) {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: `Please fill all required fields: ${missingFields.join(", ")}`,
            life: 3000,
          });
          setLoading(false);
          return;
        }
      }

      if (newRows.length > 0) {
        await Promise.all(
          newRows.map(async (newRow) => {
            const recordData = await fetchData("RecordType/GetAll");
            const recordId = recordData?.Collection[0].Id;
            const dataToSend =
              selectedValue === "CategoryOfInterest"
                ? {
                    isDirty: true,
                    isNew: true,
                    categoryName: newRow.CategoryName,
                    description: newRow.Description,
                  }
                : selectedValue === "RecordStatus"
                  ? {
                      isDirty: true,
                      isNew: true,
                      statusName: newRow.StatusName,
                      description: newRow.Description,
                      displayName: newRow.StatusName,
                      recordTypeId: recordId,
                    }
                  : selectedValue === "MembershipPosition"
                    ? {
                        active: true,
                        isDirty: true,
                        isNew: true,
                        positionName: newRow.PositionName,
                        description: newRow.Description,
                        displayName: newRow.PositionName,
                      }
                    : {
                        isDirty: true,
                        isNew: true,
                        typeName: newRow.TypeName,
                        description: newRow.Description,
                      };

            await postData(selectedValue, dataToSend);
            added = true;
            if (added && toast.current) {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: t("configuration.addMsg"),
                life: 3000,
              });
            }
          })
        );
      }

      if (existingRows.length > 0 && !added) {
        await Promise.all(
          existingRows.map(async (rowData) => {
            await putData(selectedValue, rowData.id, rowData);
          })
        );
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: t("configuration.updateMsg"),
            life: 3000,
          });
        }
      }

      const refreshedData = await fetchData(selectedValue);
      setRow(refreshedData.Collection);
    } catch (error) {
      console.error("Error processing rows:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddIconClick = async (index: number) => {
    try {
      const selectedEndpoint = `${selectedValue}`;
      const newRow = row[index];
      const missingFields = handleValidation(newRow);

      if (missingFields.length > 0) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: `Please fill all required fields: ${missingFields.join(", ")}`,
          life: 3000,
        });
        return;
      }
      const requiredFields = ["description"];
      if (selectedValue === "RecordStatus") {
        requiredFields.push("statusName", "displayName", "recordTypeId");
      } else if (selectedValue === "CategoryOfInterest") {
        requiredFields.push("categoryName");
      } else if (selectedValue === "MembershipPosition") {
        requiredFields.push("positionName", "displayName");
      } else {
        requiredFields.push("typeName");
      }
      const recordData = await fetchData("RecordType/GetAll");
      const recordId = recordData?.Collection[0].Id;
      const dataToSend =
        selectedValue === "CategoryOfInterest"
          ? {
              isDirty: true,
              isNew: true,
              categoryName: newRow.CategoryName,
              description: newRow.Description,
            }
          : selectedValue === "RecordStatus"
            ? {
                isDirty: true,
                isNew: true,
                statusName: newRow.StatusName,
                description: newRow.Description,
                displayName: newRow.StatusName,
                recordTypeId: recordId,
              }
            : selectedValue === "MembershipPosition"
              ? {
                  active: true,
                  isDirty: true,
                  isNew: true,
                  positionName: newRow.PositionName,
                  description: newRow.Description,
                  displayName: newRow.PositionName,
                }
              : {
                  isDirty: true,
                  isNew: true,
                  typeName: newRow.TypeName,
                  description: newRow.Description,
                };

      await postData(selectedEndpoint, dataToSend);
      const updatedRow = [...row];
      updatedRow[index].isNew = false;
      setRow(updatedRow);
      if (toast.current) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: t("configuration.addMsg"),
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const requiredFieldsMap = {
    RecordStatus: ["StatusName", "Description"],
    CategoryOfInterest: ["CategoryName", "Description"],
    MembershipPosition: ["PositionName", "Description"],
    CommitteeType: ["TypeName", "Description"],
    RecordType: ["TypeName", "Description"],
  };

  const handleValidation = (newRow: any) => {
    const requiredFields =
      requiredFieldsMap[
        selectedValue as unknown as keyof typeof requiredFieldsMap
      ] || [];
    const missingFields = requiredFields.filter((field) => {
      const value = newRow[field];
      return value === undefined || value === null || value === "";
    });
    if (missingFields.length > 0) {
      return missingFields;
    } else {
      return [];
    }
  };

  const HandleMasterData = [
    { name: "Committee Type", value: "CommitteeType" },
    { name: "Membership Position", value: "MembershipPosition" },
    { name: "Record Type", value: "RecordType" },
    { name: "Record Status", value: "RecordStatus" },
    { name: "Category Of Interest", value: "CategoryOfInterest" },
  ];

  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-1">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.masterData")}
              </h2>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label htmlFor="MasterType" className="font-bold">
                    {t("configuration.selectType")}:
                  </label>
                  <Dropdown
                    inputId="MasterType"
                    value={selectedValue}
                    options={HandleMasterData}
                    optionLabel="name"
                    onChange={handleDropdownChange}
                    placeholder={t("configuration.selectAnOption")}
                    className="w-full"
                  />
                </div>
              </div>

              <div className="flex flex-column gap-2 lg:w-9">
                <div className="grid m-0 w-full gap-3 grid-resp grid-resp-lables masterdata-grid">
                  {gridHeaders.map((key, index) => (
                    <span key={index} className={`font-bold`} id={key}>
                      {key}
                    </span>
                  ))}
                </div>
                <div className="flex flex-column gap-3 w-full"></div>
                {row.map((rowData, rowIndex) => (
                  <div
                    className={`grid m-0 mb-3 md:mb-0 w-full gap-3 grid-resp masterdata-grid`}
                    key={rowIndex}
                  >
                    {gridHeaders.map((header, headerIndex) => (
                      <div
                        className="data-label"
                        key={headerIndex}
                        data-label={header}
                      >
                        {header === "Active" ? (
                          <div className="flex align-content-center h-full">
                            <Checkbox
                              className="align-self-center"
                              checked={rowData.Active}
                              onChange={(e) => {
                                const updatedRow = [...row];
                                updatedRow[rowIndex].Active =
                                  e.checked ?? false;
                                setRow(updatedRow);
                              }}
                              aria-label={`Checkbox-${rowData.Active}`}
                            />
                          </div>
                        ) : (
                          <InputText
                            placeholder={header}
                            className="w-full"
                            aria-labelledby={header}
                            value={(rowData as any)[header] || ""}
                            disabled={!rowData.Active}
                            onChange={(e) => {
                              const updatedRow = [...row];
                              (updatedRow[rowIndex] as any)[header] =
                                e.target.value;
                              setRow(updatedRow);
                            }}
                          />
                        )}
                      </div>
                    ))}
                    <Button
                      text
                      className="p-button-plain p-2 text-delete"
                      aria-label={t("configuration.delete")}
                      onClick={() => confirmDelete(rowData.id, rowIndex)}
                      style={{
                        display: selectedValue ? "flex" : "none",
                      }}
                    >
                      <FeatherIcon name="trash-2" size={20} />
                    </Button>
                  </div>
                ))}

                <div className="mt-3">
                  <Button
                    text
                    className="p-button-plain gap-2 pl-0"
                    onClick={handleAdd}
                    style={{ display: selectedValue ? "inline-flex" : "none" }}
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold underline">
                      {t("configuration.addRow")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <Button
              className="button-md"
              severity="secondary"
              onClick={handleCancel}
            >
              <span className="font-bold">{t("configuration.cancel")}</span>
            </Button>
            <Button
              className="button-md"
              onClick={() => selectedValue && handleUpdate(selectedValue)}
            >
              <span className="font-bold">{t("configuration.submit")}</span>
            </Button>

            <ConfirmDialog
              className="custom-dialog hide-close"
              ariaCloseIconLabel="Close"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterData;
