import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { OverlayPanel } from "primereact/overlaypanel";
import DataGrid from "../common/DataGrid";
import FeatherIcon from "../common/FeatherIconComponent";
import { useLanguageContext } from "../LanguageContext";
import { fetchData } from "../../services/apiService";
import { reset } from "../../slices/createRecordSlice";
import { usePermission } from "../auth/Authorization";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import { clearAllSessionStorage } from "../../utils/storage";
import { formatedRecordData } from "../../utils/utils";
import CustomMenu, { getStatusBadge } from "../common/customMenu";
interface RecordList {
  [x: string]: string;
  Committee: any;
  id: string;
  name: string;
  committeeApplied: string;
  recordSubType: string;
  subSubType: string;
  position: string;
  categoryOfInterest: string;
  createdOn: string;
  status: string;
  action: string;
}

const NewRecordList: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const [search, setSearch] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [committees, setCommittees] = useState<any>([]);
  const [coiData, setCoiData] = useState([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const [recordStatus, setRecordStatus] = useState([]);
  const [selectedCommittees, setSelectedCommittees] = useState<any[]>([]);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [selectedRecordStatuses, setSelectedRecordStatuses] = useState<any[]>(
    []
  );
  const [allRecordsFilter, setAllRecordsFilter] = useState<boolean>(false);
  const [committeeCheckboxChecked, setCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [recordStatusCheckboxChecked, setRecordStatusCheckboxChecked] =
    useState<boolean>(false);
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [recordList, setRecordList] = useState<RecordList[]>([]);
  const userProfileID = localStorage.getItem("userProfileID");
  useState<Date | null>(null);
  useState<Date | null>(null);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedLanguage } = useLanguageContext();
  //Pagination
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortField, setSortField] = useState<string>("CreatedOn");

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    clearAllSessionStorage();
    dispatch(reset());
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const navigate = useNavigate();

  const handleCheckboxChange =
    (checkbox: string) => async (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "committee":
          setCommitteeCheckboxChecked(isChecked);
          break;
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "recordStatus":
          setRecordStatusCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        case "allViewRecords":
          setAllRecordsFilter(isChecked);
          break;
        default:
          break;
      }
    };

  const getFilter = async () => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: any }[] = [];
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    if (committeeCheckboxChecked) {
      const committeeIds = selectedCommittees.map((committee) => committee.Id);
      filters.push({ Key: "CommitteeId", Value: committeeIds.join(",") });
    }
    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (positionCheckboxChecked) {
      const positionIds = selectedPositions.map((position) => position.Id);
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (recordStatusCheckboxChecked) {
      const recordStatusIds = selectedRecordStatuses.map((status) => status.Id);
      filters.push({ Key: "RecordStatusId", Value: recordStatusIds.join(",") });
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({
        Key: "requestedon",
        Value: `${fromDateStr},${toDateStr}`,
      });
    }

    filters.push({ Key: "isResignRecord", Value: false });
    filters.push({ Key: "isMemberReappointmentRecord", Value: false });
    filters.push({ Key: "IsTerminateRecord", Value: false });

    switch (userRole) {
      case "Staff":
        if (!allRecordsFilter) {
          filters.push({ Key: "StaffId", Value: userProfileID });
        }
        break;
      case "Member":
        filters.push({ Key: "UserProfileId", Value: userProfileID });
        break;
      default:
        break;
    }

    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });

    let sortValue = sortField === "statusName" ? "Status" : sortField;
    queryParams["PageIndex"] = pageIndex;
    queryParams["PageSize"] = pageSize;
    queryParams["SearchText"] = debouncedSearchTerm;
    queryParams["SortBy"] = sortValue;
    queryParams["SortDir"] = sortOrderValue;
    return queryParams;
  };

  const fetchFilteredData = async (event: any) => {
    overlayPanelRef.current?.toggle(event);
    if (pageIndex !== 1) {
      setPageIndex(1);
    } else {
      await fetchDataByRole();
    }
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setToDateError(`${t("record.fromDateBeforeToDate")}`);
        setDateActivationFrom(null);
      } else {
        setToDateError("");
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setToDateError(`${t("record.toDateAfterFromDate")}`);
        setDateActivationTo(null);
      } else {
        setToDateError("");
        setDateActivationTo(date);
      }
    }
  };

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event);
    }
  };

  const fetchCommittees = async () => {
    try {
      const response = await fetchData("Committee/GetAll");
      const committeesData = response.Collection;
      setCommittees(committeesData);
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };

  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  const fetchRecordStatus = async () => {
    try {
      const response = await fetchData("RecordStatus/GetAll");
      const recordstatus = response.Collection;
      setRecordStatus(recordstatus);
    } catch (error) {
      console.error("Error fetching Record Status:", error);
    }
  };

  useEffect(() => {
    fetchCommittees();
    fetchCategoryOfInterest();
    fetchMembershipPosition();
    fetchRecordStatus();
  }, []);

  const fetchDataByRole = async () => {
    const getParams = getFilter();
    setLoading(true);
    try {
      let responseData;
      responseData = await fetchData(
        `Record/GridSearch?${new URLSearchParams(await getParams).toString()}`
      );
      setTotalCount(responseData?.TotalCount);
      const formattedRecordList = formatedRecordData(responseData?.Collection);
      setRecordList(formattedRecordList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userProfileID) return;
    if (userRole) {
      fetchDataByRole();
    }
  }, [
    userRole,
    userProfileID,
    pageIndex,
    pageSize,
    sortField,
    sortOrder,
    debouncedSearchTerm,
  ]);

  const handleViewMember = (id: any) => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        sessionStorage.setItem("recordId", id);
        navigate("/record/create");
      } else {
        sessionStorage.setItem("recordId", id);
        navigate("/record/record-preview");
      }
    };
  };

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 500); // 500ms delay
    // Clear the timeout if searchTerm changes (i.e. user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("record.viewEditRecord"),
        command: handleViewMember(rowData?.Id),
      },
    ];

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap md:justify-content-between py-3 gap-3 w-full">
        <IconField iconPosition="left" className="w-full md:w-16rem">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            id="searchCommittee"
            className="w-full"
            placeholder={t("record.searchCommittee")}
            aria-label={t("record.searchCommittee")}
            onChange={(e) => setSearch(e.target.value)}
          />
        </IconField>

        <div className="flex gap-3 w-full md:w-auto align-items-center">
          {userRole === "Staff" ? (
            <label
              htmlFor="allRecordsFilter"
              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer flex align-items-center"
            >
              <Checkbox
                inputId="allRecordsFilter"
                onChange={handleCheckboxChange("allViewRecords")}
                checked={allRecordsFilter}
                className="mr-2"
                tabIndex={0}
              />
              {t("record.viewallrecords")}
            </label>
          ) : null}
          <div className="relative">
            <Button
              className="button-md gap-1"
              severity="secondary"
              onClick={toggleFilterPanel}
              aria-label={t("record.filter")}
            >
              <FeatherIcon name="filter" size={20} color="inherit" />
              <span className="font-bold text-capitalize hidden md:flex">
                {t("record.filter")}
              </span>
            </Button>
            {/* OverlayPanel for Filter */}
            <OverlayPanel
              ref={overlayPanelRef}
              onHide={() => setShowFilterPanel(false)}
              appendTo={"self"}
              dismissable
              className="p-overlaypanel-filter left-auto md:right-0 top-100"
              aria-labelledby="filter-heading"
              role="dialog"
            >
              <div className="flex flex-column gap-1" tabIndex={0}>
                <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                  {t("record.filter")}
                </p>
                <div className="filter-wrap">
                  <div className="">
                    <label
                      htmlFor="committeeNameFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="committeeNameFilter"
                        onChange={handleCheckboxChange("committee")}
                        checked={committeeCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("record.committeeName")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedCommittees}
                        onChange={(e) => setSelectedCommittees(e.value)}
                        options={committees}
                        optionLabel="Name"
                        filter
                        placeholder={t("record.committeeName")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("record.committeeName")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="categoryOfInterestFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="categoryOfInterestFilter"
                        onChange={handleCheckboxChange("coi")}
                        checked={coiCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("record.categoryOfInterest")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedCOIs}
                        onChange={(e) => setSelectedCOIs(e.value)}
                        options={coiData}
                        optionLabel="CategoryName"
                        filter
                        placeholder={t("record.categoryOfInterest")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("record.categoryOfInterest")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="membershipPositionFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="membershipPositionFilter"
                        onChange={handleCheckboxChange("position")}
                        checked={positionCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("record.membershipPosition")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedPositions}
                        onChange={(e) => setSelectedPositions(e.value)}
                        options={memberPosition}
                        optionLabel="PositionName"
                        filter
                        placeholder={t("record.membershipPosition")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("record.membershipPosition")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="recordStatusFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="recordStatusFilter"
                        onChange={handleCheckboxChange("recordStatus")}
                        checked={recordStatusCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("record.recordStatus")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedRecordStatuses}
                        onChange={(e) => setSelectedRecordStatuses(e.value)}
                        options={recordStatus}
                        optionLabel="StatusName"
                        filter
                        placeholder={t("record.recordStatus")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("record.recordStatus")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="requestDateFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="requestDateFilter"
                        onChange={handleCheckboxChange("date")}
                        checked={dateCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("record.requestDate")}
                    </label>
                    <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationFrom"
                        onChange={(e) =>
                          handleDateSelect(
                            e.value as Date,
                            "dateActivationFrom"
                          )
                        }
                        value={dateActivationFrom}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("record.fromDate")}
                        placeholder={t("record.fromDate")}
                        maxDate={
                          dateActivationTo
                            ? (dateActivationTo as Date)
                            : undefined
                        }
                      />
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationTo"
                        onChange={(e) =>
                          handleDateSelect(e.value as Date, "dateActivationTo")
                        }
                        value={dateActivationTo}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("record.toDate")}
                        placeholder={t("record.toDate")}
                        minDate={
                          dateActivationFrom
                            ? (dateActivationFrom as Date)
                            : undefined
                        }
                      />
                      {toDateError && (
                        <div className="p-error font-bold">{toDateError}</div>
                      )}
                    </div>
                  </div>
                </div>
                <Button
                  className="button-md w-full justify-content-center"
                  onClick={(e: any) => fetchFilteredData(e)}
                >
                  {t("record.applyFilter")}
                </Button>
              </div>
            </OverlayPanel>
          </div>
          {userRole !== "Member" && (
            <Button
              className="button-md gap-1 justify-content-center w-full md:w-auto"
              onClick={() => navigate("/record/volunteer")}
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold">{t("record.newRecord")}</span>
            </Button>
          )}
        </div>
      </div>

      <DataGrid
        loading={loading}
        products={recordList}
        columns={[
          {
            field: "RecordNumber",
            header: t("record.recordId"),
            link: "/record/record-preview",
          },
          {
            field: "MemberName",
            header: t("record.memberName"),
          },
          {
            field: "CommitteeName",
            header: t("record.committeeApplied"),
          },
          {
            field: "RecordSubType",
            header: t("record.recordSubType"),
          },
          {
            field: "Position",
            header: t("record.position"),
          },
          {
            field: "CategoryOfInterest",
            header: t("record.categoryOfInterest"),
          },
          {
            field: "CreatedOn",
            header: t("record.createdOn"),
            sortable: true,
          },
          {
            field: "Status",
            header: t("record.status"),
            body: (rowData: any) => getStatusBadge(rowData.Status),
          },
          {
            field: "Action",
            header: t("record.action"),
            body: actionPositionBodyTemplate,
            sortable: false,
          },
        ]}
        showCheckbox={false}
        pageIndex={pageIndex}
        totalRecords={totalCount}
        pageSize={pageSize}
        onPageChange={pageHandler}
        sortField={sortField}
        sortOrder={sortOrder}
        onSortChange={sortHandler}
      />
    </>
  );
};

export default NewRecordList;
