import React from "react";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
interface SearchFieldProps {
  onSearch: (value: string) => void;
}
const StandardSearch: React.FC<SearchFieldProps> = ({ onSearch }) => {
  const [t, i18n] = useTranslation("standard");
  return (
    <IconField iconPosition="left" className="w-full md:w-16rem">
      <InputIcon className="pi pi-search"> </InputIcon>
      <InputText
        id="searchMemberOrPosition"
        placeholder={t("standard.searchMemberOrPosition")}
        className="w-full w-18rem"
        aria-label={t("standard.searchMemberOrPosition")}
        onChange={(e) => onSearch(e.target.value)}
      />
    </IconField>
  );
};
export default StandardSearch;
