import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { MenuItem } from "primereact/menuitem";
import DataGrid from "../common/DataGrid";
import FeatherIcon from "../common/FeatherIconComponent";
import { useLanguageContext } from "../LanguageContext";
import { fetchData } from "../../services/apiService";
import { reset } from "../../slices/createRecordSlice";
import { usePermission } from "../auth/Authorization";
import { clearAllSessionStorage } from "../../utils/storage";
import { formatedRecordData } from "../../utils/utils";
import CustomMenu, { getStatusBadge } from "../common/customMenu";
interface RecordList {
  [x: string]: string;
  Committee: any;
  id: string;
  name: string;
  committeeApplied: string;
  recordSubType: string;
  subSubType: string;
  position: string;
  categoryOfInterest: string;
  createdOn: string;
  status: string;
  action: string;
}

const TerminationRecordList: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const [search, setSearch] = useState("");
  const [recordList, setRecordList] = useState<RecordList[]>([]);
  const userProfileID = localStorage.getItem("userProfileID");
  useState<Date | null>(null);
  useState<Date | null>(null);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedLanguage } = useLanguageContext();
  //Pagination
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortField, setSortField] = useState<string>("CreatedOn");

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    clearAllSessionStorage();
    dispatch(reset());
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const navigate = useNavigate();

  const getFilter = async () => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: any }[] = [];
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    switch (userRole) {
      case "Staff":
        filters.push({ Key: "StaffId", Value: userProfileID });
        break;
      case "Member":
        filters.push({ Key: "UserProfileId", Value: userProfileID });
        break;
      default:
        break;
    }
    filters.push({ Key: "IsTerminateRecord", Value: true });
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    let sortValue = sortField === "statusName" ? "Status" : sortField;
    queryParams["PageIndex"] = pageIndex;
    queryParams["PageSize"] = pageSize;
    queryParams["SearchText"] = debouncedSearchTerm;
    queryParams["SortBy"] = sortValue;
    queryParams["SortDir"] = sortOrderValue;
    return queryParams;
  };

  const fetchDataByRole = async () => {
    setLoading(true);
    const getParams = getFilter();
    try {
      let responseData;
      responseData = await fetchData(
        `Record/GridSearch?${new URLSearchParams(await getParams).toString()}`
      );
      setTotalCount(responseData?.TotalCount);
      const formattedRecordList = formatedRecordData(responseData.Collection);
      setRecordList(formattedRecordList);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userProfileID) return;
    if (userRole) {
      fetchDataByRole();
    }
  }, [
    userRole,
    userProfileID,
    pageIndex,
    pageSize,
    sortField,
    sortOrder,
    debouncedSearchTerm,
  ]);

  const handleViewMember = (id: any) => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        sessionStorage.setItem("recordId", id);
        navigate("/record/create");
      } else {
        sessionStorage.setItem("recordId", id);
        navigate("/record/record-preview");
      }
    };
  };

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 500); // 500ms delay
    // Clear the timeout if searchTerm changes (i.e. user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const newTermination = async () => {
    sessionStorage.setItem("termination", "true");
    navigate("/record/create");
  };

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("record.viewEditRecord"),
        command: handleViewMember(rowData?.Id),
      },
    ];

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  return (
    <>
      <div className="flex py-3 gap-3 w-full">
        <Button
          className="ml-auto button-md gap-1 w-auto"
          onClick={newTermination}
        >
          <FeatherIcon name="plus" size={20} color="inherit" />
          <span className="font-bold">{t("record.newTerminte")}</span>
        </Button>
      </div>
      <DataGrid
        loading={loading}
        products={recordList}
        columns={[
          {
            field: "RecordNumber",
            header: t("record.recordId"),
            link: "/record/record-preview",
          },
          {
            field: "MemberName",
            header: t("record.memberName"),
          },
          {
            field: "CommitteeName",
            header: t("record.committeeApplied"),
          },
          {
            field: "RecordSubType",
            header: t("record.recordSubType"),
          },
          {
            field: "Position",
            header: t("record.position"),
          },
          {
            field: "CategoryOfInterest",
            header: t("record.categoryOfInterest"),
          },
          {
            field: "CreatedOn",
            header: t("record.createdOn"),
            sortable: true,
          },
          {
            field: "Status",
            header: t("record.status"),
            body: (rowData: any) => getStatusBadge(rowData.Status),
          },
          {
            field: "Action",
            header: t("record.action"),
            body: actionPositionBodyTemplate,
            sortable: false,
          },
        ]}
        showCheckbox={true}
        pageIndex={pageIndex}
        totalRecords={totalCount}
        pageSize={pageSize}
        onPageChange={pageHandler}
        sortField={sortField}
        sortOrder={sortOrder}
        onSortChange={sortHandler}
      />
    </>
  );
};

export default TerminationRecordList;
