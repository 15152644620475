import { configureStore } from "@reduxjs/toolkit";
import createCommitteeReducer from "../slices/createCommitteeSlice";
import adminDataReducer from "../slices/createCommitteeSlice";
import createMemberRequestReducer from "../slices/createMemberRequestSlice";
import createProfileReducer from "../slices/createProfileSlice"; // Updated import
import createRecordReducer from "../slices/createRecordSlice";
import addUserRolePermissions from "../slices/userRolePermissions";
import selectRecordsReducer from "../slices/ballotSelectRecords";
import ballotDetailsReducer from "../slices/ballotDetails";
import ballotCommitteeReducer from "../slices/ballotCommittees";
import ballotListingsReducer from "../slices/ballotListing";
import documentReducer from "../slices/documentSlice";
import toastReducer from "../slices/toastSlice";
import createStandardReducer from "../slices/createStandard";

const store = configureStore({
  reducer: {
    adminData: adminDataReducer,
    createCommittee: createCommitteeReducer,
    createMemberRequest: createMemberRequestReducer,
    createProfile: createProfileReducer,
    createRecord: createRecordReducer,
    ballotListingData: ballotListingsReducer,
    ballotSelectRecords: selectRecordsReducer,
    ballotDetails: ballotDetailsReducer,
    ballotCommittee: ballotCommitteeReducer,
    addUserRolePermission: addUserRolePermissions,
    document: documentReducer,
    toast: toastReducer,
    standardRequest: createStandardReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
