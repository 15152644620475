import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { TabPanel, TabView } from "primereact/tabview";
import { Badge } from "primereact/badge";
import { Chart } from "primereact/chart";
import { fetchData } from "../../services/Ballot/apiservice";
import { useLanguageContext } from "../LanguageContext";
import { BallotDetails, BallotRecord, InvitedCommittee } from "./types/vote";
import useUserData from "../../hooks/useUserData";
import { DropdownType } from "../../CommonTypes/utils";
import LoadingOverlay from "../common/LoadingOverlay";

interface SummaryType {
  BallotId: string;
  BallotNumber: string;
  CommitteeId: string;
  CommitteesName: string;
  RecordId: string;
  RecordNumber: string;
  ApproveCount: number;
  DisapproveCount: number;
  AbstainCount: number;
  NotReturnCount: number;
  ApprovedMember: string;
  DisapproveMember: string;
  AbstainMember: string;
  NotReturnedMember: string;
  NonSubstaintiveCount: number;
  SubstaintiveCount: number;
  NoCommentCount: number;
  NonSubstentiveMember: string;
  SubstaintiveMember: string;
  NoCommentMember: string;
}

const BallotTabVoteSummary: React.FC = () => {
  const { id: ballotId } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [selectedRecordId, setSelectedRecordId] = useState<string>("");
  const [recordLists, setRecordList] = useState<BallotRecord[] | [] | any>([]);
  const [intialInvitedCommittee, setIntialInvitedCommitteess] = useState<
    InvitedCommittee[]
  >([]);
  const [invitedCommittees, setInvitedCommitteess] = useState<
    { label: string; value: string }[] | []
  >([]);
  const [activeRecordId, setActiveRecordId] = useState<string | null>(null);
  const [selectedRecordApplicantName, setSelectedRecordApplicantName] =
    useState<string>("");
  const [ballotDetails, setBallotDetails] = useState<BallotDetails | null>(
    null
  );
  const { userRole } = useUserData();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [voteSummaries, setVoteSummaries] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (recordLists[activeIndex]?.record?.recordId) {
      setActiveRecordId(recordLists[activeIndex]?.record?.recordId);
    }
  }, [activeIndex, recordLists]);

  const getBallotDetailsHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(`Ballot/${ballotId}`);
      setBallotDetails(ballotDetailsData);
    } catch (err) {
      console.error("Error fetching ballot details", err);
    }
  };

  useEffect(() => {
    getBallotDetailsHandler();
  }, [ballotId]);

  useEffect(() => {
    const getBallotDetailsHandler = async () => {
      try {
        setLoading(true); // Start loader
        const ballotCommitteessInvited = await fetchData(
          `BallotCommittees/GetBallotInvitedComitteeListByBallotId/${ballotId}`
        );
        setIntialInvitedCommitteess(ballotCommitteessInvited);
        const forCommentCommittee: DropdownType[] = [];
        const forVoteCommittee: DropdownType[] = [];
        const sortedData = ballotCommitteessInvited.map(
          (item: InvitedCommittee) => ({
            label: item.CommitteeName,
            value: item.CommitteeId,
          })
        );
        ballotCommitteessInvited.forEach((item: any) => {
          const label = item.CommitteeName ?? "Unknown Committee";
          const value = item.CommitteeId ?? "Unknown Id";
          if (item.ForReviewAndComment) {
            forCommentCommittee.push({ label, value });
          } else {
            forVoteCommittee.push({ label, value });
          }
        });
        setInvitedCommitteess(sortedData);
        setInvitedCommitteess(sortedData);
      } catch (err) {
        console.error(err, "Error fetching ballot committee invitations");
      } finally {
        setLoading(false); // Stop loader
      }
    };
    if (ballotId) {
      getBallotDetailsHandler();
    }
  }, [ballotId, userRole]);

  useEffect(() => {
    const fetchDataMemberRecord = async () => {
      try {
        const responseData = await fetchData(
          `BallotRecord/GetListByParentId?parentId=${ballotId}`
        );
        setRecordList(responseData.collection);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    if (ballotId) {
      fetchDataMemberRecord();
    }
  }, [ballotId]);

  useEffect(() => {
    if (recordLists.length > 0 && activeRecordId === null) {
      const firstRecord = recordLists[0];
      setSelectedRecordApplicantName(
        firstRecord.record.userProfile?.firstname +
          " " +
          firstRecord.record.userProfile?.lastname
      );
      setSelectedRecordId(firstRecord.record.id);
      callFunctionForRecord(firstRecord);
      setActiveRecordId(firstRecord.record.id);
    }
  }, [recordLists, invitedCommittees]);

  useEffect(() => {
    if (recordLists.length > 0) {
      const selectedRecord = recordLists[activeIndex];
      if (selectedRecord !== activeRecordId) {
        callFunctionForRecord(selectedRecord);
        setActiveRecordId(selectedRecord.record.id);
      }
    }
  }, [activeIndex]);

  const handleRecordRediretion = (recordId: any) => {
    sessionStorage.setItem("recordId", recordId);
    navigate("/record/record-preview");
  };

  const callFunctionForRecord = async (record: any) => {
    setLoading(true);
    setNoDataAvailable(false);
    let hasData = false;
    if (invitedCommittees.length > 0) {
      for (const committee of invitedCommittees) {
        try {
          const summary = await fetchVoteSumarray(
            record.record.id,
            committee.value,
            ballotId
          );
          updateVoteSummaries(
            record.record.id,
            committee.value,
            summary,
            record.record.recordNumber
          );
        } catch (error) {
          console.error(
            `Failed to fetch summary for committee: ${committee.label}`,
            error
          );
        }
      }
      // setLoading(false);
    }
    setLoading(false); // Stop loader after fetching summaries

    if (!hasData) {
      setNoDataAvailable(true); // Set no data state if no summaries found
    }
  };

  // Updating vote summaries for each committee and record
  const updateVoteSummaries = (
    recordId: any,
    committeeID: any,
    summary: any,
    recordNumber: string
  ) => {
    setVoteSummaries((prevSummaries: any) => {
      const existingCommitteeSummary =
        prevSummaries[recordId]?.[committeeID] || [];
      if (!summary || summary.length === 0) {
        // Create a default summary if no data is returned
        const committeeName =
          invitedCommittees.find(
            (committee: any) => committee.value === committeeID
          )?.label || "";
        const defaultSummary = [
          {
            BallotId: ballotDetails?.Id,
            BallotNumber: ballotDetails?.BallotNumber,
            CommitteeId: committeeID,
            CommitteesName: committeeName,
            RecordId: recordId,
            RecordNumber: recordNumber,
            //voting option count
            ApproveCount: 0,
            DisapproveCount: 0,
            AbstainCount: 0,
            NotReturnCount: 0,
            //voting option member
            ApprovedMember: "",
            DisapproveMember: "",
            AbstainMember: "",
            NotReturnedMember: "",
            //comment option count
            SubstaintiveCount: 0,
            NonSubstaintiveCount: 0,
            NoCommentCount: 0,
            //comment option member
            SubstaintiveMember: "",
            NonSubstentiveMember: "",
            NoCommentMember: "",
          },
        ];
        return {
          ...prevSummaries,
          [recordId]: {
            ...prevSummaries[recordId],
            [committeeID]: defaultSummary,
          },
        };
      }

      // Check if the new summary needs to be merged or updated
      const needsUpdate = summary.some((item: any) => {
        const existingItem = existingCommitteeSummary.find(
          (existing: any) =>
            existing.BallotId === item.BallotId &&
            existing.CommitteeId === item.CommitteeId
        );
        return !existingItem;
      });
      if (!needsUpdate) {
        // If no update is needed, return previous summaries
        return prevSummaries;
      }

      // Merge the new summary data
      const mergedSummary = summary.reduce((acc: any[], current: any) => {
        const existingItemIndex = acc.findIndex(
          (item) =>
            item.BallotId === current.BallotId &&
            item.CommitteeId === current.CommitteeId
        );
        if (existingItemIndex > -1) {
          const existingItem = acc[existingItemIndex];
          // Update the existing item
          acc[existingItemIndex] = {
            ...existingItem,
            //voting option
            ApproveCount:
              existingItem.ApproveCount +
              (current.VoteName === "Approved" ? current.Count : 0),
            DisapproveCount:
              existingItem.DisapproveCount +
              (current.VoteName === "Disapproved" ? current.Count : 0),
            AbstainCount:
              existingItem.AbstainCount +
              (current.VoteName === "Abstain" ? current.Count : 0),
            NotReturnCount:
              existingItem.NotReturnCount +
              (current.VoteName === "Not Returned" ? current.Count : 0),
            //voting option member
            ApprovedMember:
              existingItem.ApprovedMember +
              (current.VoteName === "Approved" ? `${current.MemberName}` : ""),
            DisapproveMember:
              existingItem.DisapproveMember +
              (current.VoteName === "Disapproved"
                ? `${current.MemberName}`
                : ""),
            AbstainMember:
              existingItem.AbstainMember +
              (current.VoteName === "Abstain" ? `${current.MemberName}` : ""),
            NotReturnedMember:
              existingItem.NotReturnedMember +
              (current.VoteName === "Not Returned"
                ? `${current.MemberName}`
                : ""),
            //comment option
            SubstaintiveCount:
              existingItem.SubstaintiveCount +
              (current.VoteName === "Substantive" ? current.Count : 0),
            NonSubstaintiveCount:
              existingItem.NonSubstaintiveCount +
              (current.VoteName === "Non-Substantive" ? current.Count : 0),
            NoCommentCount:
              existingItem.NoCommentCount +
              (current.VoteName === "No Comment" ? current.Count : 0),
            //comment option member
            SubstaintiveMember:
              existingItem.SubstaintiveMember +
              (current.VoteName === "Substantive"
                ? `${current.MemberName}`
                : ""),
            NonSubstentiveMember:
              existingItem.NonSubstentiveMember +
              (current.VoteName === "Non-Substantive"
                ? `${current.MemberName}`
                : ""),
            NoCommentMember:
              existingItem.NoCommentMember +
              (current.VoteName === "No Comment"
                ? `${current.MemberName}`
                : ""),
          };
        } else {
          // Add new entry if it's not present
          acc.push({
            BallotId: current.BallotId,
            BallotNumber: current.BallotNumber,
            CommitteeId: current.CommitteeId,
            CommitteesName: current.CommitteesName,
            RecordId: current.RecordId,
            RecordNumber: current.RecordNumber,
            //voting option count
            ApproveCount: current.VoteName === "Approved" ? current.Count : 0,
            DisapproveCount:
              current.VoteName === "Disapproved" ? current.Count : 0,
            AbstainCount: current.VoteName === "Abstain" ? current.Count : 0,
            NotReturnCount:
              current.VoteName === "Not Returned" ? current.Count : 0,
            //voting option member
            ApprovedMember:
              current.VoteName === "Approved" ? current.MemberName : "",
            DisapproveMember:
              current.VoteName === "Disapproved" ? current.MemberName : "",
            AbstainMember:
              current.VoteName === "Abstain" ? current.MemberName : "",
            NotReturnedMember:
              current.VoteName === "Not Returned" ? current.MemberName : "",
            //comment option count
            NonSubstaintiveCount:
              current.VoteName === "Non-Substantive" ? current.Count : 0,
            SubstaintiveCount:
              current.VoteName === "Substantive" ? current.Count : 0,
            NoCommentCount:
              current.VoteName === "No Comment" ? current.Count : 0,
            //comment option member
            SubstaintiveMember:
              current.VoteName === "Substantive" ? current.MemberName : "",
            NonSubstentiveMember:
              current.VoteName === "Non-Substantive" ? current.MemberName : "",
            NoCommentMember:
              current.VoteName === "No Comment" ? current.MemberName : "",
          });
        }
        return acc;
      }, existingCommitteeSummary);

      return {
        ...prevSummaries,
        [recordId]: {
          ...prevSummaries[recordId],
          [committeeID]: mergedSummary,
        },
      };
    });
  };

  const fetchVoteSumarray = async (
    recordId: any,
    committeeID: any,
    ballotId: any
  ) => {
    try {
      const ballotTypesData = await fetchData(
        `BallotRecordVote/BallotRecordVoteSummary?Filters[0].Key=ballotid&Filters[0].Value=${ballotId}&Filters[1].Key=recordids&Filters[1].Value=${recordId}&Filters[2].Key=committeeid&Filters[2].Value=${committeeID}`
      );
      return ballotTypesData.Collection;
    } catch (error) {
      console.error("Error fetching data:");
    }
  };
  return (
    <>
      {loading ? (
        <LoadingOverlay visible={loading} />
      ) : (
        <div className="flex flex-column md:flex-row gap-4 mb-5">
          <div className="p-0 bg-white flex flex-column gap-0 md:gap-4 shadow-md w-full md:w-10rem flex-shrink-0">
            <h2 className="m-0 p-3 text-title display-xs">
              {t("ballot.records")}
            </h2>
            <div className="tabs-wrap">
              <TabView
                scrollable
                className="md:vertical-tabs"
                activeIndex={activeIndex}
                onTabChange={(e) => {
                  setActiveIndex(e.index);
                  const selectedRecord = recordLists[e.index];
                  setSelectedRecordApplicantName(
                    selectedRecord.record.userProfile.firstname +
                      " " +
                      selectedRecord.record.userProfile.lastname
                  );
                  setSelectedRecordId(selectedRecord.record.id); // Update the active record ID
                  callFunctionForRecord(selectedRecord); // Call the function for the selected record
                }}
              >
                {recordLists.map((recordData: any, index: any) => (
                  <TabPanel
                    key={index}
                    header={
                      <>
                        {recordData?.record?.recordNumber} <br />{" "}
                        {recordData?.record?.userProfile?.firstname +
                          " " +
                          recordData?.record?.userProfile?.lastname}
                      </>
                    }
                  />
                ))}
              </TabView>
            </div>
          </div>
          <div className="flex flex-column w-full gap-4">
            <div className="bg-white flex flex-wrap align-items-center justify-content-between px-4 py-3 gap-3">
              <div className="flex flex-column gap-1">
                <p className="text-base font-normal text-capitalize m-0">
                  {t("ballot.applicantName")}
                </p>
                <h3 className="text-title text-base font-bold text-capitalize m-0">
                  {selectedRecordApplicantName}
                </h3>
              </div>
              <Button
                label={t("ballot.viewRecord")}
                onClick={() => handleRecordRediretion(selectedRecordId)}
                className="button-md"
                severity="secondary"
              />
            </div>
            {Object.keys(voteSummaries).length === 0 ||
            !activeRecordId ||
            !voteSummaries[activeRecordId] ? (
              <div className="bg-white text-center py-8">No data available</div>
            ) : (
              Object.keys(voteSummaries).map((recordId: any) => {
                if (recordId === activeRecordId) {
                  return (
                    <div key={recordId}>
                      {Object.keys(voteSummaries[recordId]).map(
                        (committeeId) => (
                          <div key={committeeId}>
                            {voteSummaries[recordId][committeeId].map(
                              (summary: SummaryType, index: number) => {
                                const documentStyle = getComputedStyle(
                                  document.documentElement
                                );
                                const isReviewAndComment =
                                  intialInvitedCommittee?.find(
                                    (committee: any) =>
                                      committee.CommitteeId ===
                                        summary.CommitteeId &&
                                      committee.ForReviewAndComment
                                  );
                                const data = {
                                  labels: isReviewAndComment
                                    ? [
                                        "Substantive",
                                        "Non-Substantive",
                                        "No Comment",
                                      ]
                                    : [
                                        "Approved",
                                        "Disapproved",
                                        "Abstained",
                                        "Not Returned",
                                      ],
                                  datasets: [
                                    {
                                      data: isReviewAndComment
                                        ? [
                                            summary.NonSubstaintiveCount,
                                            summary.SubstaintiveCount,
                                            summary.NoCommentCount,
                                          ]
                                        : [
                                            summary.ApproveCount,
                                            summary.DisapproveCount,
                                            summary.AbstainCount,
                                            summary.NotReturnCount,
                                          ],
                                      backgroundColor: isReviewAndComment
                                        ? [
                                            documentStyle.getPropertyValue(
                                              "--approved"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--abstained"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--not-return"
                                            ),
                                          ]
                                        : [
                                            documentStyle.getPropertyValue(
                                              "--approved"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--disapproved"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--abstained"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--not-return"
                                            ),
                                          ],
                                      hoverBackgroundColor: isReviewAndComment
                                        ? [
                                            documentStyle.getPropertyValue(
                                              "--approved"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--abstained"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--not-return"
                                            ),
                                          ]
                                        : [
                                            documentStyle.getPropertyValue(
                                              "--approved"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--disapproved"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--abstained"
                                            ),
                                            documentStyle.getPropertyValue(
                                              "--not-return"
                                            ),
                                          ],
                                    },
                                  ],
                                };
                                const options = {
                                  plugins: {
                                    legend: {
                                      display: false,
                                      labels: {
                                        usePointStyle: true,
                                      },
                                    },
                                  },
                                };
                                return (
                                  <>
                                    <div
                                      className="card bg-white w-full shadow-md mb-4"
                                      key={index}
                                    >
                                      <div className="flex flex-wrap align-items-center px-4 py-3 border-bottom-1 border-gray-200 gap-3 cardHeader">
                                        <h4 className="text-title text-base font-bold text-capitalize m-0">
                                          {summary.CommitteesName}
                                        </h4>
                                        <div className="flex flex-wrap align-items-center gap-2 ml-auto">
                                          <span>{t("ballot.comments")}</span>
                                          <Chip
                                            className="text-sm bg-white border-1 custom-chip"
                                            label={`2/4 ${t("ballot.resolved")}`}
                                          />
                                          <Chip
                                            className="text-sm bg-white border-1 custom-chip"
                                            label={`2/4 ${t("ballot.responded")}`}
                                          />
                                        </div>
                                      </div>
                                      <div className="p-4 cardBody flex flex-column gap-5 lg:flex-row justify-content-between">
                                        <div className="flex flex-column gap-5">
                                          {intialInvitedCommittee?.find(
                                            (committee: any) =>
                                              committee.CommitteeId ===
                                                summary.CommitteeId &&
                                              committee.ForReviewAndComment
                                          ) ? (
                                            <>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.NonSubstaintiveCount >
                                                      0
                                                        ? `${summary.NonSubstaintiveCount} Non-Substantive`
                                                        : `Non-Substantive`
                                                    }
                                                    className="approved"
                                                  />
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.NonSubstentiveMember &&
                                                    summary.NonSubstentiveMember
                                                      .length > 0
                                                      ? summary.NonSubstentiveMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.SubstaintiveCount >
                                                      0
                                                        ? `${summary.SubstaintiveCount} Substantive`
                                                        : `Substantive`
                                                    }
                                                    className="disapproved"
                                                  />
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.SubstaintiveMember &&
                                                    summary.SubstaintiveMember
                                                      .length > 0
                                                      ? summary.SubstaintiveMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.NoCommentCount > 0
                                                        ? `${summary.NoCommentCount} No Comment`
                                                        : `No Comment`
                                                    }
                                                    className="abstained"
                                                  />
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.NoCommentMember &&
                                                    summary.NoCommentMember
                                                      .length > 0
                                                      ? summary.NoCommentMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.NotReturnCount > 0
                                                        ? `${summary.NotReturnCount} Not Returned`
                                                        : `Not Returned`
                                                    }
                                                    className="not-return"
                                                  />
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.NotReturnedMember &&
                                                    summary.NotReturnedMember
                                                      .length > 0
                                                      ? summary.NotReturnedMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.ApproveCount > 0
                                                        ? `${summary.ApproveCount} ${t("ballot.approved")}`
                                                        : `${t("ballot.approved")}`
                                                    }
                                                    className="approved"
                                                  />
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.ApprovedMember &&
                                                    summary.ApprovedMember
                                                      .length > 0
                                                      ? summary.ApprovedMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.DisapproveCount >
                                                      0
                                                        ? `${summary.DisapproveCount} ${t(
                                                            "ballot.disapproved"
                                                          )}`
                                                        : `${t("ballot.disapproved")}`
                                                    }
                                                    className="disapproved"
                                                  ></Badge>
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.DisapproveMember &&
                                                    summary.DisapproveMember
                                                      .length > 0
                                                      ? summary.DisapproveMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.AbstainCount > 0
                                                        ? `${summary.AbstainCount} Abstain`
                                                        : "Abstain"
                                                    }
                                                    className="abstained"
                                                  ></Badge>
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.AbstainMember &&
                                                    summary.AbstainMember
                                                      .length > 0
                                                      ? summary.AbstainMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-column gap-2">
                                                <div>
                                                  <Badge
                                                    value={
                                                      summary.NotReturnCount > 0
                                                        ? `${summary.NotReturnCount} Not Return`
                                                        : "Not Returned"
                                                    }
                                                    className="not-return"
                                                  ></Badge>
                                                </div>
                                                <div>
                                                  <span>
                                                    {summary.NotReturnedMember &&
                                                    summary.NotReturnedMember
                                                      .length > 0
                                                      ? summary.NotReturnedMember.split(
                                                          ","
                                                        )
                                                          .map((member) =>
                                                            member.trim()
                                                          )
                                                          .filter(
                                                            (member) =>
                                                              member !== ""
                                                          )
                                                          .join(", ")
                                                      : "None"}
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div className="flex px-3 py-3 xl:px-8 xl:py-6 justify-content-center align-items-center">
                                          <Chart
                                            type="pie"
                                            data={data}
                                            options={options}
                                            className="w-auto md:w-16rem"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </div>
                        )
                      )}
                    </div>
                  );
                }
              })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BallotTabVoteSummary;
