import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useNavigate } from "react-router-dom";
import { useScrollOnPaginatorClick } from "../../common/useScrollOnPaginatorClick";
import { TableProps, Product } from "./BallotListingTableType";
import StatusBodyTemplate from "./StatusBodyTemplate";

const BallotListingTable: React.FC<TableProps> = ({
  products,
  loading,
  columns,
  showCheckbox,
  footerGroup,
  getActionItems,
  disablePagination = false,
}) => {
  const [actionItems, setActionItems] = useState<MenuItem[]>([]);
  const menuRight = useRef<Menu>(null);
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const toggleMenu = (event: any, rowData?: any) => {
    if (rowData) {
      setActionItems(getActionItems(rowData));
    }
    if (menuRight.current) {
      menuRight.current.toggle(event);
    }
  };

  const handleNameClick = (link: string, id: any) => {
    if (link === "/ballot") {
      navigate(`${link}/${id}`);
    }
  };

  const actionBodyTemplate = (rowData: Product, col: { field: string }) => {
    return (
      <>
        <span className="p-column-title">{getHeaderLabel(col.field)}</span>
        <Menu
          model={actionItems}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
        <Button
          text
          className="text-body gap-1"
          aria-haspopup="true"
          aria-label="Action"
          onClick={(e) => toggleMenu(e, rowData)}
        >
          <FeatherIcon name="settings" size={20} color="var(--text-body)" />
          <span className="font-normal text-body">Action</span>
        </Button>
      </>
    );
  };

  const getHeaderLabel = (field: string) => {
    const column = columns.find((col) => col.field === field);
    return column ? column.header : "";
  };

  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (products !== null && products !== undefined) {
    const isEmpty = products.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }

  return (
    <div ref={tableContainerRef}>
      <DataTable
        className={tableClass}
        footerColumnGroup={footerGroup}
        value={products}
        loading={loading}
        stripedRows
        paginator={!disablePagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rows={10}
        tableStyle={{ minWidth: "50rem" }}
        selectionMode={showCheckbox ? "multiple" : "checkbox"}
        selection={selectedProducts}
        onSelectionChange={(e: { value: any }) => {
          setSelectedProducts(e.value);
        }}
      >
        {columns
          .filter(
            (item: any) =>
              item.field !== "statusName" && item.field !== "action"
          )
          .map((col, i) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              sortable={col.sortable !== undefined ? col.sortable : true}
              body={(rowData) => (
                <>
                  <span className="p-column-title">{col.header}</span>
                  {col.link ? (
                    <a
                      onClick={() => handleNameClick(col.link, rowData.Id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNameClick(col.link, rowData.Id);
                        }
                      }}
                      className="font-bold table-text-link underline"
                      tabIndex={0}
                    >
                      {rowData[col.field]}
                    </a>
                  ) : col.body ? (
                    col.body(rowData)
                  ) : (
                    rowData[col.field]
                  )}
                </>
              )}
            />
          ))}

        {columns.some((col) => col.field === "statusName") && (
          <Column
            field="statusName"
            header={columns.find((col) => col.field === "statusName")?.header}
            sortable={
              columns.find((col) => col.field === "statusName")?.sortable ??
              true
            }
            body={(rowData) => (
              <StatusBodyTemplate
                rowData={rowData}
                col={{ field: "statusName" }}
                getHeaderLabel={getHeaderLabel}
              />
            )}
          />
        )}
        {columns.some((col) => col.field === "action") && (
          <Column
            field="action"
            header={columns.find((col) => col.field === "action")?.header}
            body={actionBodyTemplate}
          />
        )}
      </DataTable>
    </div>
  );
};

export default BallotListingTable;
