import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import SearchField from "../common/StandardSearch";
import FilterField from "../common/StandardFilter";
import DataGrid from "../common/DataGrid";
import { STANDARD_TABLE_COLUMNS } from "../../components/standard/columnStandard";
import useStandard from "../../hooks/useStandard";
import { useDispatch } from "react-redux";
import { reset } from "../../slices/createStandard";
import { usePermission } from "../auth/Authorization";
const ManagementList: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(search);
  const [sortOrder, setSortOrder] = useState<number>(1);
  const [sortField, setSortField] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [Filters, setDataFilter] = useState([]);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState("");

  const dispatch = useDispatch();
  dispatch(reset());

  const { data, loading, error, totalCount } = useStandard({
    PageIndex: pageIndex,
    PageSize: pageSize,
    SearchText: debouncedSearchTerm,
    SortBy: sortField,
    SortDir: sortOrder === 1 ? "Asc" : "Desc",
    Filters,
  });
  const { selectedLanguage } = useLanguageContext();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  const collection = (data as any)?.Collection || [];

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const handleDataForFilter = (data: any) => {
    setDataFilter(data);
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  return (
    <>
      <div>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("standard.standardsManagement")}
          </h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>
        <div>
          <div className="flex flex-wrap md:flex-nowrap md:justify-content-between align-items-center py-3 gap-3 w-full">
            <SearchField onSearch={setSearch} />
            <div className="flex flex-wrap md:flex-nowrap gap-3 w-full md:w-auto align-items-center">
              <div className="flex gap-3 w-full md:w-auto align-items-center">
                <div className="relative">
                  <FilterField onFilterApply={handleDataForFilter} />
                </div>
                {userRole !== "member" && (
                  <Button
                    className="button-md gap-1 justify-content-center w-full md:w-auto"
                    onClick={() => navigate("/standard/create")}
                  >
                    <FeatherIcon name="plus" size={20} color="inherit" />
                    <span className="font-bold text-capitalize">
                      {t("standard.newStandard")}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-column gap-2 mt-5">
            <DataGrid
              loading={loading}
              products={collection}
              columns={STANDARD_TABLE_COLUMNS(t)}
              showCheckbox={false}
              pageIndex={pageIndex}
              totalRecords={totalCount}
              pageSize={pageSize}
              onPageChange={pageHandler}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortChange={sortHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementList;
