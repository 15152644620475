import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import CommitteeSteps from "./CommitteeSteps";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Link, useNavigate } from "react-router-dom";
import "./Committee.scss";
import { fetchData, postData, putData } from "../../services/apiService";
import ConfirmDialogComponent from "../common/ConfirmDialogComponent";
import {
  formatCongigurationCommitteeData,
  getCommitteeData,
  setOptionsFromResponse,
  formatMembershipRequest,
  getMembershipStatusIdByName,
  // calculateEndDate,
} from "../../utils/utils";
import { AutoComplete, AutoCompleteChangeEvent } from "primereact/autocomplete";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  addMembersInCommitteeRequest,
  addMembersInCommitteeReset,
  categoryOfInteresetRequest,
  configureCommitteeRequest,
  createCommitteeRequest,
} from "../../slices/createCommitteeSlice";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import ToastMessage from "../common/ToastMessage";
import { Toast } from "primereact/toast";
import { useLoadingContext } from "../Layout";
import { focusOnErrorField } from "../../utils/focusError";
import { focusOnField } from "../../utils/focusField";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";

interface CommitteeMembersObject {
  membershipPositionId: any;
  categoryOfInterestId: string;
  tenureLength: string;
  name: any;
  startDate?: Date;
  endDate?: Date;
  Id?: string;
  // staffName?: string;
}

interface User {
  Collection: Array<any>;
}

interface Option {
  name: string;
  code: string;
  id?: string;
}

interface ToastMessage {
  message: string;
  type: string;
}

const CommitteeMembers: React.FC = () => {
  const { setLoading } = useLoadingContext();
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );

  const [editModeForMemberCommittee, setEditModeForMemberCommittee] =
    useState(false);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committee"), url: "/committee" },
    { label: t("committee.createNewCommittee") },
  ];

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  const [categoryOfInterestOption, setCategoryOfInterestOption] = useState<
    Option[] | undefined
  >(undefined);
  const [committeePositionOption, setCommitteePositionOption] = useState<
    Option[] | undefined
  >(undefined);
  //const [staffPersonOption, setStaffPersonOption] = useState<any>();
  const [toasterFlag, setToasterFlag] = useState(false);
  const [allUsers, setAllUsers] = useState<any>();
  const [allUsersOption, setAllUsersOption] = useState<Option[] | undefined>(
    undefined
  );
  // const [filteredStaff, setFilteredStaff] = useState<string[] | undefined>([]);
  const [filteredUsers, setFilteredUsers] = useState<string[] | undefined>([]);
  const IntialMemberObject = {
    membershipPositionId: "",
    categoryOfInterestId: "",
    tenureLength: "",
    name: "",
    startDate: undefined,
    endDate: undefined,
  };
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [committeeMembersObject, setCommitteeMembersObject] = useState<
    CommitteeMembersObject[]
  >([IntialMemberObject]);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];

  const committeeMemberLableForValidation = [
    { name: "membershipPositionId", lable: t("committee.committeePosition") },
    {
      name: "categoryOfInterestId",
      lable: t("committee.categoryOfInterest"),
    },
    { name: "tenureLength", lable: t("committee.tenureLength") },
    { name: "name", lable: t("committee.name") },
    { name: "endDate", lable: t("committee.endDate") },
    { name: "startDate", lable: t("committee.startDate") },
  ];

  const percent = 70; // Set your percentage here

  // Define the style object as a regular JavaScript object
  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };
  //const [staff, setStaff] = useState<any>("");
  const [error, setError] = useState<Partial<CommitteeMembersObject[]>>([
    {
      membershipPositionId: "",
      categoryOfInterestId: "",
      tenureLength: "",
      name: "",
    },
  ]);
  const [tosterMessage, setTosterMessage] = useState<ToastMessage[]>([]);
  const navigate = useNavigate();
  const [tenureList, setTenureList] = useState(["1 year"]);

  const handleTenureDropdown = (len: number) => {
    const yearsArray = Array.from({ length: len }, (_, index) => ({
      name: `${index + 1} year`,
    }));

    return yearsArray;
  };

  const findIdByName = (data: any, value: string) => {
    const regExp = /\(([^)]+)\)/;
    var matches: RegExpExecArray | null = regExp.exec(value);
    const Id = data?.find(
      (obj: any) => obj.Email === (matches ? matches[1] : "")
    );
    return Id;
  };
  const calculateTenureLength = (startDate?: Date, endDate?: Date) => {
    if (!startDate || !endDate) return "";
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const tenureLength = endYear - startYear;
    return tenureLength.toString();
  };

  const handleInputChange = (e: any, idx: number) => {
    setError([]);
    const { name, value } = e.target;
    if (name === "name") {
      let UserProfileId = findIdByName(allUsers.Collection, value);
      if (UserProfileId) {
        const updateCommitteMembersObject = committeeMembersObject.map(
          (data, i) =>
            i === idx
              ? { ...data, UserProfileId: value.id, [name]: value.name }
              : data
        );
        setCommitteeMembersObject(updateCommitteMembersObject);
      } else {
        const updateCommitteMembersObject = committeeMembersObject.map(
          (data, i) => (i === idx ? { ...data, [name]: value } : data)
        );
        setCommitteeMembersObject(updateCommitteMembersObject);
      }
    } else if (name === "startDate") {
      const currentTime = new Date();
      const startDateWithTime = new Date(value);

      // Set the selected start date with the current time
      startDateWithTime.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      const endDate = handleTenureLengthSelection(
        committeeMembersObject[idx].tenureLength,
        startDateWithTime
      );

      const updateCommitteMembersObject = committeeMembersObject.map(
        (data, i) =>
          i === idx
            ? { ...data, [name]: startDateWithTime, endDate: endDate }
            : data
      );

      setCommitteeMembersObject(updateCommitteMembersObject);
    } else if (name === "tenureLength") {
      const endDate = handleTenureLengthSelection(
        value,
        committeeMembersObject[idx].startDate
      );
      const updateCommitteMembersObject = committeeMembersObject.map(
        (data, i) =>
          i === idx ? { ...data, [name]: value, endDate: endDate } : data
      );
      setCommitteeMembersObject(updateCommitteMembersObject);
    } else if (name === "endDate") {
      const selectedDate = new Date(value);
      const currentTime = new Date();

      // Set the selected date with the current time
      selectedDate.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      const newTenureLength = calculateTenureLength(
        committeeMembersObject[idx].startDate,
        selectedDate
      );

      const updatedCommitteeMembers = committeeMembersObject.map((data, i) =>
        i === idx
          ? { ...data, endDate: selectedDate, tenureLength: newTenureLength }
          : data
      );
      setCommitteeMembersObject(updatedCommitteeMembers);
    } else {
      const updateCommitteMembersObject = committeeMembersObject.map(
        (data, i) => (i === idx ? { ...data, [name]: value } : data)
      );
      setCommitteeMembersObject(updateCommitteMembersObject);
    }
  };

  const hasNonEmptyValue = (committeeMemberObject: any) => {
    for (let i = 0; i < committeeMemberObject.length; i++) {
      const obj = committeeMemberObject[i];
      for (const key in obj) {
        if (
          obj.hasOwnProperty(key) &&
          obj[key] !== "" &&
          obj[key] !== undefined
        ) {
          return true;
        }
      }
    }
    if (editModeForMemberCommittee) {
      return true;
    }
    return false;
  };

  const validationForCommitteeCreation = (data: any[], fields: any[]) => {
    let valid = true;
    const newError: any[] = [];

    data.forEach((row, index) => {
      const error: any = {};
      fields.forEach((field) => {
        if (hasNonEmptyValue(committeeMembersObject)) {
          if (row[field.name] === "" || !row[field.name]) {
            error[field.name] = `${field.lable} ${t("committee.isRequired")}`;
            valid = false;
          }
          // Add other validations here for other fields if needed
        }
      });
      newError.push(error);
    });

    setError(newError);
    return valid;
  };

  const handleMembersDropdownOptions = async () => {
    const [CommitteePositionResponse, UserProfileResponse] = await Promise.all([
      fetchData("MembershipPosition/GetAll"),
      fetchData("UserProfile/SearchVolenteerMembers"),
    ]);
    const CommitteePositionObject: Option[] = [
      ...setOptionsFromResponse(CommitteePositionResponse, "PositionName"),
    ];

    const CategoryOfInterestObject: Option[] = Object.values(
      createCommitteeData.categoryOfIntereset
    ).map((data: any) => data.categoryOfInterestValue);
    const UserProfileObject: Option[] = [
      ...setOptionsFromResponse(UserProfileResponse, "Email"),
    ];
    setCategoryOfInterestOption(CategoryOfInterestObject);
    setCommitteePositionOption(CommitteePositionObject);
    setAllUsersOption(UserProfileObject);
    setAllUsers(UserProfileResponse);
  };

  const messagesType = (type: string) => {
    if (type.toLowerCase() === "warning") {
      return "warn";
    } else if (type.toLowerCase() === "error") {
      return "error";
    } else if (type.toLowerCase() === "info") {
      return "info";
    }
  };

  const handleToasterMessage = (toaster: any) => {
    const toasterShow = toaster.map((data: any) => ({
      severity: data.type,
      summary: data.type === "warn" ? "warning" : data.type,
      detail: data.message,
      life: 8000,
    }));
    toast.current?.show(toasterShow);
  };

  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const [visible, setVisible] = useState<boolean>(false);
  const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

  const handleConfirm = () => {
    if (isEditCancel) {
      if (editModeForMemberCommittee) {
        sessionStorage.setItem("redirectionPath", "memberCancel");
        let committeeId = sessionStorage.getItem("committeeId");
        navigate(`/committee/details/${committeeId}`);
      }
    } else {
      navigate("/committee/");
    }
  };

  const handleCancelDialog = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(true);
  };

  const handleIsEditCancel = () => {
    setIsEditCancel(true);
    setVisible(true);
  };

  const handleSaveAndContinue = async (e: any, isDraft: string = "false") => {
    let isValid = validationForCommitteeCreation(
      committeeMembersObject,
      committeeMemberLableForValidation
    );
    if (isValid) {
      e.preventDefault();

      let toaster = [];
      if (!toasterFlag && hasNonEmptyValue(committeeMembersObject)) {
        setLoading(true);
        const membershipRequest = await formatMembershipRequest(
          committeeMembersObject,
          createCommitteeData.createCommittee.committeeId,
          true
        );
        const changeCommitteeStatus = await formatCongigurationCommitteeData(
          createCommitteeData.configureCommittee,
          createCommitteeData.createCommittee,
          true
        );

        if (editModeForMemberCommittee) {
          const committeeResponse = await putData(
            "Committee",
            createCommitteeData.createCommittee.committeeId,
            changeCommitteeStatus
          );
        }
        try {
          const membershipResponse = await postData(
            `CommitteeMembership/CommitteeMembersBulkUpload/${createCommitteeData.createCommittee.committeeId}`,
            membershipRequest
          );
          setLoading(false);
          toaster = membershipResponse?.sysMessages?.map(
            (message: string, idx: number) => ({
              message: message,
              type: messagesType(membershipResponse?.sysMessagesType[idx]),
            })
          );
        } catch (error: any) {
          setLoading(false);
          if (
            error.response?.data?.errors?.requestModel[0]?.includes(
              "limit has reached"
            )
          ) {
            errorMessageToast(t("committee.allowedMemberLimitReached"));
          }
        }
      }

      if (toaster.length > 0) {
        handleToasterMessage(toaster);
        setToasterFlag(true);
      }

      setTosterMessage(toaster);
      if (editModeForMemberCommittee) {
        sessionStorage.setItem("redirectionPath", "member");
      }
      dispatch(addMembersInCommitteeRequest(committeeMembersObject));
      if (toaster.length === 0) {
        if (editModeForMemberCommittee) {
          let committeeId = sessionStorage.getItem("committeeId");
          navigate(`/committee/details/${committeeId}`);
        } else if (isDraft === "saveAsDraft") {
          navigate("/committee");
        } else {
          setLoading(false);
          navigate("/committee/preview");
        }
      }
    } else {
      setTimeout(() => {
        focusOnErrorField(".p-invalid");
      }, 100);
      console.log(error);
    }
  };

  const handleAddCommitteeMembers = () => {
    setCommitteeMembersObject([
      ...committeeMembersObject,
      {
        membershipPositionId: "",
        categoryOfInterestId: "",
        tenureLength: "",
        name: "",
      },
    ]);
    // To focus on newly added row first element "committeeName"
    setTimeout(() => {
      focusOnField(`committeeName-${committeeMembersObject.length}`);
    }, 0); // Delay of 0 milliseconds
  };

  const handleDeleteCommitteeMember = async (idx: number) => {
    if (committeeMembersObject.length > 1) {
      const updateCommitteMembers = [...committeeMembersObject];
      if (updateCommitteMembers[idx].Id && !editModeForMemberCommittee) {
        const membershipRequest = await formatMembershipRequest(
          [updateCommitteMembers[idx]],
          createCommitteeData.createCommittee.committeeId,
          true,
          true
        );
        const membershipResponse = await postData(
          `CommitteeMembership/CommitteeMembersBulkUpload/${createCommitteeData.createCommittee.committeeId}`,
          membershipRequest
        );
        dispatch(addMembersInCommitteeReset());
      }
      updateCommitteMembers.splice(idx, 1);
      setCommitteeMembersObject(updateCommitteMembers);
      dispatch(addMembersInCommitteeRequest(updateCommitteMembers));
    } else {
      console.log("atleat one");
    }
  };
  const handleRefershCommitteMember = async (Id: string) => {
    const [
      createCommitteeData,
      configureCommitteeData,
      committeeMemberData,
      categoryOfInterestData,
    ] = await getCommitteeData();
    dispatch(createCommitteeRequest(createCommitteeData));
    dispatch(configureCommitteeRequest(configureCommitteeData));
    dispatch(addMembersInCommitteeRequest(committeeMemberData));
    dispatch(categoryOfInteresetRequest(categoryOfInterestData));
    let formatedMembershipData: any = formatMemberData(committeeMemberData);
    setCommitteeMembersObject(formatedMembershipData);
    const CategoryOfInterestObject: Option[] = categoryOfInterestData.map(
      (data: any) => data.categoryOfInterestValue
    );
    const uniqueCategoryOfInterestObject = CategoryOfInterestObject.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );
    setCategoryOfInterestOption(uniqueCategoryOfInterestObject);
  };

  const formatMemberData = (memberData: any) => {
    let formatedData = [IntialMemberObject];
    if (memberData.length > 0) {
      formatedData = memberData?.map((data: any) => {
        return {
          membershipPositionId: data.membershipPositionId,
          categoryOfInterestId: data.categoryOfInterestId,
          tenureLength: data.tenureLength,
          name: data.name,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
          Id: data.Id,
          UserProfileId: data.UserProfileId,
        };
      });
    }

    return formatedData;
  };

  const getEndDateMax = (startDate: string, tenureLength: any): Date => {
    const len = parseInt(tenureLength);
    if (isNaN(len)) {
      throw new Error("Invalid tenure length provided");
    }
    const startDateObj = new Date(startDate);
    startDateObj.setFullYear(startDateObj.getFullYear() + len);
    return startDateObj;
  };

  const handleTenureLengthSelection = (
    tenureLength: string,
    startDate?: Date
  ) => {
    const membershipExpires =
      createCommitteeData.configureCommittee.membershipExpiresOn_All;

    if (startDate && tenureLength) {
      let startDateObj = getEndDateMax(startDate.toString(), tenureLength);
      const membershipExpiresDate = new Date(membershipExpires);

      // Adding current time to end date
      const currentTime = new Date();
      startDateObj.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );

      if (
        membershipExpires &&
        startDate.getMonth() >= membershipExpiresDate.getMonth()
      ) {
        if (
          startDate.getMonth() == membershipExpiresDate.getMonth() &&
          startDate.getDate() < membershipExpiresDate.getDate()
        ) {
          startDateObj = getEndDateMax(startDate.toString(), tenureLength);
          startDateObj.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
        } else {
          const date = membershipExpiresDate.getDate();
          const month = membershipExpiresDate.getMonth();
          startDateObj.setMonth(month, date);
        }
      }

      return startDateObj;
    }
  };

  useEffect(() => {
    handleMembersDropdownOptions();
    let editMode = sessionStorage.getItem("editMemberCommittee");
    if (editMode === "On") {
      setEditModeForMemberCommittee(true);
    }
    const committeeId = sessionStorage.getItem("committeeId");
    if (committeeId && !editMode) {
      handleRefershCommitteMember(committeeId);
    }
    if (
      Object.keys(createCommitteeData.addMembersInCommittee).length > 0 &&
      !editMode
    ) {
      let draftedData: any = Object.values(
        createCommitteeData.addMembersInCommittee
      )
        .filter((val) => typeof val === "object")
        .map((val) => val);
      let committeeMemberFormatData: any = formatMemberData(draftedData);
      setCommitteeMembersObject(committeeMemberFormatData);
    }

    let yearsArray: any = handleTenureDropdown(
      parseInt(
        createCommitteeData?.configureCommittee.msExpire_Member_Tenure_Years
      )
    );
    setTenureList(yearsArray);
  }, []);

  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }
  const [positionOptionFlag, setPositionOptionFlag] = useState(true);
  useEffect(() => {
    if (
      committeePositionOption &&
      editModeForMemberCommittee &&
      !isEmpty(createCommitteeData.addMembersInCommittee)
    ) {
      if (positionOptionFlag) {
        setPositionOptionFlag(false);
        handlePreviousSelectedChairPostions();
        handlePreviousSelectedUser();
      }
    }
  }, [committeePositionOption]);

  const handlePreviousSelectedChairPostions = () => {
    let previousAddedPostions = Object.values(
      createCommitteeData?.addMembersInCommittee
    )?.map((data: any) => {
      return data.membershipPositionId.name;
    });
    const chairArray = ["Volunteer Secretary", "Vice Chair", "Chair"];
    let chairPostions = previousAddedPostions.filter((position) =>
      chairArray.includes(position)
    );
    const updatedCommitteePostionOptions = committeePositionOption?.filter(
      (data) => !chairPostions.includes(data.name)
    );
    setCommitteePositionOption(updatedCommitteePostionOptions);
  };

  const handlePreviousSelectedUser = async () => {
    let previousAddedUser = Promise.all(
      Object.values(createCommitteeData?.addMembersInCommittee)
        ?.filter(
          async (data: any) =>
            data.membershipStatusId ===
            (await getMembershipStatusIdByName("Current"))
        )
        .map((data: any) => {
          return data.name.id;
        })
    );
    const previousAddedUserResult = await previousAddedUser;
    const newUsersOptions = allUsersOption?.filter(
      (user) => !previousAddedUserResult.includes(user.id)
    );
    setAllUsersOption(newUsersOptions);
  };

  return (
    <>
      <div className="">
        {!editModeForMemberCommittee && (
          <CustomBreadcrumb
            model={breadcumbItems}
            className="bg-transparent mb-5 px-0 py-2 border-none"
          />
        )}
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {editModeForMemberCommittee
              ? t("committee.editCommittee")
              : t("committee.createNewCommittee")}
          </h1>
        </div>
        <ConfirmDialogComponent
          visible={visible}
          onHide={() => setVisible(false)}
          message="Are you sure you want to exit?"
          header="Confirmation"
          onConfirm={() => handleConfirm()}
          onCancel={() => handleCancelDialog()}
        />

        {!editModeForMemberCommittee && <CommitteeSteps activeStep={2} />}

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-1">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("committee.addCommitteeStaffAndMembers")}
              </h2>
              <p className="text-base font-normal m-0 text-help">
                {t("committee.allFieldsRequired")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="flex flex-column gap-5">
                <h3 className="font-normal text-lg m-0">
                  {t("committee.committeeMembers")}{" "}
                  {!editModeForMemberCommittee
                    ? `(${t("committee.optional")})`
                    : ""}
                </h3>

                <div className="flex flex-column gap-1">
                  <div className="grid m-0 w-full gap-3 grid-resp grid-resp-lables member-grid">
                    <span
                      className={`font-bold text-capitalize ${error[0]?.name ? "p-error" : ""}`}
                      id="committeeName"
                    >
                      {t("committee.name")}
                    </span>
                    <span
                      className={`font-bold text-capitalize ${
                        error[0]?.membershipPositionId ? "p-error" : ""
                      }`}
                      id="committeePosition"
                    >
                      {t("committee.committeePosition")}
                    </span>
                    <span
                      className={`font-bold text-capitalize ${error[0]?.startDate ? "p-error" : ""}`}
                      id="startDate"
                    >
                      {t("committee.startDate")}
                    </span>
                    <span
                      className={`font-bold text-capitalize ${
                        error[0]?.tenureLength ? "p-error" : ""
                      }`}
                      id="tenureLength"
                    >
                      {t("committee.tenureLength")}
                    </span>
                    <span
                      className={`font-bold text-capitalize ${error[0]?.endDate ? "p-error" : ""}`}
                      id="endDate"
                    >
                      {t("committee.endDate")}
                    </span>
                    <span
                      className={`font-bold text-capitalize ${
                        error[0]?.categoryOfInterestId ? "p-error" : ""
                      }`}
                      id="categoryOfInterest"
                    >
                      {t("committee.categoryOfInterest")}
                    </span>
                  </div>
                  <div className="flex flex-column gap-3 w-full">
                    {committeeMembersObject?.map((val, idx) => (
                      <div className="grid m-0 w-full gap-3 grid-resp member-grid">
                        <div
                          className="flex flex-column gap-2 data-label"
                          data-label={t("committee.name")}
                        >
                          <Dropdown
                            filter
                            inputId={`committeeName-${idx}`}
                            aria-describedby={`committeeNameError-${idx}`}
                            value={committeeMembersObject[idx]?.name}
                            onChange={(e) => handleInputChange(e, idx)}
                            options={allUsersOption?.filter((option) => {
                              return committeeMembersObject.every(
                                (item, index) => {
                                  return (
                                    index === idx ||
                                    item.name?.id !== option?.id
                                  );
                                }
                              );
                            })}
                            name="name"
                            optionLabel="name"
                            placeholder={t("committee.name")}
                            className={`w-full ${error[idx]?.name ? "p-invalid" : ""}`}
                            aria-labelledby="committeeName"
                          />
                          <span
                            id={`committeeNameError-${idx}`}
                            className={`p-error font-bold text-capitalize ${error[idx]?.name ? "" : "error-hidden"}`}
                          >
                            {error[idx]?.name}
                          </span>
                        </div>
                        <div
                          className="flex flex-column gap-2 data-label"
                          data-label={t("committee.committeePosition")}
                        >
                          <Dropdown
                            inputId={`committeePosition-${idx}`}
                            aria-describedby={`committeePositionError-${idx}`}
                            name="membershipPositionId"
                            onChange={(e) => handleInputChange(e, idx)}
                            value={
                              committeeMembersObject[idx].membershipPositionId
                            }
                            options={committeePositionOption?.filter(
                              (option) => {
                                if (
                                  option.name === "Volunteer Secretary" ||
                                  option.name === "Chair" ||
                                  option.name === "Vice Chair"
                                ) {
                                  return committeeMembersObject.every(
                                    (item, index) => {
                                      return (
                                        index === idx ||
                                        item.membershipPositionId?.id !==
                                          option?.id
                                      );
                                    }
                                  );
                                } else {
                                  return option;
                                }
                              }
                            )}
                            optionLabel="name"
                            placeholder={t("committee.selectAnOption")}
                            className={`w-full ${
                              error[idx]?.membershipPositionId
                                ? "p-invalid"
                                : ""
                            }`}
                            aria-labelledby="committeePosition"
                          />
                          <span
                            id={`committeePositionError-${idx}`}
                            className={`p-error font-bold text-capitalize ${error[idx]?.membershipPositionId ? "" : "error-hidden"}`}
                          >
                            {error[idx]?.membershipPositionId}
                          </span>
                        </div>
                        <div
                          className="flex flex-column gap-2 data-label"
                          data-label={t("committee.startDate")}
                        >
                          <Calendar
                            appendTo="self"
                            inputId={`startDate-${idx}`}
                            aria-describedby={`startDateError-${idx}`}
                            minDate={
                              createCommitteeData.configureCommittee
                                ?.createdDate
                                ? new Date(
                                    createCommitteeData.configureCommittee?.createdDate
                                  )
                                : new Date()
                            }
                            value={committeeMembersObject[idx].startDate}
                            dateFormat="mm/dd/yy"
                            placeholder={t("committee.startDate")}
                            className={`custom-datepicker ${error[idx]?.startDate ? "p-invalid" : ""}`}
                            onChange={(e) => handleInputChange(e, idx)}
                            name="startDate"
                            ariaLabelledBy="startDate"
                          />
                          <span
                            id={`startDateError-${idx}`}
                            className={`p-error font-bold text-capitalize ${error[idx]?.startDate ? "" : "error-hidden"}`}
                          >
                            {error[idx]?.startDate?.toString()}
                          </span>
                        </div>
                        <div
                          className="flex flex-column gap-2 data-label"
                          data-label={t("committee.tenureLength")}
                        >
                          <InputText
                            id={`tenureLength-${idx}`}
                            aria-describedby={`tenureLengthError-${idx}`}
                            name="tenureLength"
                            type="number"
                            value={committeeMembersObject[idx].tenureLength}
                            onChange={(e) => handleInputChange(e, idx)}
                            placeholder={t("committee.tenureLength")}
                            className={`w-full ${
                              error[idx]?.tenureLength ? "p-invalid" : ""
                            }`}
                            aria-labelledby="tenureLength"
                            min="1"
                            max="99"
                          />
                          <span
                            id={`tenureLengthError-${idx}`}
                            className={`p-error font-bold text-capitalize ${error[idx]?.tenureLength ? "" : "error-hidden"}`}
                          >
                            {error[idx]?.tenureLength}
                          </span>
                        </div>
                        <div
                          className="flex flex-column gap-2 data-label"
                          data-label={t("committee.endDate")}
                        >
                          <Calendar
                            appendTo="self"
                            disabled={
                              committeeMembersObject[idx].startDate
                                ? false
                                : true
                            }
                            inputId={`endDate-${idx}`}
                            aria-describedby={`endDateError-${idx}`}
                            name="endDate"
                            minDate={committeeMembersObject[idx].startDate}
                            value={committeeMembersObject[idx].endDate}
                            dateFormat="mm/dd/yy"
                            placeholder={t("committee.endDate")}
                            className={`custom-datepicker ${error[idx]?.startDate ? "p-invalid" : ""}`}
                            onChange={(e) => handleInputChange(e, idx)}
                            ariaLabelledBy="endDate"
                          />
                          <span
                            id={`endDateError-${idx}`}
                            className={`p-error font-bold text-capitalize ${error[idx]?.endDate ? "" : "error-hidden"}`}
                          >
                            {error[idx]?.endDate?.toString()}
                          </span>
                        </div>

                        <div
                          className="flex flex-column gap-2 data-label"
                          data-label={t("committee.categoryOfInterest")}
                        >
                          <Dropdown
                            inputId={`categoryOfInterest-${idx}`}
                            aria-describedby={`categoryOfInterestError-${idx}`}
                            name="categoryOfInterestId"
                            onChange={(e) => handleInputChange(e, idx)}
                            value={
                              committeeMembersObject[idx].categoryOfInterestId
                            }
                            options={categoryOfInterestOption}
                            optionLabel="name"
                            placeholder={t("committee.selectAnOption")}
                            className={`w-full ${
                              error[idx]?.categoryOfInterestId
                                ? "p-invalid"
                                : ""
                            }`}
                            aria-labelledby="categoryOfInterest"
                          />
                          <span
                            id={`categoryOfInterestError-${idx}`}
                            className={`p-error font-bold text-capitalize ${error[idx]?.categoryOfInterestId ? "" : "error-hidden"}`}
                          >
                            {error[idx]?.categoryOfInterestId}
                          </span>
                        </div>
                        {committeeMembersObject.length > 1 && (
                          <Button
                            text
                            className="p-button-plain p-2 text-delete"
                            aria-label={t("committee.delete")}
                          >
                            <FeatherIcon
                              name="trash-2"
                              size={20}
                              onClick={() => handleDeleteCommitteeMember(idx)}
                            />
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <Button
                  text
                  className="p-button-plain gap-1 pl-0 underline"
                  onClick={handleAddCommitteeMembers}
                >
                  <FeatherIcon name="plus" size={20} color="inherit" />
                  <span className="font-bold text-capitalize">
                    {t("committee.addCommitteeMember")}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Toast ref={toast} />
        {editModeForMemberCommittee ? (
          <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
            <Button
              label={t("committee.cancel")}
              className="button-md"
              severity="secondary"
              onClick={() => handleIsEditCancel()}
            />

            <Button
              className="button-md gap-1"
              onClick={(e) => handleSaveAndContinue(e)}
            >
              <span className="font-bold text-capitalize">
                {t("committee.submit")}
              </span>
              <FeatherIcon name="chevron-right" size={20} color="inherit" />
            </Button>
            {/* </ToastMessage> */}
          </div>
        ) : (
          <>
            <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("committee.cancel")}
                className="button-md"
                severity="secondary"
                onClick={() => handleCancel()}
              />
              <div className="flex md:flex-row gap-4">
                <Button
                  text
                  className="p-button-plain underline"
                  label={t("committee.saveAsDraft")}
                  onClick={(e) => handleSaveAndContinue(e, "saveAsDraft")}
                ></Button>
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={() => {
                    sessionStorage.setItem(
                      "isReturningFromCommitteeMember",
                      "true"
                    );
                    navigate("/committee/configuration");
                  }}
                >
                  <FeatherIcon name="chevron-left" size={20} color="inherit" />
                  <span className="font-bold text-capitalize">
                    {t("committee.back")}
                  </span>
                </Button>
                {/* <Toast ref={toast} /> */}
                <Button
                  className="button-md gap-1"
                  onClick={(e) => handleSaveAndContinue(e)}
                >
                  <span className="font-bold text-capitalize">
                    {t("committee.saveContinue")}
                  </span>
                  <FeatherIcon name="chevron-right" size={20} color="inherit" />
                </Button>
              </div>
            </div>

            {/* Mobile responsive buttions */}
            <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
              {/* <Toast ref={toast} /> */}
              <Button
                className="button-md gap-1 w-full justify-content-center"
                onClick={(e) => handleSaveAndContinue(e)}
              >
                <span className="font-bold text-capitalize">
                  {t("committee.saveContinue")}
                </span>
                <FeatherIcon name="chevron-right" size={20} color="inherit" />
              </Button>

              <div className="flex align-items-center gap-3 w-full">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={() => navigate("/committee/configuration")}
                  aria-label={t("committee.back")}
                >
                  <FeatherIcon name="chevron-left" size={20} color="inherit" />
                </Button>
                <Button
                  label={t("committee.cancel")}
                  className="button-md w-full"
                  severity="secondary"
                  onClick={() => handleCancel()}
                />
              </div>
              <Button
                text
                className="p-button-plain underline w-full"
                label={t("committee.saveAsDraft")}
                onClick={(e) => handleSaveAndContinue(e, "saveAsDraft")}
              ></Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CommitteeMembers;
