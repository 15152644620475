import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";

interface CommitteeAddDocumentProps {
  memberId: string; // memberId passed from the parent component
}

const CommitteeAddDocument: React.FC<CommitteeAddDocumentProps> = ({ memberId }) => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const [files, setFiles] = useState<File[]>([]); // Store multiple files
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  // Change language based on selectedLanguage context
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  // Handle file input change (multiple files)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validExtensions = [".xlsx", ".xls", ".doc", ".docx", ".ppt", ".pptx", ".txt", ".pdf", ".csv"];
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const newFiles: File[] = [];
      let invalidFiles = false;

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const fileExtension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase();

        // Check if the file extension is valid
        if (!validExtensions.includes(fileExtension)) {
          setErrorMessage(`${t("profile.invalidFileType")}: ${file.name}`);
          invalidFiles = true;
          break;
        }

        // Check if the total size does not exceed 500MB (example limit)
        const totalSizeInMB = file.size / (1024 * 1024);
        if (totalSizeInMB > 500) {
          setErrorMessage(`${t("record.totalFileSizeExceed500")}`);
          invalidFiles = true;
          break;
        }

        // Add the file to the new files array if it's valid
        newFiles.push(file);
      }

      if (!invalidFiles) {
        setErrorMessage(undefined);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add new files to the existing list
      }
    }
  };

  // Handle file deletion
  const handleDelete = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
  };

  return (
    <div className="committee-add-document">
      <input
        type="file"
        id="fileInput"
        multiple
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <Button
        text
        className="p-button-plain gap-2 pl-0 underline"
        onClick={() => document.getElementById("fileInput")?.click()}
      >
        <FeatherIcon name="paperclip" size={20} color="inherit" />
        <span className="font-bold text-capitalize">
          {t("committee.addAttachments")}
        </span>
      </Button>

      {/* Error message */}
      {errorMessage && <div className="p-error font-bold">{errorMessage}</div>}

      {/* List of selected files */}
      {files.length > 0 && (
        <div className="flex flex-column gap-3">
          {files.map((file, index) => (
            <div key={index} className="flex align-items-center gap-3">
              <span>{file.name}</span>
              <Button
                text
                className="p-button-plain gap-2 text-delete underline"
                onClick={() => handleDelete(index)}
                aria-label={t("record.delete")}
              >
                <FeatherIcon name="trash-2" size={20} />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CommitteeAddDocument;
