import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // PrimeReact theme
import "primereact/resources/primereact.min.css"; // PrimeReact core styles
import "primeicons/primeicons.css"; // PrimeIcons
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import { useTranslation } from "react-i18next";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import CommitteeForm from "./SelectCommitteeMember";

interface ConferenceProps {
  onDataChange: (data: {
    conferenceName: string;
    conferenceStartDate: Date | null;
    conferenceEndDate: Date | null;
    meetingTypeId: string;
    conferenceStatusId: string;
    conferenceNumber: string;
    committeeMeetings: {
      meetingName: string;
      meetingStartTime: string;
      meetingEndTime: string;
      meetingLocation: string;
      meetingAgenda: string;
      meetingStatusId: string;
      meetingNumber: string;
      meetingTypeId: string;
      meetingCommitteeDTO: {
        committeeId: string;
        userProfileIds: string[];
      }[];
    }[][];
  }) => void;
  formErrors: {
    conferenceName?: string;
    startDate?: string;
    endDate?: string;
    meetingName?: string;
    startTime?: string;
    endTime?: string;
    startDateMeeting?: string;
    meetingLocation?: string;
    meetingAgenda?: string;
  };
  onCommitteeDataChange: (
    data: {
      committeeId: string;
      userProfileIds: string[]; // Array of strings for userProfileIds
    }[]
  ) => void;
}

// Helper function to calculate the number of days between two dates
const calculateDays = (
  startDate: Date | null,
  endDate: Date | null
): number => {
  if (!startDate || !endDate) {
    return 0; // Return a default value or handle this case as needed
  }
  const timeDiff = endDate.getTime() - startDate.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // inclusive
};

const Conference: React.FC<ConferenceProps> = ({
  onDataChange,
  formErrors,
  onCommitteeDataChange,
}) => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [conferenceName, setConferenceName] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [meetingsByDay, setMeetingsByDay] = useState<any[]>([]); // Store meetings for each day
  const [conferenceDays, setConferenceDays] = useState<number>(0);
  const [committeeData, setCommitteeData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  // State to track if meetings are disabled for each day
  const [disableDay, setDisableDay] = useState<boolean[]>([]);
  useEffect(() => {
    const formattedCommitteeMeetings = meetingsByDay.map((dayMeetings: any[]) =>
      dayMeetings.map((meeting: any) => ({
        meetingName: meeting.meetingName ?? "",
        meetingStartTime: meeting.startTime ?? "",
        meetingEndTime: meeting.endTime ?? "",
        meetingLocation: meeting.location ?? "",
        meetingAgenda: meeting.agenda ?? "",
        meetingStatusId: meeting.statusId ?? "",
        meetingNumber: meeting.number ?? "",
        meetingTypeId: meeting.typeId ?? "",
        meetingCommitteeDTO:
          meeting.committees?.map((committee: any) => ({
            committeeId: committee.committeeId ?? "",
            userProfileIds: committee.userProfileIds ?? [],
          })) ?? [],
      }))
    );

    // Pass the data to onDataChange
    onDataChange({
      conferenceName,
      conferenceStartDate: startDate,
      conferenceEndDate: endDate,
      committeeMeetings: formattedCommitteeMeetings,
      meetingTypeId: "",
      conferenceStatusId: "",
      conferenceNumber: "",
    });
  }, [conferenceName, startDate, endDate, meetingsByDay]);

  // Watch for changes in startDate or endDate to calculate number of days
  useEffect(() => {
    if (startDate && endDate) {
      // Calculate total days
      const totalDays = calculateDays(startDate, endDate);
      setConferenceDays(totalDays);

      // Initialize meetings for Day 1 if not already set
      if (meetingsByDay.length === 0) {
        const initialMeetings = [
          [
            {
              meetingName: "",
              meetingStartTime: "",
              meetingEndTime: "",
              meetingLocation: "",
              meetingAgenda: "",
              meetingNumber: "",
              meetingStatusId: "",
              committeeConferenceId: "",
              meetingTypeId: "",
              meetingCommitteeDTO: committeeData.map((committee) => ({
                committeeId: committee.committeeId,
                userProfileIds: committee.userProfileIds || [], // Ensure mapping here
              })),
            },
          ],
        ];

        setMeetingsByDay(initialMeetings);
        setDisableDay([false]);
      }
    }
  }, [startDate, endDate, committeeData]);

  const nonEmptyDays = meetingsByDay.filter(
    (meetingDay) => meetingDay.length > 0
  ).length;
  const maxDays = startDate && endDate ? calculateDays(startDate, endDate) : 0;

  // Handle removing a meeting
  const removeMeeting = (dayIndex: number, meetingIndex: number) => {
    const updatedMeetings = [...meetingsByDay];
    updatedMeetings[dayIndex].splice(meetingIndex, 1); // Remove meeting

    setMeetingsByDay(updatedMeetings);

    // Notify parent
    onDataChange({
      conferenceName,
      conferenceStartDate: startDate,
      conferenceEndDate: endDate,
      committeeMeetings: updatedMeetings,
      meetingTypeId: "",
      conferenceStatusId: "",
      conferenceNumber: "",
    });
  };

  const addDay = () => {
    setMeetingsByDay((prevMeetings) => [
      ...prevMeetings,
      [
        {
          meetingName: "",
          meetingStartTime: "",
          meetingEndTime: "",
          meetingLocation: "",
          meetingAgenda: "",
          meetingNumber: "",
          meetingStatusId: "",
          committeeConferenceId: "",
          meetingTypeId: "",
          meetingCommitteeDTO: committeeData.map((committee) => ({
            committeeId: committee.committeeId, // Assuming committeeData has committeeId
            userProfileIds: committee.members || [], // Assuming committeeData has members array
          })),
        },
      ],
    ]);

    setDisableDay((prevDisableState) => [...prevDisableState, false]);
  };

  // Handle the "No meetings for the day" checkbox toggle
  const handleDisableDay = (dayIndex: number, isChecked: boolean) => {
    const newDisableState = [...disableDay];
    newDisableState[dayIndex] = isChecked;
    setDisableDay(newDisableState);

    if (isChecked) {
      // Clear all meetings for the day if the checkbox is checked
      const newMeetingsByDay = [...meetingsByDay];
      newMeetingsByDay[dayIndex] = [];
      setMeetingsByDay(newMeetingsByDay);
    }
  };

  const handleCommitteeType = async () => {
    const committeeType = await fetchData("MeetingType/GetAll");
    const formattedOptions = committeeType.Collection.map((item: any) => ({
      name: item.TypeName,
      id: item.Id,
    }));
    setOptions(formattedOptions);
  };

  const notifyParent = (
    conferenceName: string,
    startDate: Date | null,
    endDate: Date | null,
    committeeMeetings: any[]
  ) => {
    onDataChange({
      conferenceName,
      conferenceStartDate: startDate,
      conferenceEndDate: endDate,
      committeeMeetings,
      meetingTypeId: "",
      conferenceStatusId: "",
      conferenceNumber: "",
    });
  };

  const handleConferenceNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConferenceName(value);
    notifyParent(value, startDate, endDate, meetingsByDay);
  };

  const handleStartDateChange = (e: any) => {
    const value = e.value;
    setStartDate(value); // Update start date state
    notifyParent(conferenceName, value, endDate, meetingsByDay);
  };

  const handleEndDateChange = (e: any) => {
    const value = e.value;
    setEndDate(value); // Update end date state
    notifyParent(conferenceName, startDate, value, meetingsByDay);
  };

  useEffect(() => {
    if (meetingsByDay.length > 0) {
      // Notify parent when meetingsByDay is updated
      notifyParent(conferenceName, startDate, endDate, meetingsByDay);
    }
  }, [meetingsByDay]); // This runs whenever meetingsByDay is updated

  const handleCommitteeFormChange = (data: any[]) => {
    setCommitteeData(data);

    // Safely update meetingsByDay without direct mutation
    const updatedMeetingsByDay = meetingsByDay.map((dayMeetings) =>
      dayMeetings.map((meeting: any) => ({
        ...meeting,
        meetingCommitteeDTO: data.map((committee) => ({
          committeeId: committee.committeeId,
          userProfileIds: committee.userProfileIds || [],
        })),
      }))
    );

    // Update state and notify parent
    setMeetingsByDay(updatedMeetingsByDay);
    notifyParent(conferenceName, startDate, endDate, updatedMeetingsByDay);
  };

  useEffect(() => {
    handleCommitteeType();
  }, []);

  return (
    <div>
      <div>
        <div className="grid grid-xl">
          <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
            <label
              htmlFor="conferenceName"
              className={`block font-bold text-input-label capitalize ${formErrors.conferenceName ? "p-error" : ""}`}
            >
              {t("committee.conferenceName")}
            </label>
            <InputText
              id="conferenceName"
              name="conferenceName"
              type="text"
              className="w-full"
              value={conferenceName}
              onChange={handleConferenceNameChange}
              placeholder={t("committee.enterconferenceName")}
            />
            <span
              id="conferenceNameError"
              className={`p-error font-bold text-capitalize ${formErrors.conferenceName ? "" : "error-hidden"}`}
            >
              {formErrors.conferenceName}
            </span>
          </div>
        </div>

        <div className="grid grid-xl">
          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
            <label
              htmlFor="startDate"
              className={`block font-bold text-input-label capitalize ${formErrors.startDate ? "p-error" : ""}`}
            >
              {t("committee.startDate")}
            </label>
            <Calendar
              value={startDate}
              onChange={handleStartDateChange}
              minDate={new Date()}
              dateFormat="mm/dd/yy"
              placeholder={t("committee.startDate")}
            />
            <span
              id="startDateError"
              className={`p-error font-bold text-capitalize ${formErrors.startDate ? "" : "error-hidden"}`}
            >
              {formErrors.startDate}
            </span>
          </div>

          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
            <label
              htmlFor="endDate"
              className={`block font-bold text-input-label capitalize ${formErrors.endDate ? "p-error" : ""}`}
            >
              {t("committee.endDate")}
            </label>
            <Calendar
              value={endDate}
              onChange={handleEndDateChange}
              minDate={startDate ? startDate : new Date()}
              dateFormat="mm/dd/yy"
              placeholder={t("committee.endDate")}
            />
            <span
              id="endDateError"
              className={`p-error font-bold text-capitalize ${formErrors.endDate ? "" : "error-hidden"}`}
            >
              {formErrors.endDate}
            </span>
            {startDate &&
              endDate &&
              new Date(endDate).getTime() < new Date(startDate).getTime() && (
                <p style={{ color: "red" }}>
                  End Date cannot be earlier than Start Date
                </p>
              )}
          </div>
        </div>
        <div>
          {/* Meeting data array to hanlde multiple inputs according to particular day*/}
          {meetingsByDay.map((dayMeetings, dayIndex) => (
            <div key={dayIndex}>
              {/* Meetings for days */}
              {!disableDay[dayIndex] &&
                dayMeetings.map((meeting: any, meetingIndex: any) => (
                  <div key={meetingIndex}>
                    <h4>
                      Day {dayIndex + 1}/{conferenceDays}
                    </h4>

                    {/* "No meetings for the day" checkbox */}
                    <div className="flex align-items-center gap-3 pb-5">
                      <Checkbox
                        type="checkbox"
                        checked={disableDay[dayIndex]}
                        onChange={(e) =>
                          handleDisableDay(dayIndex, e.checked ?? false)
                        }
                      />
                      <label
                        htmlFor="notificationToStaff"
                        className="text-base font-normal "
                      >
                        {t("committee.noMeetingsForTheDay")}
                      </label>
                    </div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                        <label
                          htmlFor="meetingName"
                          className={`block font-bold text-input-label capitalize ${formErrors.meetingName ? "p-error" : ""}`}
                        >
                          {t("committee.meetingName")}
                        </label>
                        <InputText
                          id="meetingName"
                          type="text"
                          placeholder={t("committee.meetingName")}
                          className="w-full"
                          value={meeting.meetingName}
                          onChange={(e) => {
                            const updatedMeetings = [...meetingsByDay];
                            updatedMeetings[dayIndex][
                              meetingIndex
                            ].meetingName = e.target.value;
                            setMeetingsByDay(updatedMeetings);
                            notifyParent(
                              conferenceName,
                              startDate,
                              endDate,
                              updatedMeetings
                            );
                          }}
                        />
                        <span
                          id="conferenceNameError"
                          className={`p-error font-bold text-capitalize ${formErrors.meetingName ? "" : "error-hidden"}`}
                        >
                          {formErrors.meetingName}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="startMeetingDatw"
                          className="block font-bold text-capitalize text-input-label"
                        >
                          {t("committee.startDate")}
                        </label>
                        <Calendar
                          id="startMeetingDate"
                          placeholder={t("committee.startDate")}
                          value={meeting.startMeetingDate}
                          onChange={(e) => {
                            const updatedMeetings = [...meetingsByDay];
                            updatedMeetings[dayIndex][
                              meetingIndex
                            ].startMeetingDate = e.value;
                            setMeetingsByDay(updatedMeetings);

                            notifyParent(
                              conferenceName,
                              startDate,
                              endDate,
                              updatedMeetings
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="startTime"
                          className="block font-bold text-capitalize text-input-label"
                        >
                          {t("committee.startTime")}
                        </label>
                        <Calendar
                          id="startTime"
                          placeholder={t("committee.startTime")}
                          value={meeting.meetingStartTime}
                          onChange={(e) => {
                            const updatedMeetings = [...meetingsByDay];
                            updatedMeetings[dayIndex][
                              meetingIndex
                            ].meetingStartTime = e.value;
                            setMeetingsByDay(updatedMeetings);

                            // Notify parent
                            notifyParent(
                              conferenceName,
                              startDate,
                              endDate,
                              updatedMeetings
                            );
                          }}
                          timeOnly
                        />
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="endTime"
                          className="block font-bold text-capitalize text-input-label"
                        >
                          {t("committee.endTime")}
                        </label>
                        <Calendar
                          id="endTime"
                          placeholder={t("committee.endTime")}
                          value={meeting.meetingEndTime}
                          onChange={(e) => {
                            const updatedMeetings = [...meetingsByDay];
                            updatedMeetings[dayIndex][
                              meetingIndex
                            ].meetingEndTime = e.value;
                            setMeetingsByDay(updatedMeetings);

                            notifyParent(
                              conferenceName,
                              startDate,
                              endDate,
                              updatedMeetings
                            );
                          }}
                          timeOnly
                        />
                      </div>
                    </div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                        <label
                          htmlFor="meetingLocation"
                          className="block font-bold text-capitalize text-input-label"
                        >
                          {t("committee.meetingLocation")}
                        </label>
                        <InputText
                          id="meetingLocation"
                          type="text"
                          placeholder={t("committee.meetingLocation")}
                          className="w-full"
                          value={meeting.meetingLocation}
                          onChange={(e) => {
                            const updatedMeetings = [...meetingsByDay];
                            updatedMeetings[dayIndex][
                              meetingIndex
                            ].meetingLocation = e.target.value;
                            setMeetingsByDay(updatedMeetings);

                            notifyParent(
                              conferenceName,
                              startDate,
                              endDate,
                              updatedMeetings
                            );
                          }}
                        />
                        <span className="text-sm">
                          {t("committee.meetingLocationHelp")}
                        </span>
                      </div>
                    </div>

                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                        <label
                          htmlFor="meetingAgenda"
                          className="block font-bold text-capitalize text-input-label"
                        >
                          {t("committee.meetingAgenda")}
                        </label>
                        <InputTextarea
                          id="meetingAgenda"
                          placeholder={t("committee.meetingAgenda")}
                          value={meeting.meetingAgenda}
                          onChange={(e) => {
                            const updatedMeetings = [...meetingsByDay];
                            updatedMeetings[dayIndex][
                              meetingIndex
                            ].meetingAgenda = e.target.value;
                            setMeetingsByDay(updatedMeetings);

                            notifyParent(
                              conferenceName,
                              startDate,
                              endDate,
                              updatedMeetings
                            );
                          }}
                        />
                      </div>
                      <div className="pt-0 col-12">
                        <Button
                          text
                          className="p-button-plain gap-2 pl-0 underline"
                        >
                          <FeatherIcon
                            name="paperclip"
                            size={20}
                            color="inherit"
                          />
                          <span className="font-bold text-capitalize">
                            {t("committee.addAttachments")}
                          </span>
                        </Button>
                      </div>
                    </div>

                    <div className="grid grid-xl">
                      <CommitteeForm onDataChange={handleCommitteeFormChange} />
                    </div>

                    {/* Remove button will be disable for 1st data */}
                    {dayIndex === 0 ? null : (
                      <div className="pb-5">
                        <Button
                          type="button"
                          className="button-sm"
                          onClick={() => removeMeeting(dayIndex, meetingIndex)}
                        >
                          Remove Meeting
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              {/* 
              {!disableDay[dayIndex] && (
                <div className="flex flex-column gap-2 xl:col-3 lg:col-2 md:col-2 col-2">
                  <Button
                    type="button"
                    className="button-sm"
                    onClick={() => addMeeting(dayIndex)}
                  >
                    Add New Meeting
                  </Button>
                </div>
              )} */}
            </div>
          ))}

          {/* Add Day Button */}
          {nonEmptyDays < maxDays && (
            <Button
              text
              className="p-button-plain gap-1 pl-0 underline"
              onClick={addDay}
            >
              <FeatherIcon name="plus" size={20} />
              <span className="font-bold text-capitalize">
                {t("committee.addDay")}
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Conference;
