import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { ballotDetails } from "../../../slices/ballotDetails";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { postData } from "../../../services/Ballot/apiservice";
import { fetchData } from "../../../services/Ballot/apiservice";
import { buildQueryParams, formatDate } from "../../../utils/utils";
import { BALLOT_RECORD_OPEN, GUID_PlACEHOLDER } from "../common/constants";
import { useScrollOnPaginatorClick } from "../../common/useScrollOnPaginatorClick";
import BallotRecordFilter from "../listing/BallotRecordFilter";
import { BallotRecord } from "../common/type";
import { getTableSelectedData } from "../common/utils";
import { putData } from "../../../services/Ballot/apiservice";
import StatusBodyTemplate from "../common/StatusBodyTemplate";

const BallotRecordTable = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { postBallotRecord, isEdit } = useSelector(
    (state: any) => state.ballotSelectRecords
  );
  const { id } = useParams();
  const { ballotStatusList, ballotRecordStatusList, ballotDetailsInfo } =
    useSelector((state: any) => state.ballotDetails);
  let ballotId = ballotDetailsInfo.id;
  let ballotRecirculationId = ballotDetailsInfo.recirculationBallotId;
  const tableRecordData: any = useSelector(
    (state: any) => state.ballotSelectRecords.recordTableData
  );
  const tablerecordSelected: any = useSelector(
    (state: any) => state.ballotSelectRecords.selectedTableRecords
  );
  const preservedSelectedTableRecords: any = useSelector(
    (state: any) => state.ballotSelectRecords.preservedSelectedTableRecords
  );
  const {
    accreditationBodySelect,
    primaryCommitteSelect,
    ballotTypeSelect,
    ballotSubTypeSelect,
    ballotIdSelect,
    ballotLevelSelect,
    recordTypeSelect,
    recordSubTypeSelect,
    positionAppliedSelect,
  } = useSelector(
    (state: any) => state.ballotSelectRecords.selectedRecordValues
  );
  const [filteredData, setFilteredData] = useState(tableRecordData);
  const [allballotDetails, setAllBallotDetails] = useState<any>();
  const [ballotUID, setBallotUID] = useState<string>("");
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };
  const createBallotRecord = (
    tablerecordSelected: TableRecord[],
    ballotId: string,
    ballotRecirculationId: string | null,
    ballotRecordStatusList: any,
    BALLOT_RECORD_OPEN: string
  ): BallotRecord[] => {
    return tablerecordSelected.map((tableRecord: TableRecord) => {
      const recordId = ballotRecirculationId
        ? tableRecord.ballotRecordId
        : tableRecord.id;

      return {
        isDirty: false,
        isNew: true,
        ballotId: ballotId,
        recordId: recordId || tableRecord.id,
        ballotRecordStatusId: ballotRecordStatusList[BALLOT_RECORD_OPEN]?.id,
        term: tableRecord.termYear,
        isActive: true,
        isDeleted: false,
        ballotRecordId: "00000000-0000-0000-0000-000000000000",
      };
    });
  };
  interface TableRecord {
    id: string;
    ballotRecordId: string;
    termYear: number;
  }
  const fetchAllBallotDetails = async () => {
    try {
      const ballotDetailsRes = await fetchData(`Ballot/${id}`);
      const ballotDetailFormated = {
        committeeId: ballotDetailsRes.Committee.Id,
        recordId: ballotDetailsRes.RecordType.Id,
        recordSubTypeId: ballotDetailsRes.RecordSubType.Id,
        positionId: ballotDetailsRes.MembershipPosition.Id,
      };
      setAllBallotDetails(ballotDetailFormated);
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handleRecordData = async (prevRecordFormattedData: any) => {
    const oldBallotRecordData: any[] = [];
    const createPayload = (record: any) => ({
      isDirty: true,
      isNew: false,
      ballotId: ballotId,
      recordId: record?.id,
      ballotRecordStatusId: ballotRecordStatusList[BALLOT_RECORD_OPEN]?.id,
      term: record?.termYear,
      isActive: false,
      isDeleted: true,
      ballotRecordId: record?.ballotRecordId,
    });
    if (tableRecordData.length >= prevRecordFormattedData.length) {
      tableRecordData.forEach(async (obj1: any, index: number) => {
        const isMatched = prevRecordFormattedData.some(
          (obj2: any) => obj1.id === obj2.id
        );
        if (!isMatched) {
          const formatPayload = createPayload(prevRecordFormattedData[index]);
          oldBallotRecordData.push(formatPayload);
        }
      });
    } else if (prevRecordFormattedData.length > tableRecordData.length) {
      prevRecordFormattedData.forEach(async (obj2: any, index: number) => {
        const isMatched = tableRecordData.some(
          (obj1: any) => obj1.id === obj2.id
        );
        if (!isMatched) {
          const formatPayload = createPayload(tableRecordData[index]);
          oldBallotRecordData.push(formatPayload);
        }
      });
    }
    if (oldBallotRecordData.length > 0) {
      try {
        await postData(`BallotRecord/BulkUpload`, oldBallotRecordData);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const generateBallotPayload = (ballotId: string | null) => {
    return {
      accreditationBodyId: accreditationBodySelect?.id,
      ballotLevelId: ballotLevelSelect?.id,
      ballotTypeId: ballotTypeSelect?.id,
      ballotSubTypeId: ballotSubTypeSelect?.id,
      ballotStatusId:
        ballotDetailsInfo?.ballotStatusId ?? ballotStatusList?.Draft?.id,
      recordSubTypeId: recordSubTypeSelect?.id,
      recordTypeId: recordTypeSelect?.id,
      committeeId: primaryCommitteSelect?.id,
      membershipPositionId: positionAppliedSelect?.id,
      note: ballotDetailsInfo?.note || "",
      noOfRecords: tablerecordSelected.length,
      explaination: ballotDetailsInfo?.explaination || "",
      description: ballotDetailsInfo?.description || "",
      daysToIssue: ballotDetailsInfo?.daysToIssue,
      openDate: ballotDetailsInfo?.openDate
        ? new Date(ballotDetailsInfo.openDate)
        : null,
      closeDate: ballotDetailsInfo?.closeDate
        ? new Date(ballotDetailsInfo.closeDate)
        : null,
      ballotNumber: ballotDetailsInfo?.ballotNumber,
      isSecreteBallot: ballotDetailsInfo?.isSecret,
      recirculationBallotId: ballotIdSelect?.id,
      recirculationBallotNumber: ballotIdSelect?.value,
      isActive: true,
      isDeleted: false,
      isDirty: ballotId ? true : false,
      isNew: ballotId ? false : true,
    };
  };
  const generateEditBallotRecord = (
    tableRecordData: TableRecord[],
    preservedSelectedTableRecords: TableRecord[],
    ballotId: string,
    ballotRecordStatusList: any,
    BALLOT_RECORD_OPEN: string
  ): BallotRecord[] => {
    return preservedSelectedTableRecords.map((tableRecord: TableRecord) => {
      const isDeleted = !tablerecordSelected.find(
        (updatedRecord: TableRecord) => updatedRecord.id === tableRecord.id
      );
      return {
        isDirty: tableRecord.ballotRecordId ? true : false,
        isNew: tableRecord.ballotRecordId ? false : true,
        ballotId: ballotId,
        recordId: tableRecord.id,
        ballotRecordStatusId: ballotRecordStatusList[BALLOT_RECORD_OPEN]?.id,
        term: tableRecord.termYear,
        isActive: !isDeleted,
        isDeleted: isDeleted,
        ballotRecordId: tableRecord.ballotRecordId
          ? tableRecord.ballotRecordId
          : GUID_PlACEHOLDER,
      };
    });
  };
  const onRowSelectHandler = (data: any) => {
    dispatch(
      ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
    );
  };
  const onRowUnSelectHandler = (data: any) => {
    dispatch(
      ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
    );
  };
  const editRecordhandler = async (
    queryParams = queryString,
    filterOptionUpdate = false
  ) => {
    try {
      if (
        primaryCommitteSelect?.id &&
        recordTypeSelect?.id &&
        positionAppliedSelect?.id
      ) {
        const apiUrl = `BallotRecordsSearch?${queryParams}&PageIndex=1`;
        const searchRecordData = await fetchData("BallotRecord", apiUrl);
        let selectedRecordsData;
        try {
          if (ballotUID) {
            selectedRecordsData = await fetchData(
              `BallotRecord/GetListByParentId?parentId=${ballotUID}`
            );
          }
          if (ballotId) {
            selectedRecordsData = await fetchData(
              `BallotRecord/GetListByParentId?parentId=${ballotId}`
            );
          }
        } catch (error) {
          console.log(error);
          selectedRecordsData = { collection: [] };
        }
        const tableProducts = searchRecordData.Collection.filter(
          (record: any) =>
            record?.RecordStatusDisplayName === "Open" ||
            record?.RecordStatusDisplayName === "Reopen"
        ).map((record: any) => {
          return {
            id: record?.RecordId,
            recordNumber: record?.RecordNumber,
            applicantName: record?.RecordUserProfileDisplayName,
            applicantId: record?.RecordUserProfileId,
            committeeAppliedFor: record?.RecordCommitteeName,
            committeeAppliedId: record?.RecordCommitteeId,
            positionAppliedFor: record?.RecordMemberPositionDisplayName,
            positionId: record?.RecordMemberPositionId,
            categoryOfInterest: record?.RecordCategoryOfInterestDisplayName,
            categoryOfInterestId: record?.RecordCategoryOfInterestId,
            requestedOn: formatDate(record?.CreatedDate),
            statusName: record?.RecordStatusDisplayName,
            createdDate: record?.CreatedDate,
            ModifiedDate: record?.ModifiedDate,
          };
        });
        const tableSelectedData = getTableSelectedData(selectedRecordsData);
        const formattedData = tableProducts
          .filter(
            (record: any) =>
              record?.statusName === "Open" || record?.statusName === "Reopen"
          )
          .map((record: any) => {
            const matchedObj = tableSelectedData?.find(
              (recordSelect: any) => recordSelect.id === record.id
            );
            return {
              id: record?.id,
              recordNumber: record?.recordNumber,
              ballotRecordId: matchedObj ? matchedObj.ballotRecordId : null,
              applicantName: record?.applicantName,
              applicantId: record?.applicantId,
              committeeAppliedFor: record?.committeeAppliedFor,
              committeeAppliedId: record?.committeeAppliedId,
              positionAppliedFor: record?.positionAppliedFor,
              positionId: record?.positionId,
              categoryOfInterest: record?.categoryOfInterest,
              categoryOfInterestId: record?.categoryOfInterestId,
              requestedOn: formatDate(record?.requestedOn),
              statusName: record?.statusName,
              createdDate: record?.createdDate,
              ModifiedDate: record?.ModifiedDate,
            };
          });
        dispatch(
          ballotSelectRecords.addRecordTableData({
            rowTableData: formattedData,
          })
        );
        if (!filterOptionUpdate) {
          dispatch(
            ballotSelectRecords.addRecordTableFilterData({
              rowTableData: formattedData,
            })
          );
        }
        updateRecordSelectionHandler(tableSelectedData);
      }
    } catch (err) {
      console.log(err, "err fetch");
    }
  };
  const updateballotRecordHandler = async (ballotIdNew: string) => {
    try {
      if (
        (ballotIdNew && isEdit) ||
        (postBallotRecord && ballotIdNew && !isEdit)
      ) {
        const editBallotRecords = generateEditBallotRecord(
          tableRecordData,
          preservedSelectedTableRecords,
          ballotIdNew,
          ballotRecordStatusList,
          BALLOT_RECORD_OPEN
        );
        if (allballotDetails) {
          if (
            allballotDetails?.committeeId !== primaryCommitteSelect?.id ||
            allballotDetails?.recordId !== recordTypeSelect?.id ||
            allballotDetails?.recordSubTypeId !== recordSubTypeSelect?.id ||
            allballotDetails?.positionId !== positionAppliedSelect?.id
          ) {
            try {
              const previousRecordDataRes = await fetchData(
                `BallotRecord/GetListByParentId?parentId=${ballotId}`
              );
              const prevRecordFormattedData = getTableSelectedData(
                previousRecordDataRes
              );
              const filteredData = prevRecordFormattedData.filter(
                (item: any) =>
                  item.statusName === "Open" || item.statusName === "Reopen"
              );
              await handleRecordData(filteredData);
            } catch (err) {
              console.log(err);
            }
          }
        }
        await postData(`BallotRecord/BulkUpload`, editBallotRecords);
      }
      if (!postBallotRecord && ballotIdNew && !isEdit) {
        const newBallotRecords = createBallotRecord(
          tablerecordSelected,
          ballotIdNew,
          ballotRecirculationId,
          ballotRecordStatusList,
          BALLOT_RECORD_OPEN
        );
        await postData(`BallotRecord/BulkUpload`, newBallotRecords);
        dispatch(ballotSelectRecords.postBallotRecord({ value: true }));
      }
      dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }));
      editRecordhandler();
    } catch (err) {
      console.log(err, "err");
    }
  };
  const createBallotHandler = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const payload = generateBallotPayload(null);
      if (!ballotId) {
        try {
          const createBallot = await postData(`Ballot`, payload);
          const formattedBallotDetails = JSON.parse(createBallot.content);
          dispatch(
            ballotDetails.addBallotDetails({
              target: formattedBallotDetails.Id,
              label: "ballotid",
            })
          );
          dispatch(
            ballotDetails.addBallotDetails({
              target: formattedBallotDetails.BallotNumber,
              label: "ballotnumber",
            })
          );
          dispatch(
            ballotDetails.addBallotDetails({
              target: formattedBallotDetails.RecirculationBallotId,
              label: "recirculationBallotId",
            })
          );
          dispatch(
            ballotDetails.addBallotDetails({
              target: formattedBallotDetails.RecirculationBallotNumber,
              label: "recirculationBallotNumber",
            })
          );
          setBallotUID(formattedBallotDetails.Id);
          updateballotRecordHandler(formattedBallotDetails.Id);
        } catch (err: any) {
          if (
            err.response?.data?.errors?.requestModel[0]?.includes(
              "limit has reached"
            )
          ) {
            errorMessageToast("Allowed No. of Ballot limit has reached");
          }
        }
      } else {
        const ballotPayload = generateBallotPayload(ballotId);
        await putData(`Ballot`, `${ballotId}`, ballotPayload);
        updateballotRecordHandler(ballotId);
      }
    } catch (error) {
      console.error("Error creating ballot:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // Search Query Params
  const paramsFilters = [
    { key: "ballotid", value: ballotId },
    { key: "committeeid", value: primaryCommitteSelect },
    { key: "recordtypeid", value: recordTypeSelect },
    { key: "positionid", value: positionAppliedSelect },
  ];
  let idx = 0;
  const params = paramsFilters.reduce((acc: any, filter: any) => {
    if (filter?.value?.id) {
      acc[`Filters[${idx}].Key`] = filter.key;
      acc[`Filters[${idx}].Value`] = filter.value.id;
      idx++;
    }
    return acc;
  }, {});
  const queryString = buildQueryParams(params);

  const updateRecordSelectionHandler = (tableRecordData: any) => {
    tableRecordData.forEach((data: any) => {
      dispatch(
        ballotSelectRecords.tableRecordSelectionHandler({ rowData: data })
      );
    });
  };
  useEffect(() => {
    if ((isEdit && ballotId) || (!isEdit && ballotId)) {
      editRecordhandler();
    }
  }, [isEdit, ballotId]);
  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = tableRecordData.filter(
      (record: any) =>
        record.recordNumber.toLowerCase().includes(lowercasedSearch) ||
        record.applicantName.toLowerCase().includes(lowercasedSearch) ||
        record.positionAppliedFor.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  }, [search, tableRecordData]);
  useEffect(() => {
    if (id) {
      fetchAllBallotDetails();
    }
  }, [id]);
  // Datatable reference
  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (filteredData !== null && filteredData !== undefined) {
    const isEmpty = filteredData.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }
  return (
    <>
      <div className="toggleSearchRecords">
        <div className="flex flex-wrap align-items-center md:flex-nowrap md:justify-content-between py-3 gap-3 w-ful">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchBallotRecord"
              placeholder={t("ballot.searchBallotRecord")}
              className="w-full"
              aria-label={t("ballot.searchBallotRecord")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </IconField>
          <p className="text-base font-bold m-0 text-disabled text-link">
            {tablerecordSelected.length} {t("ballot.recordsSelected")}
          </p>
          <div className="flex gap-3 w-full w-auto align-items-center ml-auto">
            <div className="relative"></div>
          </div>
          <div className="flex gap-3 w-full md:w-auto align-items-center">
            <div className="relative">
              <BallotRecordFilter onFilterData={editRecordhandler} />
            </div>
          </div>
        </div>
        <div ref={tableContainerRef}>
          <DataTable
            className={tableClass}
            key={filteredData}
            sortOrder={1}
            stripedRows
            showGridlines
            value={filteredData}
            paginator
            rows={10}
            emptyMessage="No data found."
            selectionMode="checkbox"
            selection={tablerecordSelected}
            onSelectionChange={(e: any) => {
              // setSelectedRecords(e.value)
            }}
            dataKey={"id"}
            onRowSelect={(e: any) => {
              onRowSelectHandler(e.data);
            }}
            onRowUnselect={(e: any) => {
              onRowUnSelectHandler(e.data);
            }}
          >
            <Column
              key="IsChecked"
              selectionMode={"multiple"}
              field="IsChecked"
              headerStyle={{ width: "3rem", visibility: "hidden" }}
              body={(rowData) => {
                const isSelected = tablerecordSelected.some(
                  (record: any) => record.ballotRecordId === rowData.id
                );
                return (
                  <Checkbox
                    id={`checkbox-${rowData.id}`}
                    checked={isSelected}
                    className="mr-2"
                  />
                );
              }}
            />

            <Column
              key="recordNumber"
              field={"recordNumber"}
              header={t("ballot.recordId")}
              sortable
              style={{ cursor: "pointer" }}
              body={(rowData) => (
                <>
                  <span className="p-column-title">{t("ballot.recordId")}</span>
                  <a
                    onClick={() => {
                      sessionStorage.setItem("recordId", rowData.id);
                      navigate("/record/record-preview");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sessionStorage.setItem("recordId", rowData.id);
                        navigate("/record/record-preview");
                      }
                    }}
                    className="font-bold table-text-link underline"
                    tabIndex={0}
                  >
                    {rowData.recordNumber}
                  </a>
                </>
              )}
            />
            <Column
              key="applicantName"
              field={"applicantName"}
              header={t("ballot.applicantName")}
              sortable
              body={(rowData) => (
                <>
                  <span className="p-column-title">
                    {t("ballot.applicantName")}
                  </span>
                  {rowData.applicantName}
                </>
              )}
            />
            <Column
              key="committeeAppliedFor"
              field={"committeeAppliedFor"}
              header={t("ballot.committeeAppliedFor")}
              sortable
              body={(rowData) => (
                <>
                  <span className="p-column-title">
                    {t("ballot.committeeAppliedFor")}
                  </span>
                  {rowData.committeeAppliedFor}
                </>
              )}
            />
            <Column
              key="positionAppliedFor"
              field={"positionAppliedFor"}
              header={t("ballot.positionAppliedFor")}
              sortable
              body={(rowData) => (
                <>
                  <span className="p-column-title">
                    {t("ballot.positionAppliedFor")}
                  </span>
                  {rowData.positionAppliedFor}
                </>
              )}
            />
            <Column
              key="categoryOfInterest"
              field={"categoryOfInterest"}
              header={t("ballot.categoryOfInterest")}
              sortable
              body={(rowData) => (
                <>
                  <span className="p-column-title">
                    {t("ballot.categoryOfInterest")}
                  </span>
                  {rowData.categoryOfInterest}
                </>
              )}
            />
            <Column
              key="requestedOn"
              field={"requestedOn"}
              header={t("ballot.requestedOn")}
              sortable
              body={(rowData) => (
                <>
                  <span className="p-column-title">
                    {t("ballot.requestedOn")}
                  </span>
                  {rowData.requestedOn}
                </>
              )}
            />
            <Column
              key="statusName"
              field={"statusName"}
              header={t("ballot.status")}
              sortable
              body={(rowData) => (
                <StatusBodyTemplate
                  rowData={rowData}
                  col={{ field: `${rowData.statusName}` }}
                  getHeaderLabel={() => "rowData.statusName"}
                />
              )}
            />
          </DataTable>
        </div>
      </div>
      <div className="bg-white flex items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow justify-content-end">
        <Button
          className="button-md gap-1"
          onClick={() => createBallotHandler()}
          disabled={
            !isLoading &&
            tablerecordSelected.length !== 0 &&
            primaryCommitteSelect?.id &&
            ballotTypeSelect?.id &&
            ballotSubTypeSelect?.id &&
            (ballotSubTypeSelect.value === `Recirculation`
              ? ballotIdSelect?.id
              : true) &&
            ballotLevelSelect?.id &&
            recordTypeSelect?.id &&
            recordSubTypeSelect?.id &&
            positionAppliedSelect?.id
              ? false
              : true
          }
        >
          <span className="font-bold text-capitalize">
            {t("ballot.continue")}
          </span>
          <FeatherIcon name="chevron-right" size={20} color="inherit" />
        </Button>
      </div>
    </>
  );
};

export default BallotRecordTable;
