import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'primereact/badge';

interface BallotDetailHeaderProps {
  BallotNumber: string | undefined;
  BallotStatus: string | undefined | null;
  BallotHeading: string
}

const BallotDetailHeader: React.FC<BallotDetailHeaderProps> = ({BallotNumber = "", BallotStatus = "", BallotHeading =""}) => {
    const [t] = useTranslation("ballot");
    return (
     <>
        <div className="mb-5 flex flex-wrap gap-2">
          <div className="flex flex-column gap-2">
            <div className="flex gap-2">
              <h1 className="text-title display-xs font-bold text-capitalize m-0 flex align-items-center gap-2">
                {BallotHeading} - {BallotNumber}
              </h1>
              <Badge
                value={BallotStatus}
                className="active"
              />
            </div>
            <p className="text-base font-normal m-0">
              Any supporting text for request listing page can go here
            </p>
          </div>
        </div>
     </>
    )
}

export default BallotDetailHeader;