import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { MenuItem } from "primereact/menuitem";
import { ConfirmDialog } from "primereact/confirmdialog";
import {
  formatMembershipResponse,
  formatMembershipHistoryResponse,
  formatStaffNoteResponse,
} from "../../utils/utils";
import EmptyState from "./EmptyState";
import { useLoadingContext } from "../Layout";
import {
  postData,
  fetchData,
  getListByParentId,
  putData,
} from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import {
  configureAddressRequest,
  configureEducationRequest,
  configureExperienceRequest,
  configureLicensesRequest,
  createUserProfileRequest,
} from "../../slices/createProfileSlice";
import { Dialog } from "primereact/dialog";
import Table from "../common/Table";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { usePermission } from "../auth/Authorization";
import ListDocuments from "../common/ListDocument";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import CustomMenu, { getStatusBadge } from "../common/customMenu";
import RecordListDocument from "../common/RecordListDocument";
interface MemberList {
  id: string;
  Committee: string;
  MembershipPosition: string;
  CategoryOfInterest: string;
  NumberofBallotsMissed: string;
  NumberofMeetingMissed: string;
  StartDate: string;
  EndDate: string;
  Status: string;
  RecordId: string;
}

interface ConditionalMenuItem extends MenuItem {
  condition?: boolean;
}

interface StaffNotesList {
  id: string;
  StaffName: string;
  UserProfileStaffNotes: string;
  Date: string;
  CommitteeName: string;
}

interface DocumentListTrack {
  documentType: any;
  isAvailable: boolean;
}

interface RowData {
  Id: string;
  committeeMembershipId: string;
  isMembershipExpiring: boolean;
  recordID: string;
}

interface MembershipHistory {
  TerminatedThroughDirect: boolean;
  TerminatedThroughRecord: boolean;
  TerminatedThroughBallot: boolean;
  ThroughMeeting: boolean;
  ReasonForTermination: string | null;
  ReasonForResign: string | null;
  RemarkForEndMembership: string | null;
  TerminateRemark: string | null;
  ResignRemark: string | null;
  MembershipTerminateBallotNumber: string | null;
  MembershipTerminateMeetingNumber: string | null;
}

const UserProfile = () => {
  const { setLoading } = useLoadingContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("profile");
  const [terminatedThroughValue, setTerminatedThroughValue] = useState<
    string | null
  >(null);
  const [validationErrors, setValidationErrors] = useState({
    terminatedThroughError: "",
    ballotOrMeetingNumberError: "",
    selectedReasonError: "",
    terminateValueError: "",
    remarkError: "",
  });

  const [selectedReason, setSelectedReason] = useState("");
  const [userProfileID, setUserProfileId] = useState<string>("");
  const [staffUserProfileID, setStaffUserProfileID] = useState<string>("");
  const [ballotOrMeetingNumber, setBallotOrMeetingNumber] = useState("");
  const { selectedLanguage } = useLanguageContext();
  const [fullName, setFullName] = useState<string>("");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [isStaffEditing, setIsStaffEditing] = useState<boolean>(false);
  const [isStaffProfileEditing, setIsStaffProfileEditing] =
    useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useState<any>(null);
  const [contactDetails, setContactDetails] = useState<any>(null);
  const [addressDetails, setAddressDetails] = useState<any>(null);
  const [experienceDetails, setExperienceDetails] = useState<any>(null);
  const [professionalLicenseDetails, setProfessionalLicenseDetails] =
    useState<any>(null);
  const [auditHistoryList, setAuditHistorList] = useState<any>(null);
  const [educationDetails, setEducationDetails] = useState<any>(null);
  const [profileLoading, setProfileLoading] = useState<boolean>(true);
  const createProfile = useSelector((state: RootState) => state.createProfile);
  const [memberHistory, setMemberHistory] = useState<MemberList[]>([]);
  const [activeMember, setActiveMember] = useState<MemberList[]>([]);
  const [visibleResign, setVisibleResign] = useState<boolean>(false);
  const [visibleEndMembership, setVisibleEndMembership] =
    useState<boolean>(false);
  const [visibleTerminate, setVisibleTerminate] = useState<boolean>(false);
  const [isMemberProfile, setIsMemberProfile] = useState<boolean>(false);
  const [visibleTerminatedRecords, setVisibleTerminatedRecords] =
    useState<boolean>(false);
  const [memberShipId, setMemberShipId] = useState("");
  const [resignValidationError, setResignValidationError] = useState("");
  const [endMembershipValidationError, setEndMembershipValidationError] =
    useState("");
  const [newMemberRemarkValue, setNewMemberRemarkValue] = useState("");
  const [memberRemarks, setMemberRemarks] = useState<StaffNotesList[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [isTerminateDisabled, setIsTerminateDisabled] = useState(false);
  const [isEndMembershipDisabled, setIsEndMembershipDisabled] = useState(false);
  const [committees, setCommittees] = useState<any>([]);
  const [committeeError, setCommitteeError] = useState(""); //validation
  const [remarkError, setRemarkError] = useState(""); //validation
  const [selectedCommittee, setSelectedCommittee] = useState<string | null>(
    null
  );
  const [historyRecordID, sethistoryRecordID] = useState("");
  const [documentListTrack, setDocumentListTrack] = useState<
    DocumentListTrack[]
  >([]);
  const [documentDetailsAvailable, setDocumentDetailsAvailable] =
    useState(true);
  const toast = useRef<Toast>(null);
  const [resignValue, setResignValue] = useState("");
  const [endMembershipValue, setEndMembershipValue] = useState("");
  const [terminateValue, setTerminateValue] = useState("");
  const [membershipHistoryDetails, setMembershipHistoryDetails] =
    useState<MembershipHistory | null>(null);
  const listOfDocumentType = [
    "UserProfile_Resume",
    "UserProfile_Certification",
    "UserProfile_AdditionalDocument",
  ];
  const committeeMembershipId =
    sessionStorage.getItem("committeeMembershipId") || "";
  const breadcumbItems = [
    { label: t("profile.dashboard"), url: "/dashboard" },
    {
      label: isStaffProfileEditing
        ? "Member Profile"
        : t("profile.mainHeading"),
    },
  ];
  const showSuccessTermination = () => {
    toast.current?.show({
      severity: "success",
      summary: t("profile.success"),
      detail: t("profile.memberHasTerminatedSuccessfully"),
      life: 3000,
    });
  };

  const showSuccessEndMembership = () => {
    toast.current?.show({
      severity: "success",
      summary: t("profile.success"),
      detail: t("profile.membershiphasEndedSuccessfully"),
      life: 3000,
    });
  };
  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: t("profile.success"),
      detail: t("profile.memberResignSuccessfully"),
      life: 3000,
    });
  };

  const dropdownOptions = [
    { label: "Direct Termination", value: "DirectTermination" },
    { label: "Meeting", value: "Meeting" },
    { label: "Ballot", value: "Ballot" },
  ];

  const setError = useCallback(
    (field: any, errorMessage: any) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage,
      }));
    },
    [validationErrors]
  );

  const handleDocumentDetailsAvailable = (
    isAvailable: any,
    documentTypeFromList: any
  ) => {
    let list = { documentType: documentTypeFromList, isAvailable: isAvailable };

    setDocumentListTrack((prev) => [...prev, list]);
    //setDocumentDetailsAvailable(isAvailable);
  };

  useEffect(() => {
    if (documentListTrack.length === listOfDocumentType.length) {
      const isListAvailable = documentListTrack.some(
        (list) => list.isAvailable === true
      );
      setDocumentDetailsAvailable(isListAvailable);
    }
  }, [documentListTrack]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();

      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (userRole) {
      if (userRole === "Member") {
        setIsMemberProfile(true);
        setIsTerminateDisabled(true);
        setIsEndMembershipDisabled(true);
      }
    }
  }, [userRole]);

  const actionPositionBodyTemplate = (rowData: RowData) => {
    const actionItems: MenuItem[] = [
      ...(rowData.isMembershipExpiring
        ? [
            {
              label: t("profile.requestReappointment"),
              command: () => {
                sessionStorage.setItem("reappointment", "true");
                setTimeout(() => {
                  navigate("/member/create");
                }, 100);
              },
            },
          ]
        : []),
      ...(isTerminateDisabled
        ? []
        : [
            {
              label: t("profile.terminate"),
              command: () => {
                handleTerminateButtonClick(rowData.Id);
              },
            },
          ]),
      {
        label: t("profile.resign"),
        command: () => {
          handleResignButtonClick(rowData.Id);
        },
      },
      ...(isEndMembershipDisabled
        ? []
        : [
            {
              label: t("profile.endMembership"),
              command: () => {
                handleEndMembershipButtonClick(rowData.Id);
              },
            },
          ]),
    ];

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  const actionItems: ConditionalMenuItem[] = [
    {
      label: t("profile.terminate"),
      command: () => {
        handleTerminateButtonClick(committeeMembershipId);
      },
      condition: !isTerminateDisabled, // Add a condition property
    },
    {
      label: t("profile.resign"),
      command: () => {
        handleResignButtonClick(committeeMembershipId);
      },
    },
    {
      label: t("profile.endMembership"),
      command: () => {
        handleEndMembershipButtonClick(committeeMembershipId);
      },
      condition: !isEndMembershipDisabled, // Add a condition property
    },
  ];

  const filteredActionItems = actionItems.filter(
    (item) => item.condition !== false
  );

  const handleTerminatedThroughChange = (e: any) => {
    const value = e.value;
    setTerminatedThroughValue(value);
    setBallotOrMeetingNumber("");
    if (value === "Meeting" || value === "Ballot") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        ballotOrMeetingNumberError: "",
        terminatedThroughError: "",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        terminatedThroughError: "",
      }));
    }
  };

  useEffect(() => {
    const fetchAndSetCommittees = async () => {
      try {
        if (staffUserProfileID) {
          const response = await fetchData(
            `Committee/Search?Filters[0].Key=StaffId&Filters[0].Value=${staffUserProfileID}&PageIndex=-1`
          );

          const committeesData = response.Collection;

          const createdCommittees = committeesData.filter(
            (committee: any) =>
              committee.Status && committee.Status.Name === "Created"
          );

          setCommittees(createdCommittees);
        }
      } catch (error) {
        console.error("Error fetching committees:", error);
      }
    };

    fetchAndSetCommittees();
  }, [staffUserProfileID]);

  useEffect(() => {
    const fetchAuditHistory = async () => {
      try {
        if (userProfileID) {
          const response = await fetchData(
            `Audit/${userProfileID}/UserProfile`
          );

          setAuditHistorList(response);
        }
      } catch (error) {
        console.error("Error fetching committees:", error);
      }
    };

    fetchAuditHistory();
  }, [userProfileID]);

  const fetchCommittees = async () => {
    try {
      const responseData = await fetchData("UserProfile/GetCurrentUserProfile");
      setStaffUserProfileID(responseData.Id);

      const response = await fetchData(
        `Committee/Search?Filters[0].Key=StaffId&Filters[0].Value=${staffUserProfileID}&Filters[1].Key=UserProfileId&Filters[1].Value=${staffUserProfileID}&PageIndex=-1`
      );
      const committeesData = response.Collection;

      const createdCommittees = committeesData.filter(
        (committee: { Status: { Name: string } }) => {
          return committee.Status && committee.Status.Name === "Created";
        }
      );
      setCommittees(createdCommittees);
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };

  const fetchCountryByID = async (id: string) => {
    try {
      const responseData = fetchData("Country", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchStateByID = async (id: string) => {
    try {
      const responseData = fetchData("State", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchUniversityByID = async (id: string) => {
    try {
      const responseData = fetchData("University", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const fetchDegreeByID = async (id: string) => {
    try {
      const responseData = fetchData("Degree", id);
      return responseData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleTerminateButtonClick = async (committeeMembershipId: string) => {
    setVisibleTerminate(true);
    setMemberShipId(committeeMembershipId);
  };

  const handleResignButtonClick = async (committeeMembershipId: string) => {
    setVisibleResign(true);
    setMemberShipId(committeeMembershipId);
  };

  const handleEndMembershipButtonClick = async (
    committeeMembershipId: string
  ) => {
    setVisibleEndMembership(true);
    setMemberShipId(committeeMembershipId);
  };

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem("staffUpdatingMember");

    if (sessionStorageData && userRole != "Member") {
      setIsMemberProfile(true);
    } else if (userRole == "Member") {
      setIsMemberProfile(true);
    } else if (!sessionStorageData && userRole != "Member") {
      setIsMemberProfile(false);
    }

    const userProfileId = createProfile.staffConfigureUserProfile.userProfileId;
    if (userProfileId) {
      setUserProfileId(userProfileId);
      setIsStaffEditing(false);
      setIsStaffProfileEditing(true);
      //const memberId = userProfileId;
    } else {
      const userID = localStorage.getItem("userProfileID");
      if (userID) {
        setUserProfileId(userID);
        setIsStaffEditing(true);
        setIsStaffProfileEditing(false);
      }

      if (sessionStorageData && sessionStorageData != "undefined") {
        const memberProfileId = sessionStorageData;
        setUserProfileId(memberProfileId);
        setIsStaffEditing(true);
      } else {
        const fetchCurrentUser = async () => {
          try {
            const responseData = await fetchData(
              "UserProfile/GetCurrentUserProfile"
            );
            const userTypeDescription =
              responseData?.UserProfileUserType[0]?.UserType?.Description;
            setUserProfileId(responseData.Id);
            setIsStaffEditing(false);
          } catch (error) {
            console.error("Error fetching current user profile:", error);
          }
        };
        fetchCurrentUser();
      }
    }
  }, [createProfile]);

  const editExperience = async (id: string) => {
    dispatch(configureExperienceRequest({ experienceId: id }));
    sessionStorage.setItem("ExperienceID", id);
    navigate(`/profile/edit-experience`);
  };

  const editAddress = async (id: string) => {
    dispatch(configureAddressRequest({ addressId: id }));
    sessionStorage.setItem("AddressId", id);
    navigate(`/profile/edit-address`);
  };

  const editLicesses = async (id: string) => {
    dispatch(configureLicensesRequest({ licesesId: id }));
    sessionStorage.setItem("LicenseID", id);
    navigate(`/profile/edit-licenses`);
  };

  const fetchStaffNotes = async () => {
    if (userProfileID) {
      try {
        const postResponseData = await fetchData(
          `UserProfileStaffNote/GetUserProfileStaffNoteByUserProfileID?UserProfileId=${userProfileID}`
        );
        const HistoryData = formatStaffNoteResponse(
          postResponseData.collection
        );
        setMemberRemarks(HistoryData);
        return postResponseData;
      } catch (error) {
        console.error("Error while fetching membership history", error);
      }
    }
  };
  const handleSaveMemberRemark = async () => {
    let hasError = false;
    try {
      if (!selectedCommittee) {
        hasError = true;
        setCommitteeError("Please select a committee");
      } else {
        setCommitteeError("");
      }

      if (!newMemberRemarkValue.trim()) {
        hasError = true;
        setRemarkError("Member remark is required");
      } else {
        setRemarkError("");
      }

      if (hasError) {
        setShowDialog(true);
        // If there are validation errors, return without proceeding further
        return;
      }

      const selectedCommitteeObj = committees.find(
        (committee: { Name: null }) => committee.Name === selectedCommittee
      );
      if (!selectedCommitteeObj) {
        console.error("Selected committee not found");
        return;
      }

      const { Id: committeeId, StaffId, Staff } = selectedCommitteeObj;
      if (!Staff) {
        console.error(
          "Staff information not available for the selected committee"
        );
        return;
      }

      const { Firstname, Lastname } = Staff;
      const staffName = `${Firstname} ${Lastname}`;
      const data = {
        isDirty: true,
        isNew: true,
        StaffId: StaffId,
        StaffName: staffName,
        userProfileStaffNotes: newMemberRemarkValue,
        date: new Date().toISOString(),
        CommiteeName: selectedCommittee,
        userProfileId: userProfileID,
        committeeId: committeeId,
      };
      await postData("UserProfileStaffNote", data);
      navigate("/profile");
      fetchStaffNotes();
    } catch (error) {
      console.error("Error saving member remark:", error);
    } finally {
      if (!hasError) {
        setNewMemberRemarkValue("");
        setSelectedCommittee("");
        setShowDialog(false);
      }
    }
  };

  const handleInputChange = (name: any, value: any) => {
    //validation
    // Reset error states when input values change
    if (name === "selectedCommittee") {
      setSelectedCommittee(value);
      setCommitteeError(value ? "" : "Please select a committee");
    } else if (name === "newMemberRemarkValue") {
      setNewMemberRemarkValue(value);
      setRemarkError(value.trim() ? "" : "Member remark is required");
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
    navigate("/profile");
  };

  useEffect(() => {
    fetchStaffNotes();
  }, []);

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (userProfileID) {
        try {
          const [
            userProfile,
            phoneData,
            experienceData,
            addressData,
            licenseData,
            educationData,
          ] = await Promise.all([
            fetchData("UserProfile", userProfileID),
            getListByParentId("UserPhone", userProfileID),
            getListByParentId("UserExperience", userProfileID),
            getListByParentId("UserAddress", userProfileID),
            getListByParentId("UserProfessionalLicense", userProfileID),
            getListByParentId("UserEducationDetail", userProfileID),
            fetchCommittees(),
            // handleGetAllDocumentUpload(),
          ]);

          const displayName =
            userProfile.DisplayName ||
            `${userProfile.Firstname} ${userProfile.Lastname}`;
          const publishingName =
            userProfile.PublishingName ||
            `${userProfile.Firstname} ${userProfile.Lastname}`;

          setBasicDetails({
            ...userProfile,
            DisplayName: displayName,
            PublishingName: publishingName,
          });
          // setBasicDetails(userProfile);
          setFullName(userProfile.Firstname + " " + userProfile.Lastname);
          setContactDetails(phoneData?.Collection);
          setExperienceDetails(experienceData?.Collection);
          const sortedAddressData = addressData?.Collection.sort(
            (a: any, b: any) => {
              // Assuming you want to sort by `IsDefaultAddress` and then by another field if needed
              if (a.IsDefaultAddress === b.IsDefaultAddress) {
                // Sort by another field if `IsDefaultAddress` is the same
                return b.CountryId - a.CountryId; // Example sorting by `CountryId` in descending order
              }
              return b.IsDefaultAddress - a.IsDefaultAddress;
            }
          );

          setAddressDetails(sortedAddressData);
          //setAddressDetails(addressData?.Collection);
          setProfessionalLicenseDetails(licenseData?.Collection);
          const updatedLicenseDetails = await Promise.all(
            licenseData?.Collection.map(async (license: any) => {
              try {
                const countryData = await fetchCountryByID(license.CountryId);
                const stateData = await fetchStateByID(license.StateId);
                return {
                  ...license,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return license;
              }
            })
          );
          setProfessionalLicenseDetails(updatedLicenseDetails);
          const updatedAddressDetails = await Promise.all(
            addressData?.Collection.map(async (address: any) => {
              try {
                const countryData = await fetchCountryByID(address.CountryId);
                const stateData = await fetchStateByID(address.StateId);
                return {
                  ...address,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return address;
              }
            })
          );
          setAddressDetails(updatedAddressDetails);

          const updatedEducationDetails = await Promise.all(
            educationData?.Collection.map(async (education: any) => {
              try {
                const countryData = await fetchCountryByID(education.CountryId);
                const stateData = await fetchStateByID(education.StateId);
                const universityData = await fetchUniversityByID(
                  education.UniversityId
                );
                const degreeData = await fetchDegreeByID(education.DegreeId);
                return {
                  ...education,
                  countryName: countryData?.Name,
                  stateName: stateData?.Name,
                  universityName: universityData?.Name,
                  degreeName: degreeData?.Name,
                };
              } catch (error) {
                console.error("Error fetching education details:", error);
                return education;
              }
            })
          );
          setEducationDetails(updatedEducationDetails);
          setProfileLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchDataAsync();
    fetchMembershipHistory();
    fetchActiveMembership();
    fetchStaffNotes(); //newremarks
  }, [userProfileID]);

  useEffect(() => {
    fetchStaffNotes();
  }, []);

  const editEducation = async (id: string) => {
    dispatch(
      configureEducationRequest({
        educationId: id,
      })
    );
    sessionStorage.setItem("EducationID", id);
    navigate("/profile/edit-education");
  };

  const editBasicDetails = async (id: string) => {
    dispatch(
      createUserProfileRequest({
        UserProfileId: id,
      })
    );
    sessionStorage.setItem("detailsID", id);
    navigate("/profile/edit-details");
  };

  const fetchMembershipHistory = async () => {
    if (userProfileID) {
      try {
        const postResponseData = await fetchData(
          `CommitteeMembership/GetCommitteeMembershipHistoryByUserProfileID?UserProfileId=${userProfileID}`
        );
        const HistoryData = formatMembershipHistoryResponse(
          postResponseData.Collection
        );
        setMemberHistory(HistoryData);
        return postResponseData;
      } catch (error) {
        console.error("Error while fetching membership history", error);
      }
    }
  };

  const fetchActiveMembership = async () => {
    if (userProfileID) {
      try {
        const postResponseData = await fetchData(
          `CommitteeMembership/GetCommitteeMembershipByUserProfileID?UserProfileId=${userProfileID}`
        );
        const MembershipData = formatMembershipResponse(
          postResponseData.Collection
        );

        setActiveMember(MembershipData);
        return postResponseData;
      } catch (error) {
        console.error("Error while fetching membership history", error);
      }
    }
  };

  function formatDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  const handleTerminate = useCallback(async () => {
    let hasError = false;

    if (!terminateValue.trim()) {
      setError("remarkError", t("profile.reasonForTermination"));
      hasError = true;
    } else {
      setError("remarkError", "");
    }

    if (!terminatedThroughValue) {
      setError(
        "terminatedThroughError",
        t("profile.terminatedThroughRequired")
      );
      hasError = true;
    } else {
      setError("terminatedThroughError", "");
    }

    if (
      (terminatedThroughValue === "Ballot" ||
        terminatedThroughValue === "Meeting") &&
      !ballotOrMeetingNumber.trim()
    ) {
      setError(
        "ballotOrMeetingNumberError",
        terminatedThroughValue === "Meeting"
          ? t("profile.meetingNumberRequired")
          : t("profile.ballotNumberRequired")
      );
      hasError = true;
    } else {
      setError("ballotOrMeetingNumberError", "");
    }

    if (!selectedReason) {
      setError("selectedReasonError", t("profile.selectReasonForTermination"));
      hasError = true;
    } else {
      setError("selectedReasonError", "");
    }

    if (hasError) return;

    const membershipTerminateBallotNumber =
      terminatedThroughValue === "Ballot" ? ballotOrMeetingNumber : null;
    const membershipTerminateMeetingNumber =
      terminatedThroughValue === "Meeting" ? ballotOrMeetingNumber : null;

    setLoading(true);

    const reasonForTermination = {
      reasonForTerminate: terminateValue,
      membershipTerminateBallotNumber,
      membershipTerminateMeetingNumber,
      membershipTerminatIsLackOfParticipation:
        selectedReason === "lackOfParticipation",
      membershipTerminatIsDisciplinaryAction:
        selectedReason === "disciplinaryAction",
    };

    if (memberShipId) {
      const putDataResponse = await putData(
        `CommitteeMembership/TerminateCommitteeMembership?committeeMembershipId=${memberShipId}`,
        undefined,
        reasonForTermination
      );

      setLoading(false);
      setVisibleTerminate(false);
      setValidationErrors({
        terminatedThroughError: "",
        ballotOrMeetingNumberError: "",
        selectedReasonError: "",
        terminateValueError: "",
        remarkError: "",
      });
      setSelectedReason("");
      setBallotOrMeetingNumber("");
      setTerminatedThroughValue("");
      setTerminateValue("");

      setTimeout(() => {
        showSuccessTermination();
      }, 1000);

      navigate("/profile");

      setTimeout(() => {
        fetchMembershipHistory();
        fetchActiveMembership();
      }, 1000);

      return putDataResponse;
    }
  }, [
    terminateValue,
    terminatedThroughValue,
    ballotOrMeetingNumber,
    selectedReason,
    memberShipId,
  ]);

  const handleResign = async () => {
    setResignValidationError("");
    if (!resignValue.trim()) {
      setResignValidationError("Please provide a reason for resignation.");
      return;
    }
    setLoading(true);

    if (memberShipId) {
      const data = {
        committeeMembershipId: memberShipId,
        comments: resignValue,
      };
      const putDataResponse = await postData(
        `MembershipRequest/MembershipRequestResign`,
        data
      );
      setLoading(false);
      setVisibleResign(false);
      setTimeout(() => {
        showSuccess();
      }, 1000);
      navigate("/profile");
      setTimeout(() => {
        fetchMembershipHistory();
        fetchActiveMembership();
      }, 1000);

      return putDataResponse;
    }
  };
  const handleEndMembership = async () => {
    setEndMembershipValidationError("");
    if (!endMembershipValue.trim()) {
      setEndMembershipValidationError(
        "Please provide a reason for End Membership."
      );
      return;
    }
    setLoading(true);
    const reasonForEndMembership = {
      remarkEndMembership: endMembershipValue,
    };
    if (memberShipId) {
      const putDataResponse = await putData(
        `CommitteeMembership/EndCommitteeMembership?committeeMembershipId=${memberShipId}`,
        undefined,
        reasonForEndMembership
      );
      setLoading(false);
      setVisibleEndMembership(false);
      setTimeout(() => {
        showSuccessEndMembership();
      }, 1000);
      navigate("/profile");
      setTimeout(() => {
        fetchMembershipHistory();
        fetchActiveMembership();
      }, 1000);

      return putDataResponse;
    }
  };

  const handleTerminateCancel = () => {
    setValidationErrors({
      terminatedThroughError: "",
      ballotOrMeetingNumberError: "",
      selectedReasonError: "",
      terminateValueError: "",
      remarkError: "",
    });
    setSelectedReason("");
    setBallotOrMeetingNumber("");
    setTerminatedThroughValue("");
    setTerminateValue("");

    setVisibleTerminate(false);
  };

  const handleTerminatedResultCancel = () => {
    setVisibleTerminatedRecords(false);
  };

  const handleResignCancel = () => {
    setResignValidationError("");
    setVisibleResign(false);
  };

  const handleEndMembershipCancel = () => {
    setEndMembershipValidationError("");
    setVisibleEndMembership(false);
  };

  const footerContentTerminatedResult = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("profile.cancel")}
        onClick={handleTerminatedResultCancel}
        className="button-md"
        severity="secondary"
      />
    </div>
  );

  const footerContentTerminate = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("profile.cancel")}
        onClick={handleTerminateCancel}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("profile.terminate")}
        onClick={handleTerminate}
        className="button-md"
      />
    </div>
  );
  const footerContentResign = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("profile.cancel")}
        onClick={handleResignCancel}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("profile.resign")}
        onClick={handleResign}
        className="button-md"
      />
    </div>
  );

  const footerContentEndMembership = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("profile.cancel")}
        onClick={handleEndMembershipCancel}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("profile.endMembership")}
        onClick={handleEndMembership}
        className="button-md"
      />
    </div>
  );

  const actionBodyTemplate = (rowData: RowData) => {
    const handleCustomActionClick = () => {
      setVisibleTerminatedRecords(true);
      getMembershipHistoryDetails(rowData.committeeMembershipId);
      sethistoryRecordID(rowData.recordID);
    };

    return (
      <button
        onClick={handleCustomActionClick}
        className="p-button p-button-text"
      >
        {t("profile.view")}
      </button>
    );
  };

  const getMembershipHistoryDetails = async (committeeMembershipID: string) => {
    setLoading(true);
    try {
      const response = await fetchData(
        `CommitteeMembership/GetCommitteeMembershipHistoryPopUp?CommitteeMembershipId=${committeeMembershipID}`
      );
      setMembershipHistoryDetails(response);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <Toast ref={toast} />
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <ConfirmDialog
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {/* {fullName} {t("profile.mainHeading")} */}
            {isStaffProfileEditing ? fullName : t("profile.mainHeading")}
          </h1>
          <p className="text-sm font-normal m-0">
            {" "}
            {t("profile.subTextProfile")}{" "}
          </p>
        </div>
        {isMemberProfile && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("profile.activeMembership")}
              </h2>
              <div className="flex text-sm"></div>
            </div>
            <div>
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-2">
                  <Table
                    loading={false}
                    products={activeMember}
                    columns={[
                      {
                        field: "name",
                        header: t("profile.committees"),
                      },
                      {
                        field: "positionName",
                        header: t("profile.position"),
                      },
                      {
                        field: "categoryName",
                        header: t("profile.categoryOfInterest"),
                      },
                      {
                        field: "numberOfBallotMissed",
                        header: t("profile.ballotAttended"),
                      },
                      {
                        field: "numberOfMeetingMissed",
                        header: t("profile.meetingAttended"),
                      },
                      {
                        field: "startDate",
                        header: t("profile.startDate"),
                      },
                      {
                        field: "endDate",
                        header: t("profile.endDate"),
                      },
                      {
                        field: "StatusName",
                        header: t("profile.status"),
                        body: (rowData: any) =>
                          getStatusBadge(rowData.StatusName),
                      },
                      {
                        field: "Action",
                        header: t("profile.action"),
                        body: actionPositionBodyTemplate,
                        sortable: false,
                      },
                    ]}
                    showCheckbox={false}
                  />
                </div>
                <Dialog
                  id="wantToResign"
                  header={t("profile.wantToResign")}
                  visible={visibleResign}
                  style={{ width: "50vw" }}
                  draggable={false}
                  breakpoints={{
                    "960px": "75vw",
                    "641px": "90vw",
                    "400px": "98vw",
                  }}
                  onHide={() => setVisibleResign(false)}
                  footer={footerContentResign}
                  className="custom-dialog hide-close"
                  ariaCloseIconLabel="Close"
                >
                  <div className="flex flex-column gap-2 w-full">
                    <label
                      htmlFor="requestResign"
                      className={`block font-light font-bold text-capitalize ${
                        resignValidationError ? "p-error" : ""
                      }`}
                    >
                      {t("profile.reasonForResign")}
                    </label>
                    <InputTextarea
                      id="requestResign"
                      value={resignValue}
                      onChange={(e) => setResignValue(e.target.value)}
                      rows={5}
                      cols={30}
                      className={resignValidationError ? "p-invalid" : ""}
                    />
                    <span
                      className={`p-error font-bold text-capitalize ${resignValidationError ? "" : "error-hidden"}`}
                    >
                      {resignValidationError}
                    </span>
                  </div>
                </Dialog>

                <Dialog
                  id="wantToTerminate"
                  header={t("profile.wantToTerminate")}
                  visible={visibleTerminate}
                  style={{ width: "50vw" }}
                  breakpoints={{
                    "960px": "75vw",
                    "641px": "90vw",
                    "400px": "98vw",
                  }}
                  onHide={() => {
                    setValidationErrors({
                      terminatedThroughError: "",
                      ballotOrMeetingNumberError: "",
                      selectedReasonError: "",
                      terminateValueError: "",
                      remarkError: "",
                    });
                    setSelectedReason("");
                    setBallotOrMeetingNumber("");
                    setTerminatedThroughValue("");
                    setTerminateValue("");
                    setVisibleTerminate(false);
                  }}
                  footer={footerContentTerminate}
                  draggable={false}
                  className="custom-dialog hide-close"
                  ariaCloseIconLabel="Close"
                >
                  <div className="flex flex-column gap-3 w-full">
                    {/* Terminated Through Dropdown */}
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 sm:col-6 col-12">
                        <label
                          htmlFor="terminatedThrough"
                          className={`block font-light font-bold text-capitalize ${
                            validationErrors.terminatedThroughError
                              ? "p-error"
                              : ""
                          }`}
                        >
                          {t("profile.terminatedThrough")}
                        </label>
                        <Dropdown
                          inputId="terminatedThrough"
                          value={terminatedThroughValue}
                          options={dropdownOptions}
                          onChange={handleTerminatedThroughChange}
                          placeholder={t("profile.selectTerminatedThrough")}
                          className={`w-full ${validationErrors.terminatedThroughError ? "p-invalid" : ""}`}
                        />
                        {validationErrors.terminatedThroughError && (
                          <small className="p-error font-bold">
                            {validationErrors.terminatedThroughError}
                          </small>
                        )}
                      </div>

                      {(terminatedThroughValue === "Meeting" ||
                        terminatedThroughValue === "Ballot") && (
                        <div className="flex flex-column gap-2 sm:col-6 col-12">
                          <label
                            htmlFor="ballotOrMeetingNumber"
                            className={`block font-light font-bold text-capitalize ${
                              validationErrors.ballotOrMeetingNumberError
                                ? "p-error"
                                : ""
                            }`}
                          >
                            {terminatedThroughValue === "Meeting"
                              ? t("profile.meetingNumber")
                              : t("profile.ballotNumber")}
                          </label>
                          <InputText
                            id="ballotOrMeetingNumber"
                            value={ballotOrMeetingNumber}
                            onChange={(e) => {
                              setBallotOrMeetingNumber(e.target.value);
                              setValidationErrors((prevErrors) => ({
                                ...prevErrors,
                                ballotOrMeetingNumberError: "",
                              }));
                            }}
                            className={`w-full ${validationErrors.ballotOrMeetingNumberError ? "p-invalid" : ""}`}
                          />
                          {validationErrors.ballotOrMeetingNumberError && (
                            <small className="p-error font-bold">
                              {validationErrors.ballotOrMeetingNumberError}
                            </small>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Radio Buttons */}
                    <div className="grid grid-xl">
                      <div className="col-12">
                        <label
                          htmlFor="requestTerminate"
                          className={`block font-light font-bold text-capitalize ${
                            validationErrors.selectedReasonError
                              ? "p-error"
                              : ""
                          }`}
                        >
                          {t("profile.reasonForTerminate")}
                        </label>
                      </div>
                      <div className="flex col-12">
                        <fieldset className="border-none p-0">
                          <div className="flex flex-wrap gap-3 column-gap-5">
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="lackOfParticipation"
                                value="lackOfParticipation"
                                checked={
                                  selectedReason === "lackOfParticipation"
                                }
                                onChange={(e) => {
                                  setSelectedReason(e.value);
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    selectedReasonError: "",
                                  }));
                                }}
                              />
                              <label
                                htmlFor="lackOfParticipation"
                                className="ml-2 font-bold"
                              >
                                {t("profile.basedOnLackOfParticipation")}
                              </label>
                            </div>
                            <div className="flex align-items-center">
                              <RadioButton
                                inputId="disciplinaryAction"
                                value="disciplinaryAction"
                                checked={
                                  selectedReason === "disciplinaryAction"
                                }
                                onChange={(e) => {
                                  setSelectedReason(e.value);
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    selectedReasonError: "",
                                  }));
                                }}
                              />
                              <label
                                htmlFor="disciplinaryAction"
                                className="ml-2 font-bold"
                              >
                                {t("profile.basedOnDisciplinaryAction")}
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      {validationErrors.selectedReasonError && (
                        <div className="col-12">
                          <small className="p-error font-bold">
                            {validationErrors.selectedReasonError}
                          </small>
                        </div>
                      )}
                    </div>

                    {/* Reason for Termination Label and Textarea */}
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 col-12">
                        <label
                          htmlFor="requestTerminate"
                          className={`block font-light font-bold text-capitalize ${
                            validationErrors.remarkError ? "p-error" : ""
                          }`}
                        >
                          {t("profile.remarks")}
                        </label>
                        <InputTextarea
                          id="requestTerminate"
                          value={terminateValue}
                          onChange={(e) => {
                            setTerminateValue(e.target.value);
                            setValidationErrors((prevErrors) => ({
                              ...prevErrors,
                              remarkError: "",
                            }));
                          }}
                          className={
                            validationErrors.remarkError ? "p-invalid" : ""
                          }
                          rows={5}
                          cols={30}
                        />
                        <span
                          className={`p-error font-bold text-capitalize ${
                            validationErrors.remarkError ? "" : "error-hidden"
                          }`}
                        >
                          {validationErrors.remarkError}
                        </span>
                      </div>
                    </div>
                  </div>
                </Dialog>

                <Dialog
                  id="wantToEndMembership"
                  header={t("profile.wantToEndMembership")}
                  visible={visibleEndMembership}
                  style={{ width: "50vw" }}
                  breakpoints={{
                    "960px": "75vw",
                    "641px": "90vw",
                    "400px": "98vw",
                  }}
                  onHide={() => setVisibleEndMembership(false)}
                  footer={footerContentEndMembership}
                  draggable={false}
                  className="custom-dialog hide-close"
                  ariaCloseIconLabel="Close"
                >
                  <div className="flex flex-column gap-2 w-full">
                    <label
                      htmlFor="requestEndMembership"
                      className={`block font-light font-bold text-capitalize ${
                        endMembershipValidationError ? "p-error" : ""
                      }`}
                    >
                      {t("profile.reasonForEnd")}
                    </label>
                    <InputTextarea
                      id="requestEndMembership"
                      value={endMembershipValue}
                      onChange={(e) => setEndMembershipValue(e.target.value)}
                      rows={5}
                      cols={30}
                      className={
                        endMembershipValidationError ? "p-invalid" : ""
                      }
                    />
                    <span
                      className={`p-error font-bold text-capitalize ${endMembershipValidationError ? "" : "error-hidden"}`}
                    >
                      {endMembershipValidationError}
                    </span>
                  </div>
                </Dialog>

                <Dialog
                  id="terminateResult"
                  header={t("profile.memberHistoryDetails")}
                  visible={visibleTerminatedRecords}
                  style={{ width: "50vw" }}
                  breakpoints={{
                    "960px": "75vw",
                    "641px": "90vw",
                    "400px": "98vw",
                  }}
                  onHide={() => setVisibleTerminatedRecords(false)}
                  footer={footerContentTerminatedResult}
                  draggable={false}
                  className="custom-dialog hide-close"
                  ariaCloseIconLabel="Close"
                >
                  <div className="flex flex-column gap-3 w-full">
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 col-12">
                        <div>
                          {/* Termination Method */}
                          <p>
                            <b>{t("profile.terminationMethod")}: </b>
                            {membershipHistoryDetails?.TerminatedThroughDirect
                              ? t("profile.directTermination")
                              : membershipHistoryDetails?.TerminatedThroughRecord
                                ? t("profile.throughRecord")
                                : membershipHistoryDetails?.TerminatedThroughBallot
                                  ? t("profile.throughBallot")
                                  : membershipHistoryDetails?.ThroughMeeting
                                    ? t("profile.throughMeeting")
                                    : "NA"}
                          </p>

                          {/* Termination Through */}
                          <p>
                            <b> {t("profile.terminatedThrough")}: </b>
                            {membershipHistoryDetails?.TerminatedThroughDirect
                              ? "NA"
                              : membershipHistoryDetails?.TerminatedThroughRecord
                                ? t("profile.record")
                                : membershipHistoryDetails?.TerminatedThroughBallot
                                  ? "NA"
                                  : membershipHistoryDetails?.ThroughMeeting
                                    ? "NA"
                                    : "NA"}
                          </p>

                          {/* Termination reason */}
                          {membershipHistoryDetails && (
                            <p>
                              <b> {t("profile.reasonTermination")}: </b>
                              {membershipHistoryDetails.ReasonForTermination}
                            </p>
                          )}

                          {/* Termination through */}
                          {membershipHistoryDetails?.ThroughMeeting && (
                            <p>
                              <b> {t("profile.meetingID")}: </b>
                              {
                                membershipHistoryDetails?.MembershipTerminateMeetingNumber
                              }
                            </p>
                          )}
                          {membershipHistoryDetails?.MembershipTerminateBallotNumber && (
                            <p>
                              <b> {t("profile.ballotID")}: </b>
                              {
                                membershipHistoryDetails?.MembershipTerminateBallotNumber
                              }
                            </p>
                          )}

                          {/* Termination remark */}
                          {membershipHistoryDetails && (
                            <p>
                              <b>{t("profile.remarks")}: </b>
                              {membershipHistoryDetails.TerminateRemark
                                ? membershipHistoryDetails.TerminateRemark
                                : "NA"}
                            </p>
                          )}
                          {membershipHistoryDetails?.TerminatedThroughRecord && (
                            <p>
                              <b> {t("profile.documentsText")}: </b>
                              <RecordListDocument
                                documentType="RecordDocument"
                                membershipRecordID={historyRecordID}
                                showNoDataMessage={true}
                                showDeleteIcon={false}
                              />
                            </p>
                          )}
                          {membershipHistoryDetails && (
                            <p>
                              <b> {t("profile.reasonResign")}: </b>
                              {membershipHistoryDetails.ReasonForResign
                                ? membershipHistoryDetails.ReasonForResign
                                : "NA"}
                            </p>
                          )}

                          {/* Resign reason */}
                          {membershipHistoryDetails?.ReasonForResign && (
                            <p>
                              <b> {t("profile.resignationDocuments")}: </b>
                              NA
                            </p>
                          )}

                          {/* Resign retired */}
                          {membershipHistoryDetails && (
                            <p>
                              <b> {t("profile.reasonRetired")}: </b>
                              {membershipHistoryDetails.RemarkForEndMembership
                                ? membershipHistoryDetails.RemarkForEndMembership
                                : "NA"}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        )}
        {isMemberProfile && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("profile.membershipHistory")}
              </h2>
              <div className="flex text-sm"></div>
            </div>
            <div>
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-2">
                  <Table
                    loading={false}
                    products={memberHistory}
                    columns={[
                      {
                        field: "name",
                        header: t("profile.committees"),
                      },
                      {
                        field: "positionName",
                        header: t("profile.position"),
                      },
                      {
                        field: "categoryName",
                        header: t("profile.categoryOfInterest"),
                      },
                      {
                        field: "numberOfBallotMissed",
                        header: t("profile.ballotAttended"),
                      },
                      {
                        field: "numberOfMeetingMissed",
                        header: t("profile.meetingAttended"),
                      },
                      {
                        field: "startDate",
                        header: t("profile.startDate"),
                      },
                      {
                        field: "endDate",
                        header: t("profile.endDate"),
                      },
                      {
                        field: "StatusName",
                        header: t("profile.status"),
                        body: (rowData: any) =>
                          getStatusBadge(rowData.StatusName),
                      },
                      {
                        field: "Action",
                        header: t("profile.action"),
                        body: actionBodyTemplate,
                      },
                    ]}
                    showCheckbox={false}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {" "}
              {t("profile.basicDetails")}{" "}
            </h2>
            <div className="flex text-sm">
              <div>
                {!basicDetails && (
                  <Button
                    text
                    className="p-button-plain gap-2 underline"
                    onClick={() => navigate("/profile/add-details")}
                  >
                    <FeatherIcon name="plus" size={20} />
                    <span>{t("profile.add")}</span>
                  </Button>
                )}
              </div>
              {/* <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  !basicDetails && (
                    <Button
                      text
                      className="p-button-plain gap-2"
                      onClick={() => navigate("/profile/add-details")}
                    >
                      <FeatherIcon name="plus" size={20} />
                      <span className="underline">{t("profile.add")}</span>
                    </Button>
                  )
                )}
              </div> */}
              {basicDetails && (
                <Button
                  text
                  className="p-button-plain gap-2"
                  onClick={() => editBasicDetails(userProfileID)}
                >
                  <FeatherIcon name="edit-2" size={20} />
                  <span className="underline">{t("profile.edit")}</span>
                </Button>
              )}
            </div>
          </div>
          <div>
            {basicDetails && (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-1">
                  <h3 className="font-bold text-base text-title m-0">
                    {basicDetails.Prefix} <span>.</span>{" "}
                    {basicDetails.Firstname} {basicDetails.MiddleName}{" "}
                    {basicDetails.Lastname}
                  </h3>
                  <p className="m-0">
                    <b>{t("profile.preferredDisplayName")}: </b>
                    <span>{basicDetails.DisplayName}</span>
                  </p>
                  <p className="m-0">
                    <b>{t("profile.preferredPublishingName")}: </b>
                    <span>{basicDetails.PublishingName}</span>
                  </p>
                  {userRole === "Member" && (
                    <p className="m-0 font-normal">{basicDetails.Email}</p>
                  )}

                  {contactDetails.map(
                    (
                      phone: { Type: string; PhoneNumber: any },
                      index: React.Key | null | undefined
                    ) => (
                      <p className="m-0 font-normal" key={index}>
                        {phone.Type === "work" &&
                          `${t("Work")} - ${phone.PhoneNumber}`}
                        {phone.Type === "home" &&
                          `${t("Home")} - ${phone.PhoneNumber}`}
                      </p>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <div>
            {profileLoading ? (
              <div className="flex flex-column gap-3">
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="4"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              </div>
            ) : basicDetails ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-1">
                  <h3 className="font-bold text-base text-title m-0">
                    {basicDetails.Prefix} <span>.</span>{" "}
                    {basicDetails.Firstname} {basicDetails.MiddleName}{" "}
                    {basicDetails.Lastname}
                  </h3>
                  <p className="m-0 font-normal">{basicDetails.Email}</p>

                  {contactDetails.map(
                    (
                      phone: { Type: string; PhoneNumber: any },
                      index: React.Key | null | undefined
                    ) => (
                      <p className="m-0 font-normal" key={index}>
                        {phone.Type === "work" &&
                          `${t("Work")} - ${phone.PhoneNumber}`}
                        {phone.Type === "home" &&
                          `${t("Home")} - ${phone.PhoneNumber}`}
                      </p>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="p-5 cardBody">
                <div className="text-center">
                  <p>{t("profile.noDataAvailable")}</p>
                </div>
              </div>
            )}
          </div> */}
        </div>
        {userRole === "Member" && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("profile.addressText")}
              </h2>
              <div className="flex text-sm">
                <div>
                  {profileLoading ? (
                    <div className="flex flex-column gap-3">
                      <ProgressSpinner
                        style={{ width: "50px", height: "50px" }}
                        strokeWidth="4"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    </div>
                  ) : (
                    !isStaffProfileEditing &&
                    addressDetails && (
                      <Button
                        text
                        className="p-button-plain gap-2"
                        onClick={() => navigate("/profile/add-address/")}
                      >
                        <FeatherIcon name="plus" size={20} />
                        <span className="underline">{t("profile.add")}</span>
                      </Button>
                    )
                  )}
                </div>
              </div>
            </div>
            {profileLoading ? (
              <div className="flex flex-column gap-3">
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="4"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                  aria-label={t("profile.loading")}
                />
              </div>
            ) : addressDetails && addressDetails.length > 0 ? (
              <div className="p-5 cardBody">
                <div className="flex flex-column gap-5">
                  {addressDetails.map((address: any, index: number) => (
                    <div className="flex" key={index}>
                      <div className="experienceDetails flex flex-column gap-1">
                        {address.AddressLine1 && (
                          <p className="m-0 font-normal">
                            {address.AddressLine1}
                            {address.IsDefaultAddress && (
                              <span>
                                {" "}
                                - <b>{t("profile.preferredAddress")}</b>
                              </span>
                            )}
                          </p>
                        )}
                        <p className="m-0 font-normal">
                          {address.AddressLine2}
                          {address.AddressLine3
                            ? `, ${address.AddressLine3}`
                            : ""}
                        </p>
                        {address.countryName && (
                          <p className="m-0 font-normal">
                            {address.stateName ? `${address.stateName}, ` : ""}
                            {address.countryName}
                          </p>
                        )}
                        <p className="m-0 font-normal">
                          {address.City ? `${address.City}, ` : ""}
                          {address.Pincode}
                          {/* <ListDocuments documentType="UserProfile_Address"></ListDocuments> */}
                        </p>
                      </div>

                      <div className="left-auto">
                        <Button
                          text
                          className="p-button-plain gap-2"
                          onClick={() => editAddress(address.Id)}
                        >
                          <FeatherIcon name="edit-2" size={20} />
                          <span className="underline">{t("profile.edit")}</span>
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : !isStaffProfileEditing ? (
              <EmptyState />
            ) : (
              <div className="">
                <div className="card bg-white w-full mb-5 shadow-md">
                  <div className="p-5 cardBody">
                    <div className="text-center">
                      <p>
                        Staff can only edit profiles; they cannot add any new
                        information.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("profile.experienceText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                      aria-label={t("profile.loading")}
                    />
                  </div>
                ) : (
                  experienceDetails &&
                  !isStaffProfileEditing && (
                    <Button
                      text
                      className="p-button-plain gap-2"
                      onClick={() => navigate(`/profile/add-experience/`)}
                    >
                      <FeatherIcon name="plus" size={20} />
                      <span className="underline">{t("profile.add")}</span>
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>

          {profileLoading ? (
            <div className="flex flex-column gap-3">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
                aria-label={t("profile.loading")}
              />
            </div>
          ) : experienceDetails && experienceDetails.length > 0 ? (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                {experienceDetails.map((experience: any, index: number) => (
                  <div className="flex" key={index}>
                    <div className="experienceDetails flex flex-column gap-1">
                      <h3 className="font-bold text-base text-title m-0">
                        {experience.Designation} ({experience.Department})
                      </h3>
                      <p className="m-0 font-normal">
                        {experience.CompanyName}
                      </p>
                      <p className="m-0 font-normal">
                        {new Date(experience.StartDate).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )}{" "}
                        -{" "}
                        {new Date(experience.EndDate).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )}
                      </p>
                      <p className="m-0 font-normal">{experience.WebsiteURL}</p>
                    </div>
                    {experienceDetails && (
                      <div className="left-auto">
                        <Button
                          text
                          className="p-button-plain gap-2"
                          onClick={() => editExperience(experience.Id)}
                        >
                          <FeatherIcon name="edit-2" size={20} />
                          <span className="underline">{t("profile.edit")}</span>
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : !isStaffProfileEditing ? (
            <EmptyState />
          ) : (
            <div className="">
              <div className="card bg-white w-full mb-5 shadow-md">
                <div className="p-5 cardBody">
                  <div className="text-center">
                    <p>
                      Staff can only edit profiles; they cannot add any new
                      information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {" "}
              {t("profile.professionalLicesesText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                      aria-label={t("profile.loading")}
                    />
                  </div>
                ) : (
                  professionalLicenseDetails &&
                  !isStaffProfileEditing && (
                    <Button
                      text
                      className="p-button-plain gap-2"
                      onClick={() => navigate(`/profile/add-licenses/`)}
                    >
                      <FeatherIcon name="plus" size={20} />
                      <span className="underline">{t("profile.add")}</span>
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>
          {profileLoading ? (
            <div className="flex flex-column gap-3">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
                aria-label={t("profile.loading")}
              />
            </div>
          ) : professionalLicenseDetails &&
            professionalLicenseDetails.length > 0 ? (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                {professionalLicenseDetails.map(
                  (license: any, index: number) => (
                    <div className="flex" key={index}>
                      <div className="experienceDetails flex flex-column gap-1">
                        <h3 className="font-normal text-base text-title mt-1 mb-1">
                          {t("profile.licenseDetails")}
                        </h3>
                        <h3 className="font-bold text-base text-title m-0">
                          {license.LicenseNumber}
                          {license.LicenseYear
                            ? `, ${license.LicenseYear}`
                            : ""}
                        </h3>
                        <p className="m-0 font-normal">{license.LicenseName}</p>
                        <p className="m-0 font-normal">
                          {license.stateName ? `${license.stateName}, ` : ""}
                          {license.countryName}
                        </p>
                      </div>
                      {professionalLicenseDetails && (
                        <div className="left-auto">
                          <Button
                            text
                            className="p-button-plain gap-2"
                            onClickCapture={() => editLicesses(license.Id)}
                          >
                            <FeatherIcon name="edit-2" size={20} />
                            <span className="underline">
                              {t("profile.edit")}
                            </span>
                          </Button>
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          ) : !isStaffProfileEditing ? (
            <EmptyState />
          ) : (
            <div className="">
              <div className="card bg-white w-full mb-5 shadow-md">
                <div className="p-5 cardBody">
                  <div className="text-center">
                    <p>
                      Staff can only edit profiles; they cannot add any new
                      information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("profile.documentsText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                      aria-label={t("profile.loading")}
                    />
                  </div>
                ) : (
                  !isStaffProfileEditing && (
                    <Button
                      text
                      className="p-button-plain gap-2"
                      onClick={() => navigate("/profile/add-document")}
                    >
                      <FeatherIcon name="plus" size={20} />
                      <span className="underline">{t("profile.add")}</span>
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="p-5 cardBody">
            {documentDetailsAvailable ? (
              <>
                {listOfDocumentType.map((docType) => (
                  <ListDocuments
                    isDocumentType={true}
                    documentType={docType}
                    handleDocumentDetailsAvailable={
                      handleDocumentDetailsAvailable
                    }
                    userProfileID={userProfileID}
                    isDelete={true}
                    isDownload={false}
                  />
                ))}
              </>
            ) : !isStaffProfileEditing ? (
              <EmptyState />
            ) : (
              <div className="card bg-white w-full mb-5 shadow-md">
                <div className="p-5 cardBody">
                  <div className="text-center">
                    <p>
                      Staff can only edit profiles; they cannot add any new
                      information.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
            <h2 className="text-title text-lg font-bold m-0">
              {t("profile.educationText")}
            </h2>
            <div className="flex text-sm">
              <div>
                {profileLoading ? (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                      aria-label={t("profile.loading")}
                    />
                  </div>
                ) : (
                  !isStaffProfileEditing &&
                  educationDetails && (
                    <Button
                      text
                      className="p-button-plain gap-2"
                      onClick={() => navigate("/profile/add-education/")}
                    >
                      <FeatherIcon name="plus" size={20} />
                      <span className="underline">{t("profile.add")}</span>
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>

          {profileLoading ? (
            <div className="flex flex-column gap-3">
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".5s"
                aria-label={t("profile.loading")}
              />
            </div>
          ) : educationDetails && educationDetails.length > 0 ? (
            <div className="p-5 cardBody">
              <div className="flex flex-column gap-5">
                {educationDetails.map((education: any, index: number) => (
                  <div className="flex" key={index}>
                    <div className="experienceDetails flex flex-column gap-1">
                      <h3 className="m-0 font-bold text-base text-title">
                        {education.degreeName}
                      </h3>
                      <p className="m-0 font-normal">
                        {new Date(education.FromYear).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )}{" "}
                        -{" "}
                        {new Date(education.ToYear).toLocaleDateString(
                          "en-US",
                          { month: "long", year: "numeric" }
                        )}
                      </p>
                      <p className="m-0 font-normal">
                        {education.universityName}
                        {education.City ? `, ${education.City}` : ""}
                      </p>
                      <p className="m-0 font-normal">
                        {education.stateName ? `${education.stateName}, ` : ""}
                        {education.countryName}
                      </p>
                    </div>

                    <div className="left-auto">
                      {educationDetails && (
                        <Button
                          text
                          className="p-button-plain gap-2"
                          onClick={() => editEducation(education.Id)}
                        >
                          <FeatherIcon name="edit-2" size={20} />
                          <span className="underline">{t("profile.edit")}</span>
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : !isStaffProfileEditing ? (
            <EmptyState />
          ) : (
            <div className="">
              <div className="card bg-white w-full mb-5 shadow-md">
                <div className="p-5 cardBody">
                  <div className="text-center">
                    <p>
                      Staff can only edit profiles; they cannot add any new
                      information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {isMemberProfile && (
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center justify-content-between align-self-center px-5 py-4 border-bottom-1 border-gray-200">
              <h2 className="text-title text-lg font-bold m-0">
                {t("profile.staffNotes")}
              </h2>
              <div className="flex text-sm">
                {!profileLoading && userRole !== "Member" && (
                  <div>
                    <Button
                      text
                      className="p-button-plain gap-2"
                      onClick={() => setShowDialog(true)}
                    >
                      <FeatherIcon name="plus" size={20} />
                      <span className="underline">{t("profile.add")}</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <div>
              {profileLoading ? (
                <div className="flex flex-column gap-3">
                  <ProgressSpinner
                    style={{ width: "50px", height: "50px" }}
                    strokeWidth="4"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                    aria-label={t("profile.loading")}
                  />
                </div>
              ) : (
                <div className="p-5 cardBody">
                  <Table
                    loading={false}
                    products={memberRemarks}
                    columns={[
                      {
                        field: "StaffName",
                        header: t("profile.StaffName"),
                      },
                      {
                        field: "UserProfileStaffNotes",
                        header: t("profile.staffNotes"),
                      },
                      {
                        field: "createdDate",
                        header: t("profile.Date"),
                      },
                      {
                        field: "committeeName",
                        header: t("profile.committeeName"),
                      },
                    ]}
                    showCheckbox={false}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <Dialog
          visible={showDialog}
          onHide={() => setShowDialog(false)}
          header="Add Staff Notes"
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          footer={
            <div className="mt-4 flex justify-content-end">
              <Button
                label="Cancel"
                onClick={handleCancel}
                className="button-md"
                severity="secondary"
              />
              <Button
                label={t("profile.save")}
                onClick={handleSaveMemberRemark}
                autoFocus
                className="button-md"
              />
            </div>
          }
        >
          <div className="flex flex-column gap-5">
            <div className="flex flex-column gap-2 w-full">
              <label
                htmlFor="committee"
                className="block font-bold text-input-label capitalize"
              >
                Committee
              </label>
              <Dropdown
                inputId="committee"
                value={selectedCommittee}
                options={
                  Array.isArray(committees)
                    ? committees.map((committee) => ({
                        label: committee.Name,
                        value: committee.Name,
                      }))
                    : []
                }
                onChange={(e) =>
                  handleInputChange("selectedCommittee", e.value)
                }
                placeholder="Select a committee"
              />
              <span
                className={`p-error font-bold text-capitalize ${committeeError ? "" : "error-hidden"}`}
              >
                {committeeError}
              </span>
            </div>
            <div className="flex flex-column gap-2 w-full">
              <label
                htmlFor="staffNotes"
                className="block font-bold text-input-label capitalize"
              >
                Staff Notes
              </label>
              <InputTextarea
                id="staffNotes"
                value={newMemberRemarkValue}
                onChange={(e) =>
                  handleInputChange("newMemberRemarkValue", e.target.value)
                }
                rows={3}
                placeholder="Staff Notes"
              />
              <span
                className={`p-error font-bold text-capitalize ${remarkError ? "" : "error-hidden"}`}
              >
                {remarkError}
              </span>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default UserProfile;
