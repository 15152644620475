import axios from "axios";
import { BASE_URL_STANDARD } from "../../config";
import { IDENTITY_URL } from "../../config";

const axoisInstance = axios.create({
  baseURL: BASE_URL_STANDARD,
});
axoisInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axoisInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("token");
      if (refreshToken) {
        try {
          let headers = {
            "Content-Type": "application/json",
          };
          const response = await axios.post(
            `${IDENTITY_URL}/Account/RefreshToken`,
            refreshToken,
            { headers: headers }
          );
          const newAccessToken = response.data.accessToken;
          localStorage.setItem("token", newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (error) {
          console.log(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axoisInstance;
