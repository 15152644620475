import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BallotDetails {
  ballotStatusList: {};
  ballotRecordStatusList: {};
  ballotDetailsInfo: {
    isSecret: boolean;
    id: string | null;
    isDirty: boolean;
    isNew: boolean;
    ballotNumber: string | null;
    ballotLevelId: string | null;
    ballotTypeId: string | null;
    ballotSubTypeId: string | null;
    ballotStatusId: string | null;
    ballotStatusName: string | null;
    recordSubTypeId: string | null;
    recordTypeId: string | null;
    recirculationBallotId: string | null;
    recirculationBallotNumber: string | null;
    note: string | null;
    explaination: string | null;
    description: string | null;
    daysToIssue: number | null;
    openDate: Date | null;
    closeDate: Date | null;
  };
}

const initialState: BallotDetails = {
  ballotStatusList: {},
  ballotRecordStatusList: {},
  ballotDetailsInfo: {
    isSecret: false,
    id: null,
    isDirty: false,
    isNew: false,
    ballotNumber: null,
    ballotLevelId: null,
    ballotTypeId: null,
    ballotSubTypeId: null,
    ballotStatusId: null,
    ballotStatusName: null,
    recordSubTypeId: null,
    recordTypeId: null,
    recirculationBallotId: null,
    recirculationBallotNumber: null,
    note: null,
    explaination: null,
    description: null,
    daysToIssue: 0,
    openDate: null,
    closeDate: null,
  },
};
const ballotDetailsSlice = createSlice({
  name: "ballotDetails",
  initialState,
  reducers: {
    addBallotDetails: (state, action: PayloadAction<Record<string, any>>) => {
      switch (action.payload.label) {
        case "secret":
          state.ballotDetailsInfo.isSecret = action.payload.target;
          break;
        case "ballotid":
          state.ballotDetailsInfo.id = action.payload.target;
          break;
        case "recirculationBallotId":
          state.ballotDetailsInfo.recirculationBallotId = action.payload.target;
          break;
        case "recirculationBallotNumber":
          state.ballotDetailsInfo.recirculationBallotNumber =
            action.payload.target;
          break;
        case "ballottype":
          state.ballotDetailsInfo.ballotTypeId = action.payload.target;
          break;
        case "ballotnumber":
          state.ballotDetailsInfo.ballotNumber = action.payload.target;
          break;
        case "ballotsubtype":
          state.ballotDetailsInfo.ballotSubTypeId = action.payload.target;
          break;
        case "ballotlevel":
          state.ballotDetailsInfo.ballotLevelId = action.payload.target;
          break;
        case "recordtype":
          state.ballotDetailsInfo.recordTypeId = action.payload.target;
          break;
        case "recordsubtype":
          state.ballotDetailsInfo.recordSubTypeId = action.payload.target;
          break;
        case "opendate":
          state.ballotDetailsInfo.openDate = action.payload.target;
          break;
        case "enddate":
          state.ballotDetailsInfo.closeDate = action.payload.target;
          break;
        case "description":
          state.ballotDetailsInfo.description = action.payload.target;
          break;
        case "explaination":
          state.ballotDetailsInfo.explaination = action.payload.target;
          break;
        case "staffnote":
          state.ballotDetailsInfo.note = action.payload.target;
          break;
        case "ballotStatusId":
          state.ballotDetailsInfo.ballotStatusId = action.payload.target;
          break;
        case "ballotStatusName":
          state.ballotDetailsInfo.ballotStatusName = action.payload.target;
          break;
        case "addnotes":
          break;
        case "documents":
          break;
        default:
          break;
      }
    },
    addBallotStatusList: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      const transformedObject = action.payload.value.reduce(
        (acc: any, status: any) => {
          acc[status.name] = { id: status.id };
          return acc;
        },
        {}
      );
      state.ballotStatusList = transformedObject;
    },
    addBallotRecordStatusList: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      const transformedObject = action.payload.value.reduce(
        (acc: any, status: any) => {
          acc[status.name] = { id: status.id };
          return acc;
        },
        {}
      );
      state.ballotRecordStatusList = transformedObject;
    },
    clearBallotDetails: (state, action: PayloadAction<Record<string, any>>) => {
      switch (action.payload.label) {
        case "recirculationBallotId":
          state.ballotDetailsInfo.recirculationBallotId = null;
          state.ballotDetailsInfo.recirculationBallotNumber = null;
          break;
        default:
          break;
      }
    },
    reset: (state) => {
      state.ballotDetailsInfo.id = null;
      state.ballotDetailsInfo.recirculationBallotId = null;
      state.ballotDetailsInfo.recirculationBallotNumber = null;
      state.ballotDetailsInfo.note = null;
      state.ballotDetailsInfo.explaination = null;
      state.ballotDetailsInfo.description = null;
      state.ballotDetailsInfo.daysToIssue = 0;
      state.ballotDetailsInfo.openDate = null;
      state.ballotDetailsInfo.closeDate = null;
      state.ballotDetailsInfo.isSecret = false;
      state.ballotDetailsInfo.ballotStatusId = null;
      state.ballotDetailsInfo.ballotNumber = null;
      state.ballotDetailsInfo.ballotStatusName = null;
    },
  },
});

export const ballotDetails = ballotDetailsSlice.actions;
export default ballotDetailsSlice.reducer;
