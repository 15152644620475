import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TabView, TabPanel } from "primereact/tabview";
import { useLanguageContext } from "../LanguageContext";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { reset } from "../../slices/createRecordSlice";
import { clearAllSessionStorage } from "../../utils/storage";
import RecordResignation from "./RecordResignation";
import NewRecordList from "./NewRecordList";
import AllRecordList from "./AllRecordList";
import TerminationRecordList from "./TerminationRecordList";
import ReappointmentRecordList from "./ReappointmentRecordList";

const RecordListing: React.FC = () => {
  const location = useLocation();
  const [t, i18n] = useTranslation("record");
  const [activeIndex, setActiveIndex] = useState(0);
  const { selectedLanguage } = useLanguageContext();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    clearAllSessionStorage();
    dispatch(reset());
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    if (location.state?.activeTabIndex !== undefined) {
      setActiveIndex(location.state.activeTabIndex);
    }
  }, [location.state]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.records") },
  ];

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("record.membershipRecords")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("record.membershipRecordsHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView
            scrollable
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header={t("record.new")}>
              <NewRecordList></NewRecordList>
            </TabPanel>
            <TabPanel header={t("record.reappointments")}>
              <ReappointmentRecordList />
            </TabPanel>
            <TabPanel header={t("record.termination")}>
              <TerminationRecordList />
            </TabPanel>
            <TabPanel header={t("record.resignation")}>
              <RecordResignation />
            </TabPanel>
            <TabPanel header={t("record.all")}>
              <AllRecordList />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default RecordListing;
