import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { Badge } from "primereact/badge";
import { Checkbox } from "primereact/checkbox";
import { useLanguageContext } from "../../../LanguageContext";
import FeatherIcon from "../../../common/FeatherIconComponent";

interface Option {
  name: string;
  code: string;
}

const CommentResolved: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];

  const [votingOption, setvotingOption] = useState<string>('');

  const [value, setValue] = useState<string>('');

  const [withdrawDisapproval, setwithdrawDisapproval] = useState<boolean>(false);
  const footerContentWithdrawDisapproval = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("ballot.cancel")}
        onClick={() => {
          setwithdrawDisapproval(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("ballot.save")}
        onClick={() => {
          setwithdrawDisapproval(false);
        }}
        className="button-md"
      />
    </div>
  );

  const [markResolve, setmarkResolve] = useState<boolean>(false);
  // comments action
  const menuBallot = useRef<Menu>(null);
  const toggleBallotMenu = (event: any) => {
    if (menuBallot.current) {
      menuBallot.current.toggle(event);
    }
  };

  const showActionItem: MenuItem[] = [
    {
      label: "Option 1",
    },
    {
      label: "Option 2",
    },
  ];

  return (
    <>
      <Button
        text
        className="p-button-plain gap-2 mb-3 pl-0 underline"
        onClick={() => navigate("/ballot")}
      >
        <FeatherIcon name="arrow-left" size={20} />
        <span className="">{t("ballot.back")}</span>
      </Button>
      <div className="mb-5 flex flex-column gap-2">
        <h1 className="text-title display-xs font-bold text-capitalize m-0">
          {t("ballot.ballotId")} - {773574}
        </h1>
        <p className="text-base font-normal m-0">
          Any supporting text for request listing page can go here
        </p>
      </div>
      <div className="flex flex-column gap-4">
        <div className="card bg-white w-full shadow-md">
          <div className="flex flex-wrap align-items-center px-4 py-3 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h5 className="text-title text-base font-bold text-capitalize m-0">
              Committee Name
            </h5>
            <div className="flex flex-wrap align-items-center gap-2 ml-auto">
              <span>{t("ballot.comments")}</span>
              <Chip className="text-sm bg-white border-1 custom-chip" label={`2/4 ${t('ballot.resolved')}`}
              />
              <Chip className="text-sm bg-white border-1 custom-chip" label={`2/4 ${t('ballot.responded')}`} />
              <Chip className="text-sm bg-white border-1 custom-chip" label={`1 ${t('ballot.disaaprovedWOComment')}`} />
              <Chip className="text-sm bg-white border-1 custom-chip" label={`1 ${t('ballot.unrelated')}`} />
            </div>
          </div>

          <div className="p-4 cardBody flex flex-column gap-4 md:flex-row">
            <div className="flex flex-column gap-7 w-full">
              <div className="flex flex-column gap-3">
                <fieldset className="border-none p-0">
                  <legend className="block font-bold text-input-label p-0 mb-3">{`${t('ballot.comments')} (2)`}</legend>
                  <div className="cardBody flex flex-column gap-7">
                    <div className="flex flex-column gap-4">
                      <div className="flex flex-column gap-3">
                        <div className="flex flex-wrap align-items-center justify-content-between py-3 gap-3">
                          <div className="flex flex-wrap align-items-center gap-3">
                            <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" size="large" shape="circle" className="flex-shrink-0" />
                            <div className="flex flex-column gap-1">
                              <h3 className="text-title text-base font-bold text-capitalize m-0">
                                John Doe
                              </h3>
                              <p className="text-base font-normal text-capitalize m-0">Member</p>
                            </div>
                            <Badge value={t("ballot.disapproved")} severity="danger"></Badge>
                          </div>

                          <div className="flex align-items-center gap-3">
                            <div className="flex align-items-center">
                              <Checkbox
                                inputId="markResolve"
                                name="markResolve"
                                onChange={(e) => setmarkResolve(e.checked ?? false)}
                                checked={markResolve}
                                className="mr-2"
                              />
                              <label
                                htmlFor="markResolve"
                                className="text-base font-normal"
                              >
                                {t("ballot.markedResolved")}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-column gap-3">
                          <div>
                            <label
                              htmlFor="paragraphSection"
                              className="inline font-bold text-capitalize text-input-label">
                              {t("ballot.paragraphSection")}:
                            </label>
                            <p id="paragraphSection" className="p-0 inline ml-2">
                              Partner with our team of Dreamforce Speakers, Community Leaders and Salesforce experts to empower your business. We can handle everything starting from strategic consulting and architecture, to product engineering, to implementation, administration and support
                            </p>
                          </div>
                          <div className="flex flex-column gap-2">
                            <label
                              htmlFor="typeOfComment"
                              className="block font-bold text-input-label text-capitalize"
                            >
                              {t("ballot.typeOfComment")}*
                            </label>
                            <Dropdown
                              inputId="typeOfComment"
                              // aria-describedby="typeOfCommentError"
                              name="typeOfComment"
                              value={selectedOption}
                              onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                              options={options}
                              optionLabel="name"
                              placeholder={t("ballot.selectType")}
                            />
                            {/* <span
                              id="typeOfCommentError"
                              className={`p-error font-bold text-capitalize ${error ? "" : "error-hidden"}`}
                            >
                              {error}
                            </span> */}
                          </div>
                          <div>
                            <label
                              htmlFor="comment"
                              className="inline font-bold text-capitalize text-input-label">
                              {t("ballot.comment")}:
                            </label>
                            <p id="comment" className="p-0 inline ml-2">
                              Partner with our team of Dreamforce Speakers, Community Leaders and Salesforce experts to empower your business. We can handle everything starting from strategic consulting and architecture, to product engineering, to implementation, administration and support
                            </p>
                          </div>
                          <div>
                            <label
                              htmlFor="proposedAction"
                              className="inline font-bold text-capitalize text-input-label">
                              {t("ballot.proposedAction")}:
                            </label>
                            <p id="proposedAction" className="p-0 inline ml-2">
                              Partner with our team of Dreamforce Speakers, Community Leaders and Salesforce experts to empower your business. We can handle everything starting from strategic consulting and architecture, to product engineering, to implementation, administration and support
                            </p>
                          </div>
                        </div>
                        <div>
                          <Button text className="p-button-plain gap-2 p-0 underline">
                            <span className="font-normal text-capitalize">
                              {t("ballot.reply")}
                            </span>
                          </Button>
                        </div>
                      </div>

                    </div>

                    <div className="flex flex-column gap-4">
                      <div className="flex flex-column gap-3">
                        <div className="flex flex-wrap align-items-center justify-content-between py-3 gap-3">
                          <div className="flex flex-wrap align-items-center gap-3">
                            <Avatar image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png" size="large" shape="circle" className="flex-shrink-0" />
                            <div className="flex flex-column gap-1">
                              <h3 className="text-title text-base font-bold text-capitalize m-0">
                                John Doe
                              </h3>
                              <p className="text-base font-normal text-capitalize m-0">Member</p>
                            </div>
                            <Badge value={t("ballot.approved")} severity="success"></Badge>
                          </div>

                          <div className="flex align-items-center gap-3">
                            <div className="flex align-items-center">
                              <Checkbox
                                inputId="markResolve"
                                name="markResolve"
                                onChange={(e) => setmarkResolve(e.checked ?? false)}
                                checked={markResolve}
                                className="mr-2"
                              />
                              <label
                                htmlFor="markResolve"
                                className="text-base font-normal"
                              >
                                {t("ballot.markResolve")}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-column gap-3">
                          <div>
                            <label
                              htmlFor="paragraphSection"
                              className="inline font-bold text-capitalize text-input-label">
                              {t("ballot.paragraphSection")}:
                            </label>
                            <p id="paragraphSection" className="p-0 inline ml-2">
                              Partner with our team of Dreamforce Speakers, Community Leaders and Salesforce experts to empower your business. We can handle everything starting from strategic consulting and architecture, to product engineering, to implementation, administration and support
                            </p>
                          </div>
                          <div className="flex flex-column gap-2">
                            <label
                              htmlFor="typeOfComment"
                              className="block font-bold text-input-label text-capitalize"
                            >
                              {t("ballot.typeOfComment")}*
                            </label>
                            <Dropdown
                              inputId="typeOfComment"
                              // aria-describedby="typeOfCommentError"
                              name="typeOfComment"
                              value={selectedOption}
                              onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                              options={options}
                              optionLabel="name"
                              placeholder={t("ballot.selectType")}
                            />
                            {/* <span
                              id="typeOfCommentError"
                              className={`p-error font-bold text-capitalize ${error ? "" : "error-hidden"}`}
                            >
                              {error}
                            </span> */}
                          </div>
                          <div>
                            <label
                              htmlFor="comment"
                              className="inline font-bold text-capitalize text-input-label">
                              {t("ballot.comment")}:
                            </label>
                            <p id="comment" className="p-0 inline ml-2">
                              Partner with our team of Dreamforce Speakers, Community Leaders and Salesforce experts to empower your business. We can handle everything starting from strategic consulting and architecture, to product engineering, to implementation, administration and support
                            </p>
                          </div>
                          <div>
                            <label
                              htmlFor="proposedAction"
                              className="inline font-bold text-capitalize text-input-label">
                              {t("ballot.proposedAction")}:
                            </label>
                            <p id="proposedAction" className="p-0 inline ml-2">
                              Partner with our team of Dreamforce Speakers, Community Leaders and Salesforce experts to empower your business. We can handle everything starting from strategic consulting and architecture, to product engineering, to implementation, administration and support
                            </p>
                          </div>
                        </div>
                        <div>
                          <Button text className="p-button-plain gap-2 p-0 underline">
                            <span className="font-normal text-capitalize">
                              {t("ballot.reply")}
                            </span>
                          </Button>
                        </div>
                      </div>

                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* Footer buttons */}
      <div className="bg-white flex justify-content-between align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
        <Button
          label={t("ballot.cancel")}
          className="button-md"
          severity="secondary"
          type="button"
        />
        <Button
          className="button-md gap-1"
          type="button"
        >
          <span className="font-bold text-capitalize">
            {t("ballot.save")}
          </span>
        </Button>
      </div>
    </>
  );
};

export default CommentResolved;
