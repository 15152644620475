import React, { useState, useRef, useEffect } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { OverlayPanel } from "primereact/overlaypanel"; // Import OverlayPanel
import Table from "../common/Table";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData, putData } from "../../services/apiService";
import { formatMembershipRecordResponse } from "../../utils/utils";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { MultiSelect } from "primereact/multiselect";
import { usePermission } from "../auth/Authorization";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import { useLoadingContext } from "../Layout";
interface Option {
  name: string;
  code: string;
}

interface RecordList {
  [x: string]: any;
  recordId: string;
  recordType: string;
  subSubType: string;
  createdOn: string;
  status: string;
  action: string;
}

const CommitteeTabRecords: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const [coiData, setCoiData] = useState([]);
  const [search, setSearch] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [recordStatus, setRecordStatus] = useState([]);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [selectedRecordStatuses, setSelectedRecordStatuses] = useState<any[]>(
    []
  );
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [recordStatusCheckboxChecked, setRecordStatusCheckboxChecked] =
    useState<boolean>(false);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);

  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [loading, setLoading] = useState<boolean>(true);
  const [validationError, setValidationError] = useState("");
  const [validationRejectError, setRejectValidationError] = useState("");
  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committees") },
  ];
  const [recordList, setRecordList] = useState<RecordList[]>([]);
  const [visibleClose, setVisibleClose] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [reasonValue, setReasonValue] = useState("");
  const [rejectValue, setRejectValue] = useState("");
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  // filter
  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  const handleCloseDialog = () => {
    setVisibleClose(true);
  };

  const handleRejectDialog = () => {
    setVisibleReject(true);
  };

  const handleCloseRecord = async () => {
    if (!reasonValue.trim()) {
      setValidationError(`${t("committee.reasonForCloseRecord")}`);
      return;
    }
    let reason = {
      reasonForCloseMembershipRecord: reasonValue,
    };
    const recordId = sessionStorage.getItem("recordId");
    if (recordId) {
      try {
        const terminateMember = await putData(
          `Record/CloseMembershipRecord/${recordId}`,
          undefined,
          reason
        );
        setVisibleClose(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRejectRecord = async () => {
    if (!rejectValue.trim()) {
      setRejectValidationError(`${t("committee.reasonForRejectRecord")}`);
      return;
    }
    let reason = {
      reasonForCloseMembershipRecord: rejectValue,
    };
    const recordId = sessionStorage.getItem("recordId");
    if (recordId) {
      try {
        const terminateMember = await putData(
          `Record/RejectMembershipRecord/${recordId}`,
          undefined,
          reason
        );
        setVisibleReject(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const actionItems: MenuItem[] = [
    // {
    //   label: t("committee.reasonForCloseRecord"),
    //   command: handleCloseDialog,
    // },
    // {
    //   label: t("committee.reasonForCloseRecord"),
    //   command: handleRejectDialog
    // },
    {
      label: t("committee.viewRecord"),
      command: () => {
        navigate("/record/record-preview");
      },
    },
  ];

  const fetchRecordStatus = async () => {
    try {
      const response = await fetchData("RecordStatus/GetAll");
      const recordstatus = response.Collection;
      setRecordStatus(recordstatus);
    } catch (error) {
      console.error("Error fetching Record Status:", error);
    }
  };

  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  useEffect(() => {
    fetchCategoryOfInterest();
    fetchMembershipPosition();
    fetchRecordStatus();
  }, []);

  const handleCheckboxChange =
    (checkbox: string) => (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "recordStatus":
          setRecordStatusCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        default:
          break;
      }
    };

  const fetchFilteredData = async (event: any) => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: string }[] = [];
    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (positionCheckboxChecked) {
      const positionIds = selectedPositions.map((position) => position.Id);
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (recordStatusCheckboxChecked) {
      const recordStatusIds = selectedRecordStatuses.map((status) => status.Id);
      filters.push({ Key: "RecordStatusId", Value: recordStatusIds.join(",") });
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({ Key: "DateFilter", Value: `${fromDateStr}#${toDateStr}` });
    }
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = -1;
    //queryParams["PageSize"] = 10;
    queryParams["SearchText"] = "";
    queryParams["SortBy"] = "";
    queryParams["SortDir"] = "asc";
    const apiUrl = `${BASE_URL}/Record/Search?${new URLSearchParams(queryParams).toString()}`;
    try {
      const response = await axiosInstance.get(apiUrl);

      if (response.status === 200) {
        const filteredData = response.data;
        const filterRecordIdArray = filteredData.Collection.map(
          (data: any) => data.Id
        );
        const filterRecordListData: any = recordList?.filter((data) =>
          filterRecordIdArray.includes(data.Id)
        );
        setFilteredRecords(filterRecordListData);
        setIsFilterApplied(true);
      } else {
        console.error("Failed to fetch filtered data");
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
    overlayPanelRef.current?.toggle(event);
    setCoiCheckboxChecked(false);
    setRecordStatusCheckboxChecked(false);
    setPositionCheckboxChecked(false);
    setDateCheckboxChecked(false);
    setSelectedCOIs([]);
    setSelectedRecordStatuses([]);
    setSelectedPositions([]);
    setDateActivationFrom(null);
    setDateActivationTo(null);
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setToDateError(`${t("committee.fromDateBeforeToDate")}`);
        setDateActivationFrom(null);
      } else {
        setToDateError("");
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setToDateError(`${t("committee.toDateAfterFromDate")}`);
        setDateActivationTo(null);
      } else {
        setToDateError("");
        setDateActivationTo(date);
      }
    }
  };
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const handleMembershipRecordListing = async () => {
      try {
        const committeeId = sessionStorage.getItem("committeeId");
        if (committeeId) {
          const memberRecord = await fetchData(
            `Record/Search?Filters[0].Key=CommitteeId&Filters[0].Value=${committeeId}&PageIndex=-1`
          );

          const MembershipRecord = formatMembershipRecordResponse(
            memberRecord.Collection
          );
          setRecordList(MembershipRecord);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    handleMembershipRecordListing();
  }, []);

  const handleNewRecord = () => {
    sessionStorage.removeItem("");
    sessionStorage.setItem("redirectionPath", "record");
    navigate("/record/volunteer");
  };
  const footerContentClose = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.cancel")}
        onClick={() => {
          setVisibleClose(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.close")}
        onClick={handleCloseRecord}
        className="button-md"
      />
    </div>
  );

  const footerContentReject = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.cancel")}
        onClick={() => {
          setVisibleReject(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.reject")}
        onClick={handleRejectRecord}
        className="button-md"
      />
    </div>
  );
  return (
    <>
      <div className="">
        <div className="flex flex-wrap md:flex-nowrap md:align-items-center py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="recordId"
              placeholder={t("committee.searchCommittee")}
              className="w-full"
              aria-label={t("committee.searchCommittee")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </IconField>
          <div className="flex gap-3 w-full md:w-auto align-items-center ml-auto">
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={toggleFilterPanel}
                aria-label={t("committee.filter")}
              >
                <FeatherIcon name="filter" size={20} color="inherit" />
                <span className="font-bold text-capitalize hidden md:flex">
                  {t("committee.filter")}
                </span>
              </Button>

              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-filter left-auto md:right-0 top-100"
                aria-labelledby="filter-heading"
                role="dialog"
              >
                <div className="flex flex-column gap-1" tabIndex={0}>
                  <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                    {t("committee.filter")}
                  </p>
                  <div className="filter-wrap">
                    <div className="">
                      <label
                        htmlFor="categoryOfInterestFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="categoryOfInterestFilter"
                          onChange={handleCheckboxChange("coi")}
                          checked={coiCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.categoryOfInterest")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo={"self"}
                          value={selectedCOIs}
                          onChange={(e) => setSelectedCOIs(e.value)}
                          options={coiData}
                          optionLabel="CategoryName"
                          filter
                          placeholder={t("committee.categoryOfInterest")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.categoryOfInterest")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="recordStatusFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="recordStatusFilter"
                          onChange={handleCheckboxChange("recordStatus")}
                          checked={recordStatusCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.recordStatus")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo={"self"}
                          value={selectedRecordStatuses}
                          onChange={(e) => setSelectedRecordStatuses(e.value)}
                          options={recordStatus}
                          optionLabel="StatusName"
                          filter
                          placeholder={t("committee.recordStatus")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.recordStatus")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="membershipPositionIdFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="membershipPositionIdFilter"
                          onChange={handleCheckboxChange("position")}
                          checked={positionCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.membershipPositionId")}
                      </label>
                      <div className="pr-3 py-2 pl-6 w-full relative">
                        <MultiSelect
                          // appendTo={"self"}
                          value={selectedPositions}
                          onChange={(e) => setSelectedPositions(e.value)}
                          options={memberPosition}
                          optionLabel="PositionName"
                          filter
                          placeholder={t("committee.membershipPositionId")}
                          maxSelectedLabels={3}
                          className="w-full"
                          aria-label={t("committee.membershipPositionId")}
                        />
                      </div>
                    </div>
                    <div className="p-0">
                      <label
                        htmlFor="createOnFilter"
                        className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                      >
                        <Checkbox
                          inputId="createOnFilter"
                          onChange={handleCheckboxChange("date")}
                          checked={dateCheckboxChecked}
                          className="mr-2"
                          tabIndex={0}
                        />
                        {t("committee.createOn")}
                      </label>
                      <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                        <Calendar
                          className="custom-datepicker"
                          inputId="dateActivationFrom"
                          onChange={(e) =>
                            handleDateSelect(
                              e.value as Date,
                              "dateActivationFrom"
                            )
                          }
                          value={dateActivationFrom}
                          dateFormat="mm/dd/yy"
                          tabIndex={0}
                          ariaLabel={t("committee.fromDate")}
                          placeholder={t("committee.fromDate")}
                        />
                        <Calendar
                          className="custom-datepicker"
                          inputId="dateActivationTo"
                          onChange={(e) =>
                            handleDateSelect(
                              e.value as Date,
                              "dateActivationTo"
                            )
                          }
                          value={dateActivationTo}
                          dateFormat="mm/dd/yy"
                          tabIndex={0}
                          ariaLabel={t("committee.toDate")}
                          placeholder={t("committee.toDate")}
                        />
                        {toDateError && (
                          <div className="text-red-500">{toDateError}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Button
                    className="button-md w-full justify-content-center"
                    onClick={(e: any) => fetchFilteredData(e)}
                  >
                    {t("committee.applyFilter")}
                  </Button>
                </div>
              </OverlayPanel>
            </div>
            {/* <Button className="button-md gap-1" label="Transfer"></Button> */}
            {(userRole === "Admin" || userRole === "Staff") && (
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleNewRecord}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("committee.newRecord")}
                </span>
              </Button>
            )}
          </div>
        </div>
        <Dialog
          id="visibleCloseId"
          header={t("committee.closeRecord")}
          visible={visibleClose}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => setVisibleClose(false)}
          footer={footerContentClose}
          draggable={false}
          className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="requestClose"
              className={`block font-bold text-capitalize mb-2 ${
                validationError ? "p-error" : ""
              }`}
            >
              {t("committee.reasonForClose")}
            </label>
            <InputTextarea
              id="requestClose"
              onChange={(e) => setReasonValue(e.target.value)}
              rows={5}
              cols={30}
              className={validationError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
            >
              {validationError}
            </span>
          </div>
        </Dialog>
        <Dialog
          id="visibleRejectId"
          header={t("committee.rejectRecord")}
          visible={visibleReject}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => setVisibleReject(false)}
          footer={footerContentReject}
          draggable={false}
          className="custom-dialog hide-close"
            ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="requestClose"
              className={`block font-bold text-capitalize mb-2 ${
                validationRejectError ? "p-error" : ""
              }`}
            >
              {t("committee.reasonForReject")}
            </label>
            <InputTextarea
              id="requestClose"
              onChange={(e) => setRejectValue(e.target.value)}
              rows={5}
              cols={30}
              className={validationRejectError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${validationRejectError ? "" : "error-hidden"}`}
            >
              {validationRejectError}
            </span>
          </div>
        </Dialog>
        <Table
          loading={loading}
          // products={recordList}
          products={
            isFilterApplied
              ? filteredRecords?.filter((item: any) => {
                  const TypeName = (item && item.typeName) || "";
                  const recordId = (item && item.recordId) || "";
                  const memberName = (item && item.memberName) || "";
                  return (
                    search === "" ||
                    TypeName.toLowerCase().includes(search.toLowerCase()) ||
                    recordId.toLowerCase().includes(search.toLowerCase()) ||
                    memberName.toLowerCase().includes(search.toLowerCase())
                  );
                })
              : recordList?.filter((item: any) => {
                  const TypeName = (item && item.typeName) || "";
                  const recordId = (item && item.recordId) || "";
                  const memberName = (item && item.memberName) || "";
                  return (
                    search === "" ||
                    TypeName.toLowerCase().includes(search.toLowerCase()) ||
                    recordId.toLowerCase().includes(search.toLowerCase()) ||
                    memberName.toLowerCase().includes(search.toLowerCase())
                  );
                })
          }
          columns={[
            {
              field: "recordId",
              header: t("committee.recordId"),
              link: "/record/record-preview",
            },
            {
              field: "memberName",
              header: t("committee.memberName"),
            },

            { field: "typeName", header: t("committee.recordType") },
            { field: "subType", header: t("committee.recordSubType") },
            { field: "createdOn", header: t("committee.createOn") },
            { field: "statusName", header: t("committee.status") },
            { field: "action", header: t("committee.action"), sortable: false },
          ]}
          actionItems={actionItems}
          showCheckbox={true}
        />
      </div>
    </>
  );
};

export default CommitteeTabRecords;
