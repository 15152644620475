// PreviewItem.tsx
import React from 'react';

interface PreviewItemProps {
  label: string;
  value: string | number | undefined;
  extension?: string;
  textSize?: string
} 

const PreviewItem: React.FC<PreviewItemProps> = ({ label, value, extension = "", textSize = "" }) => (
  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
    <span className={`m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label `}>
      {label}
    </span>
    <p className={`m-0 font-normal text-title w-full ${textSize ? textSize : 'text-base'}`}>
      {value ? `${value} ${extension}` : "-"}
    </p>
  </div>
);

export default PreviewItem;
