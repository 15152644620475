import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TabView, TabPanel } from "primereact/tabview";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { resetMemberRequest } from "../../slices/createMemberRequestSlice";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { clearAllSessionStorage } from "../../utils/storage";
import ResignMembers from "./ResignMembers";
import AllRequestList from "./AllRequestList";
import NewRequestList from "./NewRequestList";
import ReAppointmentRequestList from "./ReAppointmentRequestList";

const AllRequests: React.FC = () => {
  const userProfileId = localStorage.getItem("userProfileID");
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resetMemberRequest());
    clearAllSessionStorage();
  }, []);

  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    { label: t("member.membershipRequests") },
  ];

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("member.membershipRequests")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("member.myrequestsListingHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          {userProfileId && (
            <TabView scrollable>
              <TabPanel header={t("member.new")}>
                <NewRequestList userProfileId={userProfileId} />
              </TabPanel>
              <TabPanel header={t("member.reappointments")}>
                <ReAppointmentRequestList userProfileId={userProfileId} />
              </TabPanel>
              <TabPanel header={t("member.termination")}>
                {t("member.termination")}
              </TabPanel>
              <TabPanel header={t("member.resignation")}>
                <ResignMembers userProfileId={userProfileId} />
              </TabPanel>
              <TabPanel header={t("member.all")}>
                <AllRequestList userProfileId={userProfileId} />
              </TabPanel>
            </TabView>
          )}
        </div>
      </div>
    </>
  );
};

export default AllRequests;
