import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import CustomStepWizard from "../common/CustomStepWizard";
import BasicDetails, { BasicDetailsRef } from "./BasicDetails";
import ContributingCommittee, {
  ContributionCommitteeRef,
} from "./ContributingCommittee";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Preview from "./Preview";
import OtherConfiguration, {
  OtherConfigurationRef,
} from "./OtherConfiguration";
import { fetchData as apiFetchData } from "../../services/apiService";
import {
  fetchData as standardFetchData,
  putData,
} from "../../services/Standard/apiServiceStandard";

import LoadingOverlay from "../common/LoadingOverlay";
import {
  resetBasicDetails,
  resetOtherDetails,
  resetContributeDetails,
  reset,
  setActiveStep,
} from "../../slices/createStandard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setToast } from "../../slices/toastSlice";

const CreateNewStandard: React.FC = () => {
  const [t, i18n] = useTranslation("standard");
  const { selectedLanguage } = useLanguageContext();
  const [commiteeData, setCommiteeData] = useState([]);
  const [maintainanceCycleData, setMaintainanceCycleData] = useState([]);
  const [accreditationData, setAccreditationData] = useState([]);
  const [hierarchyCommitee, setHierarchyCommitee] = useState([]);
  const [standardDataTypes, setStandardDataTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveDraft, setSaveDraft] = useState(false);

  const dispatch = useDispatch();

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        const [
          hierarchyLevel,
          commiteeData,
          maintainanceCycleData,
          accrediationData,
          standardDataTypes,
        ] = await Promise.all([
          apiFetchData("HierarchyLevel/GetAll"),
          apiFetchData("Committee/GetCommitteesList?PageIndex=-1"),
          standardFetchData("MaintainanceCycle/GetMaintainanceCycles", {
            PageIndex: -1,
          }),
          standardFetchData("AccreditationBody/GetAccreditationBody", {
            PageIndex: -1,
          }),
          standardFetchData("StandardDocumentType/GetAll", {}),
        ]);

        const hierarchyData = hierarchyLevel.Collection.map((item: any) => ({
          Id: item.Id,
          Name: item.Name,
        }));

        setHierarchyCommitee(hierarchyData);
        const commiteeies = commiteeData.Collection.map((item: any) => ({
          Id: item.Id,
          Name: item.Name,
          HierarchyLevelId: item.HierarchyLevelId,
        }));
        setCommiteeData(commiteeies);
        setMaintainanceCycleData(maintainanceCycleData);
        setAccreditationData(accrediationData);
        setStandardDataTypes(standardDataTypes.Collection);
      } catch {
        console.error("");
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  const navigate = useNavigate();

  const stepItems = [
    {
      icon: "cached",
      label: t("standard.basicDetails"),
    },
    {
      icon: "cached",
      label: t("standard.otherConfiguration"),
    },
    {
      icon: "cached",
      label: t("standard.contributingCommittee"),
    },
    {
      icon: "cached",
      label: t("standard.preview"),
    },
  ];

  const createRequestDetail = useSelector(
    (state: RootState) => state.standardRequest
  );

  const [activeStep, setStep] = useState(0);
  const basicDetailsRef = useRef<BasicDetailsRef>(null);
  const otherConfigurationRef = useRef<OtherConfigurationRef>(null);
  const contributionCommitteeRef = useRef<ContributionCommitteeRef>(null);

  const triggerSubmit = () => {
    setSaveDraft(false);
    if (basicDetailsRef.current) {
      basicDetailsRef.current.submit();
    }

    if (otherConfigurationRef.current) {
      otherConfigurationRef.current.submit();
    }

    if (contributionCommitteeRef.current) {
      contributionCommitteeRef.current.submit();
    }
  };

  const saveAsDraft = () => {
    setSaveDraft(true);
    if (basicDetailsRef.current) {
      basicDetailsRef.current.submit();
    }

    if (otherConfigurationRef.current) {
      otherConfigurationRef.current.submit();
    }

    if (contributionCommitteeRef.current) {
      contributionCommitteeRef.current.submit();
    }
  };

  useEffect(() => {
    setStep(createRequestDetail.activeStep);
  }, [createRequestDetail.activeStep]);

  const handleNext = useCallback(
    (data: Record<string, any>) => {
      if (saveDraft) {
        navigate("/standard");
        dispatch(reset());
      }
      if (activeStep < 3) setStep(activeStep + 1);
    },
    [activeStep]
  );

  const handleCancel = useCallback(() => {
    if (activeStep === 0) {
      dispatch(resetBasicDetails());
    } else if (activeStep === 1) {
      dispatch(resetOtherDetails());
    } else if (activeStep === 2) {
      dispatch(resetContributeDetails());
    }
  }, [activeStep, dispatch]);

  const handleBack = useCallback(() => {
    if (activeStep === 0) {
      navigate("/standard");
    }
    if (activeStep > 0) {
      setStep(activeStep - 1);
      dispatch(setActiveStep(activeStep - 1));
    }
  }, [activeStep, dispatch, navigate, setStep]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      let createStandard = await putData(
        "Standard/submit",
        createRequestDetail.id,
        {}
      );
      dispatch(
        setToast({
          message: t("standard.standardSubmitedSuccess"),
          severity: "success",
        })
      );
      navigate("/standard");
      dispatch(reset());
    } catch (error: any) {
      dispatch(setToast({ message: error.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  }, [createRequestDetail.id, dispatch, navigate]);

  return (
    <>
      {loading && <LoadingOverlay visible={loading} />}
      <div className="mb-5">
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/standard")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span>{t("standard.back")}</span>
        </Button>
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("standard.createNewStandard")}
          </h1>
          <p className="text-base font-normal m-0">
            One line supporting text will appear here
          </p>
        </div>
        <CustomStepWizard stepItems={stepItems} activeIndex={activeStep} />
      </div>

      {/* Body - steps  */}
      {activeStep === 0 && (
        <BasicDetails
          ref={basicDetailsRef}
          commiteeData={commiteeData}
          maintainanceCycleData={maintainanceCycleData}
          standardDataTypes={standardDataTypes}
          onNext={handleNext}
        />
      )}
      {activeStep === 1 && (
        <OtherConfiguration
          commiteeData={commiteeData}
          hierarchyData={hierarchyCommitee}
          accreditationData={accreditationData}
          ref={otherConfigurationRef}
          onNext={handleNext}
        />
      )}
      {activeStep === 2 && (
        <ContributingCommittee
          hierarchyData={hierarchyCommitee}
          ref={contributionCommitteeRef}
          onNext={handleNext}
        />
      )}
      {activeStep === 3 && (
        <Preview
          committeeData={commiteeData}
          maintainanceCycleData={maintainanceCycleData}
          hierarchyData={hierarchyCommitee}
          accreditationData={accreditationData}
        />
      )}

      {/* Footer buttons */}
      <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
        {activeStep !== 3 && (
          <Button
            label={t("standard.cancel")}
            className="button-md"
            severity="secondary"
            type="button"
            onClick={handleCancel}
          />
        )}
        <Button
          className="button-md gap-1 ml-auto"
          severity="secondary"
          onClick={handleBack}
        >
          <FeatherIcon name="chevron-left" size={20} color="inherit" />
          <span className="font-bold text-capitalize">
            {t("standard.back")}
          </span>
        </Button>

        {activeStep == 3 ? (
          <Button className="button-md gap-1" onClick={handleSubmit}>
            <span className="font-bold">{t("standard.createStandard")}</span>
          </Button>
        ) : (
          <>
            <>
              <Button
                className="button-md gap-1"
                type="button"
                severity="secondary"
                onClick={saveAsDraft}
              >
                <span className="font-bold text-capitalize">
                  {t("standard.saveAsDraft")}
                </span>
                <FeatherIcon name="chevron-right" size={20} color="inherit" />
              </Button>
            </>
            <>
              <Button
                className="button-md gap-1"
                type="button"
                onClick={triggerSubmit}
              >
                <span className="font-bold text-capitalize">
                  {t("standard.next")}
                </span>
                <FeatherIcon name="chevron-right" size={20} color="inherit" />
              </Button>
            </>
          </>
        )}
      </div>
      {/* Mobile responsive buttions */}
      <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
        {activeStep == 3 ? (
          <Button className="button-md gap-1 w-full justify-content-center">
            <span className="font-bold">{t("standard.createStandard")}</span>
          </Button>
        ) : (
          <Button
            className="button-md gap-1 w-full justify-content-center"
            type="button"
            onClick={triggerSubmit}
          >
            <span className="font-bold text-capitalize">
              {t("standard.next")}
            </span>
            <FeatherIcon name="chevron-right" size={20} color="inherit" />
          </Button>
        )}
        <div className="flex align-items-center gap-3 w-full">
          <Button
            className="button-md"
            severity="secondary"
            aria-label={t("ballot.back")}
            onClick={handleBack}
          >
            <FeatherIcon name="chevron-left" size={20} color="inherit" />
          </Button>
          <Button
            label={t("standard.cancel")}
            className="button-md w-full"
            severity="secondary"
            type="button"
            onClick={() => navigate("/standard")}
          />
        </div>
      </div>
    </>
  );
};

export default CreateNewStandard;
