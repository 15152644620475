import React, { useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import CommitteeSteps from "./CommitteeSteps";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Link, useNavigate } from "react-router-dom";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { focusOnField } from "../../utils/focusField";
import { Nullable } from "primereact/ts-helpers";
interface Option {
  name: string;
  code: string;
}
const CommitteeMeetingAddAction: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: "Committee", url: "/committee" },
    { label: t("committee.createNewCommittee") },
  ];

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];

  const [selectedAttendees, setSelectedAttendees] = useState(null);
  const attendees = [
    { name: "attendees 1", code: "attendees1" },
    { name: "attendees 2", code: "attendees2" },
    { name: "attendees 3", code: "attendees3" },
    { name: "attendees 4", code: "attendees4" },
  ];

  const [dateActivation, setDateActivation] = useState(null);
  const [value, setValue] = useState("");
  const [criteria, setCriteria] = useState<string>("");
  const [time, setTime] = useState<Nullable<Date>>(null);
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain"
              aria-label={t("committee.back")}
              onClick={() => navigate("/committee/meeting")}
            >
              <FeatherIcon name="arrow-left" size={20} />
            </Button>
            <h1 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.addAction")}
            </h1>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="assignBy"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.assignBy")}
                  </label>
                  <InputText
                    id="assignBy"
                    type="text"
                    placeholder={t("committee.assignBy")}
                    className="w-full"
                    value=""
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="assignTo"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.assignTo")}
                  </label>
                  <InputText
                    id="assignTo"
                    type="text"
                    placeholder={t("committee.assignTo")}
                    className="w-full"
                    value=""
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="completionDate"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.completionDate")}
                  </label>
                  <Calendar
                    appendTo="self"
                    inputId="completionDate"
                    value={dateActivation}
                    dateFormat="mm/dd/yy"
                    className="custom-datepicker"
                    placeholder={t("committee.completionDate")}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                  <label
                    htmlFor="taskDetails"
                    className="block font-bold text-capitalize text-input-label"
                  >
                    {t("committee.taskDetails")}
                  </label>
                  <InputTextarea
                    id="taskDetails"
                    value={value}
                    placeholder={t("committee.taskDetails")}
                    onChange={(e) => setValue(e.target.value)}
                    rows={5}
                    cols={30}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-4 justify-content-end cardFooter">
            <Button
              label={t("committee.cancel")}
              className="button-md"
              severity="secondary"
              type="button"
              onClick={() => navigate("/committee/meeting")}
            />
            <Button
              label={t("committee.addAction")}
              className="button-md"
              type="button"
              onClick={() => navigate("/committee/meeting")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeMeetingAddAction;
