// src/components/SigninOidc.tsx
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../common/LoadingOverlay";
import SignInOidc from "../../components/auth/SignInOidc";
import axoisInstanceIdentity from '../../services/axiosInstanceIdentity';
import { fetchData, postData, putData } from "../../services/apiService";
import { getTenantNameFromDomain } from "../../utils/utils";
import { setTokenLS, getTokenLS, removeTokenLS, setB2CAuthenticateSSOInfo, removeB2CAuthenticateSSOInfo } from "../../utils/storage";

const SigninOidc = () => {
  const [loginComplete, setLoginComplete] = useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    removeTokenLS();
    removeB2CAuthenticateSSOInfo();
    getRedirectPromiseTokenInfo();
  }, []);

  const getRedirectPromiseTokenInfo = async () => {
    try {
      const tokenInfo: any = await instance.handleRedirectPromise();
      if(tokenInfo && tokenInfo?.accessToken) {
        setTokenLS(tokenInfo?.accessToken);
        const tenantDomain = getTenantNameFromDomain();
        const authSSORes: any = await axoisInstanceIdentity.post(`/Account/AuthenticateSSO`, {
           "tenantDomain" : tenantDomain 
        });
        if(authSSORes) {
          setB2CAuthenticateSSOInfo(authSSORes?.data || null);
          const responseData: any = await postData(`UserProfile/SSOAddUserProfile`, {});
          if(responseData) {
            await userProfileIDs();

            const UserId: any = localStorage.getItem("userProfileID");
            const responseData = await fetchData("UserProfile/GetCurrentUserProfile");
            const userProfileID = responseData.Id;

            await putData("UserProfile/UpdateUserLastLoginDate", undefined, {
              "Content-Type": "application/json",
            });
            setLoginComplete(true);
            navigate("/dashboard");
          }
        }
      }
    } catch(e) {
      navigate("/");
    }
  }

  const userProfileIDs = async () => {
    const languages = [
      {
        label: "English",
        value: "en",
      },
      { label: "French", value: "fr" },
      // {
      //   label: "German",
      //   value: "gr",
      // },
    ];
    try {
      const responseData = await fetchData("UserProfile/GetCurrentUserProfile");
      const userProfileID = responseData.Id;
      const lastLoginDate = responseData.LastLoginDate;
      if (
        responseData.LanguageId !== "00000000-0000-0000-0000-000000000000" &&
        responseData.LanguageId
      ) {
        const languageResponse = await fetchData(
          `Language/${responseData.LanguageId}`
        );
        localStorage.setItem(
          "selectedLanguage",
          languageResponse.Symbol.toLowerCase()
        );
      }
      localStorage.setItem("userProfileID", userProfileID);
      localStorage.setItem("lastLoginDate", lastLoginDate);
    } catch (error) {
      console.error(error);
    }
  };

  const redirectFinalize = () => {
    const token = localStorage.getItem("token");
    const lastloginDate = localStorage.getItem("lastLoginDate");
    if (token) {
      let fromPath = sessionStorage.getItem("fromPath");
      if (fromPath) {
        navigate(fromPath);
      } else if (lastloginDate === "undefined" || lastloginDate === "null") {
        navigate("/profile");
      } else if (lastloginDate != "null") {
        navigate("/dashboard");
      } else {
        navigate("/all-membership");
      }
    }
  }

  return <LoadingOverlay visible={!loginComplete} />;
};

export default SigninOidc;
