import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import { useLanguageContext } from "../LanguageContext";
import { resetMemberRequest } from "../../slices/createMemberRequestSlice";
import { MenuItem } from "primereact/menuitem";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { usePermission } from "../auth/Authorization";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import { clearAllSessionStorage } from "../../utils/storage";
import DataGrid from "../common/DataGrid";
import { formatRequestData } from "../../utils/utils";
import CustomMenu, { getStatusBadge } from "../common/customMenu";

interface NewRequestListProps {
  userProfileId: any;
}

const NewRequestList: React.FC<NewRequestListProps> = ({ userProfileId }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [toDateError, setToDateError] = useState("");
  const [search, setSearch] = useState("");
  const [requestList, setRequestsList] = useState([]);
  const [mycommittees, setMyCommittees] = useState<any>([]);
  const [memberStatus, setMemberStatus] = useState([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const [coiData, setCoiData] = useState([]);
  const [allRequests, setAllRequests] = useState<boolean>(false);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [selectedCommittees, setSelectedCommittees] = useState<any[]>([]);
  const [selectedMemberStatuses, setSelectedMemberStatuses] = useState<any[]>(
    []
  );
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [committeeCheckboxChecked, setCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [rejectionDateCheckboxChecked, setRejectionDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const [rejectionDate, SetRejectionDate] = useState<Date | null>(null);
  const [memberStatusCheckboxChecked, setMemberStatusCheckboxChecked] =
    useState<boolean>(false);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [t, i18n] = useTranslation("member");
  const { selectedLanguage } = useLanguageContext();
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [defaultFilter, setDefaultFilter] = useState<boolean>(false);
  const { id: submittedRequestId } = useParams();

  //Pagination
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [sortField, setSortField] = useState<string>("RequestDate");

  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const dispatch = useDispatch();

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  useEffect(() => {
    if (submittedRequestId) {
      setMemberStatusCheckboxChecked(true);
      setDefaultFilter(true);
    } else {
      setMemberStatusCheckboxChecked(false);
    }
  }, []);

  const fetchFilteredData = async (event?: any) => {
    if (userRole !== "" && userProfileId !== "") {
      if (pageIndex !== 1) {
        setPageIndex(1);
      } else {
        await fetchDataByRole();
      }
      if (event) {
        overlayPanelRef.current?.toggle(event);
        setMemberStatusCheckboxChecked(false);
        setSelectedMemberStatuses([]);
      }
      if (defaultFilter) {
        localStorage.removeItem("pendingRequest");
      }
    }
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions, userRole]);

  const fetchDataAndSetState = async (
    endpoint: string,
    setStateCallback: any,
    transformData = (data: any) => data
  ) => {
    try {
      const response = await fetchData(endpoint);
      const data = transformData(response.Collection);

      setStateCallback(data);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
    }
  };

  const fetchAndFilterMembershipStatus = async () => {
    await fetchDataAndSetState(
      "MembershipStatus/GetAll",
      setMemberStatus,
      (data) => {
        const removedStatusNames = [
          "Retired",
          "Current",
          "Terminate",
          "Resign",
        ];
        const filteredData = data
          .filter(
            (status: any) => !removedStatusNames.includes(status.StatusName)
          )
          .map((status: any) =>
            status.StatusName === "Convert to Record"
              ? { ...status, StatusName: "Converted to Record" }
              : status
          );
        const newArray = filteredData.map((item: any) => ({
          name: item.StatusName,
          code: item.Id,
        }));
        return newArray;
      }
    );
  };

  useEffect(() => {
    fetchDataAndSetState("Committee/GetAll", setMyCommittees);
    fetchDataAndSetState("CategoryOfInterest/GetAll", setCoiData);
    fetchDataAndSetState("MembershipPosition/GetAll", setMemberPosition);
    fetchAndFilterMembershipStatus();
  }, []);

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setToDateError(`${t("member.fromDateBeforeToDate")}`);
        setDateActivationFrom(null);
      } else {
        setToDateError("");
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setToDateError(`${t("member.toDateAfterFromDate")}`);
        setDateActivationTo(null);
      } else {
        setToDateError("");
        setDateActivationTo(date);
      }
    } else if (control === "rejectionDate") {
      SetRejectionDate(date);
    }
  };

  const handleCheckboxChange =
    (checkbox: string) => async (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "committee":
          setCommitteeCheckboxChecked(isChecked);
          break;
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "memberStatus":
          setMemberStatusCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        case "rejectiondate":
          setRejectionDateCheckboxChecked(isChecked);
          break;
        case "allRequests":
          setAllRequests(isChecked);
          break;
        default:
          break;
      }
    };

  const getFilter = (): any => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: any; Value: any }[] = [];
    const sortOrderValue = sortOrder === 1 ? "Asc" : "Desc";
    const sortFieldValue =
      sortField === "CreatedDate" ? "RequestDate" : sortField;

    if (committeeCheckboxChecked) {
      const committeeIds = selectedCommittees.map((committee) => committee.Id);
      filters.push({ Key: "CommitteeId", Value: committeeIds.join(",") });
    }
    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (positionCheckboxChecked) {
      const positionIds = selectedPositions.map((position) => position.Id);
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (memberStatusCheckboxChecked) {
      if (defaultFilter) {
        if (memberStatus && memberStatus.length) {
          const storedValue = submittedRequestId;
          const filteredStatus: any = memberStatus.find(
            (item: { name: string; code: string }) => item.code === storedValue
          );
          if (filteredStatus) {
            setSelectedMemberStatuses([
              { name: filteredStatus.name, code: filteredStatus.code },
            ]);
            const memberStatusIds = filteredStatus.code;
            filters.push({
              Key: "MembershipStatusId",
              Value: memberStatusIds,
            });
          }
        }
      } else {
        const memberStatusIds = selectedMemberStatuses.map(
          (status) => status.value
        );
        filters.push({
          Key: "MembershipStatusId",
          Value: memberStatusIds.join(","),
        });
        setDefaultFilter(false);
      }
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({
        Key: "requestedon",
        Value: `${fromDateStr},${toDateStr}`,
      });
    }
    if (rejectionDateCheckboxChecked) {
      const rejectionDateStr = formatDate(rejectionDate);
      filters.push({ Key: "RejectionDate", Value: `${rejectionDateStr}` });
    }
    switch (userRole) {
      case "Staff":
        if (!allRequests) {
          filters.push({ Key: "StaffId", Value: userProfileId });
        }
        break;
      case "Member":
        filters.push({ Key: "UserProfileId", Value: userProfileId });
        break;
      default:
        break;
    }

    filters.push({ Key: "IsResignRequest", Value: false });
    filters.push({ Key: "IsMemberReappoinmentRequest", Value: false });
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = pageIndex;
    queryParams["PageSize"] = pageSize;
    queryParams["SearchText"] = debouncedSearchTerm;
    queryParams["SortBy"] = sortFieldValue;
    queryParams["SortDir"] = sortOrderValue;
    return queryParams;
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const fetchDataByRole = async () => {
    const queryParams: any = await getFilter();
    setLoading(true);
    const apiUrl = `${BASE_URL}/MembershipRequest/GridSearch?${new URLSearchParams(
      queryParams
    ).toString()}`;
    try {
      const response = await axiosInstance.get(apiUrl);
      if (response.status === 200) {
        const filteredData = response.data;
        setTotalCount(filteredData.TotalCount);
        const sortedData = formatRequestData(filteredData.Collection);
        setRequestsList(sortedData);
        setLoading(false);
      } else {
        console.error("Failed to fetch filtered data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userRole) {
      fetchDataByRole();
    }
  }, [
    userRole,
    pageIndex,
    pageSize,
    sortOrder,
    sortField,
    debouncedSearchTerm,
  ]);

  React.useEffect(() => {
    dispatch(resetMemberRequest());
    clearAllSessionStorage();
  }, []);

  const handleViewMember = (id: any) => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        sessionStorage.setItem("memberShipId", id);
        navigate("/member/create");
      } else {
        sessionStorage.setItem("memberShipId", id);
        navigate("/member/request-view");
      }
    };
  };

  const navigate = useNavigate();

  const pageHandler = (pageData: any) => {
    setPageIndex(pageData.page + 1);
    setPageSize(pageData.pageSize);
  };

  const sortHandler = (sortOptions: any) => {
    setSortOrder(sortOptions.sortOrder);
    setSortField(sortOptions.sortField);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(search);
      setPageIndex(1);
    }, 300); // 500ms delay
    // Clear the timeout if searchTerm changes (i.e. user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("member.viewEditRequest"),
        command: handleViewMember(rowData.Id),
      },
    ];

    return <CustomMenu actionItems={actionItems} rowData={rowData} />;
  };

  return (
    <>
      <div className="flex flex-wrap md:flex-nowrap md:align-items-center py-3 gap-3 w-full">
        <IconField iconPosition="left" className="w-full md:w-16rem">
          <InputIcon className="pi pi-search"> </InputIcon>
          <InputText
            id="searchCommittee"
            className="w-full"
            placeholder={"Search"}
            aria-label={t("record.searchCommittee")}
            onChange={(e) => setSearch(e.target.value)}
          />
        </IconField>

        <div className="flex gap-3 w-full md:w-auto align-items-center ml-auto">
          <div className="relative">
            <Button
              className="button-md gap-1"
              severity="secondary"
              onClick={toggleFilterPanel}
              aria-label={t("member.filter")}
            >
              <FeatherIcon name="filter" size={20} color="inherit" />
              <span className="font-bold text-capitalize hidden md:flex">
                {t("member.filter")}
              </span>
            </Button>
            {/* OverlayPanel for Filter */}
            <OverlayPanel
              ref={overlayPanelRef}
              onHide={() => setShowFilterPanel(false)}
              appendTo={"self"}
              dismissable
              className="p-overlaypanel-filter left-auto md:right-0 top-100"
              aria-labelledby="filter-heading"
              role="dialog"
            >
              <div className="flex flex-column gap-1" tabIndex={0}>
                <p id="filter-heading" className="text-lg font-bold m-0 p-3">
                  {t("member.filter")}
                </p>
                <div className="filter-wrap">
                  <div className="">
                    <label
                      htmlFor="committeeNameFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="committeeNameFilter"
                        onChange={handleCheckboxChange("committee")}
                        checked={committeeCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.committeeName")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedCommittees}
                        onChange={(e) => setSelectedCommittees(e.value)}
                        options={mycommittees}
                        optionLabel="Name"
                        filter
                        placeholder={t("member.committeeName")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("member.committeeName")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="memberPositionFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="memberPositionFilter"
                        onChange={handleCheckboxChange("position")}
                        checked={positionCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.memberPosition")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedPositions}
                        onChange={(e) => setSelectedPositions(e.value)}
                        options={memberPosition}
                        optionLabel="PositionName"
                        filter
                        placeholder={t("member.memberPosition")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("member.memberPosition")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="categoryOfInterestFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="categoryOfInterestFilter"
                        onChange={handleCheckboxChange("coi")}
                        checked={coiCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.categoryOfInterest")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedCOIs}
                        onChange={(e) => setSelectedCOIs(e.value)}
                        options={coiData}
                        optionLabel="CategoryName"
                        filter
                        placeholder={t("member.categoryOfInterest")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("member.categoryOfInterest")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="memberStatusFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="memberStatusFilter"
                        onChange={handleCheckboxChange("memberStatus")}
                        checked={memberStatusCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.memberStatus")}
                    </label>
                    <div className="pr-3 py-2 pl-6 w-full relative">
                      <MultiSelect
                        // appendTo="self"
                        value={selectedMemberStatuses}
                        onChange={(e) => setSelectedMemberStatuses(e.value)}
                        options={memberStatus}
                        optionLabel="name"
                        filter
                        placeholder={t("member.memberStatus")}
                        maxSelectedLabels={3}
                        className="w-full"
                        aria-label={t("member.memberStatus")}
                      />
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="requestDateFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="requestDateFilter"
                        onChange={handleCheckboxChange("date")}
                        checked={dateCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.requestDate")}
                    </label>
                    <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationFrom"
                        onChange={(e) =>
                          handleDateSelect(
                            e.value as Date,
                            "dateActivationFrom"
                          )
                        }
                        value={dateActivationFrom}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("member.fromDate")}
                        placeholder={t("member.fromDate")}
                        maxDate={
                          dateActivationTo
                            ? (dateActivationTo as Date)
                            : undefined
                        }
                      />
                      <Calendar
                        className="custom-datepicker"
                        inputId="dateActivationTo"
                        onChange={(e) =>
                          handleDateSelect(e.value as Date, "dateActivationTo")
                        }
                        value={dateActivationTo}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("member.toDate")}
                        placeholder={t("member.toDate")}
                        minDate={
                          dateActivationFrom
                            ? (dateActivationFrom as Date)
                            : undefined
                        }
                      />
                      {toDateError && (
                        <div className="text-red-500">{toDateError}</div>
                      )}
                    </div>
                  </div>
                  <div className="p-0">
                    <label
                      htmlFor="rejectionDateFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                    >
                      <Checkbox
                        inputId="rejectionDateFilter"
                        onChange={handleCheckboxChange("rejectiondate")}
                        checked={rejectionDateCheckboxChecked}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("member.rejectionDate")}
                    </label>
                    <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                      <Calendar
                        className="custom-datepicker"
                        inputId="rejectionDate"
                        onChange={(e) =>
                          handleDateSelect(e.value as Date, "rejectionDate")
                        }
                        value={rejectionDate}
                        dateFormat="mm/dd/yy"
                        tabIndex={0}
                        ariaLabel={t("member.rejectionDate")}
                        placeholder={t("member.rejectionDate")}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  className="button-md w-full justify-content-center"
                  onClick={(e: any) => fetchFilteredData(e)}
                >
                  {t("member.applyFilter")}
                </Button>
              </div>
            </OverlayPanel>
          </div>
          {userRole === "Member" && (
            <Button
              className="button-md gap-1 justify-content-center w-full md:w-auto"
              onClick={() => navigate("/member/create")}
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold">
                {t("member.newMembershipRequest")}
              </span>
            </Button>
          )}
        </div>
      </div>
      <DataGrid
        loading={loading}
        products={requestList}
        columns={[
          {
            field: "MembershipRequestNumber",
            header: t("member.request"),
            link: "/member/request-view",
          },
          { field: "MemberName", header: t("member.memberName") },
          {
            field: "CommitteeName",
            header: t("member.committeeApplied"),
          },
          {
            field: "MemberPosition",
            header: t("member.positionApplied"),
          },
          {
            field: "CategoryOfInterest",
            header: t("member.categoryofIntrest"),
          },
          {
            field: "CreatedDate",
            header: t("member.requestDate"),
            sortable: true,
          },
          {
            field: "StatusName",
            header: t("member.status"),
            body: (rowData: any) => getStatusBadge(rowData.StatusName),
          },

          {
            field: "Action",
            header: t("member.action"),
            body: actionPositionBodyTemplate,
            sortable: false,
          },
        ]}
        pageIndex={pageIndex}
        totalRecords={totalCount}
        pageSize={pageSize}
        onPageChange={pageHandler}
        sortField={sortField}
        sortOrder={sortOrder}
        onSortChange={sortHandler}
      />
    </>
  );
};

export default NewRequestList;
