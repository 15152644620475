import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { useNavigate } from "react-router-dom";
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import Table from "../common/Table";
import { CSVLink } from "react-csv"; // Import CSVLink
import { usePermission } from "../auth/Authorization";
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useScrollOnPaginatorClick } from "../common/useScrollOnPaginatorClick";


interface Option {
  name: string;
  code: string;
}

const BalanceOfInterest: React.FC = () => {
  const [t, i18n] = useTranslation("report");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [dates, setDates] = useState<any>(null);
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [statusId, setStatusId] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } = usePermission();
  const [committeeList, setCommitteeList] = useState<any>(null);
  const [committeeValue, setCommitteeValue] = useState<any>(null);
  const [memberValue, setMembersValue] = useState<any>(null);
  const [memberList, setMemberList] = useState<any>(null);
  const [userRole, setUserRole] = useState<string>("");
  const [requestList, setRequestLists] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [balanceRepresentation, setBalanceRepresentation] = useState<string>('overallThreshold');
  const [expandedRows, setExpandedRows] = useState<any>(null);

  const breadcumbItems = [
    { label: t("report.dashboard"), url: "/dashboard" },
    { label: t("report.reports") },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const responseData = await fetchData(
          `MembershipStatus/GetAll`
        );
        // const apiUrl = `Committee/Search?${new URLSearchParams(queryParams).toString()}`;
        const filteredData = responseData.Collection.filter((item: any) => {
          if (item.StatusName !== "Submitted") {
            return false;
          }
          return true;
        });
        setStatusId(filteredData[0].Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchStatus();
    const fetchCommittes = async () => {
      try {
        if (userRole !== "Admin") {
          const responseData = await fetchData(`Committee/Search?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`);
          const labelValueArray = responseData.Collection.map(
            (category: any) => ({
              label: category.Name,
              value: category.Id,
            })
          );
          setCommitteeList(labelValueArray);
        } else {
          const responseData = await fetchData(
            `Committee/GetAll`
          );
          const labelValueArray = responseData.Collection.map(
            (category: any) => ({
              label: category.Name,
              value: category.Id,
            })
          );
          setCommitteeList(labelValueArray);
        }
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };

    const fetchBalanceOfInterest = async () => {
      try {
        const responseData = await fetchData(`CategoryOfInterest/GetAll`);
        const labelValueArray = responseData.Collection.map(
          (category: any) => ({
            label: category.CategoryName,
            value: category.Id,
          })
        );
        setMemberList(labelValueArray);

      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchBalanceOfInterest();
    fetchCommittes();
    fetchStatus();
  }, []);


  const exportData = () => {
    if (!requestList) {
      // Handle the case when requestList is null or undefined
      return null; // Or any other fallback behavior
    }
    const sortedData = requestList.map((item: any) => ({
      CommitteeName: item.Committee ? item.Committee.Name : null,
      MemberName: item?.UserProfile?.Firstname + " " + item?.UserProfile?.Lastname,
      MemberShipExpirationDate: item.Committee?.MembershipExpiresOn_All ? new Date(item.Committee.MembershipExpiresOn_All).toLocaleDateString() : null,
      StaffContact: item.Committee?.Staff?.Email,
      CreatedDate: item.CreatedDate ? new Date(item.CreatedDate).toLocaleDateString() : null,
      RequestStatus: item.MembershipStatus ? item.MembershipStatus.StatusName : null,
      TotalMember: "30",
      CommitteeBalance: "360"
    }));
    const csvData = [...sortedData];
    return (
      <CSVLink data={csvData} >
        <Button className="button-md gap-1 justify-content-center w-full md:w-auto">
          <span className="font-bold text-capitalize">{t("report.export")}</span>
        </Button>
      </CSVLink>
    );
  };

  const onRowCollapse = (event: any) => {
    //toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
  };

  const onRowExpand = (event: any) => {
    // toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="p-3">
        <DataTable className={tableClass} value={data.CommitteCategoryofInterests} showGridlines>
          <Column field="CategoryOfInterest.CategoryName" header={t("report.categoryOfInterest")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.categoryOfInterest")}</span>
                {rowData.CategoryOfInterest.CategoryName}
              </>
            )}
          ></Column>
          <Column field="MaxMember" header={t("report.memberCount")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.memberCount")}</span>
                {rowData.MaxMember}
              </>
            )}
          ></Column>
          <Column field="MinMember" header={t("report.min")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.min")}</span>
                {rowData.MinMember}
              </>
            )}
          ></Column>
          <Column field="MinMember" header={t("report.max")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.max")}</span>
                {rowData.MinMember}
              </>
            )}
          ></Column>
          <Column field="MinMember" header={t("report.threshold")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.threshold")}</span>
                {rowData.MinMember}
              </>
            )}
          ></Column>
          <Column field="MinMember" header={t("report.inBalance")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.inBalance")}</span>
                {rowData.MinMember}
              </>
            )}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData: any) => {
    return rowData.CommitteCategoryofInterests.length > 0;
  };

  const handleChangeCommittee = (e: any) => {
    const selectedCommittee = e.value;
    setCommitteeValue(selectedCommittee);
  };

  const handleChangeMembers = (e: any) => {
    const selectedMembers = e.value;
    setMembersValue(selectedMembers);
  };

  const buildUrl = (dates: any, memberValue: any, committeeValue: any, userProfileId: string) => {
    let url = 'Committee/BalanceOfInterestReports?';
    const filters: any = [];
    if (dates) {
      const localDates = dates.map((date: any) => new Date(date));
      const formattedDates = localDates.map((date: any) => {
        const isoString = date.toISOString();
        const formattedDate = isoString.slice(0, 10); // Extract YYYY-MM-DD
        return formattedDate;
      });
      const formattedDateRange = formattedDates.join('%23');
      filters.push({ Key: 'datefilter', Value: formattedDateRange });
    }
    if (committeeValue && committeeValue.length > 0) {
      filters.push({ Key: 'CommitteeId', Value: committeeValue });
    }
    if (memberValue && memberValue.length > 0) {
      filters.push({ Key: 'UserProfileId', Value: memberValue });
    }
    if (userProfileId && userRole !== 'Admin') {
      filters.push({ Key: 'StaffId', Value: userProfileId });
    }
    url += filters.map((filter: any) => `Filters[${filters.indexOf(filter)}].Key=${filter.Key}&Filters[${filters.indexOf(filter)}].Value=${filter.Value}`).join('&');
    url += `&PageIndex=-1`;
    // if(pageSize === -1) {
    //   url += `&PageIndex=${pageSize}`;
    // } else {
    //   url += `&PageIndex=${page}&PageSize=${pageSize}`;
    // }
    return url;
  }

  const fetchDataBasedOnFilters = async (url: string, setRequestLists: any, setMemberList: any) => {
    try {
      const responseData = await fetchData(url);
      const sortedData = responseData.Collection.map((item: any) => ({
        ...item,
        CommitteeName: item.Name ? item.Name : null,
        TotalMembers: "40",
        CommitteeBalance: "360"
      }));
      setRequestLists(sortedData);
      if (!committeeValue && !memberValue) {
        const uniqueUserIds = new Set();
        const memberListData = responseData.Collection.reduce((accumulator: any, item: any) => {
          if (!uniqueUserIds.has(item.UserProfileId)) {
            accumulator.push({
              label: `${item?.UserProfile?.Firstname ?? ''} ${item?.UserProfile?.Lastname ?? ''}`,
              value: item.UserProfileId
            });
            uniqueUserIds.add(item.UserProfileId);
          }
          return accumulator;
        }, []);
        setMemberList(memberListData);
      }
    } catch (error) {
      console.error("Error fetching current user profile:", error);
    }
  }

  useEffect(() => {
    if (!userProfileId) return;
    const url = buildUrl(dates, memberValue, committeeValue, userProfileId);
    if (url) {
      fetchDataBasedOnFilters(url, setRequestLists, setMemberList);
    }
  }, [dates, memberValue, committeeValue, userProfileId]);

  // Datatable reference
  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (requestList !== null && requestList !== undefined) {
    const isEmpty = requestList.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-wrap md:flex-nowrap md:align-items-center gap-3 w-full justify-content-between">
          <h1 className="text-title display-xs font-bold m-0">
            {t("report.balanceOfInterests")}
          </h1>
          {/* <div>
            <div className="card flex justify-content-center">
              <Calendar value={dates} placeholder={t("report.selectDateRange")} onChange={(e: any) => setDates(e.value)} selectionMode="range" readOnlyInput />
            </div>
          </div> */}
        </div>
        <div className="flex flex-wrap gap-3 mb-4">
          <div className="flex align-items-center">
            <RadioButton inputId="overallThreshold" name="pizza" value="overallThreshold" onChange={(e) => setBalanceRepresentation(e.value)} checked={balanceRepresentation === 'overallThreshold'} />
            <label htmlFor="overallThreshold" className="ml-2">{t("report.balanceBasedOnOverallThreshold")}</label>
          </div>
          <div className="flex align-items-center">
            <RadioButton inputId="categoryOfInterest" name="pizza" value="categoryOfInterest" onChange={(e) => setBalanceRepresentation(e.value)} checked={balanceRepresentation === 'categoryOfInterest'} />
            <label htmlFor="categoryOfInterest" className="ml-2">{t("report.balanceBasedOnIndividualCategoryOfInterest")}</label>
          </div>
          <div className="flex align-items-center">
            <RadioButton inputId="basedOnOrganization" name="pizza" value="basedOnOrganization" onChange={(e) => setBalanceRepresentation(e.value)} checked={balanceRepresentation === 'basedOnOrganization'} />
            <label htmlFor="basedOnOrganization" className="ml-2">{t("report.balanceBasedOnRepresentationByOrganization")}</label>
          </div>
        </div>
        <div className="">
          <div className="flex flex-wrap md:align-items-center py-3 gap-3 w-full justify-content-between">
            {/* <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("report.search")}
                aria-label={t("report.search")}
                />
            </IconField> */}
            <div className="flex flex-wrap gap-3 w-full md:w-auto align-items-center ml-auto">
              <MultiSelect value={committeeValue} onChange={handleChangeCommittee} options={committeeList} optionLabel="label" display="chip" filter
                placeholder={t("report.selectCommittees")} aria-label={t("report.selectCommittees")} maxSelectedLabels={3} className="w-full md:w-18rem lg:w:20rem" />
              <MultiSelect value={memberValue} onChange={handleChangeMembers} options={memberList} optionLabel="label" display="chip" filter
                placeholder={t("report.selectCategoryOfInterest")} aria-label={t("report.selectCategoryOfInterest")} maxSelectedLabels={3} className="w-full md:w-18rem lg:w:20rem" />

              {/* <Button className="button-md gap-1 justify-content-center p-0 w-full md:w-auto"> */}
              {exportData()}
              {/* </Button> */}
              {userRole === "Member" && (
                <Button
                  className="button-md gap-1 justify-content-center w-full md:w-auto"
                  onClick={() => navigate("/member/create")}
                >
                  <FeatherIcon name="plus" size={20} color="inherit" />
                  <span className="font-bold">
                    {t("report.export")}
                  </span>
                </Button>
              )}

            </div>
          </div>

          <div ref={tableContainerRef}>
            <DataTable className={tableClass} value={requestList} showGridlines expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
              onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id" tableStyle={{ minWidth: '60rem' }}>
              <Column expander={allowExpansion} style={{ width: '5rem' }} header="#" />
              <Column field="CommitteeName" header={t("report.committeeName")} body={(rowData) => (
                <>
                  <span className="p-column-title">{t("report.committeeName")}</span>
                  {rowData.CommitteeName}
                </>
              )} />
              <Column field="TotalMembers" header={t("report.totalMembers")} body={(rowData) => (
                <>
                  <span className="p-column-title">{t("report.totalMembers")}</span>
                  {rowData.TotalMembers}
                </>
              )} />
              <Column field="CommitteeBalance" header={t("report.committeeBalance")} body={(rowData) => (
                <>
                  <span className="p-column-title">{t("report.committeeBalance")}</span>
                  {rowData.CommitteeBalance}
                </>
              )} />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceOfInterest;
