import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
interface CommentSectionProps {
  index: number;
  votingData: any;
  commentTypeOptions: any[];
  disapproveId?: string;
  selectedVoteOptionId?: string;
  handleCommentChange: (index: number, field: string, value: any) => void;
  handleSubmitComment: (data: any, index: number) => void;
  resetCommentFields: (index: number) => void;
  IsDisable: boolean;
}

const VotingCommentSection: React.FC<CommentSectionProps> = ({
  index,
  votingData,
  commentTypeOptions,
  handleCommentChange,
  handleSubmitComment,
  resetCommentFields,
  disapproveId = "",
  selectedVoteOptionId = "",
  IsDisable = false,
}) => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const [commentTypeData, setCommentTypeData] =
    useState<{ label: string; value: string }[]>(commentTypeOptions);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };

  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const submitData = () => {
    const fields = [
      "paragraphSection",
      "commentType",
      "comment",
      "proposedAction",
    ];
    const commentData = fields.reduce((data: any, field: string) => {
      data[field] = votingData[index]?.[field] || "";
      return data;
    }, {});
    handleSubmitComment(commentData, index);
  };

  useEffect(() => {
    const reasonForDisapprovalLabel = "Reason for disapproval";
    if (selectedVoteOptionId !== disapproveId) {
      // Filter out "Reason for disapproval" when vote option is disapprove
      setCommentTypeData(
        commentTypeOptions.filter(
          (item) => item.label !== reasonForDisapprovalLabel
        )
      );
    } else {
      // Set all options when vote option is not disapprove
      setCommentTypeData(commentTypeOptions);
    }
  }, [selectedVoteOptionId, disapproveId, commentTypeOptions]);
  return (
    <div className="flex flex-column gap-3">
      <fieldset className="border-none p-0">
        <legend className="block font-bold text-input-label p-0 mb-3">
          {t("ballot.comments")}
        </legend>
        <div className="p-3 lg:p-5 cardBody flex flex-column gap-4 surface-100">
          <div className="flex flex-column gap-2">
            <label
              htmlFor={`paragraphSection${index}`}
              className="block font-bold text-capitalize text-input-label"
            >
              {t("ballot.paragraphSection")}
            </label>
            <InputTextarea
              id={`paragraphSection${index}`}
              name="paragraphSection"
              placeholder={t("ballot.paragraphSection")}
              value={votingData[index]?.paragraphSection || ""}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleCommentChange(
                  index,
                  "paragraphSection",
                  e.target.value.slice(0, 500)
                )
              }
              rows={3}
              cols={50}
              disabled={IsDisable}
            />
            <small>
              {(votingData[index]?.paragraphSection || "").length}/500
              characters
            </small>
          </div>
          <div className="flex flex-column gap-2">
            <label
              htmlFor={`typeOfComment${index}`}
              className="block font-bold text-capitalize text-input-label"
            >
              {t("ballot.typeOfComment")}
            </label>
            <Dropdown
              inputId={`typeOfComment${index}`}
              name="typeOfComment"
              value={votingData[index]?.commentType || ""}
              onChange={(e) =>
                handleCommentChange(index, "commentType", e.value)
              }
              options={commentTypeData}
              optionLabel="label"
              placeholder="Select an Option"
              disabled={IsDisable}
            />
          </div>
          <div className="flex flex-column gap-2">
            <label
              htmlFor={`comment${index}`}
              className="block font-bold text-capitalize text-input-label"
            >
              {t("ballot.comment")}
            </label>
            <InputTextarea
              id={`comment${index}`}
              name="charterName"
              placeholder={t("ballot.comment")}
              value={votingData[index]?.comment || ""}
              onChange={(e) =>
                handleCommentChange(
                  index,
                  "comment",
                  e.target.value.slice(0, 500)
                )
              }
              rows={3}
              cols={50}
              disabled={IsDisable}
            />
            <small>
              {(votingData[index]?.comment || "").length}/500 characters
            </small>
          </div>
          <div className="flex flex-column gap-2">
            <label
              htmlFor={`proposedAction${index}`}
              className="block font-bold text-capitalize text-input-label"
            >
              {t("ballot.proposedAction")}
            </label>
            <InputTextarea
              id={`proposedAction${index}`}
              name="charterName"
              placeholder={t("ballot.proposedAction")}
              value={votingData[index]?.proposedAction || ""}
              onChange={(e) =>
                handleCommentChange(
                  index,
                  "proposedAction",
                  e.target.value.slice(0, 500)
                )
              }
              rows={3}
              cols={50}
              disabled={IsDisable}
            />
            <small>
              {(votingData[index]?.proposedAction || "").length}/500 characters
            </small>
          </div>
          <div>
            <Button text className="p-button-plain gap-2 pl-0 underline">
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("ballot.uploadDocument")}
              </span>
            </Button>
          </div>
          <div className="w-full flex gap-3 justify-content-end align-items-center">
            <div className="relative">
              <Button
                className="button-md gap-1"
                severity="secondary"
                disabled={
                  IsDisable || // Disable if voting is disabled for this record
                  (!selectedVoteOptionId && !votingData[index]?.votingOption) // Disable if no vote option is selected
                }
                onClick={() => resetCommentFields(index)}
              >
                <span className="font-bold text-capitalize">
                  {t("ballot.discardChanges")}
                </span>
              </Button>
            </div>
            <Button
              className="button-md gap-1 justify-content-center w-full md:w-auto"
              disabled={
                IsDisable || // Disable if voting is disabled for this record
                (!selectedVoteOptionId && !votingData[index]?.votingOption) // Disable if no vote option is selected
              }
              onClick={submitData}
            >
              <span className="font-bold text-capitalize">
                {t("ballot.submitComment")}
              </span>
            </Button>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default VotingCommentSection;
