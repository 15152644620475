// Ballot Status
export const BALLOT_CLOSE = "Closed";
export const BALLOT_OPEN = "Open";
export const BALLOT_OUT_FOR_BALLOT = "Out for Ballot";
export const BALLOT_DRAFT = "Draft";
export const BALLOT_CANCELLED = "Cancelled";
export const BALLOT_CLOSED = "closed";
// Ballot Record Status
export const BALLOT_RECORD_CLOSE = "Closed";
export const BALLOT_RECORD_OPEN = "Open";
export const BALLOT_RECORD_OUT_FOR_BALLOT = "Out for ballot";
export const BALLOT_RECORD_OUT_FOR_BALLOT_CLOSE = "Out for Ballot";
export const BALLOT_RECORD_DRAFT = "Draft";

export const MEMBERSHIP_PROFILE_KEY = "loggedinuserprofileid";
export const BALLOT_STATUS_KEY = "ballotstatusid";

export const BALLOT_RECIRCULATION = "Recirculation";

export const BALLOT_REVIEW_AND_COMMENT = "Review and Comments";

export const GUID_PlACEHOLDER = "00000000-0000-0000-0000-000000000000";

export const RECORD_REOPEN = "Reopen";
export const RECORD_APPROVED_PENDING_MEMBERSHIP =
  "Approved - Pending Grant Membership";
export const RECORD_APPROVED = "Approved";
export const RECORD_REJECTED = "Rejected";
