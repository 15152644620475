import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import FeatherIcon from "../../common/FeatherIconComponent";

interface BallotConfigurationWeightBallotProps {
}

const BallotConfigurationWeighted: React.FC<BallotConfigurationWeightBallotProps> = ({

}) => {
  const [t, i18n] = useTranslation("ballot");
  return (
    <>
      <div>
        <h3 className="font-bold text-lg m-0">
          {t("configuration.rankingPoints")}
        </h3>
        <div className="flex flex-column gap-2 w-full">
          <div className="flex flex-column gap-2">
            <div className="grid grid-xl mt-0">
              <span
                className="flex xl:col-3 lg:col-4 md:col-5 col-5 py-1 font-bold text-capitalize"
                id="rank"
              >
                {t("configuration.rank")}
              </span>
              <span
                className="flex xl:col-3 lg:col-3 md:col-5 col-5 py-1 font-bold text-capitalize"
                id="points"
              >
                {t("configuration.points")}
              </span>
            </div>
            <div className="flex flex-column w-full">
              <div className="grid grid-xl align-items-center idx">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                  <Dropdown
                    inputId="rank-0"
                    name="rank-0"
                    optionLabel="name"
                    placeholder={t(
                      "configuration.selectAnOption"
                    )}
                    aria-labelledby="rank"
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                  <InputText
                    id="points-0"
                    name="points-0"
                    type="number"
                    placeholder=""
                    className="w-full"
                  />
                </div>
                <div className="w-auto p-0">
                  <Button
                    text
                    className="p-button-plain p-2 text-delete"
                    aria-label={t("configuration.delete")}
                  >
                    <FeatherIcon name="trash-2" size={20} />
                  </Button>
                </div>
              </div>
              <div className="grid grid-xl align-items-center idx">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                  <Dropdown
                    inputId="rank-1"
                    name="rank-1"
                    optionLabel="name"
                    placeholder={t(
                      "configuration.selectAnOption"
                    )}
                    aria-labelledby="rank"
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-5 col-5">
                  <InputText
                    id="points-1"
                    name="points-1"
                    type="number"
                    placeholder=""
                    className="w-full"
                  />
                </div>
                <div className="w-auto p-0">
                  <Button
                    text
                    className="p-button-plain p-2 text-delete"
                    aria-label={t("configuration.delete")}
                  >
                    <FeatherIcon name="trash-2" size={20} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              text
              className="p-button-plain gap-1 pl-0 underline"
            >
              <FeatherIcon name="plus" size={20} />
              <span className="font-bold text-capitalize">
                {t("configuration.addRank")}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BallotConfigurationWeighted;
